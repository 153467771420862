<template>
    <li class="c-nav-main-right">
        <navigation-main-right-list />
        <navigation-main-right-mobile />
    </li>
</template>

<script>
import NavigationMainRightList from "@/templates/components/Navigation/NavigationMain/NavigationMainRight/NavigationMainRightList";
import NavigationMainRightMobile from "@/templates/components/Navigation/NavigationMain/NavigationMainRight/NavigationMainRightMobile";
export default {
    components: {
        NavigationMainRightList,
        NavigationMainRightMobile
    }
};
</script>
<style lang="scss" scoped>
.c-nav-main-right {
    opacity: 0;
    grid-column: 3/4;
    @include transform(translateY(-5rem));
    @media #{md("xs")} {
        @include transform(translateY(0rem));
    }
}
</style>
