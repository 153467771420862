<template>
    <section class="c-story-article-overlap">
        <!-- text -->
        <stories-article-overlap-copy v-if="copy" :copy="copy" @goToNextSection="goToNextSection" />
        <!-- image -->
        <stories-article-overlap-img :image="image" />
    </section>
</template>

<script>
import { mapState } from "vuex";

import StoriesArticleOverlapCopy from "@/templates/components/Stories/StoriesArticle/StoriesArticleOverlap/StoriesArticleOverlapCopy/StoriesArticleOverlapCopy";
import StoriesArticleOverlapImg from "@/templates/components/Stories/StoriesArticle/StoriesArticleOverlap/StoriesArticleOverlapImg/StoriesArticleOverlapImg";
export default {
    components: {
        StoriesArticleOverlapCopy,
        StoriesArticleOverlapImg
    },
    computed: {
        ...mapState({
            currentEntry: state => state.global.currentEntry
        }),
        copy() {
            return this.currentEntry.sequencesText[0].body;
        },
        image() {
            return this.currentEntry.image;
        }
    },
    methods: {
        ////////////////////////////////
        //       START GO TO NEXT SECTION
        ////////////////////////////////
        goToNextSection() {
            this.$emit("goToNextSection");
        }
        ////////////////////////////////
        //       END GO TO NEXT SECTION
        ////////////////////////////////
    }
};
</script>

<style lang="scss" scoped>
.c-story-article-overlap {
    display: flex;
    flex-direction: column;
    flex: 1;

    position: static;
}
</style>
