<template>
    <div class="c-credit-cta-line">
        <div class="c-credit-cta-line__inner">
            <shared-tips-line :is-vertical="true" :is-center-overwrite="true" />
        </div>
    </div>
</template>

<script>
import SharedTipsLine from "@/templates/components/Shared/SharedTips/SharedTipsLine";
export default {
    components: {
        SharedTipsLine
    }
};
</script>

<style lang="scss" scoped>
.c-credit-cta-line {
    display: flex;
    justify-content: center;
    grid-row: 4/6;
    flex: 1;
    &__inner {
        height: 100%;
        position: relative;
        max-height: 28rem;
    }
}
</style>
