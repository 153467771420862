<template>
    <li class="c-shared-tips-manager">
        <!-- info -->
        <shared-tips-info :tips="tips" :is-solo-credit="false" :is-vertical="isVertical" :is-credit="isCredit" />
        <!-- lines -->
        <shared-tips-line
            v-if="!isLast"
            :is-credit="isCredit"
            :is-vertical="isVertical"
            :is-line-purple="isLinePurple"
        />
    </li>
</template>

<script>
import SharedTipsInfo from "@/templates/components/Shared/SharedTips/SharedTipsInfo/SharedTipsInfo";
import SharedTipsLine from "@/templates/components/Shared/SharedTips/SharedTipsLine";
export default {
    components: {
        SharedTipsInfo,
        SharedTipsLine
    },
    props: {
        tips: {
            type: Object,
            required: false,
            default: null
        },
        itemIndex: {
            type: Number,
            required: false,
            default: 0
        },
        isCredit: {
            type: Boolean,
            required: false,
            default: false
        },
        isVertical: {
            type: Boolean,
            required: false,
            default: false
        },
        isLast: {
            type: Boolean,
            required: false,
            default: false
        },
        isLinePurple: {
            type: Boolean,
            required: false,
            default: false
        }
    }
};
</script>

<style lang="scss" scoped>
.c-shared-tips-manager {
    display: flex;
    flex: 1;
    flex-direction: row;
    position: relative;
    justify-content: center;
    height: 100%;
    min-width: 100px;
}
</style>
