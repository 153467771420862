<template>
    <div
        class="c-neighbourhood"
        :class="{
            'c-neighbourhood--blend': goToSiblingUrl || isReturningHomeFromStory,
            'c-neighbourhood--hidden': !isExperienceVisible
        }"
    >
        <neighbourhood-scene
            v-if="gltf && gltfPath"
            :labels="labels"
            :gltf-path="gltfPath"
            :gltf-rotation="gltf.rotation"
            :frustum="gltf.frustum"
            :is-story-visible="isStory"
            :map-control-limits="gltf.mapControlLimits"
            :total-stars="totalStars"
            @toggleExperienceVisibility="toggleExperienceVisibility"
        />
        <neighbourhood-labels v-if="gltf && gltfPath" :labels="labels" />
        <neighbourhood-tips :isStory="isStory" />
        <neighbourhood-story v-if="isStory" />
    </div>
</template>

<script>
import { mapState } from "vuex";

import NeighbourhoodLabels from "@/templates/components/Neighbourhood/NeighbourhoodLabels/NeighbourhoodLabels";
import NeighbourhoodScene from "@/templates/components/Neighbourhood/Neighbourhood/NeighbourhoodScene";
import NeighbourhoodStory from "@/templates/components/Neighbourhood/NeighbourhoodStory/NeighbourhoodStory";
import NeighbourhoodTips from "@/templates/components/Neighbourhood/NeighbourhoodTips/NeighbourhoodTips";

export default {
    components: {
        NeighbourhoodLabels,
        NeighbourhoodScene,
        NeighbourhoodStory,
        NeighbourhoodTips
    },
    props: {
        // I think path will from the DB. If not, get the path from here
        gltf: {
            type: Object,
            required: true
        },
        labels: {
            type: Array,
            required: true
        },
        totalStars: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            timelines: {
                onLeaveFromStoryToHome: null
            },
            isReturningHomeFromStory: false,
            gltfPath: null,
            isExperienceVisible: true // avoid some flickering on the prod server
        };
    },

    mounted() {
        this.$nextTick(() => {
            this.setDistrict();
        });
    },

    computed: {
        ...mapState({
            currentEntry: state => state.global.currentEntry,
            audio: state => state.global.currentEntry.audio,
            model: state => state.global.currentEntry.model,
            goToSiblingUrl: state => state.constellations.goToSiblingUrl
        }),
        isStory() {
            return this.currentEntry.type && this.currentEntry.type !== "district";
        }
    },

    watch: {
        audio() {
            this.setDistrict();
        }
    },
    methods: {
        updateAudioTrack() {
            // user landed on this page either on the map either on a story
            // if the user land on a story audio, the value in global.currentEntry.audio is the one from the story, not from the district
            // So if the user land on an story, fetch the audio from the district instead

            this.audio && this.audio.url ? this.whichAudioTrack() : null;
        },
        whichAudioTrack() {
            this.isStory ? this.getDistrictAudio() : this.updateAudioGlobally(this.audio.url);
        },

        getDistrictAudio() {
            this.$store.dispatch("constellations/getDistrictAudio", this.currentEntry.district.slug).then(result => {
                // console.log("result", result);
                result.audio.length ? this.updateAudioGlobally(result.audio[0].url) : null;
            });
        },

        setDistrict() {
            // if this is a story so we need to fetch the API
            // if its not a story so we can get the value directly from the store
            this.isStory ? this.fetchDistrictInfoFromAPI() : this.setAudioAndModelFromStore();
        },

        fetchDistrictInfoFromAPI() {
            this.$store.dispatch("constellations/getDistrictInfo", this.currentEntry.district.slug).then(result => {
                // console.log("result", result);
                this.updateInfoFetchedFromAPI(result);
            });
        },
        updateInfoFetchedFromAPI(result) {
            // audio
            result.audio.length ? this.updateAudioGlobally(result.audio[0].url) : null;
            //  gltf
            result.model.length ? this.updateGLTFPath(result.model[0].url) : null;
        },
        setAudioAndModelFromStore() {
            this.audio && this.audio.url ? this.updateAudioGlobally(this.audio.url) : null;
            this.model && this.model.url ? this.updateGLTFPath(this.model.url) : null;
        },
        updateGLTFPath(path) {
            // set model
            path ? (this.gltfPath = path) : null;
        },

        updateAudioGlobally(url) {
            this.$store.commit("global/updateUpComingTrack", url);
        },

        ////////////////////////////////
        //       START OVERWRITE SCENE VISIBLILTY
        ////////////////////////////////
        toggleExperienceVisibility(bool) {
            this.isExperienceVisible = bool;
        }
        ////////////////////////////////
        //       END OVERWRITE SCENE VISIBLILTY
        ////////////////////////////////
    }
};
</script>

<style lang="scss" scoped>
.c-neighbourhood {
    --opacity-blend: 0;
    @include fullHeightMobile;
    position: relative;
    width: 100vw;

    &:before {
        // blend used for story transition
        @include fullWidthFixed(fixed);
        @include transition(0.3s ease all);
        content: " ";
        background-color: rgba(28, 12, 46, var(--opacity-blend));
        pointer-events: none;
        z-index: 2;
    }
    &--blend {
        --opacity-blend: 1;
    }
}
</style>
