<template>
    <div class="c-home">
        <home-intro v-if="!isExperienceStarted && isNewVisit" @startExperience="startExperiencePage" />
        <home-navigation />
        <home-scene />
    </div>
</template>

<script>
import { mapState } from "vuex";

import HomeIntro from "components/Home/HomeIntro/HomeIntro";
import HomeNavigation from "components/Home/HomeNavigation/HomeNavigation";
import HomeScene from "components/Home/HomeScene/HomeScene";
export default {
    components: {
        HomeIntro,
        HomeScene,
        HomeNavigation
    },
    data() {
        return {};
    },
    computed: {
        ...mapState({
            isExperienceStarted: state => state.home.isExperienceStarted,
            isNewVisit: state => state.home.isNewVisit,
            audio: state => state.global.settings.audio
        })
    },

    mounted() {
        this.$nextTick(() => {
            this.userReturingToHomePage();
            this.setAudio();
        });
    },
    beforeDestroy() {
        this.updateValueGlobally(false);
    },
    watch: {
        audio() {
            this.setAudio();
        }
    },

    methods: {
        // 2 path:
        // 1) The user landed on the website, so close the loader on the homepage and then launch the animation
        // 2) The user came back to the homepage, so don't show the loader page and trigger the animation of the slider + the can
        userReturingToHomePage() {
            this.isNewVisit ? null : this.startExperiencePage();
        },
        startExperiencePage() {
            this.updateValueGlobally(true);
        },
        updateValueGlobally(bool) {
            //  most of the animations on the intro are managed onn home intro
            this.$store.commit("home/toggleStartExperience", bool);
        },
        ////////////////////////////////
        //       START AUDIO
        ////////////////////////////////
        setAudio() {
            this.audio && this.audio.url ? this.$store.commit("global/updateUpComingTrack", this.audio.url) : null;
        }

        ////////////////////////////////
        //       END AUDIO
        ////////////////////////////////
    }
};
</script>

<style lang="scss" scoped>
.c-home {
    position: relative;
    /* min-height: 100vh; */
    &:before {
        @include sideShadow();
        @include rotate(180deg);
        left: 0;
    }
    &:after {
        @include sideShadow();
        right: 0;
    }
}
</style>
