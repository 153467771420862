<template>
    <div class="c-stories-article-illustrated-main-img-side">
        <ul class="c-stories-article-illustrated-main-img-side__list">
            <!-- list -->
            <stories-article-illustrated-main-img-asset
                v-for="(sequence, index) in sequences"
                :image="sequence.image"
                :key="index"
                :index-img="index"
                :is-visible="isImageVisible(index)"
            />
        </ul>
        <stories-article-illustrated-main-img-side-corners />
    </div>
</template>

<script>
import StoriesArticleIllustratedMainImgAsset from "@/templates/components/Stories/StoriesArticle/StoriesArticleIllustrated/StoriesArticleIllustratedMain/StoriesArticleIllustratedMainImg/StoriesArticleIllustratedMainImgAsset";
import StoriesArticleIllustratedMainImgSideCorners from "@/templates/components/Stories/StoriesArticle/StoriesArticleIllustrated/StoriesArticleIllustratedMain/StoriesArticleIllustratedMainImg/StoriesArticleIllustratedMainImgSide/StoriesArticleIllustratedMainImgSideCorners";
export default {
    components: {
        StoriesArticleIllustratedMainImgAsset,
        StoriesArticleIllustratedMainImgSideCorners
    },
    props: {
        sequences: {
            type: Array,
            required: false,
            default: () => []
        },
        activeStep: {
            type: Number,
            required: false,
            default: 0
        }
    },
    methods: {
        isImageVisible(index) {
            return this.activeStep >= index;
        }
    }
};
</script>

<style lang="scss" scoped>
.c-stories-article-illustrated-main-img-side {
    @include transform(translateY(200px)); // intro animation
    opacity: 0; //intro animation
    --padding-side-img: 0px;
    padding: var(--padding-side-img);
    position: relative;
    @media #{md('xs')} {
        --padding-side-img: var(--grid-gutter-half);
        max-height: 50vh;
        height: auto;
    }
    // @media screen and (min-width: 992px), screen and (max-height: 860px) {
    //     height: 100%;
    // }
    @media (max-height: 860px) {
        @media #{md('xs')} {
            height: 100%;
        }
    }

    &__list {
        @include reset-list;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        // justify-content: flex-start;
        height: auto;
        overflow: hidden;

        // @media screen and (min-width: 992px), screen and (max-height: 860px) {
        //     height: 100%;
        // }
        @media (max-height: 860px) {
            @media #{md('xs')} {
                height: 100%;
            }
        }
    }
}
</style>
