<template>
    <div
        class="c-neighbourhood-nav-btn-map-circle-progress"
        :class="{ 'c-neighbourhood-nav-btn-map-circle-progress--ease': isScrollProgressEased }"
        :style="`--rotation-progress: ${rotationProgress(overwriteProgress)}deg; --progress: ${calculateProgress}`"
    >
        <svg
            class="c-neighbourhood-nav-btn-map-circle-progress__circle c-neighbourhood-nav-btn-map-circle-progress__circle--desktop"
            width="80"
            height="80"
            viewBox="0 0 80 80"
        >
            <circle
                class="c-neighbourhood-nav-btn-map-circle-progress__circle__progress"
                cx="40"
                cy="40"
                r="39.5"
                fill="none"
                stroke-width="1"
                pathLength="100"
            />
        </svg>
        <svg
            class="c-neighbourhood-nav-btn-map-circle-progress__circle c-neighbourhood-nav-btn-map-circle-progress__circle--mobile"
            width="60"
            height="60"
            viewBox="0 0 60 60"
        >
            <circle
                class="c-neighbourhood-nav-btn-map-circle-progress__circle__progress"
                cx="30"
                cy="30"
                r="29.5"
                fill="none"
                stroke-width="1"
                pathLength="100"
            />
        </svg>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState({
            scrollProgressStory: state => state.constellations.scrollProgressStory,
            isScrollProgressEased: state => state.constellations.isScrollProgressEased
        }),
        overwriteProgress() {
            // progress always starts from 20%
            return 20 + (80 * this.scrollProgressStory) / 100;
        },
        calculateProgress() {
            return 100 - this.overwriteProgress;
        }
    },
    methods: {
        rotationProgress(progress) {
            return -(180 * (progress / 100));
        }
    }
};
</script>

<style lang="scss" scoped>
.c-neighbourhood-nav-btn-map-circle-progress {
    --rotation-progress: 0;
    --progress: 0;
    --ease-duration: 0s;

    @include transform(rotate(-90deg));
    aspect-ratio: 1 / 1;
    width: 6rem;
    height: 6rem;

    @media #{md("xs")} {
        width: 8rem;
        height: 8rem;
    }

    &--ease {
        --ease-duration: 0.4s;
    }

    &__circle {
        @include transition(var(--ease-duration) ease all);
        @include transform(rotate(var(--rotation-progress)));
        stroke: var(--color-light);
        &--mobile {
            display: flex;
            @media #{md("xs")} {
                display: none;
            }
        }
        &--desktop {
            display: none;
            @media #{md("xs")} {
                display: flex;
            }
        }
        &__progress {
            @include transition(var(--ease-duration) ease all);
            stroke-dasharray: 100;
            stroke-dashoffset: var(--progress);
            // stroke-dashoffset: calc(100 - 30);
        }
    }
}

//  @include transition(1.5s ease all);
//             width: 100%;
//             aspect-ratio: 1 / 1;
//             stroke: var(--color-light);
//             opacity: 0;
</style>
