import getAsset from "./assets";

/*
 * Craft GraphQL Snippets
 */

/**********************************
 *
 *
 *  DEFAULTS
 *
 *
 **********************************/

// Default data
const defaultData = `
id
uri
`;

// Default entry
const entry = `
id
section: sectionHandle
title
slug
uri
`;

// Default category
const category = `
id
slug
title
`;

/**********************************
 *
 *
 *  ATOMS
 *
 *
 **********************************/

// Children
const children = `
children {
    ${entry}
}
`;

// SEO
const seo = `
seo {
    title
    description
}
`;

// Map (SuperTable)
const map = `
map {
    ...on map_BlockType {
        lng
        lat
    }
}
`;

// Sequences
const sequences = `
sequences {
    ...on sequences_sequence_BlockType {
        body
        ${getAsset("image")}
    }
}
`;
// Sequences Text
const sequencesText = `
sequencesText {
    ...on sequencesText_sequence_BlockType {
        body
    }
}
`;

// Sequences TitleText
const sequencesCredits = `
sequencesCredits {
    ...on sequencesCredits_sequence_BlockType {
        heading,
        body,
        ${getAsset("image")}
    }
}
`;

/**********************************
 *
 *
 *  ASSET
 *
 *
 **********************************/

const audio = `
audio {
    id
    url
}
`;

const model = `
model {
    id
    url
}
`;

/**********************************
 *
 *
 *  RELATIONS
 *
 *
 **********************************/

// Shortcut for parent District, not exposed
const relatedDistrict = `
district: ancestors (level: 1) {
    ${entry}
}
`;

// Shortcut for parent Constellation, not exposed
const relatedConstellation = `
constellation: parent {
    ${entry}
}
`;

/**********************************
 *
 *
 *  BUILDER
 *
 *
 **********************************/

/*


    Note / 29 sep 2021

    ML: J"ai commenté l'entièreté du builder pour ne pas avoir de warning; SI on ne s'en ai pas servi rendu à la toute fin, on pourra enlever les snippets ET le champs dans le panel

*/

// // Accordions
// const blockAccordions = `
// ...on layout_BlockAccordions_BlockType {
//     typeHandle
//     openFirst: trueOrFalse1
//     accordions: children {
//         ...on layout_accordion_BlockType {
//             heading
//             content: body
//         }
//     }
// }
// `
//
// // Content
// const blockContent = `
// ...on layout_BlockContent_BlockType {
//     typeHandle
//     content: body
// }
// `
//
// // Heading
// const blockHeading = `
// ...on layout_BlockHeading_BlockType {
//     typeHandle
//     heading
// }
// `
//
// // Gallery
// const blockGallery = `
// ...on layout_BlockGallery_BlockType {
//     typeHandle
//     ${getAsset('images')}
// }
// `
//
// // Image
// const blockImage = `
// ...on layout_BlockImage_BlockType {
//     typeHandle
//     ${getAsset()}
// }
// `
//
// // Spacer
// const blockSpacer = `
// ...on layout_BlockSpacer_BlockType {
//     typeHandle
// }
// `
//
// // Section
// const blockSection = `
// ...on layout_BlockSection_BlockType {
//     typeHandle
//     id
//     label
//     displayLabel: trueOrFalse1
//     children {
//         ${blockAccordions}
//         ${blockContent}
//         ${blockHeading}
//         ${blockGallery}
//         ${blockImage}
//         ${blockSpacer}
//     }
// }
// `
//
// // Layout
// const layout = `
// sections: layout {
//     ${blockSection}
// }
// `

/**********************************
 *
 *
 *  CHANNELS AND STRUCTURES
 *
 *
 **********************************/

const district = `
...on constellations_district_Entry {
    ${audio}
    ${model}
    totalStars: label
    constellations: ${children}
    ${seo}
}
`;

const constellation = `
...on constellations_constellation_Entry {
    ${relatedDistrict}
    stories: ${children}
    ${seo}
}
`;
const storyText = `
...on constellations_storyText_Entry {
    writer
    illustrator
    template
    lightMode: trueOrFalse1
    textOnLeft: trueOrFalse2
    ${relatedConstellation}
    ${relatedDistrict}

    # Template 1 (side)
    ${sequences}

    # Template 2 (overlap)
    ${sequencesText}
    ${getAsset()}

    ${seo}
}
`;

const storyAudio = `
...on constellations_storyAudio_Entry {
    ${relatedConstellation}
    ${relatedDistrict}
    writer
    narrator
    ${audio}
    lightMode: trueOrFalse1
    ${seo}
}
`;

const storyTextAuthors = `
...on constellations_storyText_Entry {
    writer,
    illustrator
}
`;
const storyAudioAuthors = `
...on constellations_storyAudio_Entry {
    writer,
    narrator
}
`;

const creditSequence = `
...on credits_credits_Entry {
    ${sequencesCredits}
}
`;
// ${getAsset()}

// Exports
export default {
    // DEFAULTS
    defaultData,
    entry,
    category,

    // ATOMS
    seo,
    map,

    // ASSETS
    audio,
    model,

    // CHANNELS AND STRUCTURES
    district,
    constellation,
    storyText,
    storyAudio,
    storyTextAuthors,
    storyAudioAuthors,
    creditSequence
};
