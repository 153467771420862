<template>
    <li class="c-home-navigation-control-buttons-items">
        <btn
            :label="convertToRoman(itemNumber)"
            :circle="true"
            typo="serif"
            :is-parent-handle-states="true"
            :is-active="isActive"
            @clicked="clicked"
        />
    </li>
</template>

<script>
import Btn from "@/templates/components/Shared/SharedBtn/SharedBtn";
import convertorMixin from "@/mixins/convertorMixin.js";
export default {
    mixins: [convertorMixin],
    components: {
        Btn
    },
    props: {
        item: {
            type: Object,
            required: true
        },
        itemIndex: {
            type: Number,
            required: false,
            default: 0
        },
        isActive: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {};
    },
    computed: {
        itemNumber() {
            return this.itemIndex + 1;
        }
    },
    beforeDestoy() {
        this.resetStoreIndex();
    },
    methods: {
        clicked() {
            this.udpateCurentSlideGlobaly();
        },
        udpateCurentSlideGlobaly() {
            this.$store.commit("home/updateCurrent", { value: this.itemIndex });
        },
        ////////////////////////////////
        //       START BEFORE DESTROY
        ////////////////////////////////
        resetStoreIndex() {
            // this.$store.commit("home/updateCurrent", { value: 0 });
        }
        ////////////////////////////////
        //       END BEFORE DESTROY
        ////////////////////////////////
    }
};
</script>

<style lang="scss" scoped>
.c-home-navigation-control-buttons-items {
    @include transform(translate(0px, 50px)); // animation intro
    opacity: 0;
}
</style>
