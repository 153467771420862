<template>
    <div class="c-navigation-list" :class="{ 'c-navigation-list--mobile': isMobile }">
        <btn
            v-for="(item, index) in navList(nav)"
            :key="index"
            tag="button"
            :url="item.url"
            :title="item.entry.title"
            :label="item.label ? item.label : item.entry.title"
            class="c-navigation-list__link"
            :class="[`c-navigation-list__link--${index}`, 'c-navigation-list__link--mobile', isMobile]"
            exact
            itemprop="url"
            :is-filled-by-default="false"
            :is-parent-handle-states="true"
            :is-active="isActive(item.url)"
            @clicked="clicked"
        />
    </div>
</template>

<script>
import { mapState } from "vuex";
import Btn from "@/templates/components/Shared/SharedBtn/SharedBtn";
export default {
    components: {
        Btn
    },
    props: {
        isMobile: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        ...mapState({
            nav: state => state.global.navs,
            currentEntryUri: state => state.global.currentEntry.uri,
            transitionOutUrl: state => state.global.transitionOutUrl
        })
    },
    methods: {
        navList(navigtionItems) {
            // Temporary
            return navigtionItems.filter(nav => {
                return nav.url != "/" && nav.url != "/montcalm";
            });
        },
        isActive(url) {
            return this.$route.path === url || this.transitionOutUrl === url;
        },
        clicked(url) {
            this.$store.commit("global/updatePageTransitionOutUrl", url);
            this.toggleConstellationHeader(false);
        },
        toggleConstellationHeader(bool) {
            this.$store.commit("constellations/toggleConstellationHeaderVisibility", bool);
        }
    }
};
</script>

<style lang="scss" scoped>
.c-navigation-list {
    display: grid;
    grid-auto-flow: row;
    gap: var(--grid-gutter);

    @media #{md('xs')} {
        grid-auto-flow: column;
        justify-content: flex-end;
    }

    &__link {
        padding: var(--grid-gutter-half) 0;
        justify-content: center;
        @media #{md('xs')} {
            justify-content: flex-end;
        }
    }
}
</style>
