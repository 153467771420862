<template>
    <div
        class="c-shared-tips-line"
        :class="{
            'c-shared-tips-line--credit': isCredit,
            'c-shared-tips-line--vertical': isVertical,
            'c-shared-tips-line--centered': isCenterOverwrite,
            'c-shared-tips-line--purple': isLinePurple
        }"
    >
        <div class="c-shared-tips-line__inner"></div>
    </div>
</template>

<script>
export default {
    props: {
        isCredit: {
            type: Boolean,
            required: false,
            default: false
        },
        isVertical: {
            type: Boolean,
            required: false,
            default: false
        },
        isCenterOverwrite: {
            type: Boolean,
            required: false,
            default: false
        },
        isLinePurple: {
            type: Boolean,
            required: false,
            default: false
        }
    }
};
</script>

<style lang="scss" scoped>
.c-shared-tips-line {
    --width: 0%;
    --rotation: 90deg;
    --opacity-line: 1;
    --fill-line: 252, 239, 239; //color of the line

    position: relative;
    display: flex;

    @media #{md('xs')} {
        left: var(--grid-gutter);
    }

    &--vertical {
        position: absolute;
        height: 100%;
        right: 0%;
        left: auto;
        right: calc(0px - var(--grid-gutter-half));
        @media #{md('xs')} {
            right: calc(0px - var(--grid-gutter));
        }
    }
    &--centered {
        left: auto;
        right: auto;
    }

    &--purple {
        --fill-line: 169, 160, 187;
        --opacity-line: 0.5;
    }

    &__inner {
        position: relative;
        display: flex;

        .c-shared-tips-line--credit & {
            margin-bottom: calc(0px - var(--grid-gutter));
        }

        .-debug & {
            --width: 100%;
        }

        &:after {
            content: " ";
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            width: var(--width);
            height: 1px;

            background: rgb(var(--fill-line));
            background: linear-gradient(var(--rotation), rgba(var(--fill-line), 0) 0%, rgba(var(--fill-line), 1) 100%);
            opacity: var(--opacity-line);

            @media #{md('xs')} {
                width: 1px;
                height: var(--width);
                --rotation: 0deg;
            }
            .c-shared-tips-line--credit & {
                background: rgb(var(--fill-line));
            }
            .c-shared-tips-line--vertical & {
                width: 1px;
                height: var(--width);
            }
        }
    }
}
</style>
