<template>
    <li class="c-stories-article-illustrated-main">
        <ul class="c-stories-article-illustrated-main__inner">
            <!-- copy component -->
            <stories-article-illustrated-main-copy
                :is-scroll-disabled="isScrollDisabled"
                :position="positionCopy"
                :sequences="sequences"
                :sequencesText="sequences"
                @activeStep="emitActiveStep"
                @goToNextSection="goToNextSection"
            />

            <!-- img component -->
            <stories-article-illustrated-main-img
                :position="positionCopy"
                :active-step="activeStep"
                :sequences="sequences"
            />
        </ul>
    </li>
</template>

<script>
import StoriesArticleIllustratedMainCopy from "@/templates/components/Stories/StoriesArticle/StoriesArticleIllustrated/StoriesArticleIllustratedMain/StoriesArticleIllustratedMainCopy/StoriesArticleIllustratedMainCopy";
import StoriesArticleIllustratedMainImg from "@/templates/components/Stories/StoriesArticle/StoriesArticleIllustrated/StoriesArticleIllustratedMain/StoriesArticleIllustratedMainImg/StoriesArticleIllustratedMainImg";
export default {
    components: {
        StoriesArticleIllustratedMainCopy,
        StoriesArticleIllustratedMainImg
    },
    props: {
        activeStep: {
            type: Number,
            required: false,
            default: 0
        },

        positionCopy: {
            type: String,
            required: false,
            default: "left"
        },
        sequences: {
            type: Array,
            required: false,
            default: () => []
        },
        isScrollDisabled: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {},
    methods: {
        emitActiveStep(val) {
            this.$emit("activeStep", val);
        },
        goToNextSection() {
            this.$emit("goToNextSection");
        }
    }
};
</script>

<style lang="scss" scoped>
.c-stories-article-illustrated-main {
    display: flex;
    flex: 1;
    position: static; // allow the image to be at the bottom on mobile

    &__inner {
        @include reset-list;
        position: static; // allow the image to be at the bottom on mobile

        display: flex;

        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: repeat(1, 1fr);

        grid-gap: var(--grid-gutter-half);
        width: 100%;
        overflow: visible;

        @media #{md("xs")} {
            display: grid;
            position: relative;
            grid-template-rows: repeat(1, 1fr);
            grid-template-columns: repeat(12, 1fr);
            grid-gap: var(--grid-gutter);
        }
    }
}
</style>
