<template>
    <div class="p-error">
        <error-page />
    </div>
</template>

<script>
import { SECTION_HANDLES } from "src/constants";
import ErrorPage from "@/templates/components/Error/Error";

export default {
    name: "Error",
    components: {
        ErrorPage
    },
    metaInfo() {
        return {
            title: this.entry.seo ? this.entry.seo.title : null
        };
    },
    props: {
        entry: Object
    },
    data: () => ({
        SECTION_HANDLES
    })
};
</script>

<style lang="scss">
.p-error {
}
</style>
