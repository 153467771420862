<template>
    <ul class="c-nav-list">
        <navigation-main-audio />
        <navigation-main-titles />
        <navigation-main-right />
    </ul>
</template>

<script>
import NavigationMainAudio from "@/templates/components/Navigation/NavigationMain/NavigationMainAudio";
import NavigationMainRight from "@/templates/components/Navigation/NavigationMain/NavigationMainRight/NavigationMainRight";
import NavigationMainTitles from "@/templates/components/Navigation/NavigationMain/NavigationMainTitles/NavigationMainTitles";
export default {
    components: {
        NavigationMainAudio,
        NavigationMainRight,
        NavigationMainTitles
    },

    data() {
        return {};
    },
    mounted() {},

    methods: {},
    watch: {}
};
</script>

<style lang="scss" scoped>
.c-nav-list {
    @include reset-list;
    display: grid;
    align-items: center;
    --after-height: 0px; // used for the animtion

    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(1, 1fr);
    column-gap: var(--grid-gutter-half);
    padding: var(--grid-gutter) 0 var(--grid-gutter) 0;

    @media #{md('xs')} {
        padding: var(--grid-gutter-half) 0 var(--grid-gutter-half) 0;
    }
    &:after {
        /*  add a small space below the nav (mobile only) */
        content: " ";
        height: var(--after-height);
        @media #{md('xs')} {
            content: none;
        }
    }
}
</style>
