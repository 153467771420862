<template>
    <div
        class="c-about-content-list-item-connector-line"
        :style="style"
        :class="`c-about-content-list-item-connector-line--${current}`"
    ></div>
</template>

<script>
export default {
    props: {
        isCurrent: {
            type: Boolean,
            required: false,
            default: false
        },
        current: {
            type: Number,
            required: false,
            default: 0
        },
        target: {
            type: Number,
            required: false,
            default: 0
        },
        isRotationOverwritten: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            style: null
        };
    },

    mounted() {
        window.addEventListener("resize", this.onResize);
        const delayDrawLine = setTimeout(() => {
            this.drawLine();
            clearTimeout(delayDrawLine);
        }, 2000);
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.onResize);
    },

    methods: {
        onResize() {
            this.drawLine();
        },
        initLine() {},

        setX2(off1, off2) {
            return this.isRotationOverwritten ? off1 : off2;
        },
        overwriteX1(off1, off2) {
            return this.isCurrent ? off1 : off2;
        },
        responsiveTargetPosition(mobile, desktop) {
            return window.innerWidth <= 991 ? mobile : desktop;
        },

        drawLine() {
            const off1 = this.getOffset(`c-about-content-list-item-connector__button--${this.current}`, false);
            const off2 = this.getOffset(`c-about-content-list-item--${this.target}`, true);
            const off3 = this.getOffset(`c-about-content-list-item__inner__content--${this.target}`, false);
            const thickness = 1;
            const x1 = off1.left + off1.width / 2;
            const y1 = off1.top + off1.height / 2;

            const x2 = this.responsiveTargetPosition(off1.left + off1.width / 2, off2.width - off3.width);
            const y2 = off2.top - 10;

            const length = Math.sqrt((x2 - x1) * (x2 - x1) + (y2 - y1) * (y2 - y1));

            const cx = (x1 + x2) / 2 - length / 2;
            const cy = (y1 + y2) / 2 - thickness / 2;

            const angle = Math.atan2(y1 - y2, x1 - x2) * (180 / Math.PI);
            this.style =
                "height:" +
                thickness +
                "px;  left:" +
                cx +
                "px; top:" +
                cy +
                "px; width:" +
                length +
                "px; -moz-transform:rotate(" +
                angle +
                "deg); -webkit-transform:rotate(" +
                angle +
                "deg); -o-transform:rotate(" +
                angle +
                "deg); -ms-transform:rotate(" +
                angle +
                "deg); transform:rotate(" +
                angle +
                "deg);'";
        },

        getOffset(elementUniqueClass, isTarget) {
            const el = document.getElementsByClassName(elementUniqueClass)[0];
            const rect = el.getBoundingClientRect();

            return {
                left: el.offsetLeft,
                top: this.offsetTop(el.offsetTop, isTarget),
                width: rect.width || el.offsetWidth,
                height: rect.height || el.offsetHeight
            };
        },

        isOdd(num) {
            return num % 2;
        },
        offsetTop(top, isTarget) {
            return isTarget ? top + 20 : this.offsetTopCurrent(top);
        },
        offsetTopCurrent(top) {
            return this.isCurrent ? top - 20 : top;
        }
    }
};
</script>

<style lang="scss" scoped>
.c-about-content-list-item-connector-line {
    --gradient-line: 252, 239, 239;
    --fade-fill: 100%;
    --rotation: 90deg;
    opacity: 1;
    line-height: 1px;
    padding: 0px;
    margin: 0px;
    position: absolute;
    left: 100%;

    background: var(--gradient-line);
    background: linear-gradient(
        var(--rotation),
        rgba(var(--gradient-line), 0) var(--fade-fill),
        rgba(var(--gradient-line), 1) 100%
    );
}
</style>
