export const stars = {
    // x red y green, z blue
    saintSauveur: [
        [240, -300, 141],
        [100, -350, -41],
        [120, -100, -10],
        [180, -150, -10],
        [240, 80, 41],
        [100, 180, -180],
        [50, 180, -150],
        [40, -200, -240],
        [80, 80, -120],
        [40, -100, -130],
        [-30, -170, -181],
        [-100, -170, -181],
        [-160, -170, -221],
        [-150, 150, -200],
        [-150, 180, -280],
        [-150, 250, -180],
        [-250, 250, -130],
        [-150, 350, -50],
        [-100, 200, -150],
    ],
    saintRoch: [
        [-240, 130, -241],
        [-240, 100, -201],
        [-40, 200, -151],
        [-40, -100, -251],
        [40, -150, -351],
        [-340, 150, -251],
        [240, 100, -201],
        [240, 150, -151],
        [240, 150, -151],
        [140, -250, -51],
        [140, 50, -51],
        [140, 250, -251],
        [-240, 250, -151],
        [440, 50, -101],
        [440, 150, -184],
        [400, 350, 4],
        [200, 400, 4],
        [10, 300, -214],
    ],
    Limoilou: [
        [240, 130, 41],
        [240, -130, 41],
        [-240, 130, -41],
        [140, 230, 41],
        [-140, 230, -41],
        [-40, -230, -141],
        [110, -30, -141],
        [-110, -260, -41],
        [110, -410, -201],
        [-310, -320, -231],
        [-310, -140, 31],
        [-410, 140, 321],
        [110, 40, 321],
        [110, 140, 241],
        [160, -140, 241],
        [-60, 240, 241],
        [-60, -240, -341],
        [260, -240, -41],
    ],
    saintJeanBaptiste: [
        [320, -50, 10],
        [420, 10, 5],
        [420, 100, -50],
        [350,  20, -50],
        [410, 200, -150],
        [0, 400, -280],
        [10, 420, -220],
        [100, 420, -220],
        [100, 10, -220],
        [100, -100, -130],
        [200, -120, -150],
        [200, -240, -200],
        [-100, 0, -230],
        [-280, 100, -290],
        [-280, -100, -350],
        [-280, -300, -300],
        [-200, -300, -100],
        [-170, -240, -180],
        [110, -400, -10],
    ],
    montcalm: [
        [240, -130, -241],
        [240, 100, -241],
        [50, 100, -241],
        [50, 100, -120],
        [-150, 200, -120],
        [-160, 350, -120],
        [-160, 350, -20],
        [100, 350, 20],
        [100, 400, 120],
        [240, -100, -120],
        [300, -100, 20],
        [300, 200, 20],
        [300, 250, 120],
        [200, 220, -120],
        [-100, -130, -341],
        [-200, -100, -341],
        [-200, 150, -301],
        [-200, -150, -301],
    ],
    vieuxQuebec: [
        [240, 130, 41],
        [240, 330, 41],
        [340, 330, 41],
        [340, 330, -241],
        [140, 330, -241],
        [-140, 330, -241],
        [-140, 330, -121],
        [-240, 430, -121],
        [-240, 230, -121],
        [-40, 230, -121],
        [-40, 230, -221],
        [40, 280, 21],
        [-40, 180, 21],
        [-240, 280, -21],
        [240, 250, -321],
        [140, -250, -321],
        [-40, -320, -321],
        [-270, -200, -271],
    ],
}

export default stars
