<template>
    <li class="c-enter-cta-btn-loader">
        <svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="75" cy="75" r="75" />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M73.2615 1.00001C73.2615 0.592143 73.5921 0.261505 74 0.261505C115.829 0.261505 149.739 34.1708 149.739 76C149.739 76.4079 149.408 76.7385 149 76.7385C148.592 76.7385 148.262 76.4079 148.262 76C148.262 34.9865 115.013 1.73851 74 1.73851C73.5921 1.73851 73.2615 1.40787 73.2615 1.00001Z"
                fill="url(#paint0_linear_12:7)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_12:7"
                    x1="73.925"
                    y1="76"
                    x2="73.925"
                    y2="-2.04491"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#1C0C2E" />
                    <stop offset="1" stop-color="#1C0C2E" stop-opacity="0" />
                </linearGradient>
            </defs>
        </svg>
    </li>
</template>

<script>
// import Icon from "@/templates/objects/Icon";
export default {
    components: {
        // Icon
    }
};
</script>

<style lang="scss" scoped>
.c-enter-cta-btn-loader {
    position: absolute;
    top: 1rem;
    right: 1rem;
    bottom: 1rem;
    left: 1rem;
    z-index: 1;
    pointer-events: none;
    opacity: 0;

    svg {
        width: 100%;
        height: 100%;
    }

    animation: rotation 3s linear infinite;
    @keyframes rotation {
        to {
            @include rotate(360deg);
        }
    }
}
</style>
