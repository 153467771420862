<template>
    <li class="c-shared-tips-discocer-info-copy" :class="{ 'c-shared-tips-discocer-info-copy--vertical': isVertical }">
        <p>{{ copy }}</p>
    </li>
</template>

<script>
export default {
    props: {
        copy: {
            type: String,
            required: true
        },
        isVertical: {
            type: Boolean,
            required: false,
            default: false
        }
    }
};
</script>

<style lang="scss" scoped>
.c-shared-tips-discocer-info-copy {
    --text-align: left;
    display: flex;
    align-items: center;

    height: 100%;

    &--vertical {
        --text-align: center;
    }

    p {
        @include lightCopy();
        text-align: var(--text-align);
        @media #{md('xs')} {
            --text-align: center;
        }
    }
}
</style>
