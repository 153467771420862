<template>
    <li class="c-shared-tips-info-icons" :class="{ 'c-shared-tips-info-icons--credit': isCredit }">
        <div class="c-shared-tips-info-icons__svg" :class="{ 'c-shared-tips-info-icons__svg--shadow': !isCredit }">
            <icon :icon="iconName" />
        </div>
        <div class="c-shared-tips-info-icons__lines">
            <icon icon="credits-lines" />
        </div>
    </li>
</template>

<script>
import Icon from "@/templates/objects/Icon";
export default {
    components: {
        Icon
    },
    props: {
        iconName: {
            type: String,
            required: true
        },
        isCredit: {
            type: Boolean,
            required: false,
            default: false
        }
    }
};
</script>

<style lang="scss" scoped>
.c-shared-tips-info-icons {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;

    &__svg {
        &--shadow {
            --shadow-intensity: 0;
            @include radius(50%);
            @include box-shadow($radius: 2.5em, $intensity: var(--shadow-intensity));
            &::v-deep .o-icon {
                margin-top: -2px;
            }
        }
        &::v-deep svg {
            --color-light-svg: var(--color-purple-light);
            width: 5rem;
            height: 5rem;
            aspect-ratio: 1/1;
            fill: var(--color-light-svg);

            .c-nbhd-story--light & {
                --color-light-svg: var(--color-dark);
            }
            .c-shared-tips-info-icons--credit & {
                width: 2rem;
                // height: 2.5rem;
            }
        }
    }
    &__lines {
        display: none;
        position: absolute;
        .c-shared-tips-info-icons--credit & {
            display: flex;
        }
        &::v-deep svg {
            width: 5rem;
            height: 5rem;
            aspect-ratio: 1/1;
            stroke: var(--color-purple-light);
            opacity: 0.1;
        }
    }
}
</style>
