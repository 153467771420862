<template>
    <li class="c-enter-cta-btn-center">
        <div class="c-enter-cta-btn-center__main">
            <anim-text
                :text="copy.start"
                class="t-t-1 c-enter-cta-btn-center__main__copy c-enter-cta-btn-center__main__copy--1"
                tag="p"
                :visible="isCTAVisible"
                :reveal="true"
                :chars="true"
                :word="false"
                :lines="false"
                :is-axis-y="true"
            />
            <anim-text
                :text="copy.experience"
                class="t-t-1 c-enter-cta-btn-center__main__copy c-enter-cta-btn-center__main__copy--2"
                tag="p"
                :visible="isCTAVisible"
                :reveal="true"
                :chars="true"
                :word="false"
                :lines="false"
                :is-axis-y="true"
            />
        </div>
        <enter-cta-btn-arrow />
    </li>
</template>

<script>
import { mapState } from "vuex";

import AnimText from "@/templates/objects/AnimText";
import EnterCtaBtnArrow from "@/templates/components/Enter/EnterCta/EnterCtaBtn/EnterCtaBtnArrow";
export default {
    components: {
        AnimText,
        EnterCtaBtnArrow
    },
    props: {
        copy: {
            type: Object,
            required: false,
            default: null
        }
    },
    computed: {
        ...mapState({
            isCTAVisible: state => state.home.isCTAVisible
        })
    }
};
</script>

<style lang="scss" scoped>
.c-enter-cta-btn-center {
    --width-first-line: 0%;
    --width-second-line: 0%;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    position: relative;
    z-index: 1;
    &__main {
        @include titles;
        font-size: 3rem;
        line-height: 1.1;
        display: flex;
        justify-content: center;
        align-content: center;
        flex-direction: column;
        position: relative;

        text-align: center;
        color: var(--color-purple-dark);
        p {
            &:after {
                content: " ";
                position: absolute;
                bottom: -0.5rem;
                left: 0rem;
                right: 0rem;
                height: 0.2rem;
                width: var(--width-second-line);
                background: var(--color-purple-dark);
            }
            &:nth-child(1) {
                &:after {
                    left: 0.8rem;
                    width: calc(var(--width-first-line) - 1.6rem);
                }
            }
        }
    }
}
</style>
