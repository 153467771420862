<template>
    <li
        class="c-navigation-main-titles-logo"
        :class="{ 'c-navigation-main-titles-logo--visible': isHeaderLogoVisible }"
    >
        <a
            class="c-navigation-main-titles-logo__anchor"
            @click="userClicked"
            @mouseover="isMouseOver(true)"
            @mouseleave="isMouseOver(false)"
            @focusin="isMouseOver(true)"
            @focusout="isMouseOver(false)"
        >
            <h2 class="c-navigation-main-titles-logo__anchor__logo">
                <anim-text
                    :text="$t('shared.constellation')"
                    class=" rotate"
                    tag="p"
                    :visible="isHeaderLogoVisible"
                    :reveal="true"
                    :chars="true"
                    :word="false"
                    :lines="false"
                    :is-axis-y="true"
                />
            </h2>
        </a>
    </li>
</template>

<script>
import { mapState } from "vuex";

import AnimText from "@/templates/objects/AnimText";
export default {
    components: {
        AnimText
    },
    data() {
        return {
            isActive: false
        };
    },
    computed: {
        ...mapState({
            isHeaderLogoVisible: state => state.global.isHeaderLogoVisible
        })
    },
    methods: {
        isMouseOver(bool) {
            this.isActive = bool;
        },
        userClicked() {
            this.$route.path !== "/" ? this.$store.commit("global/updatePageTransitionOutUrl", "/") : null;
        }
    }
};
</script>

<style lang="scss" scoped>
.c-navigation-main-titles-logo {
    --alignment: center;
    @include transform(translateY(-0.2rem)); // for some reason anim text unalign title
    display: flex;
    align-items: center;
    justify-content: var(--alignment);
    z-index: 1;
    min-width: 11.2rem;

    @media #{md("xs")} {
        --alignment: flex-start;
    }
    @media #{md("md")} {
        min-width: inherit;
    }

    &__anchor {
        @include remove-button-style;
        --intensity-shadow: 0rem;
        cursor: pointer;
        &:hover {
            --intensity-shadow: 1rem;
        }
    }

    &::v-deep p {
        @include titles;

        @include transition(1s ease color);
        @include shadowTitlesHover;

        font-size: 3rem;
        color: var(--color-light);
    }
    &::v-deep .o-at__c {
        @include rotateLetterInTitle(0.3rem, 0.3rem, 0, -0.1rem);
        @include tittleShadow(0.5rem, 0.2rem);

        --play-state: running;
    }
}
</style>
