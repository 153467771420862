import stars from './homeStars'

const starsSaintSauveur = stars['saintSauveur']
const starsSaintRoch = stars['saintRoch']
const starsLimoilou = stars['Limoilou']
const starsSaintJeanBaptiste = stars['saintJeanBaptiste']
const starsMontcalm = stars['montcalm']
const starsVieuxQuebec = stars['vieuxQuebec']

/**
 * Lines coordinates for each group of stars
 * [
 *  [ //ConnectedLines1
 *      [star1, star2],
 *      [star1, star2]
 *  ],
 *  [ //ConnectedLines2
 *      [star1, star2],
 *      [star1, star2],
 *      [star1, star2]
 *  ]
 * ]
 *
 * ConnectedLines1 and ConnectedLines2 doesn't connect
 * star1 and star2 will connect to form a line
 * each lines in the same connectedLines array will connect to each other
 * to make something like \/\
 */
export const lines = {
    saintSauveur: [
        [
            [starsSaintSauveur[4], starsSaintSauveur[8]],
            [starsSaintSauveur[8], starsSaintSauveur[9]],
            [starsSaintSauveur[9], starsSaintSauveur[7]]
        ],
        [
            [starsSaintSauveur[5], starsSaintSauveur[12]],
            [starsSaintSauveur[12], starsSaintSauveur[18]],
            [starsSaintSauveur[18], starsSaintSauveur[15]],
        ],
        [
            [starsSaintSauveur[16], starsSaintSauveur[14]],
            [starsSaintSauveur[14], starsSaintSauveur[11]],
        ],
    ],
    saintRoch: [
        [
            [starsSaintRoch[0], starsSaintRoch[1]],
            [starsSaintRoch[1], starsSaintRoch[2]],
            [starsSaintRoch[2], starsSaintRoch[3]],
        ],
        [
            [starsSaintRoch[4], starsSaintRoch[5]],
            [starsSaintRoch[5], starsSaintRoch[6]],
        ],
        [
            [starsSaintRoch[13], starsSaintRoch[14]],            // [starsSaintRoch[8], starsSaintRoch[12]],
            [starsSaintRoch[14], starsSaintRoch[16]],
        ]
    ],
    Limoilou: [
        [
            [starsLimoilou[0], starsLimoilou[1]],
            [starsLimoilou[1], starsLimoilou[5]],
        ],
        [
            [starsLimoilou[2], starsLimoilou[4]],
            [starsLimoilou[4], starsLimoilou[6]],
        ],
        [
            [starsLimoilou[8], starsLimoilou[9]],
            [starsLimoilou[16], starsLimoilou[9]], //16
        ]
    ],
    saintJeanBaptiste: [
        [
            [starsSaintJeanBaptiste[4], starsSaintJeanBaptiste[3]],
            [starsSaintJeanBaptiste[4], starsSaintJeanBaptiste[5]],
        ],
        [
            [starsSaintJeanBaptiste[8], starsSaintJeanBaptiste[9]],
            [starsSaintJeanBaptiste[9], starsSaintJeanBaptiste[11]],
        ],
        [
            [starsSaintJeanBaptiste[13], starsSaintJeanBaptiste[14]],
            [starsSaintJeanBaptiste[14], starsSaintJeanBaptiste[16]],
        ],
    ],
    montcalm: [
        [
            [starsMontcalm[2], starsMontcalm[16]],
            [starsMontcalm[16], starsMontcalm[15]],
        ],
        [
            [starsMontcalm[5], starsMontcalm[6]],
            [starsMontcalm[6], starsMontcalm[7]],
        ],
        [
            [starsMontcalm[0], starsMontcalm[13]],
            [starsMontcalm[4], starsMontcalm[13]],
        ],
    ],
    vieuxQuebec: [
        [
            [starsVieuxQuebec[1], starsVieuxQuebec[2]],
            [starsVieuxQuebec[2], starsVieuxQuebec[4]],
            [starsVieuxQuebec[4], starsVieuxQuebec[5]]
        ],
        [
            [starsVieuxQuebec[6], starsVieuxQuebec[7]],
            [starsVieuxQuebec[6], starsVieuxQuebec[8]],
        ],
        [
            [starsVieuxQuebec[11], starsVieuxQuebec[9]],
            [starsVieuxQuebec[14], starsVieuxQuebec[9]],
            [starsVieuxQuebec[14], starsVieuxQuebec[15]]
        ]
    ],
}

export default lines
