<template>
    <li class="c-stories-article-illustrated-main-copy" :class="`c-stories-article-illustrated-main-copy--${position}`">
        <!-- centered copy -->
        <ul class="c-stories-article-illustrated-main-copy__inner">
            <stories-article-illustrated-main-copy-vert
                v-if="isReady"
                class="c-stories-article-illustrated-main-copy__inner__hor"
                :is-scroll-disabled="isScrollDisabled"
                :position="position"
                :sequences="sequences"
                :sequencesText="sequences"
                @activeStep="emitActiveStep"
                @goToNextSection="goToNextSection"
            />
        </ul>
    </li>
</template>

<script>
import StoriesArticleIllustratedMainCopyVert from "@/templates/components/Stories/StoriesArticle/StoriesArticleIllustrated/StoriesArticleIllustratedMain/StoriesArticleIllustratedMainCopy/StoriesArticleIllustratedMainCopyVert";
export default {
    components: {
        StoriesArticleIllustratedMainCopyVert
    },
    props: {
        position: {
            type: String,
            required: false,
            default: "centered"
        },
        sequences: {
            type: Array,
            required: false,
            default: () => []
        },
        isScrollDisabled: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    data() {
        return {
            isReady: false
        };
    },
    mounted() {
        this.displayChildren();
        window.addEventListener("resize", this.onResize);
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.onResize);
    },

    methods: {
        displayChildren() {
            // not important, but just to avoid the component to load and destroy next tick on mount
            this.isReady = true;
        },
        emitActiveStep(val) {
            this.$emit("activeStep", val);
        },
        goToNextSection() {
            this.$emit("goToNextSection");
        }
    }
};
</script>

<style lang="scss" scoped>
.c-stories-article-illustrated-main-copy {
    @include articleParagraphFadeIn(0deg, var(--grid-gutter-double));
    position: relative;
    grid-column: 1/7;
    overflow: hidden;

    margin-top: calc(0px - var(--grid-gutter-double));

    height: calc(100% + var(--grid-gutter-double));
    width: 100%;

    @media #{md("xs")} {
        grid-column: 1/13;
    }
    &--left {
        --list-column: 1/7;
        @media #{md("xs")} {
            grid-column: 1/7;
            --list-column: 1/9;
        }
        @media #{md("md")} {
            grid-column: 1/6;
            --list-column: 2/7;
        }
    }
    &--right {
        --list-column: 1/7;
        @media #{md("xs")} {
            grid-column: 7/13;
            --list-column: 1/7;
        }
        @media #{md("md")} {
            grid-column: 7/13;
            --list-column: 1/6;
        }
        @media #{md("lg")} {
            grid-column: 8/13;
            --list-column: 1/6;
        }
    }
    &__inner {
        display: flex;
        flex: 1;
        height: 100%;
        // height: calc(100% + var(--grid-gutter-double));
        &__hor {
            // display: none;
            // @media #{md("md")} {
            //     display: grid;
            // }
        }
    }
}
</style>
