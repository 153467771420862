<template>
    <app-base id="app" :style="`--vh: ${W.h / 100}px`" />
</template>

<script>
import "assets/scss/styles.scss";

import Variables from "src/mixins/variables";
import { reducedMotion } from "src/utils";

import AppBase from "layout/AppBase";

export default {
    name: "app",
    components: {
        AppBase
    },
    mixins: [Variables],
    metaInfo() {
        return {
            titleTemplate: `%s`
        };
    },
    created() {
        const $html = document.documentElement;
        // Browser and OS classes
        $html.classList.add(`is-${this.browser.os}`);
        $html.classList.add(`is-${this.browser.name}`);



        if (this.isMobile || this.isTablet) {
            $html.classList.add("is-mobile");
        }

        if (reducedMotion) {
            $html.classList.add("reduced-motion");
        }
    }
};
</script>

<style lang="scss">
body {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
        display: none;
    }

    // If you to put it back the scroll, you can use that below
    // &.p-home,
    // &.p-constellations {
    //     -ms-overflow-style: none; /* IE and Edge */
    //     scrollbar-width: none; /* Firefox */

    //     &::-webkit-scrollbar {
    //         display: none;
    //     }

    // }

    // &.p-about,
    // &.p-credits {
    //     &::-webkit-scrollbar {
    //         width: 1.2rem;
    //     }

    //     &::-webkit-scrollbar-track {
    //         box-shadow: inset 0 0 .5rem rgba($color-purple-light, .5);
    //     }

    //     &::-webkit-scrollbar-thumb {
    //         background-color: rgba($color-light--shadow, 0.1);
    //         outline: 1px solid $color-light;
    //         box-shadow: inset 0 0 1rem rgba($color-purple-light, .5);
    //     }
    // }
}
</style>
