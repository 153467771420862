<template>
    <div class="c-song-wave" :class="{ 'c-song-wave--muted': isMute }">
        <div class="c-song-wave__inner">
            <span v-for="n in totalWaves" :key="n" ref="wave" :class="`wave wave-${n}`"></span>
        </div>
    </div>
</template>

<script>
import gsap from "gsap";
export default {
    name: "SongWave",
    data() {
        return {
            totalWaves: 4,
            timelines: {
                play: null
            }
        };
    },
    props: {
        isMute: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    mounted() {
        this.initPlayTimeline();
    },
    computed: {},
    methods: {
        initPlayTimeline() {
            this.wave = gsap.utils.toArray(this.$refs.wave);
            //To set with the music frequency
            this.timelines.play = gsap
                .timeline({
                    repeat: -1,
                    ease: "sine.in",
                    yoyo: true,
                    paused: true
                })
                .to(this.wave, {
                    duration: 0.6,
                    stagger: 0.15,
                    ease: "sine.out",
                    scaleY: 0.4
                })
                .to(this.wave, {
                    duration: 0.6,
                    delay: -0.3,
                    stagger: 0.2,
                    ease: "sine.out",
                    scaleY: 1
                });
            this.playTimeline();
        },
        playTimeline() {
            this.timelines.play.play();
        }
    }
    // watch: {
    //     isMute(bool) {
    //         bool ? null : this.playTimeline();
    //     }
    // }
};
</script>

<style lang="scss">
.c-song-wave {
    --max-height: 1;
    @include transform(scaleY(var(--max-height)));
    @include transition(0.3s ease all);
    transform-origin: bottom center;
    display: flex;
    height: 1.5rem;
    &--muted {
        --max-height: 0.3;
    }
    &__inner {
        @include transition(0.3s ease all);
        width: 2rem;
        height: 1.5rem;
        display: flex;
        justify-content: space-between;
        overflow: hidden;

        // Default state
        --y1: 1;
        --y2: 0.6;

        .wave {
            width: 2px;
            height: 1.5rem;
            background: var(--color-purple-light);
            transform: scaleY(var(--y1));
            transform-origin: bottom center;

            &-2,
            &-4 {
                transform: scaleY(var(--y2));
            }
        }
    }
}
</style>
