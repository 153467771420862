<template>
    <div class="c-error">
        <div class="c-error__inner">
            <!-- stars -->
            <home-intro-stars />
            <!-- copy -->
            <enter :is-error-page="true" @clicked="playAnimationOnLeave" />
            <error-title :visible-titles="visibleTitles.list" />
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

import gsap from "gsap/all";

import Enter from "@/templates/components/Enter/Enter";
import ErrorTitle from "@/templates/components/Error/ErrorTitle/ErrorTitle";
import HomeIntroStars from "components/Home/HomeIntro/HomeIntroStars";
export default {
    components: {
        Enter,
        ErrorTitle,
        HomeIntroStars
    },

    data() {
        return {
            timelineOnLeave: null,
            timelineOnStart: null,
            visibleTitles: { list: [], interval: null }
        };
    },
    computed: {
        ...mapState({
            audio: state => state.global.settings.audio
        })
    },
    mounted() {
        this.$nextTick(() => {
            this.setInterUntilClassIsSet();
            this.setAudio();
        });
    },

    beforeDestroy() {
        this.destroyTimelines();
        this.clearStartTimeout();
    },
    watch: {
        audio() {
            this.setAudio();
        }
    },
    methods: {
        setInterUntilClassIsSet() {
            const findClassInterval = setInterval(() => {
                this.isClassExist("c-enter-cta-btn-arrow")
                    ? ((this.initTimelineStart(), this.setAnimationOnLeave()), clearInterval(findClassInterval))
                    : null;
            }, 100);
        },

        isClassExist(nameOfTheClass) {
            // true/false is easier to read even on a ternary, please don't judge too fast :D
            return document.getElementsByClassName(nameOfTheClass).length ? true : false;
        },

        ////////////////////////////////
        //       START START ANIMATION ON ENTER
        ////////////////////////////////
        initTimelineStart() {
            this.timelineOnStart = gsap.timeline({
                paused: true
            });

            const durationAnimation = 1.5;
            const durationAnimationUp = 2.5;
            const ease = "expo.inOut";
            // stars
            this.timelineOnStart
                .to(".c-home-intro-stars", {
                    opacity: 1,

                    duration: durationAnimation,
                    ease: ease
                })
                // title
                .add(() => {
                    this.$store.commit("home/toggleAnimationStarted", true);
                })
                .add(() => {
                    this.startIntervalAnimation();
                })

                // kicker
                .to(
                    ".c-enter-main-title__kicker",
                    {
                        opacity: 1,
                        y: 0,

                        duration: durationAnimation,
                        ease: ease
                    },
                    "+=1.5"
                )
                // move everything up
                .to(
                    ".c-enter-main",
                    {
                        y: 0,

                        duration: durationAnimationUp,
                        ease: ease
                    },
                    "goUp"
                )

                // background button + circles + line tips
                .to(
                    ".c-enter-cta-btn__inner",
                    {
                        "--scale-background": 1,
                        y: 0,

                        duration: durationAnimationUp,
                        ease: ease
                    },
                    "goUp"
                )

                // animation copy button
                .add(() => {
                    this.$store.commit("home/toggleCirclesVisible", true);
                }, "goUp")

                .to(
                    ".c-enter-cta-circles",
                    {
                        y: 0,
                        opacity: 1,

                        duration: durationAnimationUp + 1,
                        ease: ease
                    },
                    "goUp"
                )

                // underline
                .to(
                    ".c-enter-cta-btn-center",
                    {
                        "--width-first-line": "100%",

                        duration: 1,
                        ease: ease
                    },
                    "goUp+=1.5"
                )

                .to(
                    ".c-enter-cta-btn-center",
                    {
                        "--width-second-line": "100%",

                        duration: 1,
                        ease: ease
                    },
                    "goUp+=2"
                )

                .to(
                    ".c-enter-cta-btn-loader",
                    {
                        opacity: 1,

                        duration: 1.5,
                        ease: ease
                    },
                    "goUp+=2"
                )
                .to(
                    ".c-enter-cta-btn-arrow .c-shared-animated-icon-arrow",
                    {
                        "--width-line": "100%",
                        opacity: 1,

                        duration: durationAnimation,
                        ease: ease
                    },
                    "goUp+=2.5"
                )
                .to(
                    ".c-enter-cta-btn-arrow .c-shared-animated-icon-arrow svg",
                    {
                        scaleY: 1,
                        scaleX: 1,

                        duration: durationAnimation,
                        ease: ease
                    },
                    "goUp+=2.5"
                )

                .add(() => {
                    this.$store.commit("home/toggleCTAVisible", true);
                }, "goUp+=1")

                // no audio
                .to(
                    ".c-enter-tips-audio-with-out-btn__left",
                    {
                        x: 0,
                        opacity: 1,

                        duration: durationAnimation,
                        ease: ease
                    },
                    "noAudio-=0.5"
                )

                .to(
                    ".c-enter-tips-audio-with-out-btn .c-shared-animated-icon-arrow",
                    {
                        "--width-line": "100%",
                        opacity: 1,

                        duration: durationAnimation,
                        ease: ease
                    },
                    "noAudio"
                )
                .to(
                    ".c-enter-tips-audio-with-out-btn .c-shared-animated-icon-arrow .o-icon svg",
                    {
                        scaleY: 1,
                        scaleX: 1,

                        duration: durationAnimation,
                        ease: ease
                    },
                    "noAudio"
                )
                .to(
                    ".c-shared-animated-icon-mute",
                    {
                        "--animation": "running",

                        duration: durationAnimation,
                        ease: ease
                    },
                    "-=1"
                )

                .to(
                    ".c-enter-tips-audio-with-out-btn-mute",
                    {
                        "--scale-mute": 1,

                        duration: durationAnimation,
                        ease: ease
                    },
                    "-=1.3"
                );

            // this.startIntervalAnimation();
            this.delayStartAnimation();
        },

        //======= START TIMEOUT  =======//

        delayStartAnimation() {
            // this.startTimeout = setTimeout(() => {
            this.timelineOnStart.play();
            // this.clearStartTimeout();
            // }, 100);
        },
        clearStartTimeout() {
            this.startTimeout ? clearTimeout(this.startTimeout) : null;
        },

        //======= END TIMEOUT    =======//

        ////////////////////////////////
        //       END START ANIMATION ON ENTER
        ////////////////////////////////

        ////////////////////////////////
        //       START SET INTERVAL TITLES
        ////////////////////////////////

        startIntervalAnimation() {
            this.visibleTitles.interval = setInterval(() => {
                this.updateAnimation();
            }, 200);
        },
        updateAnimation() {
            this.visibleTitles.list.length < 3 ? this.addTitle() : this.clearAnimationInterval();
        },
        addTitle() {
            this.visibleTitles.list.push(this.visibleTitles.list.length);
        },
        clearAnimationInterval() {
            // clear timeout
            clearInterval(this.visibleTitles.interval);
            this.timelineOnStart.play();
        },
        ////////////////////////////////
        //       END SET INTERVAL TITLES
        ////////////////////////////////

        ////////////////////////////////
        //       START ANIMATION LEAVE
        ////////////////////////////////
        setAnimationOnLeave() {
            this.timelineOnLeave = gsap.timeline({
                paused: true,
                onComplete: () => {
                    this.startExperience();
                }
            });

            const durationAnimationOut = 1.5;
            const easeOut = "expo.out";

            // fade out
            // this.timelineOnLeave
            this.timelineOnLeave
                .to(
                    ".c-enter-cta-btn",
                    {
                        scale: 0.8,

                        duration: durationAnimationOut,
                        ease: easeOut
                    },
                    "onLeave"
                )

                .to(
                    ".c-error",
                    {
                        opacity: 0,

                        duration: durationAnimationOut - 0.2,
                        ease: easeOut
                    },
                    "onLeave"
                )
                .to(
                    ".c-error",
                    {
                        scale: 0.95,

                        duration: durationAnimationOut + 0.5,
                        ease: easeOut
                    },
                    "onLeave"
                );
        },
        /*------------------------------
        Start Play Timeline
        ------------------------------*/
        playAnimationOnLeave(bool) {
            this.timelineOnLeave.play();
            bool ? this.startAudioExperience() : null;
        },
        startAudioExperience() {
            this.$store.commit("global/toggleMute", false);
        },
        /*------------------------------
        End Play Timeline
        ------------------------------*/
        ////////////////////////////////
        //       END ANIMATION LEAVE
        ////////////////////////////////

        ////////////////////////////////
        //       START CLOSE INTRO PAGE WITH ANIMATION
        ////////////////////////////////
        startExperience() {
            // there will be some animation below
            this.redirectToHomePage();
            // hide home intro (true for audio);
            this.displayHeader();
        },
        redirectToHomePage() {
            // bool stand for audio or not
            // this.$emit("startExperience", true);
            this.$router.push("/");
        },
        displayHeader() {
            this.$store.commit("global/toggleHeaderAnimation", true);
        },
        ////////////////////////////////
        //       END CLOSE INTRO PAGE WITH ANIMATION
        ////////////////////////////////

        ////////////////////////////////
        //       START ON BEFORE DESTROY
        ////////////////////////////////
        destroyTimelines() {
            if (this.timelineOnStart) {
                this.timelineOnStart.kill();
                this.timelineOnStart = null;
            }
            if (this.timelineOnLeave) {
                this.timelineOnLeave.kill();
                this.timelineOnLeave = null;
            }
        },
        ////////////////////////////////
        //       END ON BEFORE DESTROY
        ////////////////////////////////

        ////////////////////////////////
        //       START AUDIO
        ////////////////////////////////
        setAudio() {
            this.audio && this.audio.url ? this.$store.commit("global/updateUpComingTrack", this.audio.url) : null;
        }

        ////////////////////////////////
        //       END AUDIO
        ////////////////////////////////
    }
};
</script>

<style lang="scss" scoped>
.c-error {
    @include fullWidthFixed(fixed);
    background-color: var(--color-dark);
    z-index: 90;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;

    &__inner {
        position: relative;
        width: 100%;
        height: 100%;

        @media #{md('sm')} {
            @media (max-height: 750px) {
                overflow: scroll;
            }
        }
    }
}
</style>
