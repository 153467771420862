<template>
    <div class="c-enter-cta-circles">
        <ul class="c-enter-cta-circles__list">
            <enter-cta-circles-item v-for="index in 3" :key="index" :index-circle="index" />
        </ul>
    </div>
</template>

<script>
import { mapState } from "vuex";
import { gsap } from "gsap/all";
import EnterCtaCirclesItem from "@/templates/components/Enter/EnterCta/EnterCtaCircles/EnterCtaCirclesItem";
export default {
    components: {
        EnterCtaCirclesItem
    },
    data() {
        return {
            circleTimeline: null
        };
    },
    mounted() {
        this.initTimeline();
    },
    computed: {
        ...mapState({
            areCirclesVisible: state => state.home.areCirclesVisible
        })
    },
    watch: {
        areCirclesVisible() {
            this.playTimeline();
        }
    },
    beforeDestroy() {
        this.circleTimeline ? this.destoyTimeLine() : null;
    },
    methods: {
        initTimeline() {
            this.circleTimeline = gsap.timeline({ paused: true, repeat: -1 });
            const durationAnimation = 3;

            const scales = [0, 0.25, 1, 4];
            const ease = "power3.inOut";

            this.circleTimeline.to(
                ".c-enter-cta-circles-items--1",
                {
                    "--scale": scales[3],
                    opacity: 0,

                    duration: durationAnimation,
                    ease: ease
                },
                "start"
            );
            this.circleTimeline.to(
                ".c-enter-cta-circles-items--2",
                {
                    "--scale": scales[2],

                    duration: durationAnimation,
                    ease: ease
                },
                "start"
            );
            this.circleTimeline.to(
                ".c-enter-cta-circles-items--3",
                {
                    "--scale": scales[1],

                    duration: durationAnimation,
                    ease: ease
                },
                "start"
            );

            this.circleTimeline.to(
                ".c-enter-cta-circles-items--1",
                {
                    "--scale": scales[0],

                    duration: 0,
                    opacity: 1,
                    ease: ease
                },
                "end"
            );
            this.circleTimeline.to(
                ".c-enter-cta-circles-items--2",
                {
                    "--scale": scales[3],
                    opacity: 0,

                    duration: durationAnimation,
                    ease: ease
                },
                "end"
            );
            this.circleTimeline.to(
                ".c-enter-cta-circles-items--3",
                {
                    "--scale": scales[2],

                    duration: durationAnimation,
                    ease: ease
                },
                "end"
            );
            this.circleTimeline.to(
                ".c-enter-cta-circles-items--1",
                {
                    "--scale": scales[1],

                    duration: durationAnimation,
                    ease: ease
                },
                "end"
            );
        },
        playTimeline() {
            this.circleTimeline.play();
        },
        destoyTimeLine() {
            this.circleTimeline.kill();
            this.circleTimeline = null;
        }
    }
};
</script>

<style lang="scss" scoped>
.c-enter-cta-circles {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    pointer-events: none;
    z-index: 1;
    opacity: 0;
    &__list {
        @include reset-list;
        position: relative;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(1, 1fr);
        @media #{md('sm')} {
            @media (max-height: 750px) {
                display: none;
            }
        }
    }
}
</style>
