export const aboutStars = [
    [-100, 200, -300],
    [-100, -200, -300],
    [100, 100, -300],
    [100, -400, -350],
    [200, -400, -350],
    [200, -350, -150],
    [350, -350, -150],
    [-150, -350, -350],
    [-250, -50, -250],
    [-250, -150, -180],
    [350, -210, -80],
    [50, -210, -280],
    [50, 110, -80],
    [250, 110, -80],
    [-250, 110, -80],
    [-250, 180, -110],
    [-290, -380, -160],
]

export default aboutStars
