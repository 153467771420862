<template>
    <li class="c-stories-article-illustrated-main-img" :class="`c-stories-article-illustrated-main-img--${position}`">
        <!-- list of images -->
        <stories-article-illustrated-main-img-side
            v-if="position !== 'overlap'"
            :sequences="sequences"
            :active-step="activeStep"
        />
    </li>
</template>

<script>
import StoriesArticleIllustratedMainImgSide from "@/templates/components/Stories/StoriesArticle/StoriesArticleIllustrated/StoriesArticleIllustratedMain/StoriesArticleIllustratedMainImg/StoriesArticleIllustratedMainImgSide/StoriesArticleIllustratedMainImgSide";
export default {
    components: {
        StoriesArticleIllustratedMainImgSide
    },
    props: {
        activeStep: {
            type: Number,
            required: false,
            default: 0
        },

        position: {
            type: String,
            required: false,
            default: "overlap"
        },
        sequences: {
            type: Array,
            required: false,
            default: () => []
        }
    }
};
</script>

<style lang="scss" scoped>
.c-stories-article-illustrated-main-img {
    @include disableTouchHold;
    position: absolute;
    right: 0px;
    bottom: calc(0px - var(--grid-gutter-double));
    left: 0px;

    height: calc(50% + var(--grid-gutter));
    overflow: hidden;

    opacity: 0;

    pointer-events: none;

    @media #{md("xs")} {
        position: relative;
        right: 0;
        bottom: 0;
        left: 0;

        height: 100%;
        grid-row: 1/1;
        grid-column: 1/13;
    }

    &--left {
        @media #{md("xs")} {
            grid-column: 7/13;
        }
    }
    &--right {
        @media #{md("xs")} {
            grid-column: 1/7;
        }
    }
}
</style>
