/*
 * Debounce function
 */
export const debounce = (callback, delay = 250) => {
    let timeoutId
    return (...args) => {
        clearTimeout(timeoutId)
        timeoutId = setTimeout(() => {
            timeoutId = null
            callback(...args)
        }, delay)
    }
}


/*
 * Round number with n decimals
 */
export const round = (x, n=2) => {
  const decimals = 10 ** n
  return Math.round(x * decimals)/decimals
}


/*
 * Reduced motion var
 */
export const reducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches


/*
 * Add resizeEnd event to window
 */
export const resizeEnd = () => {
    const resizeEnd = new Event('resizeEnd');

    window.addEventListener('resize', debounce(() => {
        window.dispatchEvent(resizeEnd)
    }, 200));
}


/*
 *    Check a response status
 */
export const checkStatus = (response) => {

    if (response.status >= 200 && response.status < 300) {
        return response
    } else {
        var error = new Error(response.statusText)
        error.response = response
        throw error
    }
}


/*
 *    Get a filemame from an url
 */
export const getFilename = (url) => {
    if (!url) return
    return url.substring(url.lastIndexOf('/')+1)
}


/*
 *    Capitalize a string
 */
export const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1)


/*
 *    Return a-kebab-formatted-string
 */
export const toKebab = (str) => {
    if(!str) return null
    return str.toLowerCase().replace('-', '').replace(/[^\w\u00C0-\u024f]+/g, "-").replace(/^-+|-+$/g, "").normalize("NFD").replace(/[\u0300-\u036f]/g, "")
}


/**
 * Format a string to a "max" of characters
 * @param {String} str The string to format
 * @param {Number} max The max length wanted
 * @returns {String} The string formated
 */
export const formatString = (str, max = 20) => {
    let _max = parseInt(max, 10)

    if (typeof str != 'string' || isNaN(_max) || _max <= 0) {

        /* eslint-disable-next-line */
        console.warn(`'str' must be of type string but is of type ${typeof str}
            And 'max' must be a number > 0 but is of type ${typeof max} and value is: ${max}`)

        return str
    }

    let string = [...str].slice(0, _max)

    if ([...str].length > _max)
        string.push('...')

    return string.join('')
}

/**
 *  Format the date
 * @param {String} date
 * @param {Object} formatOptions
 * @returns {String}
 */
export const formatDate = (date, formatOptions = { }) => {
    let { locales, ..._options } = formatOptions

    if (!locales) locales = 'fr-FR'

    const options = {
        month: 'short',
        day: 'numeric',
        year: 'numeric'
    }
    const formatedDate = new Date(date)

    return formatedDate.toLocaleDateString(locales, {...options, ..._options})
}

export const getRandom = (arr, n) => {
    var result = new Array(n),
        len = arr.length,
        taken = new Array(len);
    if (n > len)
        throw new RangeError("getRandom: more elements taken than available");
    while (n--) {
        var x = Math.floor(Math.random() * len);
        result[n] = arr[x in taken ? taken[x] : x];
        taken[x] = --len in taken ? taken[len] : len;
    }
    return result;
}

export const decodeHTMLCharCode = str => {
    return str.replace(/(&#(\d+);)/g, function(match, capture, charCode) {
        return String.fromCharCode(charCode);
    });
}

export const hexToRgb = (hex) => {
    var c;
    if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
        c= hex.substring(1).split('');
        if(c.length== 3){
            c= [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c= '0x'+c.join('');
        return [(c>>16)&255, (c>>8)&255, c&255].join(',');
    }
    throw new Error('Bad Hex');
}
