<template>
    <button
        class="c-enter-tips-audio-with-out-btn"
        :class="{
            'c-enter-tips-audio-with-out-btn--focus': isFocus,
            'c-enter-tips-audio-with-out-btn--fixed': isErrorPage
        }"
        @click="clicked"
        @focusin="isUserFocusing(true)"
        @focusout="isUserFocusing(false)"
    >
        <div class="c-enter-tips-audio-with-out-btn__inner">
            <div class="c-enter-tips-audio-with-out-btn__left">
                <!-- icon audio -->
                <enter-tips-audio-with-out-btn-mute />
                <!-- copy -->
                <div class="c-enter-tips-audio-with-out-btn__left__copy">{{ $t(`${pathI18n}.tips.noAudio`) }}</div>
            </div>
            <!-- arrow -->

            <div>
                <shared-animated-icon-arrow class=" c-btn__icon" iconAfter="arrow-right" />
            </div>
        </div>
    </button>
</template>

<script>
import EnterTipsAudioWithOutBtnMute from "@/templates/components/Enter/EnterTips/EnterTipsAudio/EnterTipsAudioWithOut/EnterTipsAudioWithOutBtn/EnterTipsAudioWithOutBtnMute";
import SharedAnimatedIconArrow from "@/templates/components/Shared/SharedAnimatedIcons/SharedAnimatedIconArrow";
export default {
    components: {
        EnterTipsAudioWithOutBtnMute,
        SharedAnimatedIconArrow
    },
    props: {
        isErrorPage: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            isFocus: false
        };
    },
    computed: {
        pathI18n() {
            return this.isErrorPage ? "error" : "enter";
        }
    },
    methods: {
        clicked() {
            this.$emit("clicked", false);
        },
        isUserFocusing(bool) {
            this.isFocus = bool;
        }
    }
};
</script>

<style lang="scss" scoped>
.c-enter-tips-audio-with-out-btn {
    @include remove-button-style;
    cursor: pointer;
    &__inner {
        display: grid;
        grid-auto-flow: column;
        align-items: center;
        gap: var(--grid-gutter-half);
    }

    @media #{md("lg")} {
        position: fixed;
        right: var(--grid-gutter);
        bottom: var(--grid-gutter);
    }
    &--focus,
    &:hover {
        .c-shared-animated-icon-arrow {
            @include transform(translate(0.5rem, 0px));
        }
    }
    &--fixed {
        display: flex;
        position: fixed;
        justify-content: center;
        right: var(--grid-gutter);
        bottom: var(--grid-gutter);
        width: 100%;

        @media #{md("lg")} {
            width: inherit;
            justify-content: flex-end;
        }
        > div {
            max-width: 26rem;
            @media #{md("lg")} {
                max-width: 100%;
            }
        }
    }
    &__left {
        @include transform(translateX(50px));
        display: grid;
        grid-auto-flow: column;
        justify-content: center;
        gap: var(--grid-gutter-half);
        opacity: 0;

        &__copy {
            @include lightCopy;
            max-width: 16rem;
            text-align: left;
            display: flex;
            align-items: center;
        }
    }
}
</style>
