<template>
    <div class="c-stories-more-siblings">
        <shared-list
            v-if="siblings.length"
            :siblings="siblings"
            :current-story="currentStory"
            :is-user-scrolling="isUserScrolling"
            :current-item-index="currentStory"
            :filtered-list="filteredList"
            :current-sibling="siblings[currentStory]"
        />
    </div>
</template>

<script>
import SharedList from "@/templates/components/Shared/SharedList/SharedList";
export default {
    components: {
        SharedList
    },
    props: {
        currentStory: {
            type: Number,
            required: true
        },

        isUserScrolling: {
            type: Boolean,
            required: false,
            default: false
        },

        siblings: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            timelines: {
                activeSibbling: null,
                scrollTrigger: [],
                timelines: []
            },
            current: 0,
            isFirstBtnVisible: false
        };
    },

    computed: {
        // this is a bandaid for https://mambomambo-team.atlassian.net/jira/software/projects/CN/boards/2?selectedIssue=CN-470, need refactor later
        filteredList() {
            return this.siblings.filter(x => x.id !== String(this.siblings[this.currentStory].id));
        }
    },

    methods: {}
};
</script>

<style lang="scss" scoped>
.c-stories-more-siblings {
}
</style>
