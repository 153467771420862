<template>
    <div
        class="c-neighbourhood-nav-btn-map-circle"
        :class="{ 'c-neighbourhood-nav-btn-map-circle--story': isStory }"
        :style="`transform: rotate(${rotationCircle}deg);`"
    >
        <!-- circle -->

        <!-- progress -->
        <neighbourhood-nav-btn-map-circle-progress />
        <!-- svg -->
        <div class="c-neighbourhood-nav-btn-map-circle__svg">
            <shared-map-icon :neighbourhood-name="neigbhourhoodName" />
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import NeighbourhoodNavBtnMapCircleProgress from "@/templates/components/Neighbourhood/NeighbourhoodNav/NeighbourhoodNavBtnMap/NeighbourhoodNavBtnMapCircle/NeighbourhoodNavBtnMapCircleProgress";
import SharedMapIcon from "@/templates/components/Shared/SharedMapIcon/SharedMapIcon";
export default {
    components: {
        NeighbourhoodNavBtnMapCircleProgress,
        SharedMapIcon
    },
    data() {
        return {
            neigbhourhoodName: null
        };
    },

    computed: {
        ...mapState({
            currentEntry: state => state.global.currentEntry,
            rotation: state => state.constellations.rotation
        }),
        neigbhourhoodUri() {
            return this.currentEntry.district && this.currentEntry.district.uri
                ? this.currentEntry.district.uri
                : this.currentEntry.uri;
        },
        isStory() {
            return this.currentEntry.type && this.currentEntry.type !== "district";
        },
        rotationCircle() {
            return this.isStory ? 0 : this.rotation;
        }
    },
    watch: {
        neigbhourhoodUri(val) {
            this.uriNeigbourhood(val);
        }
    },
    methods: {
        uriNeigbourhood(uri) {
            // avoid flicker bug
            return uri ? (this.neigbhourhoodName = uri) : null;
        }
    }
};
</script>

<style lang="scss" scoped>
.c-neighbourhood-nav-btn-map-circle {
    --rotation-speed: 4.5s;

    @include transition(var(--rotation-speed) ease all);
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    justify-items: center;
    align-content: center;

    .is-chrome & {
        --rotation-speed: 2s;
    }
    .is-mobile & {
        // its a bit repetitive but bug proof
        --rotation-speed: 4.5s;
    }
    &--story {
        --rotation-speed: 0.5s; // rotate to 0deg when user going to a story
    }

    &__svg {
        @include transform(translate(0px, -50%));
        top: 50%;
        position: absolute;
        padding: 10px;
    }
}
</style>
