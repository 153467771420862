<template>
    <div class="c-enter-cta-btn-arrow">
        <div class="c-enter-cta-btn-arrow__center">
            <shared-animated-icon-arrow :is-dark="true" />
        </div>
    </div>
</template>

<script>
import SharedAnimatedIconArrow from "@/templates/components/Shared/SharedAnimatedIcons/SharedAnimatedIconArrow";
export default {
    components: {
        SharedAnimatedIconArrow
    }
};
</script>

<style lang="scss" scoped>
.c-enter-cta-btn-arrow {
    display: flex;
    justify-content: center;
    &__center {
        @include transform(translateY(2rem));
        display: flex;
        flex-direction: column;
        max-width: 5rem;
        position: relative;
        height: 1.3rem;
    }
}
</style>
