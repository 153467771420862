<template>
    <div class="c-navigation-mobile-down">
        <div class="c-navigation-mobile-down__inner">
            <shared-explore
                v-if="currentEntry"
                :title="$t('neigbourhood.story.more.return.title')"
                :copy="entryTitle"
                :is-visible="isButtonVisible"
                @clicked="clicked"
            />
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import SharedExplore from "@/templates/components/Shared/SharedExplore/SharedExplore";
export default {
    components: {
        SharedExplore
    },
    props: {
        isButtonVisible: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    computed: {
        ...mapState({
            currentEntry: state => state.global.currentEntry
        }),
        entryTitle() {
            return this.currentEntry.title ? this.currentEntry.title : this.currentEntry.heading;
        }
    },
    methods: {
        clicked() {
            this.$store.commit("global/toggleNavigation");
        }
    }
};
</script>

<style lang="scss" scoped>
.c-navigation-mobile-down {
    display: flex;
    flex: 0.75;
    width: 100%;
    &__inner {
        @include transform(translateY(5rem));
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        opacity: 0;
    }
}
</style>
