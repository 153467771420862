<template>
    <!-- this component only goals is to improve the scroll animation -->
    <div class="c-stories-more-spacer">
        <div class="c-stories-more-spacer__inner" />
    </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.c-stories-more-spacer {
    @include transition(1s linear all);
    background-color: var(--color-purple-dark);
    position: relative;
    width: 100%;

    // temporary commented in case my fix fails
    // padding-bottom: 30vh;
    // @media (max-height: 800px) {
    //     padding-bottom: 50vh;
    // }
    // padding-bottom: 300px;
    // height: 100%;

    div {
        margin-top: 25vh;
    }
}
</style>
