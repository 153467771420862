<template>
    <section class="c-enter-main-title">
        <div class="c-enter-main-title__kicker" :class="{ 'c-enter-main-title__kicker--error': isErrorPage }">
            <p>{{ $t(`${pathI18n}.kicker`) }}</p>
        </div>

        <div v-if="!isErrorPage" class="c-enter-main-title__constellations">
            <h1 class="c-enter-main-title__constellations__logo">
                <anim-text
                    :text="$t('shared.constellation')"
                    class=" rotate c-enter-main-title__constellations__logo__copy"
                    tag="p"
                    :visible="isAnimationStarted"
                    :reveal="true"
                    :chars="true"
                    :word="false"
                    :lines="false"
                    :is-axis-y="true"
                />
            </h1>
        </div>
    </section>
</template>

<script>
import { mapState } from "vuex";

import AnimText from "@/templates/objects/AnimText";

export default {
    components: {
        AnimText
    },

    props: {
        isErrorPage: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    computed: {
        ...mapState({
            isAnimationStarted: state => state.home.isAnimationStarted
        }),
        pathI18n() {
            return this.isErrorPage ? "error" : "enter";
        }
    }
};
</script>

<style lang="scss" scoped>
.c-enter-main-title {
    display: grid;
    height: 100%;
    grid-column: 1/2;
    grid-row: 1/1;
    justify-items: center;
    padding-top: var(--grid-gutter-double);

    @media #{md("xs")} {
        grid-row: 2/3;
        padding-top: 0px;
        // @media (max-height: 750px) {
        //     grid-row: 1/1;
        // }
    }

    &__kicker {
        @include transform(translateY(100px));
        max-width: 27rem;
        text-align: center;
        opacity: 0;
        .c-shared-page-cta & {
            padding-bottom: var(--grid-gutter); // quick fix, might come back later
        }

        p {
            line-height: 2rem;
            color: var(--color-purple-light);
            white-space: pre-wrap;
        }

        &--error {
            display: flex;
            align-items: flex-end;
        }
    }

    &__constellations {
        &__logo {
            &::v-deep p {
                @include largeTitle;
            }
            &::v-deep .o-at__c {
                @include rotateLetterInTitle(0.9rem, 1.4rem, 0, -0.3rem);
                @include tittleShadow();
            }
        }
    }
}
</style>
