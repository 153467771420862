<template>
    <li
        class="c-shared-stars-item"
        :class="{ 'c-shared-stars-item--visible': isVisible }"
        :style="`--left:${left}%; --top:${top}%; --scale:${scale}`"
    ></li>
</template>

<script>
export default {
    data() {
        return {
            top: 0,
            left: 0,
            scale: 0,
            opacity: 0,
            isVisible: false,
            timer: 1000,
            interval: null
        };
    },
    mounted() {
        this.generateRandomPosition();
        this.createAnimation();
    },
    beforeDestroy() {
        this.clearInterval();
    },
    methods: {
        ////////////////////////////////
        //       START GENERATE STARS
        ////////////////////////////////
        generateRandomPosition() {
            this.top = this.randomIntFromInterval(1, 100);
            this.left = this.randomIntFromInterval(1, 100);
            this.scale = Math.random();
        },
        randomIntFromInterval(min, max) {
            // min and max included
            return Math.floor(Math.random() * (max - min + 1) + min);
        },

        ////////////////////////////////
        //       END GENERATE STARS
        ////////////////////////////////

        ////////////////////////////////
        //       START ANIMATION
        ////////////////////////////////
        createAnimation() {
            // generate a random timer
            this.setRandomTime();
            // timeout
            this.interval = setInterval(() => {
                this.toggleVisibility();
            }, this.timer);
        },
        setRandomTime() {
            this.timer = this.randomIntFromInterval(2000, 5000);
        },

        toggleVisibility() {
            this.isVisible = !this.isVisible;
        },
        clearInterval() {
            clearInterval(this.timer);
            this.timer = null;
        }
        ////////////////////////////////
        //       END ANIMATION
        ////////////////////////////////
    }
};
</script>

<style lang="scss">
.c-shared-stars-item {
    --color-shadow-stars: 252, 239, 239;
    @include transition(2s ease opacity);
    @include customShadow(0px 0px 20px 0px rgba(var(--color-shadow-stars), 0.5));
    --left: 0%;
    --top: 0%;
    --scale: 0;
    --opacity: 0;
    @include transform(scale(var(--scale)));
    position: absolute;
    background: var(--color-light);
    z-index: 18;
    border-radius: 100%;
    height: 0.3em;
    width: 0.3em;

    left: var(--left);
    top: var(--top);
    opacity: var(--opacity);

    @media #{md('xs')} {
        height: 0.6em;
        width: 0.6em;
    }

    &--visible {
        --opacity: 1;
    }
    .c-nbhd-story--light & {
        --color-shadow-stars: 28, 12, 46;
    }
}
</style>
