<template>
    <ul class="c-stories-article-illustrated">
        <!-- bread crumbs -->
        <stories-article-illustrated-progress
            v-if="sequences && sequences.length"
            :sequences="sequences.length"
            :active-step="activeStep"
        />
        <!-- main -->
        <stories-article-illustrated-main
            v-if="sequences && sequences.length"
            :sequences="sequences"
            :position-copy="positionCopy"
            :is-scroll-disabled="isScrollDisabled"
            :active-step="activeStep"
            @activeStep="updatedActiveStep"
            @goToNextSection="goToNextSection"
        />
    </ul>
</template>

<script>
import { mapState } from "vuex";

import StoriesArticleIllustratedMain from "@/templates/components/Stories/StoriesArticle/StoriesArticleIllustrated/StoriesArticleIllustratedMain/StoriesArticleIllustratedMain";
import StoriesArticleIllustratedProgress from "@/templates/components/Stories/StoriesArticle/StoriesArticleIllustrated/StoriesArticleIllustratedProgress/StoriesArticleIllustratedProgress";
export default {
    components: {
        StoriesArticleIllustratedMain,
        StoriesArticleIllustratedProgress
    },

    data() {
        return {
            activeStep: 0
        };
    },
    props: {
        isScrollDisabled: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        ...mapState({
            currentEntry: state => state.global.currentEntry,
            isScrolledDown: state => state.constellations.isScrolledDown
        }),

        sequences() {
            return this.currentEntry.sequences && this.currentEntry.sequences.length
                ? this.currentEntry.sequences
                : this.currentEntry.sequencesText;
        },

        positionCopy() {
            return this.currentEntry.textOnLeft ? "left" : "right";
        }
    },

    methods: {
        updatedActiveStep(val) {
            this.activeStep = val;

            this.updateScrollProgression(val);
        },

        ////////////////////////////////
        //       START UPDATE STORY SCROLL PROGRESS
        ////////////////////////////////
        updateScrollProgression(progress) {
            this.emitPageScrollGlobally(this.scrollProgress(progress));
        },
        scrollProgress(progress) {
            // calculate total progress story scroll / 2 because 50% is the section story more scroll
            return ((progress / this.sequences.length) * 100) / 2;
        },
        emitPageScrollGlobally(progress) {
            this.isScrolledDown ? null : this.$store.commit("constellations/updateStoryProgression", progress);
        },
        ////////////////////////////////
        //       END UPDATE STORY SCROLL PROGRESS
        ////////////////////////////////

        goToNextSection() {
            this.$emit("goToNextSection");
        }
    }
};
</script>

<style lang="scss" scoped>
.c-stories-article-illustrated {
    @include reset-list;
    position: static; // allow the image to be position on mobile
    display: flex;
    flex-direction: row;
    height: 50%;

    @media #{md("xs")} {
        height: 100%;
    }
}
</style>
