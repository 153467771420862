import { fetchApi } from "src/graphql/config";
import queries from "src/graphql/queries";
import { linkResolver } from "src/router";
import { STORE_MODULES } from "src/constants";

/*
 * Global
 */

// State
const state = {
    navs: [],
    neighbourhoods: [],
    settings: {},
    currentEntry: {},
    currentEntryName: null,
    isDev: false,

    isHeaderLogoVisible: false,
    isHeaderVisible: false,
    transitionOutUrl: false,

    // TODO Move to a separate store

    /*------------------------------
    Start Mobile Nav
    ------------------------------*/
    isNavOpen: false,
    isNavBackgroundLight: false,
    /*------------------------------
    End Mobile Nav
    ------------------------------*/
    /*------------------------------
    Start AUDIO
    ------------------------------*/
    isMute: true,
    isPlaying: false,
    currentTrack: null,
    upComingAudioTrack: null
    /*------------------------------
    End AUDIO
    ------------------------------*/
};

// Getters
const getters = {
    getNeighbourhoodBySlug: state => slug => state.neighbourhoods.find(n => n.slug === slug)
};

// Actions
const actions = {
    // Initial load for navigation, globals, etc...
    initLoad(store) {
        return Promise.all([store.dispatch("loadGeneral")]).catch(e => {
            console.error(e.message); // eslint-disable-line
        });
    },
    loadGeneral(store) {
        fetchApi(queries.general)
            .then(data => {
                const model = data.settings.model[0] || null,
                    audio = data.settings.audio[0] || null,
                    neighbourhoods = data.neighbourhoods,
                    nav = data.navs.navNodes;

                // Format nav and add route path to each nav item
                nav.forEach(item => {
                    item.entry = item.entry[0];
                    item.path = linkResolver(item.entry.section);
                    item.url = item.entry.section == "home" ? "/" : `/${item.entry.uri}`;
                });

                store.commit("setNav", nav);
                store.commit("setNeighbourhoods", neighbourhoods);
                store.commit("setSettings", { model, audio });
            })
            .catch(e => {
                console.error(e.message); // eslint-disable-line
            });
    },
    loadContent(store, to) {
        return new Promise((resolve, reject) => {
            // Timer in seconds
            const timer = new Date();

            // Start loader
            store.dispatch("loader/startLoad", null, { root: true });

            // Load entry content
            let fetchContent;
            const handle = to.meta.section;
            const singleEntry = to.meta.single;

            // Empty promise if nothing to load (static entry)
            if (typeof handle === "undefined") {
                fetchContent = new Promise(r => r({}));
            }
            // For single entries, load entry with handle
            else if (singleEntry) {
                fetchContent = store.dispatch("entries/loadSingle", handle, { root: true });

                // Vuex Modules exceptions (that extend the crud.js)
            } else if (STORE_MODULES.includes(handle)) {
                const slug = to.params.slug;
                fetchContent = store.dispatch(`${handle}/loadElement`, slug, { root: true });

                // Use default `entries` as fallback, loads the Entry using both handle and slug
            } else {
                const slug = to.params.slug;
                fetchContent = store.dispatch("entries/loadEntry", { handle, slug }, { root: true });
            }

            fetchContent
                .then(entry => {
                    // Duration in ms of the fetchContent + 300ms for the loader cover delay (see loader component) + 10ms for latency
                    let delay = timer - new Date() + 800;
                    delay = delay < 0 ? 0 : delay;

                    setTimeout(() => {
                        store.commit("setEntry", {});
                        store.commit("setEntryName", to.name);
                        resolve(entry);

                        // Timeout to let template to render data
                        setTimeout(() => {
                            // Set entry after router entry has updated to avoid js errors
                            store.commit("setEntry", entry);
                            store.dispatch("loader/endLoad", null, { root: true });
                        }, 10);
                    }, delay);
                })
                .catch(e => {
                    reject(e);
                    store.dispatch("loader/endLoad", null, { root: true });
                });
        });
    }
};

// Mutations
const mutations = {
    setNav(state, nav) {
        state.navs = nav;
    },
    setNeighbourhoods(state, neighbourhoods) {
        state.neighbourhoods = neighbourhoods;
    },
    setSettings(state, settings) {
        state.settings = settings;
    },
    setEntry(state, entry) {
        state.currentEntry = entry;
    },
    setEntryName(state, name) {
        state.currentEntryName = name;
    },

    toggleHeaderLogo(state, bool) {
        state.isHeaderLogoVisible = bool;
    },
    toggleHeaderAnimation(state, bool) {
        state.isHeaderVisible = bool;
    },
    updatePageTransitionOutUrl(state, url) {
        state.transitionOutUrl = url;
    },
    // TODO: Move to a separate store
    /*------------------------------
    Start Navigation
    ------------------------------*/
    toggleNavigation(state) {
        state.isNavOpen = !state.isNavOpen;
    },
    toggleNavigationBackground(state, bool) {
        state.isNavBackgroundLight = bool;
    },
    /*------------------------------
    End Navigation
    ------------------------------*/
    /*------------------------------
    Start Audio
    ------------------------------*/
    toggleMute(state, bool) {
        state.isMute = bool;
    },
    togglePlayback(state, bool) {
        state.isPlaying = bool;
    },
    updateCurrentTrack(state, url) {
        state.currentTrack = url;
    },
    updateUpComingTrack(state, url) {
        state.upComingAudioTrack = url;
    }

    /*------------------------------
    End Audio
    ------------------------------*/
};

// Export module
export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true
};
