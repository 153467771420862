<template>
    <div class="c-credit-list">
        <!-- :current-story="0" -->
        <!-- :sibblings="sequences" -->
        <shared-list
            v-if="this.isListReady"
            :is-user-scrolling="true"
            :current-item-index="0"
            :filtered-list="list"
            :current-sibling="list[0]"
            :is-story="false"
            :scroller="null"
            tag="div"
        />
        <!-- :is-rotation-overwritten="isRotationOverwritten" -->
    </div>
</template>

<script>
import SharedList from "@/templates/components/Shared/SharedList/SharedList";
export default {
    components: {
        SharedList
    },
    props: {
        sequences: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    data() {
        return {
            list: [],
            isListReady: false
        };
    },
    watch: {
        sequences() {
            this.filterSequences();
        }
    },
    mounted() {
        this.clearLoop();
        this.filterSequences();
    },
    methods: {
        filterSequences() {
            this.sequences.length && !this.list.length ? this.loopEachCredits() : null;
        },
        loopEachCredits() {
            this.sequences.forEach((element, index) => {
                this.pushToArray(element);
                this.addIdToSequences(index);
                index + 1 === this.sequences.length ? this.closeLoop(true) : null;
            });
        },
        pushToArray(element) {
            this.list.push({ element });
        },
        addIdToSequences(index) {
            this.list[index].id = String(index);
        },
        closeLoop(bool) {
            this.isListReady = bool;
        },
        clearLoop() {
            this.isLoopStarted = false;
            this.list = [];
            this.closeLoop(false);
        }
    }
};
</script>

<style lang="scss" scoped>
.c-credit-list {
    &::v-deep .c-shared-list-item-item-title {
        @include transform(translateY(0));
    }
}
</style>
