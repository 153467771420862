<template>
    <div class="c-neighbourhood-labels" :class="{ 'c-neighbourhood-labels--visible': areLabelsVisible }">
        <ul class="c-neighbourhood-labels__list">
            <neighbourhood-labels-item
                v-for="(label, index) in labels"
                :key="index"
                :label="label"
                :index-label="index"
            />
        </ul>
    </div>
</template>

<script>
import { gsap } from "gsap/all";
import { mapState } from "vuex";
import NeighbourhoodLabelsItem from "@/templates/components/Neighbourhood/NeighbourhoodLabels/NeighbourhoodLabelsItem/NeighbourhoodLabelsItem";
export default {
    components: {
        NeighbourhoodLabelsItem
    },
    props: {
        labels: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            timelines: [],
            constellations: [],
            isAnimatedPlayed: false
        };
    },
    computed: {
        ...mapState({
            areLabelsVisible: state => state.constellations.areLabelsVisible
        })
    },
    watch: {
        areLabelsVisible(bool) {
            bool && !this.isAnimatedPlayed ? this.playAllAnimations() : null;
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.buildArraysOfConstellations();
        });
    },
    beforeDestroy() {
        this.destroyTimelines();
    },

    methods: {
        ////////////////////////////////
        //       START SETUP THE ARRAYS OF ANIMATIONS
        ////////////////////////////////
        buildArraysOfConstellations() {
            // loop all labels
            this.labels.forEach((label, index) => {
                // push new constellations to array
                label.constellation.id ? this.addConstellation(label.constellation.id) : null;
                index + 1 === this.labels.length ? this.loopAllTimelinesToCreate() : null;
            });
        },
        addConstellation(constellation) {
            this.findIndexInArrayOfConstellation(constellation) ? null : this.addConstellationToArray(constellation);
        },

        findIndexInArrayOfConstellation(key) {
            return this.constellations.find(x => x === key);
        },
        addConstellationToArray(key) {
            this.constellations.push(key);
        },
        loopAllTimelinesToCreate() {
            this.constellations.forEach((key, index) => {
                this.isClassExist(`c-neighbourhood-labels-item-line__line--${key}`)
                    ? this.initAnimationLines(key, index)
                    : null;
            });
        },
        isClassExist(nameOfTheClass) {
            // true/false is easier to read even on a ternary, please don't judge too fast :D
            return document.getElementsByClassName(nameOfTheClass).length ? true : false;
        },
        ////////////////////////////////
        //       END SETUP THE ARRAYS OF ANIMATIONS
        ////////////////////////////////

        ////////////////////////////////
        //       START ANIMATION
        ////////////////////////////////
        initAnimationLines(key, index) {
            const easeAnimation = "expo.inOut";
            const durationAnimation = 2;

            this.timelines.push({
                key: key,
                timeline: null
            });
            this.timelines[index].timeline = gsap.timeline({
                paused: true
            });
            this.timelines[index].timeline.to(`.c-neighbourhood-labels-item-line__line--${key}`, {
                width: "100%",
                ease: easeAnimation,
                duration: durationAnimation,
                stagger: {
                    // wrap advanced options in an object
                    each: 0.5,
                    from: "left",
                    grid: "auto",
                    ease: easeAnimation
                }
            });
        },

        //======= START PLAY ANIMATION =======//

        playAllAnimations() {
            this.toggleAnimation(true);
            this.destroyArrayOfConstellations();
            for (let i in this.timelines) {
                this.timelines[i].timeline.play();
            }
        },
        toggleAnimation(bool) {
            this.isAnimatedPlayed = bool;
        },

        //======= END PLAY ANIMATION =======//

        ////////////////////////////////
        //       END ANIMATION
        ////////////////////////////////

        ////////////////////////////////
        //       START ON DESTROY
        ////////////////////////////////
        destroyArrayOfConstellations() {
            // it's not used anymore after its used for the animation
            this.constellations = [];
        },
        onDestroy() {
            // destroy GSAP timelines
            this.timelines.length ? this.destroyTimelines() : null;
            // destroy arrays
            this.constellations.length ? this.destroyArrayOfConstellations() : null;
        },

        destroyTimelines() {
            // destroy GSAP timelines
            for (let i in this.timelines) {
                this.timelineKiller(i);
                i + 1 === this.timelines.length ? this.destroyArrayOfTimelines() : null;
            }
        },
        timelineKiller(index) {
            this.timelines[index]
                ? (this.timelines[index].timeline.kill(), (this.timelines[index].timeline = null))
                : null;
        },
        destroyArrayOfTimelines() {
            this.timelines = [];
        }

        ////////////////////////////////
        //       END ON DESTROY
        ////////////////////////////////
    }
};
</script>

<style lang="scss" scoped>
.c-neighbourhood-labels {
    display: flex;
    opacity: 1;

    pointer-events: none;
    &--visible {
        pointer-events: auto;
    }

    .c-neighbourhood--hidden & {
        opacity: 0; // avoid some flickering issue on prod server
    }

    &__list {
        @include reset-list;
    }
}
</style>
