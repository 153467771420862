<template>
    <div class="c-shared-tips-info" :class="{ 'c-shared-tips-info--vertical': isVertical }">
        <ul class="c-shared-tips-info__list">
            <shared-tips-info-icon v-if="tips.icon" :icon-name="tips.icon" :is-credit="isCredit" />
            <shared-tips-info-copy :copy="tips.copy" :is-vertical="isVertical" />
        </ul>
    </div>
</template>

<script>
import SharedTipsInfoCopy from "@/templates/components/Shared/SharedTips/SharedTipsInfo/SharedTipsInfoCopy";
import SharedTipsInfoIcon from "@/templates/components/Shared/SharedTips/SharedTipsInfo/SharedTipsInfoIcon";
export default {
    components: {
        SharedTipsInfoCopy,
        SharedTipsInfoIcon
    },
    props: {
        tips: {
            type: Object,
            required: true
        },
        isSoloCredit: {
            type: Boolean,
            required: false,
            default: false
        },

        isVertical: {
            type: Boolean,
            required: false,
            default: false
        },
        isCredit: {
            type: Boolean,
            required: false,
            default: false
        }
    }
};
</script>

<style lang="scss" scoped>
.c-shared-tips-info {
    @include transform(translateY(100px));

    --info-row-gap: var(--grid-gutter);
    --info-column-gap: var(--grid-gutter);
    --info-direction: column;

    display: grid;
    align-items: center;
    justify-content: flex-start;
    opacity: 0;
    grid-template-rows: repeat(1, 1fr);
    width: 100%;

    @media #{md('xs')} {
        grid-template-rows: repeat(3, 1fr);
        justify-content: center;
        .c-home-intro & {
            grid-template-rows: 0.5fr 1fr auto;
        }
    }

    &--vertical {
        --info-row-gap: var(--grid-gutter);
        --info-column-gap: 0px;
        --info-direction: row;
        display: flex;
        align-content: flex-start;
        justify-content: center;
    }
    .-debug & {
        @include transform(translateY(0px));
        opacity: 1;
    }
    &__list {
        @include reset-list;

        max-width: 230px;
        display: grid;
        grid-auto-flow: var(--info-direction);
        gap: var(--info-row-gap);
        align-items: flex-start;
        align-self: flex-start;
        grid-row: 1/2;
        @media #{md('xs')} {
            --info-row-gap: var(--grid-gutter);
            --info-direction: row;
            grid-row: 2/3;
            max-width: 150px;
        }
    }
}
</style>
