<template>
    <div class="c-home-navigation-control | grid" :class="{ 'c-home-navigation-control--scaling': transitionOutUrl }">
        <home-navigation-control-cta ref="controlCta" :is-navigation-visible="isNavigationVisible" :slides="slides" />
        <home-navigation-control-buttons :slides="slides" />
    </div>
</template>

<script>
import { mapState } from "vuex";

import { gsap } from "gsap/all";

import HomeNavigationControlButtons from "components/Home/HomeNavigation/HomeNavigationControl/HomeNavigationControlButtons/HomeNavigationControlButtons";
import HomeNavigationControlCta from "components/Home/HomeNavigation/HomeNavigationControl/HomeNavigationControlCta/HomeNavigationControlCta";

export default {
    components: {
        HomeNavigationControlCta,
        HomeNavigationControlButtons
    },
    props: {
        slides: {
            type: Array,
            required: true
        }
    },
    mounted() {
        this.initAnimationTimeline();
        window.addEventListener("keyup", this.onKeyBoardEvent);
    },
    beforeDestroy() {
        this.destroyTimelines();
        window.removeEventListener("keyup", this.onKeyBoardEvent);
    },

    data() {
        return {
            introTimeline: null,
            isNavigationVisible: false
        };
    },
    computed: {
        ...mapState({
            isExperienceStarted: state => state.home.isExperienceStarted,
            isSceneReady: state => state.home.isSceneReady,
            transitionOutUrl: state => state.global.transitionOutUrl,
            current: state => state.home.current
        })
    },
    watch: {
        isExperienceStarted() {
            this.isSceneAndExperienceReady();
        },
        isSceneReady() {
            this.isSceneAndExperienceReady();
        }
    },

    methods: {
        handleEnterClick() {
            const activeBtn = this.$refs.controlCta.$refs.ctaBtn
            activeBtn.redirectUserWithDelay()
        },
        // Handle keyboard events
        onKeyBoardEvent(event) {
            const key = event.key
            let index = this.current

            switch(key) {
                case 'ArrowRight':
                    index += 1
                    this.udpateCurentSlideGlobaly(index)
                    break;

                case 'ArrowLeft':
                    index -= 1
                    this.udpateCurentSlideGlobaly(index)
                    break;

                case 'Enter': this.handleEnterClick()
                    break;

                default : return
            }
        },
        udpateCurentSlideGlobaly(index) {
            this.$store.commit("home/updateCurrent", { value: index });
        },
        ////////////////////////////////
        //       START TIMELINE
        ////////////////////////////////

        //======= START SETUP TIMELINE =======//

        initAnimationTimeline() {
            this.introTimeline = gsap.timeline({});
            const durationAnimation = 1.2;

            // show cta (title neigbourhood + btn)
            this.introTimeline.to(".c-home-navigation-control-cta-btn__inner", {
                duration: durationAnimation,
                scale: 1,
                opacity: 1,

                // yPercent: 70,
                ease: "power3.out"
            });
            this.introTimeline.to(
                ".c-home-navigation-control-cta-btn",
                {
                    duration: durationAnimation,
                    x: 0,
                    yPercent: 0,

                    // yPercent: 70,
                    ease: "power3.out"
                },
                "-=1"
            );
            this.introTimeline.to(
                ".c-home-navigation-control-buttons-items",
                {
                    duration: durationAnimation,
                    y: 0,
                    opacity: 1,
                    stagger: {
                        each: 0.1,
                        from: "start"
                    },
                    ease: "power3.out",
                    overwrite: "all"
                },
                "-=0.5"
            );

            this.introTimeline.add(() => {
                this.enableSliderButtonToBeActive();
            }, "-=0.5");
            this.introTimeline.pause();
        },

        //======= START PLAY TIMELINE =======//

        playTimeline() {
            this.isNavigationVisible = true;
            this.introTimeline.play();
        },

        reverseTimeline() {
            this.introTimeline.reverse();
        },

        //======= END PLAY TIMELINE =======//

        //======= END SETUP TIMELINE =======//

        ////////////////////////////////
        //       END TIMELINE
        ////////////////////////////////

        ////////////////////////////////
        //       START ANIMATION
        ////////////////////////////////
        /*------------------------------
       Start animation of this component
       ------------------------------*/
        isSceneAndExperienceReady() {
            this.isSceneReady && this.isExperienceStarted ? this.startExperience() : null;
        },
        startExperience() {
            this.playTimeline();
        },
        /*------------------------------
       End animation of this component
       ------------------------------*/
        /*------------------------------
       Start Animation on a far away child (btn slider)
       ------------------------------*/
        enableSliderButtonToBeActive() {
            // on the animation set by Guillaume, the buttons are active at the end of the animation
            this.$store.commit("home/toggleActiveSlider", true);
        },
        /*------------------------------
       End Animation on a far away child (btn slider)
       ------------------------------*/
        ////////////////////////////////
        //       END ANIMATION
        ////////////////////////////////

        ////////////////////////////////
        //       START ON BEFORE DESTROY
        ////////////////////////////////
        destroyTimelines() {
            if (!this.introTimeline) return;
            this.introTimeline.kill();
            this.introTimeline = null;
        }
        ////////////////////////////////
        //       END ON BEFORE DESTROY
        ////////////////////////////////
    }
};
</script>

<style lang="scss" scoped>
.c-home-navigation-control {
    display: grid;
    gap: var(--grid-gutter);
    grid-template-columns: repeat(1, 1fr);

    &--scaling {
        @include transition(1s ease-in-out);
        @include transform(scale(0.8));
        opacity: 0;
    }
}
</style>
