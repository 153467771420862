<template>
    <div class="c-neighbourhood-nav-btn-copy">
        <div class="c-neighbourhood-nav-btn-copy__inner">
            <svg viewBox="0 0 117 61">
                <!-- <defs> -->
                <path
                    id="curve"
                    d="M25.545 50.6375C33.539 56.245 44.2179 60 58.5 60C72.7821 60 83.461 56.245 91.4551 50.6375C99.4535 45.027 104.794 37.5407 108.361 30.0354C111.928 22.5288 113.714 15.0157 114.608 9.3749C115.054 6.55571 115.277 4.2074 115.389 2.56574C115.445 1.745 115.472 1.10112 115.486 0.663611C115.493 0.444863 115.497 0.277727 115.498 0.16589C115.499 0.109972 115.5 0.0678812 115.5 0.0400786L115.5 0.00914931L115.5 0.00183048V0.00022649C115.5 5.52973e-10 115.5 5.51814e-10 116 5.51814e-10C116.5 5.51814e-10 116.5 0.000463278 116.5 0.00115644L116.5 0.0128911L116.5 0.0476213C116.5 0.0779775 116.499 0.122664 116.498 0.181217C116.496 0.298323 116.493 0.470908 116.486 0.695276C116.471 1.144 116.443 1.79992 116.387 2.63347C116.273 4.30041 116.047 6.67867 115.595 9.53135C114.692 15.2343 112.884 22.8462 109.264 30.4646C105.643 38.0843 100.203 45.723 92.0293 51.4562C83.8515 57.1925 72.9679 61 58.5 61C44.0321 61 33.1485 57.1925 24.9707 51.4562C16.7973 45.723 11.3569 38.0843 7.73591 30.4646C4.1156 22.8462 2.30795 15.2343 1.4046 9.53135C0.952726 6.67867 0.726617 4.30041 0.613454 2.63348C0.556866 1.79992 0.528504 1.144 0.514288 0.695276C0.50718 0.470908 0.503608 0.298323 0.501813 0.181217C0.500915 0.122664 0.500462 0.0779778 0.500233 0.0476216L0.50003 0.0128913L0.500004 0.00369429L0.5 0.0011567C0.499999 0.00046353 0.499999 5.51814e-10 0.999999 5.51814e-10C1.49706 5.51814e-10 1.49998 -1.928e-06 1.5 0.000222239C1.5 0.000223564 1.5 0.000220898 1.5 0.000222239L1.5 0.00183023L1.50002 0.00914905L1.5002 0.0400783C1.50041 0.067881 1.50084 0.109972 1.5017 0.16589C1.50341 0.277727 1.50686 0.444863 1.51379 0.663611C1.52765 1.10112 1.55544 1.745 1.61116 2.56574C1.7226 4.2074 1.94572 6.55571 2.39228 9.3749C3.28581 15.0157 5.07192 22.5288 8.63911 30.0354C12.2057 37.5407 17.5465 45.027 25.545 50.6375Z"
                />
                <!-- </defs> -->
                <text x="3.5">
                    <textPath xlink:href="#curve">
                        <tspan>{{ $t("neigbourhood.navigation.map") }}</tspan>
                    </textPath>
                </text>
            </svg>
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.c-neighbourhood-nav-btn-copy {
    position: relative;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    font-family: ff("body");
    font-size: fs("regular");
    &__inner {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: -43px;

        @media #{md('xs')} {
            margin-top: -38px;
        }

        svg {
            left: 0%;
            width: 100%;
            max-width: 117px;
            fill: none;
        }
        text {
            @include transition(1s ease all);
            fill: var(--color-light);
        }
    }
}
</style>
