/*
 * Constellations
 */
import { fetchApi } from "src/graphql/config";
import { fetchJSON } from "src/graphql/config";
import queries from "src/graphql/queries";

import Crud from "../crud";
const crud = new Crud("constellations");

// State
const state = {
    ...crud.state,
    spotLightPosition: {
        x: 0,
        y: 0,
        z: 0
    },
    isSpotLightVisible: false,
    areLabelsVisible: false,
    rotation: 0,
    isSceneReady: false,
    isConstellationHeaderVisible: false,

    // TODO MOVE TO A SEPARATED STORE
    isMapVisibleFromStory: false,
    goToSiblingUrl: null,
    isLightModeOverwritten: false,
    isTipsVisible: true,

    isUserReturnToMapFromNav: false,

    scrollProgressStory: 0,
    isScrollProgressEased: false,
    isScrolledDown: false
};

// Getters
const getters = {
    ...crud.getters
};

// Actions
const actions = {
    ...crud.actions,
    getSiblingStories(store, entry) {
        if (entry.type != "storyText" && entry.type != "storyAudio") {
            throw "The entry need to be a Story";
        }

        const constellation = entry.constellation;

        return new Promise((resolve, reject) => {
            // Start load
            store.dispatch("loader/startLoad", null, { root: true });

            fetchApi(queries.siblingStories(constellation))
                .then(r => {
                    // Exit on error
                    if (!r || !r.entry) {
                        throw new Error(`Sibling stories failed`);
                    } else {
                        resolve(r.entry.children);

                        // End load
                        store.dispatch("loader/endLoad", null, { root: true });
                    }
                })
                .catch(e => {
                    reject({
                        code: 404,
                        message: e.message
                    });

                    // End load
                    store.dispatch("loader/endLoad", null, { root: true });
                });
        });
    },
    // get district audio when user landed on a story audio
    getDistrictAudio(store, entry) {
        return new Promise((resolve, reject) => {
            // Start load
            store.dispatch("loader/startLoad", null, { root: true });

            fetchApi(queries.constellations(entry))
                .then(r => {
                    // Exit on error
                    if (!r || !r.entry) {
                        throw new Error(`Sibling stories failed`);
                    } else {
                        resolve(r.entry);

                        // End load
                        store.dispatch("loader/endLoad", null, { root: true });
                    }
                })
                .catch(e => {
                    reject({
                        code: 404,
                        message: e.message
                    });

                    // End load
                    store.dispatch("loader/endLoad", null, { root: true });
                });
        });
    },

    getDistrictInfo(store, entry) {
        // fetch full info of the disctrict when behing in a story
        return new Promise((resolve, reject) => {
            // Start load
            store.dispatch("loader/startLoad", null, { root: true });

            fetchApi(queries.constellations(entry))
                .then(r => {
                    // Exit on error
                    if (!r || !r.entry) {
                        throw new Error(`Sibling stories failed`);
                    } else {
                        resolve(r.entry);

                        // End load
                        store.dispatch("loader/endLoad", null, { root: true });
                    }
                })
                .catch(e => {
                    reject({
                        code: 404,
                        message: e.message
                    });

                    // End load
                    store.dispatch("loader/endLoad", null, { root: true });
                });
        });
    },

    getNeigbourhoodLabels(store, url) {
        return new Promise((resolve, reject) => {
            // console.log("url", url);
            // Start load
            // store.dispatch("loader/startLoad", null, { root: true });

            fetchJSON(`/api/neighbourhood/${url}.json`)
                .then(r => {
                    // Exit on error
                    if (!r || !r.stories) {
                        throw new Error(`Error ${r}`);
                    } else {
                        resolve(r.stories);
                        // End load
                        // store.dispatch("loader/endLoad", null, { root: true });
                    }
                })
                .catch(e => {
                    reject({
                        code: 404,
                        message: e.message
                    });

                    // End load
                    // store.dispatch("loader/endLoad", null, { root: true });
                });
        });
    }
};

// Mutations
const mutations = {
    // TODO Refactor Mutation Name to Mambo Naming Convention
    /*------------------------------
    Start Scene Status
    ------------------------------*/
    TOGGLE_SCENE(state, bool) {
        state.isSceneReady = bool;
    },
    toggleLabelsVisibility(state, bool) {
        state.areLabelsVisible = bool;
    },
    toggleConstellationHeaderVisibility(state, bool) {
        state.isConstellationHeaderVisible = bool;
    },
    /*------------------------------
    End Scene Status
    ------------------------------*/
    /*------------------------------
    Start Rotation
    ------------------------------*/
    // it's used on the neighbourhood navigation
    UPDATE_ROTATION(state, rotation) {
        state.rotation = rotation;
    },
    /*------------------------------
    End Rotation
    ------------------------------*/
    /*------------------------------
    Start SpotLight Management
    ------------------------------*/
    TOGGLE_LIGHT(state, bool) {
        state.isSpotLightVisible = bool;
    },
    UPDATE_POSITION_LIGHT(state, pos) {
        state.spotLightPosition.x = pos.x;
        state.spotLightPosition.y = pos.y;
        state.spotLightPosition.z = pos.z;
    },
    /*------------------------------
    End SpotLight Management
    ------------------------------*/

    /*------------------------------
    Start Stories Management
    ------------------------------*/

    toggleStoryVisible(state, bool) {
        state.isStoryVisible = bool;
    },
    toggleMapFromStory(state, bool) {
        state.isMapVisibleFromStory = bool;
    },
    userGoToOtherStory(state, url) {
        state.goToSiblingUrl = url;
    },
    overwriteStoryLightMode(state, bool) {
        state.isLightModeOverwritten = bool;
    },

    toggleReturnToMapFromNav(state, bool) {
        state.isUserReturnToMapFromNav = bool;
    },

    updateStoryProgression(state, progress) {
        state.scrollProgressStory = progress;
    },

    toggleScrollProgressEase(state, bool) {
        state.isScrollProgressEased = bool;
    },
    toggleScrollToBottom(state, bool) {
        state.isScrolledDown = bool;
    },

    /*------------------------------
    End Stories Management
    ------------------------------*/

    /*------------------------------
    Start Tips
    ------------------------------*/
    toggleTips(state, bool) {
        state.isTipsVisible = bool;
    },
    /*------------------------------
    End Tips
    ------------------------------*/

    ...crud.mutations
};

// Export module
export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true
};
