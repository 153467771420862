<template>
    <div class="c-error-title">
        <ul class="c-error-title__list">
            <error-title-item
                v-for="index in 3"
                :key="index"
                :item-index="index"
                :copy="copyErrorItem(index)"
                :is-visible="isTitleVisible(index)"
            />
        </ul>
    </div>
</template>

<script>
import ErrorTitleItem from "@/templates/components/Error/ErrorTitle/ErrorTitleItem";
export default {
    components: {
        ErrorTitleItem
    },
    props: {
        visibleTitles: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    methods: {
        copyErrorItem(index) {
            return index === 2 ? "0" : "4";
        },
        isTitleVisible(index) {
            return this.visibleTitles.length >= index;
        }
    }
};
</script>

<style lang="scss" scoped>
.c-error-title {
    &__list {
        @include reset-list;
    }
}
</style>
