<template>
    <button
        class="c-enter-cta-btn"
        :class="{ 'c-enter-cta-btn--overwrite-shadow': isLeaveAnimationStared || isFocus }"
        @click="clicked"
        @focusin="isUserFocusing(true)"
        @focusout="isUserFocusing(false)"
    >
        <ul class="c-enter-cta-btn__inner">
            <!-- copy -->
            <enter-cta-btn-center :copy="copy" />
            <!-- circles -->
            <!-- loader -->
            <enter-cta-btn-loader />
        </ul>
    </button>
</template>

<script>
import { mapState } from "vuex";
import EnterCtaBtnCenter from "@/templates/components/Enter/EnterCta/EnterCtaBtn/EnterCtaBtnCenter";
import EnterCtaBtnLoader from "@/templates/components/Enter/EnterCta/EnterCtaBtn/EnterCtaBtnLoader";
export default {
    components: {
        EnterCtaBtnCenter,
        EnterCtaBtnLoader
    },
    props: {
        copy: {
            type: Object,
            required: false,
            default: null
        }
    },
    computed: {
        ...mapState({
            isSceneReady: state => state.home.isSceneReady
        })
    },
    data() {
        return {
            isLeaveAnimationStared: false,
            isFocus: false
        };
    },
    methods: {
        clicked() {
            this.$emit("clicked", true);
            this.toggleLeaveAnimtion(true);
        },
        toggleLeaveAnimtion(bool) {
            this.isLeaveAnimationStared = bool;
        },
        isUserFocusing(bool) {
            this.isFocus = bool;
        }
    }
};
</script>

<style lang="scss" scoped>
.c-enter-cta-btn {
    --scale: 1;
    --scale-background: 0;
    --shadow-intensity: 0;
    @include radius(50%);
    @include aspectRatioFallBack;

    aspect-ratio: 1 / 1;

    cursor: pointer;
    position: relative;
    z-index: 2;
    opacity: 1;

    &--overwrite-shadow,
    &--overwrite-shadow,
    &:hover {
        --shadow-intensity: 0.8;
    }

    &--disabled {
        opacity: 0.5;
    }

    &__inner {
        @include transform(scale(var(--scale)));
        @include reset-list;
        --padding-cta: 2rem;
        --max-width-circle-btn: 18rem;
        --min-width-circle-btn: 17rem;
        display: flex;
        justify-content: center;
        height: 100%;
        width: 100%;
        padding: var(--padding-cta);
        max-width: var(--max-width-circle-btn);
        max-height: var(--max-width-circle-btn);

        min-width: var(--min-width-circle-btn); // fix some bug on ios
        min-height: var(--min-width-circle-btn); // fix some bug on ios
        @media #{md('xs')} {
            --max-width-circle-btn: 22rem;
            --padding-cta: 5rem;
        }
        @media #{md('xxl')} {
            // --max-width-circle-btn: 16.7rem;
            --padding-cta: 5rem;
        }
        @media #{md('sm')} {
            // --max-width: 18.7rem;
            @media (max-height: 800px) {
                --padding-cta: 3rem;
            }
        }
        &:after {
            @include transition(box-shadow 0.8s ease);
            @include radius(50%);
            @include transform(scale(var(--scale-background)));
            @include box-shadow($intensity: var(--shadow-intensity));

            content: " ";
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: var(--color-light);
        }
        &__copy {
        }
        @include aspectRatioFallBack;
        aspect-ratio: 1 / 1;
    }
}
</style>
