<template>
    <div class="c-stories-article-audio-line">
        <div class="c-stories-article-audio-line__line"></div>
    </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.c-stories-article-audio-line {
    --fill-line-audio: 252, 239, 239; //color of the line
    --rotation: 180deg;
    display: flex;
    flex: 1;
    height: 100%;
    justify-content: center;
    align-items: flex-end;
    padding-top: var(--grid-gutter);
    .c-nbhd-story--light & {
        --fill-line-audio: 28, 12, 46;
    }
    &__line {
        @include transform(translateY(-1rem));
        --line-height-audio: 0%;
        opacity: 0;
        max-width: 1px;
        height: 100%;
        max-height: 165px;
        display: flex;
        flex: 1;
        position: relative;

        &:before {
            @include fullWidthFixed(absolute);
            content: " ";
            height: var(--line-height-audio);
            background: rgb(var(--fill-line-audio));
            background: linear-gradient(
                var(--rotation),
                rgba(var(--fill-line-audio), 0) 0%,
                rgba(var(--fill-line-audio), 1) 100%
            );
        }
    }
}
</style>
