<template>
    <div class="c-navigation-mobile" :class="{ 'c-navigation-mobile--open': isNavOpen }">
        <div class="c-navigation-mobile__inner">
            <navigation-mobile-upper />
            <navigation-mobile-down :is-button-visible="isButtonVisible" />
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

import { gsap } from "gsap";

import NavigationMobileDown from "@/templates/components/Navigation/NavigationMobile/NavigationMobileDown/NavigationMobileDown";
import NavigationMobileUpper from "@/templates/components/Navigation/NavigationMobile/NavigationMobileUpper/NavigationMobileUpper";

export default {
    components: {
        NavigationMobileDown,
        NavigationMobileUpper
    },

    computed: {
        ...mapState({
            isNavOpen: state => state.global.isNavOpen,
            transitionOutUrl: state => state.global.transitionOutUrl
        })
    },
    data() {
        return {
            timelines: {
                mobileNav: null
            },
            isButtonVisible: false
        };
    },
    watch: {
        isNavOpen(bool) {
            this.timelines.mobileNav ? this.toggleTimeline(bool) : null;
        },
        transitionOutUrl(val) {
            val && this.isNavOpen ? this.toggleNav() : null;
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.setInterUntilClassIsSet();
        });
    },
    methods: {
        //======= START FIND SPECIFIC CLASS BEFORE STARTING =======//

        setInterUntilClassIsSet() {
            const findClassInterval = setInterval(() => {
                this.isClassExist("c-navigation-list__link--mobile")
                    ? (this.nextTick(), clearInterval(findClassInterval))
                    : null;
            }, 300);
        },
        isClassExist(nameOfTheClass) {
            // true/false is easier to read even on a ternary, please don't judge too fast :D
            return document.getElementsByClassName(nameOfTheClass).length ? true : false;
        },

        //======= END FIND SPECIFIC CLASS BEFORE STARTING =======//

        ////////////////////////////////
        //       START TIMELINE
        ////////////////////////////////
        nextTick() {
            this.$nextTick(() => {
                this.initTimeline();
            });
        },
        initTimeline() {
            this.timelines.mobileNav = gsap.timeline({
                paused: true,
                onStart: () => {
                    this.toggleVisibilityButton(true);
                    this.toggleNavBackground(true);
                },
                onReverseComplete: () => {
                    this.toggleVisibilityButton(false);
                    // this.toggleNavBackground(false);
                }
            });
            this.timelines.mobileNav
                .to(
                    // opacity
                    ".c-navigation-mobile",
                    {
                        opacity: 1,
                        ease: "none",
                        duration: 1
                    },
                    "started"
                )
                // stagger links
                .to(
                    ".c-navigation-mobile-upper__inner .c-navigation-list__link",
                    {
                        y: 0,
                        opacity: 1,
                        stagger: {
                            each: 0.2,
                            from: "start"
                        },

                        overwrite: "all",
                        ease: "expo.inOut",
                        duration: 1.5
                    },
                    "-=0.3"
                )
                // show button
                .to(
                    ".c-navigation-mobile-down__inner",
                    {
                        opacity: 1,
                        y: 0,
                        ease: "expo.inOut",
                        duration: 1.5
                    },
                    "-=0.8"
                )
                .add(() => {
                    // on mobile delay background color nav to the near end of the animation
                    this.toggleNavBackground(this.isNavOpen);
                }, "started+=0.5");
        },
        toggleTimeline(bool) {
            bool ? this.timelines.mobileNav.play() : this.timelines.mobileNav.reverse();
        },
        ////////////////////////////////
        //       END TIMELINE
        ////////////////////////////////

        toggleVisibilityButton(bool) {
            this.isButtonVisible = bool;
        },
        toggleNav() {
            this.$store.commit("global/toggleNavigation");
        },

        toggleNavBackground(bool) {
            this.$store.commit("global/toggleNavigationBackground", bool);
        }
    }
};
</script>

<style lang="scss" scoped>
.c-navigation-mobile {
    --gradient-color: 28, 12, 46;
    --pointer-event-mobile: none;
    height: 100vh;
    width: 100%;
    z-index: 1;
    position: fixed;
    top: 0px;

    pointer-events: var(--pointer-event-mobile);
    opacity: 0;

    &--open {
        --pointer-event-mobile: auto;
    }

    @media #{md('xs')} {
        display: none;
    }

    &__inner {
        display: flex;
        flex: 1;
        height: 100%;
        flex-direction: column;
        position: relative;
        cursor: pointer;
        // pointer-events: none;
        // .c-navigation-mobile--open & {
        pointer-events: var(--pointer-event-mobile);
        // }
        &:before {
            @include fullWidthFixed(absolute);
            content: " ";
            width: 100%;
            height: 100%;
            background: rgb(28, 12, 46);
            background: linear-gradient(
                180deg,
                rgba(28, 12, 46, 1) 73%,
                rgba(28, 12, 46, 0.5872724089635855) 91%,
                rgba(28, 12, 46, 0.3127626050420168) 100%
            );
        }
    }
    // &::v-deep .c-navigation-mobile-down__inner {
    //     pointer-events: var(--pointer-event-mobile);
    // }
}
</style>
