<template>
    <li class="c-neighbourhood-story-article-audio-player-main-duration">
        <p>{{ convertToMinutes(duration) }}</p>
    </li>
</template>

<script>
import convertorMixin from "@/mixins/convertorMixin.js";
export default {
    mixins: [convertorMixin],
    props: {
        duration: {
            type: Number,
            required: false,
            default: 0
        }
    }
};
</script>

<style lang="scss" scoped>
.c-neighbourhood-story-article-audio-player-main-duration {
    display: flex;
    flex: 0;
    justify-content: center;
    align-content: center;
    align-items: center;
    min-width: 2.6rem;
    p {
        @include lightCopy;
    }
}
</style>
