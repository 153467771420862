<template>
    <li class="c-about-content-list-item" :class="`c-about-content-list-item--${paragraphIndex}`">
        <div class="c-about-content-list-item__inner">
            <div v-if="isIntroText" class="c-about-content-list-item__inner__title">
                <anim-text
                    text="Constellations"
                    :class="` | t-t2`"
                    :tag="`h2`"
                    :chars="true"
                    :word="false"
                    :lines="false"
                    :visible="isVisible"
                    :is-axis-y="true"
                />
            </div>

            <div
                class="c-about-content-list-item__inner__content"
                :class="[
                    {
                        'c-about-content-list-item__inner__content--large': isIntroText
                    },
                    `c-about-content-list-item__inner__content--${paragraphIndex}`
                ]"
            >
                <div class="c-about-content-list-item__inner__content__copy | t-cms" v-html="paragraph.body" />
            </div>
            <about-content-list-item-connector v-if="!isLast" :paragraph-index="paragraphIndex" />
        </div>
    </li>
</template>

<script>
// import Lines from "objects/Lines";
import AnimText from "objects/AnimText";
import AboutContentListItemConnector from "@/templates/components/About/AboutContent/AboutContentList/AboutContentListItem/AboutContentListItemConnector/AboutContentListItemConnector";
export default {
    components: {
        AnimText,
        AboutContentListItemConnector
    },
    props: {
        paragraph: {
            type: Object,
            required: false,
            default: null
        },
        paragraphIndex: {
            type: Number,
            required: false,
            default: 0
        },
        isIntroText: {
            type: Boolean,
            required: false,
            default: false
        },
        isVisible: {
            type: Boolean,
            required: false,
            default: false
        },
        isLast: {
            type: Boolean,
            required: false,
            default: false
        }
    }
};
</script>

<style lang="scss" scoped>
.c-about-content-list-item {
    --opacity-copy: 0;
    --scale-x: 0;
    display: flex;
    flex: 0;
    position: static;

    &__inner {
        @include transform(translateY(100px));
        --inner-gap: var(--grid-gutter);
        display: flex;
        flex-direction: column;
        max-width: 500px;
        position: static;
        &__title {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: var(--grid-gutter);

            &:before {
                @include pseudo-el;
                width: 10rem;
                height: 1px;
                top: 4px;
                line-height: 1;
                margin-right: 1rem;
                background: #fff;
                transform: scaleX(var(--scale-x, 0));
                transform-origin: left;
            }
        }
        &__content {
            opacity: var(--opacity-copy);
            margin-bottom: var(--grid-gutter);
            @media #{md('md')} {
                margin-bottom: 0px;
            }

            &--large {
                &::v-deep p {
                    font-size: 2.2rem;
                }
            }
            &__copy {
                .c-about-content-list-item__inner__content--large & {
                    @include transform(translateY(100px));
                }
            }
        }
    }
}
</style>
