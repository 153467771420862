<template>
    <li class="c-credits-list-item-img">
        <ul class="c-credits-list-item-img__list" :class="`c-credits-list-item-img__list--${itemIndex}`">
            <credits-list-item-img-item v-for="(asset, index) in images" :key="index" :asset="asset" />
        </ul>
    </li>
</template>

<script>
import CreditsListItemImgItem from "@/templates/components/Credits/CreditsList/CreditsListItem/CreditsListItemImg/CreditsListItemImgItem";
export default {
    components: {
        CreditsListItemImgItem
    },
    props: {
        images: {
            type: Array,
            required: false,
            default: () => []
        },
        itemIndex: {
            type: Number,
            required: false,
            default: 0
        }
    }
};
</script>

<style lang="scss" scoped>
.c-credits-list-item-img {
    display: flex;
    flex: 1;
    width: 100%;
    justify-content: center;
    // padding-top: var(--grid-gutter-half);
    &__list {
        @include reset-list;
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: var(--grid-gutter);
    }
}
</style>
