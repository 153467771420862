<template>
    <div class="c-neighbour-scene" :class="{ 'c-neighbour-scene--visible': isSceneReady }">
        <canvas
            ref="home_scene__container"
            class="c-neighbour-scene__container"
            :class="{ 'c-neighbour-scene__container--grabbing': animations.cssCursor.isUserGrab }"
            @mouseup="userGrabScene(false)"
            @mousedown="userGrabScene(true)"
        ></canvas>
    </div>
</template>

<script>
import { mapState } from "vuex";

import _ from "lodash";

import isDevMixin from "@/mixins/isDevMixin";
import variables from "@/mixins/variables";

import { gsap } from "gsap/all";

import * as THREE from "three";
import { MapControls } from "three/examples/jsm/controls/OrbitControls";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import { fragmentShader, vertexShader } from "src/shaders/neighbourhood";

// STATS
import Stats from "stats-js";
import { GUI } from "@/vendors/dat.gui.module.js";

export default {
    mixins: [isDevMixin, variables],
    props: {
        labels: {
            type: Array,
            required: true
        },
        gltfPath: {
            type: String,
            required: true
        },
        gltfRotation: {
            type: Number,
            required: false,
            default: 0
        },
        // frustum is bascily the camera zoom for this project
        frustum: {
            type: Number,
            required: false,
            default: 25
        },

        // limit of the map
        mapControlLimits: {
            type: Object,
            required: false,
            default: null
        },
        // hide labels on mount
        isStoryVisible: {
            type: Boolean,
            required: false,
            default: false
        },
        totalStars: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            // base
            container: null,
            scene: null,
            camera: null,
            controls: null,
            animation: undefined,

            clock: {
                clock: null,
                then: 0
            },

            raycaster: {
                raycaster: null,
                mouse: null
            },

            //   lights
            lights: {
                directionalLightMain: {
                    directionalLight: null,
                    colour: {
                        colour: 0xff94c1,
                        intensity: 4
                    },
                    position: [25, 20, -25]
                },
                directionalLightFront: {
                    directionalLight: null,
                    colour: {
                        colour: 0xfcacac,
                        intensity: 4
                    },
                    position: [-25, 20, 25]
                },
                directionalLightLight: {
                    directionalLight: null,
                    colour: {
                        colour: 0xffffff,
                        intensity: 2
                    },
                    position: [20, 10, 20]
                },
                labelSpotLight: {
                    spotLight: null,
                    isVisible: false,
                    position: [0, 1, 0],
                    colour: {
                        colour: 0xc99797,
                        intensity: 10
                    }
                }
            },
            // fog
            fog: {
                colour: 0x1c0c2e,
                near: 180,
                far: 300
            },

            //  helper
            helpers: {
                stats: null,
                debugMode: {
                    switch: false
                }
            },
            cameras: {
                startCameraPosition: {
                    camX: -180,
                    camY: 225,
                    camZ: -100
                },
                defaultCameraPosition: {
                    camY: 150
                },

                zoom: {
                    defaultMaxMin: {
                        min: 0,
                        max: 50
                    }
                },
                frustum: 25,

                windowUser: {
                    width: 0,
                    height: 0
                },
                limitPan: {
                    min: null,
                    max: null,
                    baseVector: null,
                    isActive: true
                }
            },
            gltf: {
                GLTFLoader: null
            },
            neigbourhoodGLTF: undefined,

            progress: {
                totalProgress: 0
            },

            // list of interative labels
            labelsList: {
                list: [],
                isReady: false
            },
            animations: {
                gsapAnimation: {
                    spotlight: {
                        timeline: null,
                        isRunning: false,
                        interval: null
                    },
                    rotation: {
                        isActive: false,
                        previousRotation: 0,
                        rotation: 0
                    },
                    intro: {
                        timeline: null,
                        duration: 2
                    },
                    leave: {
                        timeline: null
                    },
                    gltfPos: {
                        start: {
                            y: -20
                        },
                        default: {
                            y: -5
                        }
                    }
                },
                cssCursor: {
                    isUserGrab: false
                }
            }
        };
    },
    computed: {
        ...mapState({
            isSceneReady: state => state.constellations.isSceneReady,
            isSpotLightVisible: state => state.constellations.isSpotLightVisible,
            rotation: state => state.constellations.rotation,
            spotLightPosition: state => state.constellations.spotLightPosition,
            isMapVisibleFromStory: state => state.constellations.isMapVisibleFromStory,
            transitionOutUrl: state => state.global.transitionOutUrl
        }),
        progressInPercent() {
            return this.progress.totalProgress * 100;
        },
        cameraAspectRatio() {
            return this.cameras.windowUser.width / this.cameras.windowUser.height;
        }
    },
    watch: {
        isSpotLightVisible(bool) {
            this.playSpotlight(bool);
        },
        transitionOutUrl(url) {
            url ? this.animationOnLeave(url) : null;
        },
        isMapVisibleFromStory(bool) {
            // this.fadeInMap(bool);
            bool ? this.animationEntrance(true) : null;
        },
        isStoryVisible(bool) {
            bool ? this.toggleLabelVisibility(false) : this.toggleLabelVisibility(true);
        }
    },
    mounted() {
        this.resetContellationsStore();
        this.toggleVisibilityExperience(true); // avoid flickering on dev server
        this.setWindowSize();
        this.init();
        // Register an event listener when the Vue component is ready
        window.addEventListener("resize", this.onResize);
        window.addEventListener("keydown", this.keyPressed);
        window.addEventListener("contextmenu", this.mouseClicked);
    },
    beforeDestroy() {
        this.beforeDestroyCluster();
    },

    methods: {
        ////////////////////////////////
        //       START ON MOUNTED METHODS
        ////////////////////////////////

        // reset store to default
        resetContellationsStore() {
            this.toggleSceneStatus(false);
            this.emitRotationToStore(0);
        },

        //   set window width
        setWindowSize() {
            this.cameras.windowUser.width = window.innerWidth;
            this.cameras.windowUser.height = window.innerHeight;
        },

        onResize() {
            // Update sizes
            this.setWindowSize();

            // Update camera
            this.camera.aspect = this.cameraAspectRatio;

            this.resizeCameraSize();

            this.camera.updateProjectionMatrix();

            // Update renderer
            this.renderer.setSize(this.cameras.windowUser.width, this.cameras.windowUser.height);
            this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
        },

        resizeCameraSize() {
            this.camera.left = -this.frustum * this.cameraAspectRatio;
            this.camera.right = this.frustum * this.cameraAspectRatio;
            this.camera.top = this.frustum;
            this.camera.bottom = -this.frustum;
        },

        ////////////////////////////////
        //       END ON MOUNTED METHODS
        ////////////////////////////////

        ////////////////////////////////
        //       START INIT SCENE AND RENDER
        ////////////////////////////////

        init() {
            this.setBaseScene();

            this.setLight();
            this.addSceneFog();

            /*------------------------------
            Start add meshes to the scenes
            ------------------------------*/
            this.addGLTF();
            /*------------------------------
            End add meshes to the scenes
            ------------------------------*/
            /*------------------------------
            Start Add labels dynamicly
            ------------------------------*/
            this.addLabels();
            /*------------------------------
            End Add labels dynamicly
            ------------------------------*/
            this.setCamera();
            this.setControl();

            this.setRenderer();

            this.gameLoop();
        },

        //======= START GAME LOOP =======//

        gameLoop() {
            // don't display the stats if on prod. Maybe this dev stuff should be handle differently
            this.isDevEnv() ? this.helpers.stats.update() : "";

            const elapsedTime = this.clock.clock.getElapsedTime();

            this.CPUSaver(elapsedTime);

            // Call gameLoop again on the next frame
            this.animation = requestAnimationFrame(this.gameLoop);
        },

        CPUSaver(now) {
            // code inpirted from > https://gist.github.com/elundmark/38d3596a883521cb24f5
            // the difference is that I used timelapse istead of date, so instead of 1000 interval, we only need 1 / fps
            const fps = 60;
            const interval = 1 / fps; // replaced
            const delta = now - this.clock.then;

            if (delta > interval) {
                this.cameras.limitPan.max && this.cameras.limitPan.isActive ? this.limitPan() : null;

                this.moveInteractiveLabels();
                this.animateStars(now);

                // Render
                this.controls.update();

                this.renderer.render(this.scene, this.camera);

                // Just `then = now` is not enough.
                // Lets say we set fps at 10 which means
                // each frame must take 100ms
                // Now frame executes in 16ms (60fps) so
                // the loop iterates 7 times (16*7 = 112ms) until
                // delta > interval === true
                // Eventually this lowers down the FPS as
                // 112*10 = 1120ms (NOT 1000ms).
                // So we have to get rid of that extra 12ms
                // by subtracting delta (112) % interval (100).
                // Hope that makes sense.
                this.clock.then = now - (delta % interval);
            }
        },

        //======= END GAME LOOP =======//

        //======= START BASE THREEJS =======//

        setBaseScene() {
            // set container
            this.container = this.$refs.home_scene__container;

            // create scene
            this.scene = new THREE.Scene();
            this.scene.background = new THREE.Color(0x1c0c2e);

            this.isDevEnv() ? this.setHelpers() : "";

            this.setClock();
        },

        setHelpers() {
            // stats
            this.helpers.stats = new Stats();
            document.body.appendChild(this.helpers.stats.dom);

            // axes helpers
            const axesHelper = new THREE.AxesHelper(5);
            // x y z
            this.scene.add(axesHelper);

            // set gui globaly
            this.setGUI();
        },
        setGUI() {
            this.helpers.gui = new GUI();

            this.parameterLightGenerator("directionalLightMain", "directionalLight");
            this.parameterLightGenerator("directionalLightFront", "directionalLight");
            this.parameterLightGenerator("directionalLightLight", "directionalLight");
            this.parameterLightGenerator("labelSpotLight", "spotLight");
        },

        parameterLightGenerator(lightName, lightType) {
            const parameters = {
                color: this.lights[lightName].colour.colour
            };

            this.helpers.gui.addColor(parameters, "color").onChange(() => {
                this.lights[lightName][lightType].color.set(parameters.color);
            });
        },

        setClock() {
            this.clock.clock = new THREE.Clock();
        },

        //======= END BASE THREEJS =======//

        //======= START LIGHTS =======//

        setLight() {
            this.setAmbientLight();
            this.directionalLightManager("directionalLightMain");
            this.directionalLightManager("directionalLightFront");
            this.directionalLightManager("directionalLightLight");

            this.setPointLight();
        },
        setAmbientLight() {
            const ambientLight = new THREE.AmbientLight(0xfcefef, 1);
            this.scene.add(ambientLight);
        },

        directionalLightManager(lightName) {
            this.lights[lightName].directionalLight = new THREE.DirectionalLight(
                this.lights[lightName].colour.colour,
                this.lights[lightName].colour.intensity
            );
            this.lights[lightName].directionalLight.position.set(
                this.lights[lightName].position[0],
                this.lights[lightName].position[1],
                this.lights[lightName].position[2]
            );
            this.scene.add(this.lights[lightName].directionalLight);

            this.isDevEnv() ? this.directioLightHelper(lightName) : null;
        },

        directioLightHelper(lightName) {
            const directionalLightHelper = new THREE.DirectionalLightHelper(
                this.lights[lightName].directionalLight,
                0.1
            );
            this.scene.add(directionalLightHelper);
        },

        setPointLight() {
            // SpotLight( color : Integer, intensity : Float, distance : Float, angle : Radians, penumbra : Float, decay : Float )
            this.lights.labelSpotLight.spotLight = new THREE.SpotLight(0xc99797, 0, 10, Math.PI * 0.15, 1, 0);
            this.lights.labelSpotLight.spotLight.position.set(0.5, 19, 1);
            this.scene.add(this.lights.labelSpotLight.spotLight);

            this.scene.add(this.lights.labelSpotLight.spotLight.target);
            // if you are not familiar with spotlight, this always helps me: // this help https://threejs.org/examples/webgl_lights_spotlight.html
            // this.isDevEnv() ? this.addSpotLightHelper() : null;
            this.setSpotLightTimeline();
        },
        addSpotLightHelper() {
            const spotLightHelper = new THREE.SpotLightHelper(this.lights.labelSpotLight.spotLight);
            this.scene.add(spotLightHelper);
        },

        //======= END LIGHTS =======//

        //======= START SET FOG =======//

        addSceneFog() {
            this.scene.fog = new THREE.Fog(this.fog.colour, this.fog.near, this.fog.far);
            this.scene.background = new THREE.Color(this.fog.colour);
        },

        //======= END SET FOG =======//

        //======= START CAMERA AND CONTROL =======//

        setCamera() {
            this.camera = new THREE.OrthographicCamera(
                -this.frustum * this.cameraAspectRatio,
                this.frustum * this.cameraAspectRatio,
                this.frustum,
                -this.frustum,
                0.1,
                1000
            );

            this.camera.position.set(
                Math.cos(this.gltfRotation) * 210,
                this.cameras.startCameraPosition.camY,
                Math.sin(this.gltfRotation) * 210
            );

            this.scene.add(this.camera);
        },

        setControl() {
            this.controls = new MapControls(this.camera, this.container);
            this.controls.enableDamping = true;

            this.controls.dampingFactor = 0.05;
            this.controls.panSpeed = window.innerWidth >= 1024 ? 0.35 : 1;
            this.controls.enableRotate = false;
            this.controls.enableZoom = false;
            this.controls.screenSpacePanning = false;

            this.controls.minDistance = this.cameras.zoom.defaultMaxMin.min;
            this.setTarget();
        },
        setTarget() {
            this.controls.target.set(0, 25, 0);
        },

        //======= END CAMERA AND CONTROL =======//

        //======= START RENDERER  =======//

        setRenderer() {
            this.renderer = new THREE.WebGLRenderer({
                canvas: this.container,
                powerPreference: "high-performance",
                antialias: true
            });
            this.renderer.setSize(this.cameras.windowUser.width, this.cameras.windowUser.height);

            this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
        },

        //======= END RENDERER  =======//

        ////////////////////////////////
        //       END INIT SCENE AND RENDER
        ////////////////////////////////

        ////////////////////////////////
        //       START ADD MESHES TO SCENE
        ////////////////////////////////

        //======= START ADD GLTF =======//

        addGLTF() {
            this.setBaseLoader();
            this.gtflLoader(this.gltfPath, "neigbourhoodGLTF");
        },

        setBaseLoader() {
            // draco always before GLTF Loader
            this.setDraco();
            this.setLoader();
        },
        setDraco() {
            this.gltf.dracoLoader = new DRACOLoader();
            //  path to a folder containing WASM/JS decoding libraries.
            this.gltf.dracoLoader.setDecoderPath("/three-assets/vendors/draco/"); // path needs be public
            this.gltf.dracoLoader.preload();
        },
        setLoader() {
            // set loader
            this.gltf.GLTFLoader = new GLTFLoader();
            this.gltf.GLTFLoader.setDRACOLoader(this.gltf.dracoLoader);
        },

        gtflLoader(path, GTLFName) {
            this.gltf.GLTFLoader.load(
                path,
                gltf => {
                    this[GTLFName] = gltf; // add model dynamicly
                    gltf.scene.traverse(child => {
                        if (child.isMesh) {
                            child.castShadow = false;
                            child.receiveShadow = false;
                            child.material.name === "road" ? child.material.color.set(0x151821) : null;
                        }
                    });
                    gltf.scene.scale.set(0.05, 0.05, 0.05);

                    gltf.scene.position.set(0, -2, 0); // Easier than the camera

                    this.scene.add(gltf.scene);

                    this.launchScene();
                    this.isDevEnv() ? this.addGUIRoadColour() : null;
                    this.addTwinklingStars(gltf.scene);
                },
                xhr => {
                    this.progressManager(xhr.loaded, xhr.total);
                },
                undefined
            );
        },

        addGUIRoadColour() {
            const parameters = {
                color: 0x151821
            };
            const indexRoad = this.neigbourhoodGLTF.scene.children.findIndex(element => element.name === "Rues");

            this.helpers.gui.addColor(parameters, "color").onChange(() => {
                this.neigbourhoodGLTF.scene.children[indexRoad].material.color.set(parameters.color);
            });
        },

        addTwinklingStars(scene) {
            this.twinklingStars = this.generateStars();
            scene.add(this.twinklingStars);
        },
        generateStars() {
            const totalStars = this.totalStars;
            const positions = new Float32Array(totalStars * 3);
            const frequencies = new Float32Array(totalStars);
            const spreadRadius = 3000; // stars will be spread in a radius of 3000u // to the floor
            const heightRadius = 1000; // Stars are positionned in a window of 1000u
            const minHeight = 1000; // stars are positionned at least 1000u above the floor

            for (let i = 0; i < totalStars; i++) {
                let x = i * 3,
                    y = i * 3 + 1,
                    z = i * 3 + 2;

                positions[x] = spreadRadius * (Math.random() * 2 - 1); // -3000 < x < 3000
                positions[y] = (heightRadius * (Math.random() + minHeight)) / minHeight; // 1000 < y < 2000
                positions[z] = spreadRadius * (Math.random() * 2 - 1); // -3000 < z < 3000

                frequencies[i] = Math.random() * 60; // 0 < frequency < 60
            }

            const starGeometry = new THREE.BufferGeometry();
            starGeometry.setAttribute("position", new THREE.BufferAttribute(positions, 3));

            starGeometry.setAttribute("aFrequency", new THREE.BufferAttribute(frequencies, 1));

            const starShaderMaterial = new THREE.ShaderMaterial({
                depthWrite: false,
                blending: THREE.AdditiveBlending,
                uniforms: {
                    uSize: { value: 3 * this.renderer.getPixelRatio() },
                    uTime: { value: 0 },
                    uOpacity: { value: 0.0 }
                },
                vertexColors: true,
                fragmentShader: fragmentShader,
                vertexShader: vertexShader
            });

            const stars = new THREE.Points(starGeometry, starShaderMaterial);
            return stars;
        },

        /*------------------------------
       Start Progress on GLTF Load
       ------------------------------*/
        progressManager(loaded, total) {
            this.progress.totalProgress = this.calculateProgress(loaded, total);
        },
        calculateProgress(loaded, total) {
            return loaded / total;
        },

        /*------------------------------
       End Progress on GLTF Load
       ------------------------------*/

        //======= END ADD GLTF =======//

        ////////////////////////////////
        //       END ADD MESHES TO SCENE
        ////////////////////////////////

        ////////////////////////////////
        //       START ANIMATION
        ////////////////////////////////

        //======= START ANIMATION ON MOUNT =======//

        /*------------------------------
        Start animation on mount
        ------------------------------*/

        launchScene() {
            this.$nextTick(() => {
                this.startExperience();
            });
        },

        startExperience() {
            !this.isSceneReady ? this.toggleSceneStatus(true) : null;
            // add animation more bellow
            this.initAnimationEntrance();
            // animate header
            this.toggleConstellationHeader(true);
            // pan down
            this.isStoryVisible ? null : this.animationEntrance(true);
        },

        toggleVisibilityExperience(bool) {
            this.$emit("toggleExperienceVisibility", bool); // avoid some flickering on the prod server
        },
        toggleSceneStatus(bool) {
            this.$store.commit("constellations/TOGGLE_SCENE", bool);
        },
        toggleLabelVisibility(bool) {
            this.$store.commit("constellations/toggleLabelsVisibility", bool);
        },
        toggleConstellationHeader(bool) {
            this.$store.commit("constellations/toggleConstellationHeaderVisibility", bool);
        },

        initAnimationEntrance() {
            this.animations.gsapAnimation.intro.timeline = gsap.timeline({
                paused: true,
                onComplete: () => {
                    // show the labels
                    this.delayAnimationLabel(true);
                    this.setLimitPan();
                }
            });
            this.animations.gsapAnimation.intro.timeline

                .to(
                    this.controls.target,
                    {
                        duration: this.animations.gsapAnimation.intro.duration,
                        y: 0,
                        ease: "power4.inOut"
                    },
                    "camera"
                )
                .to(
                    this.camera.position,
                    {
                        duration: this.animations.gsapAnimation.intro.duration,
                        y: this.cameras.defaultCameraPosition.camY,
                        ease: "power4.inOut"
                    },
                    "camera+=0.2"
                )
                .to(
                    this.twinklingStars.material.uniforms.uOpacity,
                    {
                        duration: this.animations.gsapAnimation.intro.duration,
                        value: 1.0,
                        ease: "power4.inOut"
                    },
                    "camera+=1"
                );
        },

        //======= START SHOW LABELS =======//

        delayAnimationLabel(bool) {
            // ensure that it's not triggered when user switch between siblings
            const timeoutDelayAnimationLabel = setTimeout(() => {
                this.displayLabels(bool);
                clearTimeout(timeoutDelayAnimationLabel);
            }, 500);
        },
        displayLabels(bool) {
            // unsure that labels are not visible
            return this.isStoryVisible ? this.toggleLabelVisibility(!bool) : this.toggleLabelVisibility(bool);
        },

        //======= END SHOW LABELS =======//

        //======= START SET LIMIT MAP CONTROL AFTER ANIMATION =======//

        setLimitPan() {
            this.cameras.limitPan.min = new THREE.Vector3(
                this.mapControlLimits.min.x,
                this.mapControlLimits.min.y,
                this.mapControlLimits.min.z
            );
            this.cameras.limitPan.max = new THREE.Vector3(
                this.mapControlLimits.max.x,
                this.mapControlLimits.max.y,
                this.mapControlLimits.max.z
            );

            this.cameras.limitPan.baseVector = new THREE.Vector3();
        },

        //======= END SET LIMIT MAP CONTROL AFTER ANIMATION =======//

        animationEntrance(bool) {
            this.toggleVisibilityExperience(true);
            bool
                ? this.animations.gsapAnimation.intro.timeline.play()
                : this.animations.gsapAnimation.intro.timeline.reverse();
        },

        destroyAnimationEntrance() {
            this.animations.gsapAnimation.intro.timeline.kill();
            this.animations.gsapAnimation.intro.timeline = null;
        },

        /*------------------------------
        End animation on mount
        ------------------------------*/

        //======= END ANIMATION ON MOUNT =======//

        //======= START ROTATION =======//

        keyPressed(key) {
            key.code === "Space" &&
            !this.animations.gsapAnimation.rotation.isActive &&
            this.isSceneReady &&
            !this.isStoryVisible
                ? this.rotateCamera(true)
                : null;
        },

        mouseClicked(mouseKey) {
            mouseKey.button === 2 &&
            !this.animations.gsapAnimation.rotation.isActive &&
            this.isSceneReady &&
            !this.isStoryVisible
                ? this.rotateCamera(false)
                : null;
        },

        rotateCamera(isLeftRotation) {
            // Rotation on a quardi is a pain, I was never happy with the result in any of my projects so instead I did like below.
            //If you want, there is some documentaion online to add a quaternion to a tween: https://discourse.threejs.org/t/animating-quaternion-rotation/8015/9 or https://greensock.com/forums/topic/28083-tweening-threejs-quaternions/

            // This rotation hack is actually better than other solution I did in many project.
            // Rotation on a quaternion is a pain to do with GSAP, and in this project GSAP have a weird but with the camera which I don't understand why because I did the same many time
            // futhermore is way better than rotate the models
            // Tested and it's much cheaper than other solutions and the rotation is clean for the user.
            // Really the only down point is that you need to calculate the rotation to do with a duration, but the user don't feel any of that
            const durationRotation = this.isBrowserChrome() ? 1: 2.5;
            // const SpeedRotation = 42; (large rotations)
            // const SpeedRotation = Math.PI * 4;
            const speedRotation = this.directionRotationSpeed(isLeftRotation);
            this.disableRotation(true);
            this.quadriCameraRotation(isLeftRotation);
            gsap.to(this.controls, {
                duration: durationRotation,
                autoRotateSpeed: speedRotation / this.customRotation(2.05,5), // https://mambomambo-team.atlassian.net/browse/CN-549
                ease: "power3.out",
                onComplete: () => {
                    this.controls.autoRotate = false;
                    const blockRotation = setTimeout(() => {
                        this.animations.gsapAnimation.rotation.isActive = false;
                        clearTimeout(blockRotation);
                    }, 1000);
                }
            });
        },


        /*------------------------------
        Start Improve Chrome Desktop Rotation
        ------------------------------*/
        customRotation(fast,slow){
            return this.isBrowserChrome() ? fast : slow;
        },
        isBrowserChrome(){
            return this.browser.name === "chrome" && (!this.isMobile || !this.isTablet)
        },
        /*------------------------------
        End Improve Chrome Desktop Rotation
        ------------------------------*/

        directionRotationSpeed(isLeftRotation) {
            return isLeftRotation ? Math.PI * 4 : -(Math.PI * 4);
        },
        disableRotation(bool) {
            this.controls.autoRotate = bool;
            this.animations.gsapAnimation.rotation.isActive = bool;
        },
        quadriCameraRotation(isLeftRotation) {
            const newRotation = isLeftRotation ? this.rotation + 36 : this.rotation - 36;

            this.emitRotationToStore(newRotation);
        },

        emitRotationToStore(rotation) {
            this.$store.commit("constellations/UPDATE_ROTATION", rotation);
        },

        //======= END ROTATION =======//

        //======= START CSS ANIMATION =======//

        userGrabScene(bool) {
            this.animations.cssCursor.isUserGrab = bool;
        },

        //======= END CSS ANIMATION =======//

        ////////////////////////////////
        //       END ANIMATION
        ////////////////////////////////

        ////////////////////////////////
        //       START LABELS
        ////////////////////////////////

        //======= START ADD ALL LABELS =======//

        addLabels() {
            // clear the list
            this.labelsList.list = [];

            // loop each labels
            this.labels.forEach((item, index) => {
                this.labelsList.list.push({
                    key: item.key,
                    index: index,
                    position: new THREE.Vector3(item.position.x, item.position.y, item.position.z),
                    constellations: {
                        key: item.constellation.id
                    },
                    story: item.story
                });
                index + 1 === this.labels.length ? (this.labelsList.isReady = true) : null;
            });
        },

        //======= END ADD ALL LABELS =======//

        //======= START MOVE LABELS =======//

        moveInteractiveLabels() {
            this.isSceneReady && this.labelsList.isReady ? this.loopLabelsToMove() : null;
        },
        loopLabelsToMove() {
            // Switched for For OF to For each for lisibility and I become faster than back in the days https://javascript.plainenglish.io/which-type-of-loop-is-fastest-in-javascript-ec834a0f21b9
            this.labelsList.list.forEach((label, index) => {
                label.constellations.key && !label.constellations.isLast ? this.moveLinesDom(index) : null;
                this.moveDom(label.index);
            });
        },

        moveDom(interactiveLabelKeyIndex) {
            const interactiveLabel = document.querySelectorAll(
                `.c-neighbourhood-labels-items--${interactiveLabelKeyIndex}`
            );
            const screenPosition = this.labelsList.list[interactiveLabelKeyIndex].position.clone();
            screenPosition.project(this.camera);
            const translateX = screenPosition.x * window.innerWidth * 0.5;
            const translateY = -screenPosition.y * window.innerHeight * 0.5;
            interactiveLabel[0].style.transform = `translateX(${translateX}px) translateY(${translateY}px)`;
        },
        moveLinesDom(index) {
            const interactiveLabelLine = document.querySelectorAll(`.c-neighbourhood-labels-item-line--${index}`)[0];
            if (!interactiveLabelLine) return;

            const off1 = this.getOffset(`.c-neighbourhood-labels-items--${index + 1}`);
            const off2 = this.getOffset(`.c-neighbourhood-labels-items--${index}`);
            const thickness = 0.2;

            const x1 = off1.left;
            const y1 = off1.top;

            const x2 = off2.left;
            const y2 = off2.top;

            const length = Math.sqrt((x2 - x1) * (x2 - x1) + (y2 - y1) * (y2 - y1));

            const angle = Math.atan2(y1 - y2, x1 - x2) * (180 / Math.PI);

            const overwriteTransition = angle >= 179.8 || angle <= -179.8 ? "transition: 0s linear all;" : ""; // https://mambomambo-team.atlassian.net/browse/CN-549
            const lineStyle = `height:${thickness}rem; width:${length}px;  transform:rotate(${angle}deg); ${overwriteTransition} `;

            interactiveLabelLine.style = lineStyle;
        },
        getOffset(elementID) {
            const el = document.querySelectorAll(elementID)[0];

            const rect = el.getBoundingClientRect();

            return {
                left: rect.left + window.pageXOffset,
                top: rect.top + window.pageYOffset,
                width: rect.width || el.offsetWidth,
                height: rect.height || el.offsetHeight
            };
        },

        //======= END MOVE LABELS =======//

        //======= START SPOTLIGHT ON THE LABELS =======//

        playSpotlight(bool) {
            !this.animations.gsapAnimation.spotlight.isRunning ||
            (!bool && this.animations.gsapAnimation.spotlight.isRunning)
                ? this.spotlightOnLabelHover(bool, 1)
                : this.delaySpotLight(bool);
        },

        delaySpotLight(bool) {
            this.animations.gsapAnimation.spotlight.interval = setInterval(() => {
                !this.animations.gsapAnimation.spotlight.isRunning ? this.debouncedSpotLight(bool) : null;
            }, 100);
        },

        debouncedSpotLight: _.throttle(
            function(bool) {
                this.spotlightOnLabelHover(bool, 2);
            },
            300,
            { trailing: false }
        ),
        toggleSpotlightIsRunning(bool) {
            this.animations.gsapAnimation.spotlight.isRunning = bool;
        },
        clearSpotlightInterval() {
            this.animations.gsapAnimation.spotlight.interval
                ? (clearInterval(this.animations.gsapAnimation.spotlight.interval),
                  (this.animations.gsapAnimation.spotlight.interval = null))
                : null;
        },

        spotlightOnLabelHover(bool) {
            this.toggleSpotlightIsRunning(true);
            this.clearSpotlightInterval();

            this.moveSpotLight();
            this.fadeInSpotLight(bool);
        },
        moveSpotLight() {
            this.lights.labelSpotLight.spotLight.position.set(this.spotLightPosition.x, 25, this.spotLightPosition.z);

            this.lights.labelSpotLight.spotLight.target.position.x = this.spotLightPosition.x;
            this.lights.labelSpotLight.spotLight.target.position.z = this.spotLightPosition.z;
        },
        setSpotLightTimeline() {
            this.animations.gsapAnimation.spotlight.timeline = gsap.timeline({
                paused: true,
                onComplete: () => {
                    this.toggleSpotlightIsRunning(false);
                },
                onReverseComplete: () => {
                    this.toggleSpotlightIsRunning(false);
                }
            });
            const durationAnimationSpotLight = 0.3;

            this.animations.gsapAnimation.spotlight.timeline.to(this.lights.labelSpotLight.spotLight, {
                duration: durationAnimationSpotLight,
                intensity: 5,
                ease: "none"
            });
        },
        fadeInSpotLight(bool) {
            bool
                ? this.animations.gsapAnimation.spotlight.timeline.play()
                : this.animations.gsapAnimation.spotlight.timeline.reverse();
        },

        //======= END SPOTLIGHT ON THE LABELS =======//

        ////////////////////////////////
        //       END LABELS
        ////////////////////////////////

        ////////////////////////////////
        //       START STARS
        ////////////////////////////////
        //======= START ANIMATE STARS IN GAME LOOP =======//

        animateStars(delta) {
            if (!this.twinklingStars) return;

            this.twinklingStars.material.uniforms.uTime.value = delta;
        },

        //======= END ANIMATE STARS IN GAME LOOP =======//
        ////////////////////////////////
        //       END STARS
        ////////////////////////////////

        ////////////////////////////////
        //       START MAP CONTROL LIMIT
        ////////////////////////////////

        limitPan() {
            this.cameras.limitPan.baseVector.copy(this.controls.target);
            this.controls.target.clamp(this.cameras.limitPan.min, this.cameras.limitPan.max);

            this.cameras.limitPan.baseVector.sub(this.controls.target);
            this.camera.position.sub(this.cameras.limitPan.baseVector);
        },

        ////////////////////////////////
        //       END MAP CONTROL LIMIT
        ////////////////////////////////

        ////////////////////////////////
        //       START ANIMATION ON LEAVE
        ////////////////////////////////

        animationOnLeave(url) {
            // hide the labels
            this.toggleLabelVisibility(false);
            // fade out
            this.animationEntrance(false);
            // hide hero
            this.isConstellationHeaderRequired(url) ? this.toggleConstellationHeader(false) : null;
            // redirect
            const redirectionDelay = setTimeout(() => {
                this.toggleVisibilityExperience(false); // avoid flickering on dev server
                this.managePageRedirection();

                clearTimeout(redirectionDelay);
                // dont wait the end of the animation
            }, this.animations.gsapAnimation.intro.duration * 1000);
        },
        toggleTimelineOnLeave(bool) {
            bool
                ? this.animations.gsapAnimation.leave.timeline.play()
                : this.animations.gsapAnimation.leave.timeline.reverse();
        },

        isConstellationHeaderRequired(url) {
            // TODO: [CN-546] use a similar method to this one I coded "isUserLeavingTheNeigbourhood" in the file "SotriesMoreReturn"
            // there is probably a better way to do that, but it works
            return url === "/" || url === "/credits" || url === "/about";
        },
        fadeInMap(bool) {
            this.toggleTimelineOnLeave(bool);
        },

        /*------------------------------
        Start Manage page redirection
        ------------------------------*/
        managePageRedirection() {
            this.transitionOutUrl ? this.pageRedirection(this.transitionOutUrl) : null;
        },
        pageRedirection(url) {
            this.$router.push(`${url}`);
        },

        /*------------------------------
        End Manage page redirection
        ------------------------------*/

        //======= START USER LEFT =======//

        beforeDestroyCluster() {
            this.resetContellationsStore();

            cancelAnimationFrame(this.animation);
            this.animation = undefined;
            window.removeEventListener("resize", this.onResize);
            window.removeEventListener("keydown", this.keyPressed);
            window.removeEventListener("contextmenu", this.mouseClicked);

            this.toggleLabelVisibility(false);
            this.toggleSceneStatus(false);
            this.toggleConstellationHeader(false);
            this.toggleVisibilityExperience(true);

            this.destroyAnimationEntrance();
        }

        //======= END USER LEFT =======//

        ////////////////////////////////
        //       END ANIMATION ON LEAVE
        ////////////////////////////////
    }
};
</script>

<style lang="scss">
.c-neighbour-scene {
    // avoid the flicker issue when page load
    background-color: var(--color-purple-dark);
    opacity: 0;
    &--visible {
        opacity: 1;
    }

    .c-neighbourhood--hidden & {
        opacity: 0; // avoid some flickering issue on prod server
    }

    &__container {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1; // this can be improved by setting up other component to relative z-index 1

        cursor: grab;
        &--grabbing {
            cursor: grabbing;
        }
    }

    &__debug {
        position: fixed;
        z-index: 999;
        top: 0px;
        left: 50%;
        color: white;
        button {
            padding: 3px;
            background: red;
        }
    }
}

.dg {
    z-index: -2 !important; //debug solution
}
</style>
