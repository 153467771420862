<template>
    <div
        class="c-nbhd-story"
        :class="{
            'c-nbhd-story--light': isStoryLightMode
        }"
        :style="`--scroll: ${scroll}`"
    >
        <div class="c-nbhd-story__inner">
            <stories-article
                v-if="areChildrenVisible"
                :current-story-index="currentStoryIndex + 1"
                :has-sibling="hasSibling"
                :is-scroll-disabled="isScrollDisabled"
                @goToNextSection="goToNextSection"
            />
            <stories-more
                v-if="areChildrenVisible"
                :siblings="siblings"
                :current-story-index="currentStoryIndex"
                :is-user-scrolling="isUserScrolling"
                @goToNextSection="goToNextSection"
            />
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import _ from "lodash";
import { gsap, ScrollTrigger, ScrollToPlugin } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

import isDevMixin from "@/mixins/isDevMixin";

import StoriesArticle from "@/templates/components/Stories/StoriesArticle/StoriesArticle";
import StoriesMore from "@/templates/components/Stories/StoriesMore/StoriesMore";

export default {
    mixins: [isDevMixin],
    components: {
        StoriesArticle,
        StoriesMore
    },

    data() {
        return {
            scroll: "hidden",
            isScrollDisabled: true,
            isUserScrolling: false,

            areChildrenVisible: true,
            timelines: {
                onMounted: null,
                scrollDown: null,
                scrollUp: null,
                return: null,
                fadeInOut: null
            },
            siblings: [],
            currentStoryIndex: 0,
            isFetchSiblingDone: false
        };
    },

    mounted() {
        this.overwriteLightMode(false);
        this.resetPageScrollGradually();
        this.$nextTick(() => {
            this.fullyFetched
                ? (this.initMethodsOnMount(), window.addEventListener("resize", this.debounceOnResize))
                : null;
        });
    },
    beforeDestroy() {
        this.destroyAllTimelines();
        // reset the visiblility map to false
        this.revealMap(false);
        window.removeEventListener("resize", this.debounceOnResize);
        this.resetPageScrollGradually();
        this.emitUserScrolledDown(false);
    },
    computed: {
        ...mapState({
            fullyFetched: state => state.global.currentEntry.fullyFetched,
            currentEntry: state => state.global.currentEntry,
            goToSiblingUrl: state => state.constellations.goToSiblingUrl,
            isLightMode: state => state.global.currentEntry.lightMode,
            isLightModeOverwritten: state => state.constellations.isLightModeOverwritten,
            isMapVisibleFromStory: state => state.constellations.isMapVisibleFromStory,
            isSceneReady: state => state.constellations.isSceneReady,
            storyTemplate: state => state.global.currentEntry.type,
            scrollProgressStory: state => state.constellations.scrollProgressStory
        }),
        isStoryLightMode() {
            return this.isLightMode && !this.isLightModeOverwritten;
        },
        isAudioTemplate() {
            return this.storyTemplate === "storyAudio";
        },
        isMapVisible() {
            return this.fullyFetched && this.isSceneReady;
        },
        hasSibling() {
            return this.siblings.length >= 1;
        }
    },
    watch: {
        fullyFetched(bool) {
            bool && this.areChildrenVisible ? this.iniTimelines() : null;
        },
        areChildrenVisible(bool) {
            bool ? this.iniTimelines() : null;
        },
        // isFetchSiblingDone(bool) {
        //     bool && this.areChildrenVisible ? this.iniTimelines() : null;
        // },
        goToSiblingUrl(url) {
            url ? this.startGoToSibling(url) : null;
        },
        isMapVisible() {
            this.revealMapBelow();
        },
        currentEntry() {
            return this.getSiblings();
        }
    },

    methods: {
        ////////////////////////////////
        //       START ON MOUNT
        ////////////////////////////////
        initMethodsOnMount() {
            this.getSiblings(this.currentEntry);
            this.iniTimelines();
            this.revealMapBelow(true);
        },
        iniTimelines() {
            if (this.timelines.scrollDown) return;
            this.initScrollDownTimeline();
            this.initScrollTriggerReturn();
            this.initFadeInAnimation();
            this.initFadeInOutStories();
        },

        //======= START REVEALS MAP BELOW THE STORY =======//

        revealMapBelow() {
            this.isMapVisible && !this.isMapVisibleFromStory ? this.delayRevealMapBelow() : null;
        },

        delayRevealMapBelow() {
            const delayRevealMap = setTimeout(() => {
                this.revealMap(true);
                clearTimeout(delayRevealMap);
            }, 500);
        },

        revealMap(bool) {
            // when user go to the story, the map fadein and fade back when the story is loaded (but user don't see it)
            this.$store.commit("constellations/toggleMapFromStory", bool);
        },

        //======= END REVEALS MAP BELOW THE STORY =======//

        ////////////////////////////////
        //       END ON MOUNT
        ////////////////////////////////
        goToNextSection() {
            this.toggleScroll(false);
            this.scrollToSection();
        },

        ////////////////////////////////
        //       START ANIMATION FADEIN ON MOUNT
        ////////////////////////////////

        initFadeInAnimation() {
            this.timelines.onMounted = gsap.timeline();
            this.timelines.onMounted
                .to(".l-header__inner", {
                    "--opacity-header-background": 0,
                    ease: "none",
                    duration: 0
                })

                .to(
                    ".c-nbhd-story",
                    {
                        opacity: 1,
                        ease: "none",
                        duration: 0.5
                    },
                    "mountedAnimation"
                )

                .add(() => {
                    this.resetStoreGoToOtherStory();
                })
                .to(
                    ".l-header__inner",
                    {
                        "--opacity-header-background": 1,
                        ease: "none",
                        duration: 0
                    },
                    "mountedAnimation+=1.5"
                );
        },
        resetStoreGoToOtherStory() {
            this.$store.commit("constellations/userGoToOtherStory", null);
        },
        ////////////////////////////////
        //       END ANIMATION FADEIN ON MOUNT
        ////////////////////////////////
        ////////////////////////////////
        //       START TOGGLE SCROLL BEHAVIOR
        ////////////////////////////////
        toggleScroll(bool) {
            // disable child to emit values on scroll
            this.toggleDisabledScroll(bool);
            // allow scroll via scsss
            this.toggleScrollScss(bool);
        },
        toggleDisabledScroll(bool) {
            this.isScrollDisabled = bool;
        },
        toggleScrollScss(bool) {
            bool ? (this.scroll = "hidden") : (this.scroll = "scroll");
        },
        ////////////////////////////////
        //       END TOGGLE SCROLL BEHAVIOR
        ////////////////////////////////

        ////////////////////////////////
        //       START SCROLL TO NEXT SECTION
        ////////////////////////////////
        initScrollDownTimeline() {
            // timelime animation

            // scroll down
            this.timelines.scrollDown = gsap.timeline({
                paused: true
            });

            this.timelines.scrollDown
                .to(
                    ".c-nbhd-story__inner",
                    {
                        scrollTo: { y: ".c-nbhd-story-more-credit__inner", offsetY: -10 },

                        ease: "power4.inOut",
                        duration: 1
                    },
                    "scrollStarted"
                )

                .add(() => {
                    this.toggleIsUserScrolling(true);
                    this.overwriteLightMode(true);
                }, "-=0.8")
                .to(
                    ".c-nbhd-story-more-return",
                    {
                        "--opacity-return-background": 0,
                        ease: "none",
                        duration: 0.3
                    },
                    "scrollStarted+=1"
                );
        },
        toggleIsUserScrolling(bool) {
            // trigger animation on the more section
            this.isUserScrolling = bool;
        },
        scrollToSection() {
            // ease animation scrollProgress
            this.easeScrollProgress(1000);
            // start to scroll down
            this.timelines.scrollDown.play();
            // ensure that progress isn't reset when scrolled articles are reseting
            this.emitUserScrolledDown(true);
        },
        overwriteLightMode(bool) {
            // on scroll light mode become dark. Please note that it wasn't originaly design this way so there might be some arhcitecture imporvement nedded later
            this.$store.commit("constellations/overwriteStoryLightMode", bool);
        },

        ////////////////////////////////
        //       END SCROLL TO NEXT SECTION
        ////////////////////////////////

        isMarkerVisible() {
            return this.isDevEnv() ? true : false;
        },

        initScrollTriggerReturn() {
            this.timelines.scrollUp = ScrollTrigger.create({
                trigger: ".c-stories-more-spacer__inner",
                start: "top bottom",
                markers: this.isMarkerVisible(),
                scroller: ".c-nbhd-story__inner",
                scrub: 0,
                onEnterBack: () => {
                    this.scrollBackToTop();
                    this.toggleIsUserScrolling(false);
                }
            });
        },
        scrollBackToTop() {
            if (this.isScrollDisabled) return;

            this.scrollToTop = gsap.to(".c-nbhd-story__inner", {
                scrollTo: { y: 0, offsetY: 0 },

                ease: "power4.inOut",
                duration: 0.5,
                onComplete: () => {
                    this.toggleScroll(true);
                    this.scrollToTop = null;
                    this.timelines.scrollDown ? this.resetScrollDownTimeline() : null;
                    this.overwriteLightMode(false);
                    this.resetPageScrollGradually(0);
                    this.emitUserScrolledDown(false);
                }
            });
        },
        resetScrollDownTimeline() {
            this.timelines.scrollDown.seek(0);
            this.timelines.scrollDown.pause();
        },

        ////////////////////////////////
        //       START SCROLL PROGRESSION
        ////////////////////////////////
        resetPageScrollGradually() {
            this.easeScrollProgress(500);
            this.emitPageScrollGlobally(0);
        },
        easeScrollProgress(duration) {
            this.$store.commit("constellations/toggleScrollProgressEase", true);
            const easeScrollProgress = setTimeout(() => {
                this.$store.commit("constellations/toggleScrollProgressEase", false);
                clearTimeout(easeScrollProgress);
            }, duration);
        },

        emitPageScrollGlobally(progress) {
            this.$store.commit("constellations/updateStoryProgression", progress);
        },

        emitUserScrolledDown(bool) {
            this.$store.commit("constellations/toggleScrollToBottom", bool);
        },
        ////////////////////////////////
        //       END SCROLL PROGRESSION
        ////////////////////////////////

        ////////////////////////////////
        //       END ANIMATION
        ////////////////////////////////

        ////////////////////////////////
        //       START GET SIBBLINGS
        ////////////////////////////////
        // this is refactoring to include a last minute udpate: https://mambomambo-team.atlassian.net/browse/CN-470

        getSiblings(entry) {
            // If you move this method to a 'only Story' component, the condition won't be needed
            if (entry.type != "storyText" && entry.type != "storyAudio") return;

            return this.$store.dispatch("constellations/getSiblingStories", entry).then(result => {
                this.siblings = result;
                result.length ? this.findCurrentStoryIndex() : this.toggleFetchSiblingDone(true);
            });
        },
        findCurrentStoryIndex() {
            // id from craft is a string
            this.currentStoryIndex = this.siblings.findIndex(x => x.id === this.currentEntry.id);
            this.toggleFetchSiblingDone(true);
        },
        toggleFetchSiblingDone(bool) {
            this.isFetchSiblingDone = bool;
        },
        ////////////////////////////////
        //       END GET SIBBLINGS
        ////////////////////////////////

        ////////////////////////////////
        //       START GO TO SIBBLINGS
        ////////////////////////////////
        startGoToSibling() {
            this.fadeInOutStories(true);
        },
        initFadeInOutStories() {
            this.timelines.fadeInOut = gsap.timeline({
                paused: true,
                onComplete: () => {
                    this.resetAnimations();
                }
            });
            this.timelines.fadeInOut.to(
                ".c-nbhd-story__inner",
                {
                    opacity: 0,
                    ease: "none",
                    duration: 1
                },
                "+=0.3"
            );
        },
        fadeInOutStories(bool) {
            bool ? this.timelines.fadeInOut.play() : this.timelines.fadeInOut.reverse();
        },

        //======= START RESET ANIMATION =======//

        resetAnimations() {
            this.toggleChildrenAreVisible(false);
            // destroy all timelines and scroll
            // reset all states: (  scroll/isScrollDisabled/isUserScrolling)
            const arrayOfTimelinesToDestroy = ["onMounted", "scrollDown", "scrollUp", "shadows"];
            this.destroyTimelines(arrayOfTimelinesToDestroy);

            this.resetToOriginalState();
            // go to the top
            this.windowScrollToTop();

            // redirect
            this.redirectToNewStory();

            // display the new story

            this.displayNewStory();
        },

        resetToOriginalState() {
            // scroll related states
            this.isScrollDisabled = true;
            this.isUserScrolling = false;
            this.scroll = "hidden";
            // destroy all children component
            this.toggleChildrenAreVisible(false);
        },

        windowScrollToTop() {
            window.scroll(0, 0);
        },
        toggleChildrenAreVisible(bool) {
            this.areChildrenVisible = bool;
        },
        redirectToNewStory() {
            this.$router.push(`/${this.goToSiblingUrl}`);
        },

        displayNewStory() {
            // avoid some visual bug while doing a transition too fast
            const findClassInterval = setInterval(() => {
                this.isClassExist("c-nbhd-story-more-return") && this.isFetchSiblingDone
                    ? (this.toggleChildrenAreVisible(true), clearInterval(findClassInterval))
                    : null;
            }, 300);
        },
        isClassExist(nameOfTheClass) {
            // true/false is easier to read even on a ternary, please don't judge too fast :D
            return document.getElementsByClassName(nameOfTheClass).length ? true : false;
        },

        //======= END RESET ANIMATION =======//

        ////////////////////////////////
        //       END GO TO SIBBLINGS
        ////////////////////////////////

        debounceOnResize: _.debounce(function() {
            this.onResize();
        }, 400),

        onResize() {
            // clear all timelines
            this.destroyAllTimelines();
            // restart all timelines
            this.iniTimelines();
        },

        ////////////////////////////////
        //       START DESTROY
        ////////////////////////////////
        destroyAllTimelines() {
            const arrayOfTimelines = ["onMounted", "scrollDown", "scrollUp", "return", "fadeInOut"];
            this.destroyTimelines(arrayOfTimelines);
        },
        destroyTimelines(arrayOfTimelines) {
            // destroy GSAP timelines
            arrayOfTimelines.forEach(element => {
                this.timelineKiller(element);
            });
        },
        timelineKiller(name) {
            this.timelines[name] ? (this.timelines[name].kill(), (this.timelines[name] = null)) : null;
        }

        ////////////////////////////////
        //       END DESTROY
        ////////////////////////////////
    }
};
</script>

<style lang="scss" scoped>
.c-nbhd-story {
    @include fullWidthFixed(fixed);
    @include transition(0.3s linear background);
    --padding-children: 0 var(--grid-gutter-half);
    --scroll: hidden;

    z-index: 2;
    overflow: scroll;
    opacity: 0;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
        display: none;
    }

    @media #{md("sm")} {
        --padding-children: 0 var(--grid-gutter);
    }

    &--light {
        --color-light: #1c0c2e;
        --color-light-copy: #1c0c2e;
        --color-purple-dark: #fcefef;
    }

    &__inner {
        @include hide-scrollbar;
        display: flex;
        flex-direction: column;
        flex: 1;
        height: 100%;
        overflow: var(--scroll);
        position: relative;
    }
}
</style>
