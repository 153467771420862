<template>
    <div class="c-story-article-overlap-img">
        <asset class="c-story-article-overlap-img__img" :asset="image" />
    </div>
</template>

<script>
import Asset from "@/templates/objects/Asset";
export default {
    components: {
        Asset
    },
    props: {
        image: {
            type: Object,
            required: false,
            default: null
        }
    }
};
</script>

<style lang="scss" scoped>
.c-story-article-overlap-img {
    --gradient-color-dark: 28, 12, 46;
    --gradient-color-light: 252, 239, 239;
    --opacity-shadow: 1;
    --max-height: 30vh;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;

    height: 100vh;
    max-height: var(--max-height);
    pointer-events: none;

    @media #{md("xs")} {
        --max-height: 40vh;
    }

    &__img {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: flex-end;
        z-index: 3;
        object-position: bottom;
        &::v-deep img {
            object-fit: cover;
            min-width: 100%;
            height: 100%;
            // height: calc(100% - 5rem);
            object-position: top;
            // @media #{md("xs")} {
            //     height: calc(100% - 10rem);
            // }
        }
    }
}
</style>
