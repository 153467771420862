import snippets from "./snippets";
//import getAsset from './assets'

/*
 * Craft GraphQL Queries
 */

/**********************************
 *
 *
 *  GENERAL
 *
 *
 **********************************/

// Main query
export const general = `{
    navs: globalSet(handle: "navs") {
        ... on navs_GlobalSet {
            navNodes {
                ...on navNodes_BlockType {
                    entry {
                        ${snippets.defaultData}
                        title
                        section: sectionHandle
                    }
                    label
                }
            }
        }
    }
    neighbourhoods: entries(section: "constellations", level: 1) {
        id
        slug
        title
    }
    settings: globalSet(handle: "settings") {
        ... on settings_GlobalSet {
            ${snippets.model}
            ${snippets.audio}
        }
    }
}`;

/**********************************
 *
 *
 *  SEARCH
 *
 *
 **********************************/

export const categoryGroup = `query Categories($group: [String!], $relations: [EntryCriteriaInput]){
    categories(group: $group, relatedToEntries: $relations) {
        id
        title
        handle: slug
    }
}
`;

export const searchEntries = (section, options) => {
    if (section == undefined) {
        throw "A section is required to perform the searchEntries query";
    }

    let appendQuery = "";

    if (options.relatedToCategories && options.relatedToCategories.length) {
        let relatedToCategories = [];

        options.relatedToCategories.forEach(categ => {
            let category = `{
                group: ["${categ.group}"],
                id: [${categ.id}]
           }`;

            relatedToCategories.push(category);
        });

        appendQuery += `, relatedToCategories: [${relatedToCategories}]`;
    }
    if (options.searchText) {
        appendQuery += `, search: "${options.searchText}"`;
    }

    if (options.fixedOrder) {
        appendQuery += `, fixedOrder: ${options.fixedOrder}`;
    } else {
        let orderKey = options.order ? options.order.key : "postDate",
            orderDirection = options.order ? options.order.direction : "DESC";

        let orderBy = `${orderKey} ${orderDirection}`;
        appendQuery += `, limit: ${options.limit || 100}, offset: ${options.offset || 0}`;
        appendQuery += `, orderBy: "${orderBy}"`;
    }

    if (options.ids) {
        let _ids = options.ids.map(id => parseInt(id));

        _ids.forEach(id => {
            if (isNaN(id)) throw "ids should be of type Number";
        });
        appendQuery += `, id: [${_ids}]`;
    }

    return `{
        totalCount: entryCount(section: "${section}")
        resultsCount: entryCount(section: "${section}" ${appendQuery})
        entries(section: "${section}", ${appendQuery}) {
            ${snippets.entry}

        }
    }`;
};

/**********************************
 *
 *
 *  CONTENT
 *
 *
 **********************************/

// Home page
export const home = `{
    entry(section: "home") {
        ${snippets.defaultData}
        section: sectionHandle

        ...on home_home_Entry {
            heading
            ${snippets.seo}
        }
    }
}`;

// About
export const about = `{
    entry(section: "about") {
        ${snippets.defaultData}
        section: sectionHandle

        ...on about_about_Entry {
            heading

            paragraphs: sequencesText {
                ...on sequencesText_sequence_BlockType {
                    body
                }
            }
            ${snippets.seo}
        }
    }
}`;

// Credits
export const credits = `{
    entry(section: "credits") {
        ${snippets.defaultData}
        section: sectionHandle

        ...on credits_credits_Entry {
            heading
            ${snippets.seo}
            ${snippets.creditSequence}
        }
    }
}`;

// Error page
export const error = `{
    entry(section: "error") {
        ${snippets.defaultData}
        section: sectionHandle

        ...on error_error_Entry {
            heading
            ${snippets.seo}
        }
    }
}`;

/**********************************
 *
 *
 *  STRUCTURES
 *
 *
 **********************************/

export const constellations = slug => `{
    entry(section: "constellations", slug: "${slug}") {
        ${snippets.entry}
        type: typeHandle
        ${snippets.district}
        ${snippets.constellation}
        ${snippets.storyText}
        ${snippets.storyAudio}
    }
}`;

export const relatedStoriesCount = id => `{
    entryCount(section: "constellations", descendantOf: ${id}, level: 3)
}`;

export const siblingStories = constellation => `{
    entry(section: "constellations", slug: "${constellation.slug}") {
        children {
            ${snippets.entry}
            ${snippets.storyTextAuthors}
            ${snippets.storyAudioAuthors}
        }
    }
}`;

export default {
    // GENERAL
    general,

    // SEARCH
    categoryGroup,
    searchEntries,

    // CONTENT
    home,
    about,
    credits,
    error,

    // STRUCTURES
    constellations,
    relatedStoriesCount,
    siblingStories
};
