<template>
    <div class="p-credits">
        <credits-page :entry="entry" />
    </div>
</template>

<script>
import { mapState } from "vuex";
import { SECTION_HANDLES } from "src/constants";

// import DesignSystem from "components/designSystem/DesignSystem";
import CreditsPage from "@/templates/components/Credits/Credits";

export default {
    name: "Credits",
    components: {
        // DesignSystem,
        CreditsPage
    },
    metaInfo() {
        return {
            title: this.entry.seo ? this.entry.seo.title : null
        };
    },
    props: {
        entry: Object
    },
    data: () => ({
        SECTION_HANDLES
    }),
    mounted() {
        this.$nextTick(() => {
            this.setAudio();
            this.displayHeader();
        });
    },

    computed: {
        ...mapState({
            audio: state => state.global.settings.audio
        })
    },

    watch: {
        audio() {
            this.setAudio();
        }
    },
    methods: {
        ////////////////////////////////
        //       START HEADER ANIMATION MANAGEMENT
        ////////////////////////////////
        displayHeader() {
            this.$store.commit("global/toggleHeaderAnimation", true);
        },
        ////////////////////////////////
        //       END HEADER ANIMATION MANAGEM
        ////////////////////////////////

        ////////////////////////////////
        //       START AUDIO
        ////////////////////////////////
        setAudio() {
            this.audio && this.audio.url ? this.$store.commit("global/updateUpComingTrack", this.audio.url) : null;
        }

        ////////////////////////////////
        //       END AUDIO
        ////////////////////////////////
    }
};
</script>

<style lang="scss">
.p-credits {
    display: flex;
    flex-direction: column;
}
</style>
