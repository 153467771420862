<template>
    <ul class="c-shared-stars">
        <shared-stars-item v-for="index in this.quantity" :key="index" />
    </ul>
</template>

<script>
import SharedStarsItem from "@/templates/components/Shared/SharedStars/SharedStarsItem";
export default {
    components: {
        SharedStarsItem
    },
    props: {
        quantity: {
            type: Number,
            required: false,
            default: 20
        }
    }
};
</script>

<style lang="scss">
.c-shared-stars {
    @include reset-list;
    @include fullWidthFixed(absolute);
    z-index: 1;
}
</style>
