<template>
    <div class="p-home">
        <home-page />
    </div>
</template>

<script>
// import { mapState } from "vuex";
import { SECTION_HANDLES } from "src/constants";

import HomePage from "components/Home/Home";

export default {
    name: "Home",
    components: {
        HomePage
    },
    metaInfo() {
        return {
            title: this.entry.seo ? this.entry.seo.title : null
        };
    },
    props: {
        entry: Object
    },
    data: () => ({
        SECTION_HANDLES,
        isActive: false
    }),
    // computed: {
    //     ...mapState({
    //         mainModel: state => state.global.settings.model
    //     })
    // },
    mounted() {
        // this.mockStuff();
    },
    methods: {
        // mockStuff() {
        //     setTimeout(() => {
        //         this.testGetAllSibling();
        //     }, 1000);
        // },
        // testGetAllSibling() {
        //     this.$store.dispatch("home/getSiblingStories", "montcalm").then(result => {
        //         // this.siblings = result;
        //         console.log("shoudl resutn somwthing", result);
        //     });
        // }
    }
};
</script>

<style lang="scss">
.p-home {
}
</style>
