<template>
    <div
        class="c-home-navigation-slider"
        :class="{ 'c-home-navigation-slider--grabbing': isUserGrabbing }"
        @mouseup="userGrabScene(false)"
        @mousedown="userGrabScene(true)"
    >
        <!-- Using the slider component -->
        <slider v-if="isSliderReady" ref="slider" :options="options" @slide="userSwiped" :slideTo="options.currentPage">
            <!-- slideritem wrapped package with the components you need -->
            <slideritem v-for="(item, index) in list" :key="index">
                <home-navigation-slider-item :item="item"
            /></slideritem>
            <!-- Customizable loading -->
            <div slot="loading">loading...</div>
        </slider>
    </div>
</template>

<script>
import { mapState } from "vuex";

import { slider, slideritem } from "vue-concise-slider";
import HomeNavigationSliderItem from "components/Home/HomeNavigation/HomeNavigationSlider/HomeNavigationSliderItem/HomeNavigationSliderItem";
export default {
    components: {
        HomeNavigationSliderItem,
        slider,
        slideritem
    },
    props: {
        slides: {
            type: Array,
            required: true
        }
    },

    data() {
        return {
            list: this.slides,
            isSliderReady: false,
            options: {
                currentPage: 0,
                pagination: false,

                loop: true,
                speed: 1000,
                timingFunction: "ease",

                slidesToScroll: 1
            },
            isUserGrabbing: false
        };
    },

    watch: {
        current(val) {
            this.slideTo(val);
        }
    },
    computed: {
        ...mapState({
            current: state => state.home.current
        })
    },

    mounted() {
        this.setDefaultSlide();
    },

    methods: {
        ////////////////////////////////
        //       START ON MOUNT
        ////////////////////////////////
        setDefaultSlide() {
            this.current ? (this.options.currentPage = this.current) : null;
            this.toggleSlide();
        },
        toggleSlide() {
            this.isSliderReady = true;
        },
        ////////////////////////////////
        //       END ON MOUNT
        ////////////////////////////////
        ////////////////////////////////
        //       START SLIDE MANAGEMENT
        ////////////////////////////////
        slideTo(val) {
            this.$refs.slider.$emit("slideTo", val);
        },
        userSwiped(data) {
            return data.currentPage !== this.current ? this.udpateCurentSlideGlobaly(data.currentPage) : null;
        },
        udpateCurentSlideGlobaly(index) {
            this.$store.commit("home/updateCurrent", { value: index });
        },
        ////////////////////////////////
        //       END SLIDE MANAGEMENT
        ////////////////////////////////

        //======= START CSS ANIMATION =======//

        userGrabScene(bool) {
            this.isUserGrabbing = bool;
        }

        //======= END CSS ANIMATION =======//
    }
};
</script>

<style lang="scss">
.c-home-navigation-slider {
    display: flex;
    flex: 1;
    z-index: 2;
    align-items: stretch;
    justify-content: center;
    flex-direction: row;

    cursor: grab;
    z-index: 1;

    &--grabbing {
        cursor: grabbing;
    }

    .slider-container {
        position: absolute;
        height: 100%;
        flex: 1;
    }

    .slider-item {
        /* transform: scale(0.8); */
        transition-timing-function: ease;
        transition-duration: 300ms;
        /* max-width: 50vw; */
    }
    /* .slider-item.slider-active {
        transform: scale(1);
        z-index: 999;
    }
    .slider-item.slider-active-copy {
        transform: scale(1);
        z-index: 999;
    } */
}
</style>
