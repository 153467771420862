<template>
    <div class="c-enter-tips-audio-with-out-btn-mute">
        <shared-animated-icon-mute />
    </div>
</template>

<script>
import SharedAnimatedIconMute from "@/templates/components/Shared/SharedAnimatedIcons/SharedAnimatedIconMute";
export default {
    components: {
        SharedAnimatedIconMute
    }
};
</script>

<style lang="scss" scoped>
.c-enter-tips-audio-with-out-btn-mute {
    --scale-mute: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 1rem;
    position: relative;
    aspect-ratio: 1/1;

    &:after {
        @include radius(50%);
        @include buttonBorder;
        @include transform(scale(var(--scale-mute)));
        content: " ";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}
</style>
