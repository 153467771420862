<template>
    <div class="c-shared-map-icon">
        <component :is="mapName"></component>
    </div>
</template>

<script>
import SharedMapIconLimoilou from "@/templates/components/Shared/SharedMapIcon/SharedMapIconLimoilou";
import SharedMapIconMontcalm from "@/templates/components/Shared/SharedMapIcon/SharedMapIconMontcalm";
import SharedMapIconSaintJeanBaptiste from "@/templates/components/Shared/SharedMapIcon/SharedMapIconSaintJeanBaptiste";
import SharedMapIconSaintRoch from "@/templates/components/Shared/SharedMapIcon/SharedMapIconSaintRoch";
import SharedMapIconSaintSauveur from "@/templates/components/Shared/SharedMapIcon/SharedMapIconSaintSauveur";
import SharedMapIconVieuxQuebec from "@/templates/components/Shared/SharedMapIcon/SharedMapIconVieuxQuebec";
export default {
    components: {
        SharedMapIconMontcalm,
        SharedMapIconSaintJeanBaptiste,
        SharedMapIconLimoilou,
        SharedMapIconSaintRoch,
        SharedMapIconSaintSauveur,
        SharedMapIconVieuxQuebec
    },
    props: {
        neighbourhoodName: {
            type: String,
            required: false,
            default: null
        }
    },
    computed: {
        // issue with svg sprite generator so instead I use the svg directly in a component
        mapName() {
            switch (this.neighbourhoodName) {
                case "limoilou":
                    return "SharedMapIconLimoilou";
                case "montcalm":
                    return "SharedMapIconMontcalm";
                case "saint-jean-baptiste":
                    return "SharedMapIconSaintJeanBaptiste";

                case "saint-roch":
                    return "SharedMapIconSaintRoch";
                case "saint-sauveur":
                    return "SharedMapIconSaintSauveur";

                case "vieux-quebec":
                    return "SharedMapIconVieuxQuebec";

                default:
                    return "SharedMapIconMontcalm";
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.c-shared-map-icon {
    width: 100%;
    aspect-ratio: 1 / 1;
}
</style>
