export const SECTION_HANDLES = Object.freeze({
    HOME: "home",
    CONSTELLATIONS: "constellations",
    ABOUT: "about",
    CREDITS: "credits",
    ERROR: "error"
});

// For Vuex stores
export const STORE_MODULES = Object.freeze(["home", "global", "entries", "constellations", "loader"]);
