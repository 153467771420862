import { render, staticRenderFns } from "./SharedTipsInfo.vue?vue&type=template&id=0eb5e97e&scoped=true&"
import script from "./SharedTipsInfo.vue?vue&type=script&lang=js&"
export * from "./SharedTipsInfo.vue?vue&type=script&lang=js&"
import style0 from "./SharedTipsInfo.vue?vue&type=style&index=0&id=0eb5e97e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0eb5e97e",
  null
  
)

export default component.exports