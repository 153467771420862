<template>
    <div class="c-neighbourhood-nav">
        <div class="c-neighbourhood-nav__inner">
            <neighbourhood-nav-btn />
        </div>
    </div>
</template>

<script>
import NeighbourhoodNavBtn from "@/templates/components/Neighbourhood/NeighbourhoodNav/NeighbourhoodNavBtn";
export default {
    components: {
        NeighbourhoodNavBtn
    }
};
</script>

<style lang="scss" scoped>
.c-neighbourhood-nav {
    --pointer-event: none;
    /* animations */
    @include transform(translateY(5rem));
    opacity: 0;
    /* animations */

    display: flex;
    justify-content: center;
    pointer-events: var(--pointer-event);
    &__inner {
        position: absolute;
    }
}
</style>
