/*
 * Home
 */
import { fetchApi } from "src/graphql/config";
import queries from "src/graphql/queries";

import Crud from "../crud";
const crud = new Crud("constellations");

// State
const state = {
    ...crud.state,
    current: 0,
    isExperienceStarted: false,
    isTitleUpdated: false,
    isSceneReady: false,
    isActiveSliderVisible: false,

    isNewVisit: true, // meaning is user need to see the animation of the home page

    // TODO Move below to an other store
    isAnimationStarted: false,
    isCTAVisible: false,
    isNoAudioVisible: false,
    areCirclesVisible: false,
    isRotationCompleted: false
};

// Getters
const getters = {
    ...crud.getters
};

// Actions
const actions = {
    ...crud.actions,
    getRelatedStoriesCount(store, id) {
        return new Promise((resolve, reject) => {
            fetchApi(queries.relatedStoriesCount(id))
                .then(r => {
                    // Exit on error
                    if (!r || !r.entryCount) {
                        throw new Error(`Related stories query failed`);
                    } else {
                        resolve(r.entryCount);
                    }
                })
                .catch(e => {
                    reject({
                        code: 404,
                        message: e.message
                    });
                });
        });
    },
    getSiblingStories(store, entry) {
        return new Promise((resolve, reject) => {
            // Start load
            store.dispatch("loader/startLoad", null, { root: true });

            fetchApi(queries.constellations(entry))
                .then(r => {
                    // Exit on error
                    if (!r || !r.entry) {
                        throw new Error(`Sibling stories failed`);
                    } else {
                        resolve(r.entry);

                        // End load
                        // store.dispatch("loader/endLoad", null, { root: true });
                    }
                })
                .catch(e => {
                    reject({
                        code: 404,
                        message: e.message
                    });

                    // End load
                    // store.dispatch("loader/endLoad", null, { root: true });
                });
        });
    }
};

// Mutations
const mutations = {
    ...crud.mutations,
    toggleIsSceneReady(state, bool) {
        state.isSceneReady = bool;
    },
    updateCurrent(state, value) {
        state.current = value.value;
    },
    toggleTitleIsUpdated(state, value) {
        state.isTitleUpdated = value;
    },
    toggleStartExperience(state, bool) {
        state.isExperienceStarted = bool;
    },
    updateUserVisit(state, bool) {
        state.isNewVisit = bool;
    },

    toggleActiveSlider(state, bool) {
        // the slider buttons (I,II,III) are active at the end of the intro animation in the video sent by Guillaume
        state.isActiveSliderVisible = bool;
    },
    toggleRotationIsComplete(state, bool) {
        state.isRotationCompleted = bool;
    },

    // TODO Move that an other store
    toggleAnimationStarted(state, bool) {
        state.isAnimationStarted = bool;
    },
    toggleCTAVisible(state, bool) {
        state.isCTAVisible = bool;
    },
    toggleIsNoAudioVisible(state, bool) {
        state.isNoAudioAnimationVisible = bool;
    },
    toggleCirclesVisible(state, bool) {
        state.areCirclesVisible = bool;
    }
};

// Export module
export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true
};
