<template>
    <ul class="c-about-content-list" v-if="paragraphs && paragraphs.length">
        <about-content-list-item
            v-for="(paragraph, index) in paragraphs"
            :key="index"
            :paragraph="paragraph"
            :paragraph-index="index"
            :is-larger-copy="isIntroText(index)"
            :is-intro-text="isIntroText(index)"
            :is-visible="isVisible(index + 1)"
            :is-last="isLast(index)"
        />
    </ul>
</template>

<script>
import { ScrollTrigger, gsap } from "gsap/all";

import isDevMixin from "@/mixins/isDevMixin";
import AboutContentListItem from "@/templates/components/About/AboutContent/AboutContentList/AboutContentListItem/AboutContentListItem";

export default {
    mixins: [isDevMixin],
    components: {
        AboutContentListItem
    },
    props: {
        paragraphs: {
            type: Array,
            required: false,
            default: () => []
        }
    },

    data() {
        return {
            list: [],
            isListReady: false,
            timelines: {
                scrollTrigger: [],
                timelines: []
            },
            current: 0,
            started: false
        };
    },
    beforeDestroy() {
        this.destroyTimelines();
    },
    mounted() {
        this.$nextTick(() => {
            this.initAllTimelines();
        });
    },
    methods: {
        ////////////////////////////////
        //       START UPDATE LIST ITEM
        ////////////////////////////////
        isIntroText(index) {
            return index === 0;
        },
        isVisible(index) {
            return this.current >= index ? true : false;
        },
        isLast(index) {
            return index + 1 === this.paragraphs.length;
        },

        ////////////////////////////////
        //       END UPDATE LIST ITEM
        ////////////////////////////////
        initAllTimelines() {
            const delaySetTimeline = setTimeout(() => {
                for (let index in this.paragraphs) {
                    this.addAnimation(index);
                    index + 1 === this.paragraphs.length ? clearTimeout(delaySetTimeline) : null;
                }
            }, 500);
        },
        addAnimation(index) {
            this.initTimelines(index);
            // used a loop instead of trigger on each class in purpose, refactor at your own risk
            this.initScrollTrigger(index, this.setPosition(index));
        },
        setPosition(index) {
            return index === 0 ? "top center" : "top center";
        },
        initScrollTrigger(index, position) {
            this.timelines.scrollTrigger[index] = ScrollTrigger.create({
                trigger: `.c-about-content-list-item--${index}`,
                start: position,
                markers: this.isMarkerVisible(),
                end: "-=100",
                scrub: 0,
                onEnter: () => {
                    this.timelines.timelines[index].play();
                }
            });
        },
        isMarkerVisible() {
            return this.isDevEnv() ? true : false;
        },
        initTimelines(index) {
            this.timelines.timelines[index] = gsap.timeline({ paused: true });
            const easeAnimation = "expo.inOut";
            const duractionAnimation = 2;

            this.timelines.timelines[index]
                .to(
                    `.c-about-content-list-item--${index}`,
                    {
                        "--opacity-copy": 1,
                        "--fade-fill": "20%",
                        "--scale-x": 1,
                        ease: easeAnimation,
                        duration: duractionAnimation
                    },
                    "started"
                )

                .to(
                    `.c-about-content-list-item--${index} .c-about-content-list-item__inner`,
                    {
                        y: 0,
                        ease: easeAnimation,
                        duration: duractionAnimation
                    },
                    "started"
                )
                .to(
                    `.c-about-content-list-item__inner__content--${index} .c-about-content-list-item__inner__content__copy`,
                    {
                        y: 0,
                        ease: easeAnimation,
                        duration: duractionAnimation
                    },
                    "started"
                )
                .add(() => {
                    this.current = index + 1;
                }, "started");

            if (index > 0) {
                this.timelines.timelines[index].to(
                    `.c-about-content-list-item-connector__button--${index - 1}`,
                    {
                        opacity: 1,
                        ease: easeAnimation,
                        duration: 1
                    },
                    "started"
                );
                this.timelines.timelines[index].to(
                    `.c-about-content-list-item-connector-line--${index - 1}`,
                    {
                        "--fade-fill": "10%",
                        ease: easeAnimation,
                        duration: duractionAnimation
                    },
                    "started"
                );
            }
            if (index === 0) {
                this.timelines.timelines[index].to(
                    `.c-about-content-list-item-connector__button--${index - 1}`,
                    {
                        y: 0,
                        ease: easeAnimation,
                        duration: 1
                    },
                    "started+=0.5"
                );
            }
        },
        isClassExist(nameOfTheClass) {
            // true/false is easier to read even on a ternary, please don't judge too fast :D
            return document.getElementsByClassName(nameOfTheClass).length ? true : false;
        },

        ////////////////////////////////
        //       START ON DESTROY
        ////////////////////////////////
        destroyTimelines() {
            this.paragraphs.forEach((item, index) => {
                this.timelineKiller(index);
                index + 1 === this.paragraphs.length ? this.resetAnimationsArrays() : null;
            });
        },
        timelineKiller(index) {
            this.timelines.timelines[index] ? this.destroyTimelineAndScroll(index) : null;
        },
        destroyTimelineAndScroll(index) {
            this.timelines.timelines[index].kill();
            this.timelines.scrollTrigger[index].kill();
        },
        resetAnimationsArrays() {
            this.timelines.timelines = [];
            this.timelines.scrollTrigger = [];
        }

        ////////////////////////////////
        //       END ON DESTROY
        ////////////////////////////////
    }
};
</script>

<style lang="scss">
.c-about-content-list {
    @include reset-list;
    --row-gap: 25rem;
    --col-gap: 3rem;

    display: grid;
    grid-auto-flow: row;
    gap: 18rem;
    z-index: 2;
    @media #{md('lg')} {
        gap: 25rem;
    }

    li {
        &:nth-child(even) {
            justify-content: center;
            @media #{md('md')} {
                justify-content: flex-end;
            }
        }
        &:nth-child(odd) {
            justify-content: center;
            @media #{md('md')} {
                justify-content: flex-start;
            }
        }
    }
}
</style>
