<template>
    <div class="c-home-navigation" :class="{ 'c-home-navigation--disabled': !isRotationCompleted }">
        <!-- slider -->
        <home-navigation-slider v-if="slides" :slides="slides" />

        <home-navigation-control v-if="slides" :slides="slides" />
    </div>
</template>

<script>
import { mapState } from "vuex";
import HomeNavigationControl from "components/Home/HomeNavigation/HomeNavigationControl/HomeNavigationControl";
import HomeNavigationSlider from "components/Home/HomeNavigation/HomeNavigationSlider/HomeNavigationSlider";

export default {
    components: {
        HomeNavigationControl,

        HomeNavigationSlider
    },
    data() {
        return {
            slides: [
                {
                    title: "saint-sauveur"
                },
                {
                    title: "saint-roch"
                },
                {
                    title: "limoilou"
                },
                {
                    title: "saint-jean-baptiste"
                },
                {
                    title: "montcalm"
                },
                {
                    title: "vieux-quebec"
                }
            ]
        };
    },
    computed: {
        ...mapState({
            isRotationCompleted: state => state.home.isRotationCompleted
        })
    }
};
</script>

<style lang="scss" scoped>
.c-home-navigation {
    @include fullHeightMobile;
    display: flex;
    flex-direction: column;

    position: relative;
    flex: 1;
    z-index: 2;

    &--disabled {
        pointer-events: none;
    }
}
</style>
