<template>
    <div class="c-navigation-mobile-upper">
        <!-- stars -->
        <shared-stars :quantity="responsiveStarsQty" />
        <div class="c-navigation-mobile-upper__inner">
            <navigation-list :is-mobile="true" />
        </div>
    </div>
</template>

<script>
import NavigationList from "@/templates/components/Navigation/NavigationMain/NavigationList";
import SharedStars from "@/templates/components/Shared/SharedStars/SharedStars";
export default {
    components: {
        NavigationList,
        SharedStars
    },
    computed: {
        responsiveStarsQty() {
            return window.innerWidth <= 480 ? 5 : 10;
        }
    }
};
</script>

<style lang="scss" scoped>
.c-navigation-mobile-upper {
    display: flex;
    flex: 1.25;
    position: relative;
    padding: var(--grid-gutter-half);

    &__inner {
        --padding-top: calc(8rem + 8rem + 2rem);
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: var(--padding-top);
        z-index: 2; // ensure that the links are above the stars
        @media #{md("sm")} {
            --padding-top: calc(8rem + 10rem + 2rem);
        }
        &::v-deep .c-navigation-list__link {
            @include transform(translateY(5rem));
            opacity: 0;
        }
    }
}
</style>
