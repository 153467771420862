<template>
    <li class="c-enter-tips-audio-without" :class="{ 'c-enter-tips-audio-without--error': isErrorPage }">
        <enter-tips-audio-with-out-btn :is-error-page="isErrorPage" @clicked="clicked" />
    </li>
</template>

<script>
import EnterTipsAudioWithOutBtn from "@/templates/components/Enter/EnterTips/EnterTipsAudio/EnterTipsAudioWithOut/EnterTipsAudioWithOutBtn/EnterTipsAudioWithOutBtn";
export default {
    components: {
        EnterTipsAudioWithOutBtn
    },
    props: {
        isErrorPage: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    methods: {
        clicked(bool) {
            this.$emit("clicked", bool);
        }
    }
};
</script>

<style lang="scss" scoped>
.c-enter-tips-audio-without {
    grid-row: 2/3;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: var(--grid-gutter-half);
    @media #{md('xs')} {
        align-items: flex-end;
        padding-top: 0px;
    }

    @media #{md('sm')} {
        grid-row: 1/1;
        grid-column: 3/4;
    }

    &:before {
        @include borderLine;
        content: " ";
        top: 0px;
        width: calc(100% + var(--grid-gutter));
        left: -var(--grid-gutter-half);
        @media #{md('sm')} {
            display: none;
        }
    }
    &--error {
        &:before {
            display: none;
        }
    }
}
</style>
