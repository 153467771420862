<template>
    <section class="c-nbhd-story-more-return" :class="{ 'c-nbhd-story-more-return--clickable': isButtonClickable }">
        <div class="c-nbhd-story-more-return__inner">
            <shared-explore
                v-if="districtTitle"
                :title="$t('neigbourhood.story.more.return.title')"
                :copy="districtTitle"
                :is-visible="isButtonVisible"
                @clicked="playAnimationOnLeave"
            />
        </div>
    </section>
</template>

<script>
import { mapState } from "vuex";
import _ from "lodash";

import { gsap, ScrollTrigger, CSSPlugin } from "gsap/all";
gsap.registerPlugin(ScrollTrigger, CSSPlugin);

import isDevMixin from "@/mixins/isDevMixin";

import SharedExplore from "@/templates/components/Shared/SharedExplore/SharedExplore";
export default {
    mixins: [isDevMixin],
    components: {
        SharedExplore
    },
    props: {
        isUserScrolling: {
            type: Boolean,
            required: false,
            default: false
        },
        hasSibblings: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.isChildrenMounted();
            window.addEventListener("resize", this.debounceOnResize);
        });
    },

    beforeDestroy() {
        this.destroyTimelines();
        window.removeEventListener("resize", this.debounceOnResize);
    },
    data() {
        return {
            timelines: {
                timeline: null,
                scrollTriggerReturn: null,
                onLeaveTimeline: null
            },
            findSiblingsInterval: null,
            isButtonVisible: false,
            isButtonClickable: false,
            isUserLeavingNeighbourhood: false
        };
    },
    computed: {
        ...mapState({
            currentEntry: state => state.global.currentEntry,
            goToSiblingUrl: state => state.constellations.goToSiblingUrl,
            isUserReturnToMapFromNav: state => state.constellations.isUserReturnToMapFromNav,
            navs: state => state.global.navs,
            transitionOutUrl: state => state.global.transitionOutUrl,
            isScrolledDown: state => state.constellations.isScrolledDown
        }),

        districtTitle() {
            return this.currentEntry.district ? this.currentEntry.district.title : null;
        }
    },
    watch: {
        isUserScrolling(bool) {
            bool ? (this.restartTimeline(), this.toggleBtnVisible(false)) : null;
        },
        goToSiblingUrl(val) {
            val ? this.playAnimationOnLeave() : null;
        },
        transitionOutUrl(val) {
            val && !this.goToSiblingUrl ? this.playAnimationOnLeave() : null;
        },
        isUserReturnToMapFromNav(bool) {
            bool ? this.playAnimationOnLeave() : null;
        }
    },

    methods: {
        ////////////////////////////////
        //       START WAIT THE IF FIND ALL THE SIBBLING BEFORE TO INIT ANIMATION BASED ON COMPONENT POSITION
        ////////////////////////////////
        // Debouce works too if you want
        isChildrenMounted() {
            this.findSiblingsInterval = setInterval(() => {
                const siblings = this.isClassExist("c-shared-list-item__btn");
                const listInner = this.isClassExist("c-shared-list__inner");

                (siblings && listInner) || !this.hasSibblings
                    ? (this.initScrollAnimation(),
                      this.initOnLeaveAnimation(listInner),
                      this.clearIntervalFindSiblings())
                    : null;
            }, 500);
        },
        isClassExist(nameOfTheClass) {
            // true/false is easier to read even on a ternary, please don't judge too fast :D
            return document.getElementsByClassName(nameOfTheClass).length ? true : false;
        },
        clearIntervalFindSiblings() {
            this.findSiblingsInterval ? clearInterval(this.findSiblingsInterval) : null;
        },
        ////////////////////////////////
        //       END WAIT THE IF FIND ALL THE SIBBLING BEFORE TO INIT ANIMATION BASED ON COMPONENT POSITION
        ////////////////////////////////
        ////////////////////////////////
        //       START ANIMATE ON SCROLL
        ////////////////////////////////
        initTimelines() {
            this.initScrollAnimation();
        },
        initScrollAnimation() {
            this.initTimeline();

            this.timelines.scrollTriggerReturn = ScrollTrigger.create({
                trigger: ".c-nbhd-story-more-return__inner",
                start: "bottom bottom",
                markers: this.isMarkerVisible(),
                scroller: ".c-nbhd-story__inner",
                scrub: 0,
                onEnter: () => {
                    this.playTimeline();
                }
            });
        },
        isMarkerVisible() {
            return this.isDevEnv() ? true : false;
        },
        initTimeline() {
            this.timelines.timeline = gsap.timeline({
                paused: true,
                onComplete: () => {
                    this.toggleClickableButton(true);
                },
                onReverseComplete: () => {
                    this.toggleClickableButton(false);
                }
            });
            this.timelines.timeline
                .to(".c-nbhd-story-more-return__inner", {
                    opacity: 1,
                    ease: "none",
                    duration: 1
                })
                .add(() => {
                    this.toggleBtnVisible(true);
                });
        },
        toggleBtnVisible(bool) {
            this.isButtonVisible = bool;
        },
        playTimeline() {
            this.timelines.timeline.play();
        },

        restartTimeline() {
            this.timelines.timeline.seek(0);
            this.timelines.timeline.pause();
        },

        toggleClickableButton(bool) {
            this.isButtonClickable = bool;
        },

        ////////////////////////////////
        //       END ANIMATE ON SCROLL
        ////////////////////////////////

        ////////////////////////////////
        //       START ON LEAVE
        ////////////////////////////////
        initOnLeaveAnimation() {
            const durationAnimation = 1.8;
            const easeAnimation = "expo.out";
            this.timelines.onLeaveTimeline = gsap.timeline({
                paused: true,
                onStart: () => {
                    this.isUserLeavingTheNeigbourhood();
                }
            });
            this.timelines.onLeaveTimeline
                .to(
                    ".c-nbhd-story__inner",
                    {
                        y: 0,
                        ease: easeAnimation,
                        duration: durationAnimation
                    },
                    "started"
                )
                .to(
                    ".c-stories-article__inner",
                    {
                        scale: 0.9,
                        ease: easeAnimation,
                        duration: durationAnimation
                    },
                    "started"
                )
                .to(
                    ".c-nbhd-story-more-return__inner",
                    {
                        scale: 0.9,
                        y: -100,
                        ease: easeAnimation,
                        duration: durationAnimation
                    },
                    "started"
                )
                .to(
                    ".c-nbhd-story-more-credit__inner",
                    {
                        scale: 0.9,
                        y: -50,
                        ease: easeAnimation,
                        duration: durationAnimation
                    },
                    "started"
                )
                .to(
                    ".c-nbhd-story",
                    {
                        opacity: 0,
                        ease: easeAnimation,
                        duration: durationAnimation - 0.1
                    },
                    "started+=0.1"
                )

                .add(() => {
                    this.forceBackgroundTobeDarkOnLeave();
                    this.hideNavBackground();
                }, `started`)

                .add(() => {
                    this.returnToMap();
                }, `started+=0.5`);
            // etoile solitaire don't have sibling, so only add sharedList animation if it has siblings
            this.hasSibblings ? this.addSharedListToTimeline(easeAnimation, durationAnimation) : null;
        },
        addSharedListToTimeline(easeAnimation, durationAnimation) {
            this.timelines.onLeaveTimeline.to(
                ".c-shared-list__inner",
                {
                    scale: 0.9,
                    y: -100,
                    ease: easeAnimation,
                    duration: durationAnimation
                },
                "started"
            );
        },

        playAnimationOnLeave() {
            this.timelines.onLeaveTimeline.play();
        },
        hideNavBackground() {
            // the background color of the nav is tricky: https://mambomambo-team.atlassian.net/browse/CN-365
            // if user already scroll, so ignore it. If user is at the top, the background might be white so simply run the methods below
            this.isScrolledDown
                ? null
                : gsap.to(
                      ".l-header__inner",
                      {
                          "--opacity-header-background": 0,
                          ease: "none",
                          duration: 0
                      },
                      "started+=0.1"
                  );
        },
        ////////////////////////////////
        //       END ON LEAVE
        ////////////////////////////////

        ////////////////////////////////
        //       START DESTROY
        ////////////////////////////////

        onDestroyComponent() {
            // destroy all gsap timeline
            this.destroyTimelines();
            // THis is a fall back in case it there is a bug and didn't find sibbling stories
            this.clearIntervalFindSiblings();
        },

        destroyTimelines() {
            this.timelineKiller("timeline");
            this.timelineKiller("scrollTriggerReturn");
            this.timelineKiller("onLeaveTimeline");
        },
        timelineKiller(name) {
            this.timelines[name] ? (this.timelines[name].kill(), (this.timelines[name] = null)) : null;
        },

        ////////////////////////////////
        //       END DESTROY
        ////////////////////////////////

        debounceOnResize: _.debounce(function() {
            this.onResize();
        }, 400),

        onResize() {
            // clear all timelines
            this.onDestroyComponent();
            // restart all timelines
            this.initScrollAnimation();
            this.initOnLeaveAnimation();
            this.clearIntervalFindSiblings();
        },

        ////////////////////////////////
        //       START RESIZE
        ////////////////////////////////

        ////////////////////////////////
        //       END RESIZE
        ////////////////////////////////
        ////////////////////////////////
        //       START RETURN TO MAP
        ////////////////////////////////
        returnToMap() {
            // don't run if user is going to a sibling OR if the user is returning redirectly to home
            this.goToSiblingUrl || this.isUserLeavingNeighbourhood ? null : this.returnToMapMethods();
        },
        forceBackgroundTobeDarkOnLeave() {
            // https://mambomambo-team.atlassian.net/browse/CN-365
            this.$store.commit("constellations/overwriteStoryLightMode", true);
        },
        returnToMapMethods() {
            this.$router.push(`/${this.currentEntry.district.slug}`);
            this.$store.commit("global/updatePageTransitionOutUrl", null);
            this.$store.commit("constellations/toggleReturnToMapFromNav", false);
        },

        ////////////////////////////////
        //       END RETURN TO MAP
        ////////////////////////////////

        ////////////////////////////////
        //       START RETURN TO HOME
        ////////////////////////////////
        isUserLeavingTheNeigbourhood() {
            this.isUserLeavingNeighbourhood = false;
            this.navs.forEach(nav => {
                this.isURLFromNav(nav.url, this.transitionOutUrl) ? (this.isUserLeavingNeighbourhood = true) : null;
            });
        },
        isURLFromNav(url, targetUrl) {
            return url !== "/montcalm" && url === targetUrl ? true : false;
        }
        ////////////////////////////////
        //       END RETURN TO HOME
        ////////////////////////////////
    }
};
</script>

<style lang="scss" scoped>
.c-nbhd-story-more-return {
    --gradient-visibility: none;
    --pointer-event-return: none;
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    background: rgb(28, 12, 46);
    background: linear-gradient(0deg, rgba(28, 12, 46, 0) 0%, rgba(28, 12, 46, 1) 100%);
    pointer-events: var(--pointer-event-return);
    min-height: 50vh;
    &--clickable {
        --pointer-event-return: auto;
    }

    --opacity-return-background: 1;
    --opacity-return-background-after: 0;

    &:before {
        @include transition(1s linear all);
        @include fullWidthFixed(absolute);
        content: " ";
        background: var(--color-purple-dark);
        width: 100%;
        height: 100%;
        opacity: var(--opacity-return-background);
        pointer-events: none;
        z-index: 9;
    }

    &__inner {
        padding-top: var(--grid-gutter-double);
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        @media #{md("xs")} {
            padding-top: var(--grid-gutter-quadruple);
        }
    }
}
</style>
