<template>
    <div class="c-nbhd-tips ">
        <div class="c-nbhd-tips__inner | grid">
            <neighbourhood-tips-panel />
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

import { gsap } from "gsap";

import NeighbourhoodTipsPanel from "@/templates/components/Neighbourhood/NeighbourhoodTips/NeighbourhoodTipsPanel";
export default {
    components: {
        NeighbourhoodTipsPanel
    },
    props: {
        isStory: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            timelines: {
                onMountAnimation: null
            },
            timeouts: {
                start: null,
                end: null
            }
        };
    },
    computed: {
        ...mapState({
            isTipsVisible: state => state.constellations.isTipsVisible,
            isSceneReady: state => state.constellations.isSceneReady,
            transitionOutUrl: state => state.global.transitionOutUrl,
            isNavOpen: state => state.global.isNavOpen
        })
    },
    beforeDestroy() {
        this.destroyTimelines();
        this.destroyTimeout();
    },
    mounted() {
        this.initTimelineOnMount();
    },
    watch: {
        isSceneReady() {
            this.delayAnimation(3000);
        },
        isStory() {
            this.delayAnimation(1000);
        },
        isNavOpen() {
            this.reverseTimeline(0);
        },
        transitionOutUrl() {
            this.cancelTips();
        }
    },
    methods: {
        ////////////////////////////////
        //       START ANIMATION
        ////////////////////////////////

        delayAnimation(duration) {
            // ensure that it's not triggered when user switch between siblings
            const timeoutDelay = setTimeout(() => {
                this.runAnimation(duration);
                clearTimeout(timeoutDelay);
            }, 500);
        },
        runAnimation(duration) {
            this.isTipsVisible && this.isSceneReady && !this.isStory && !this.isNavOpen
                ? this.playTimeline(duration)
                : null;
        },
        initTimelineOnMount() {
            this.isTipsVisible ? this.initTimeline() : null;
        },
        initTimeline() {
            const durationAnimation = 1.5;
            const ease = "expo.inOut";

            this.timelines.onMountAnimation = gsap.timeline({
                paused: true,
                onComplete: () => {
                    this.reverseTimeline(2000);
                    this.disableTipsGlobally();
                }
            });
            this.timelines.onMountAnimation
                .to(".c-nbhd-tips-panel", {
                    y: 0,
                    "--shadow-intensity": 0.6,
                    duration: durationAnimation,
                    ease: "power4.in"
                })
                .to(
                    ".c-shared-tips-line__inner",
                    {
                        "--width": "100%",

                        duration: durationAnimation,
                        ease: ease
                    },
                    "-=0.8"
                )
                .to(
                    ".c-shared-tips-info",
                    {
                        y: 0,
                        opacity: 1,

                        duration: durationAnimation,
                        ease: ease
                    },
                    "-=0.9"
                )
                .to(
                    ".c-shared-tips-info-icons__svg--shadow",
                    {
                        "--shadow-intensity": 0.3,
                        duration: durationAnimation * 0.5,
                        ease: "none"
                    },
                    "-=0.8"
                );
        },
        playTimeline(duration) {
            this.timeouts.start = setTimeout(() => {
                this.timelines.onMountAnimation.play();
                this.timeoutKiller("start");
            }, duration);
        },
        reverseTimeline(duration) {
            this.timeouts.end = setTimeout(() => {
                this.timelines.onMountAnimation.reverse();
                this.timeoutKiller("end");
            }, duration);
        },
        ////////////////////////////////
        //       END ANIMATION
        ////////////////////////////////

        disableTipsGlobally() {
            this.$store.commit("constellations/toggleTips", false);
        },

        cancelTips() {
            // user bounce, he land then leave the page right away
            this.timeoutKiller("end");
            this.timelines.onMountAnimation ? this.timelines.onMountAnimation.reverse() : null;
            this.disableTipsGlobally();
        },

        ////////////////////////////////
        //       START ON DESTROY
        ////////////////////////////////
        destroyTimelines() {
            this.timelineKiller("onMountAnimation");
        },

        timelineKiller(name) {
            this.timelines[name] ? (this.timelines[name].kill(), (this.timelines[name] = null)) : null;
        },
        destroyTimeout() {
            this.timeoutKiller("start");
            this.timeoutKiller("end");
        },
        timeoutKiller(name) {
            this.timeouts[name] ? clearTimeout(this.timeouts[name]) : null;
        }
        ////////////////////////////////
        //       END ON DESTROY
        ////////////////////////////////
    }
};
</script>

<style lang="scss" scoped>
.c-nbhd-tips {
    display: flex;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    justify-content: center;
    z-index: 1;

    pointer-events: none;
    &__inner {
        display: flex;
        justify-content: center;
    }
}
</style>
