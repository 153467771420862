<template>
    <li class="c-neighbourhood-story-article-audio-player-btn">
        <shared-explore
            tag="button"
            :is-visible="true"
            :title="title"
            :is-disabled="isButtonDisabled || isDisabled"
            :is-label="false"
            :is-small="true"
            @clicked="clicked"
        />
    </li>
</template>

<script>
import _ from "lodash";
import SharedExplore from "@/templates/components/Shared/SharedExplore/SharedExplore";
export default {
    components: {
        SharedExplore
    },
    props: {
        playing: {
            type: Boolean,
            required: false,
            default: false
        },
        isDisabled: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            isButtonDisabled: false,
            title: null
        };
    },
    mounted() {
        this.setCopyButton(this.playing);
    },
    watch: {
        playing(bool) {
            // if user is playing audio but unmute globally, which triggers pause this audio + the button to say "play"
            this.isCopyUpdatableFromWatch(bool) ? this.debouncedToggleButton(false) : null;
        }
    },

    methods: {
        isCopyUpdatableFromWatch(bool) {
            // check the title to fix some issue. This is so bad, but it fix a nasty bug and there is no EN so far
            return (
                !bool &&
                !this.isButtonDisabled &&
                this.title !== this.$t("neigbourhood.story.article.audio.button.play")
            );
        },
        ////////////////////////////////
        //       START UPDATE COPY
        ////////////////////////////////
        setCopyButton(bool) {
            this.title = this.buttonCopy(bool);
        },
        buttonCopy(bool) {
            return bool
                ? this.$t("neigbourhood.story.article.audio.button.pause")
                : this.$t("neigbourhood.story.article.audio.button.play");
        },
        resetCopyButton() {
            this.title = null;
        },
        ////////////////////////////////
        //       END UPDATE COPY
        ////////////////////////////////
        ////////////////////////////////
        //       START USER CLICKED
        ////////////////////////////////
        clicked() {
            this.debouncedToggleButton(true);
        },
        disabledButton(bool) {
            this.isButtonDisabled = bool;
        },
        debouncedToggleButton: _.debounce(function(isUserClicked) {
            // set the copy before the timeout
            const bool = this.whichCopy(isUserClicked);
            // disable button  to be clicked during the animation for UX purpose
            this.disabledButton(true);
            // remove the copy which triggers the animation
            this.resetCopyButton();

            // this debounce animation is run either from click on the button either user clicked mute globally
            isUserClicked ? this.$emit("clicked", !this.playing) : null;

            const toggleBtnTimeout = setTimeout(() => {
                this.setCopyButton(bool);
                this.disabledButton(false);
                clearTimeout(toggleBtnTimeout);
            }, 500);
        }, 500),
        whichCopy(isUserClicked) {
            return isUserClicked ? !this.playing : false;
        }
        ////////////////////////////////
        //       END USER CLICKED
        ////////////////////////////////
    }
};
</script>

<style lang="scss" scoped>
.c-neighbourhood-story-article-audio-player-btn {
    @include transform(translateY(200px));
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: var(--grid-gutter-half);
    &::v-deep .c-shared-explore-label__title {
        color: var(--color-light);
    }
}
</style>
