<template>
    <section class="about-scene" :class="{ 'about-scene--visible': isModelLoaded, 'about-scene--mobile': isMobile }">
        <canvas ref="scene__container" class="about-scene__container"></canvas>
    </section>
</template>

<script>
import { mapState } from "vuex";

import isDevMixin from "@/mixins/isDevMixin";
import Variables from "@/mixins/variables";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";

import { gsap } from "gsap/all";

import { aboutLines, aboutStars } from "@/data/about";
import { starFragmentShader, starVertexShader } from "@/shaders/about";
// STATS
// import Stats from "stats.js";
import { GUI } from "@/vendors/dat.gui.module.js";

export default {
    mixins: [isDevMixin, Variables],
    data() {
        return {
            // base
            container: null,
            scene: null,
            camera: null,
            controls: null,
            animation: undefined,

            //  helper
            helpers: {
                stats: null,
                clock: null
            },
            cameras: {
                defaultCameraPosition: {
                    camX: 0,
                    camY: 10,
                    camZ: 5
                },

                windowUser: {
                    width: 0,
                    height: 0
                },
                frustum: 5
            },
            gltf: {
                GLTFLoader: null
            },
            aboutGLTF: null,
            lights: {
                directionalLight: null,
                directionalLight2: null,
                directionalLightMain: {
                    directionalLight: null,
                    colour: {
                        colour: 0xff94c1,
                        intensity: 1.8
                    },
                    position: [-250, 200, 0]
                },
                directionalLightFront: {
                    directionalLight: null,
                    colour: {
                        colour: 0xfcacac,
                        intensity: 1.5
                    },
                    position: [-200, 100, 0]
                },
                directionalLightSide: {
                    directionalLight: null,
                    colour: {
                        colour: 0xffffff,
                        intensity: 1.5
                    },
                    position: [150, 400, 50]
                },
                directionalLightLight: {
                    directionalLight: null,
                    colour: {
                        colour: 0xff4283,
                        intensity: 1
                    },
                    position: [200, 100, 200]
                }
            },
            fog: {
                colour: 0x1c0c2e,
                near: 10,
                far: 15
            },
            modelScale: 0.0075,
            isModelLoaded: false,
            models: {
                lines: [
                    { model: null, radius: 4.5 },
                    { model: null, radius: 5.5 }
                ]
            },
            timelines: {
                onLeaveTimeline: null,
                revealGLTFTimeline: null
            }
        };
    },
    computed: {
        ...mapState({
            transitionOutUrl: state => state.global.transitionOutUrl
        }),
        cameraAspectRatio() {
            return this.cameras.windowUser.width / this.cameras.windowUser.height;
        }
    },
    mounted() {
        this.setWindowSize();
        this.setModelScale();

        this.init();
        // Register an event listener when the Vue component is ready
        window.addEventListener("resize", this.onResize);
        window.addEventListener("scroll", this.onScroll);
    },
    beforeDestroy() {
        cancelAnimationFrame(this.animation);
        this.animation = undefined;
        window.removeEventListener("resize", this.onResize);
        window.removeEventListener("scroll", this.onScroll);
        this.destroyTimelines();
    },
    watch: {
        transitionOutUrl() {
            this.transitionOutUrl ? this.leaveAboutPage() : null;
        }
    },
    methods: {
        ////////////////////////////////
        //       START ON START METHODS
        ////////////////////////////////
        //   set window width
        setWindowSize() {
            this.cameras.windowUser.width = document.documentElement.clientWidth;
            this.cameras.windowUser.height = document.documentElement.clientHeight;
        },
        addSceneFog() {
            this.scene.fog = new THREE.Fog(this.fog.colour, this.fog.near, this.fog.far);
            this.scene.background = new THREE.Color(this.fog.colour);
        },
        setLight() {
            this.setAmbientLight();
            this.directionalLightManager("directionalLightMain");
            this.directionalLightManager("directionalLightFront");
            this.directionalLightManager("directionalLightSide");
            this.directionalLightManager("directionalLightLight");
        },
        setAmbientLight() {
            const ambientLight = new THREE.AmbientLight(0xfcefef, 1);
            this.scene.add(ambientLight);
        },
        directionalLightManager(lightName) {
            this.lights[lightName].directionalLight = new THREE.DirectionalLight(
                this.lights[lightName].colour.colour,
                this.lights[lightName].colour.intensity
            );
            this.lights[lightName].directionalLight.position.set(
                this.lights[lightName].position[0],
                this.lights[lightName].position[1],
                this.lights[lightName].position[2]
            );
            this.scene.add(this.lights[lightName].directionalLight);
        },

        directioLightHelper(lightName) {
            const directionalLightHelper = new THREE.DirectionalLightHelper(
                this.lights[lightName].directionalLight,
                0.1
            );
            this.scene.add(directionalLightHelper);
        },

        onResize() {
            // console.log("onResize", window.innerHeight, document.documentElement.clientHeight);
            // Update sizes
            this.setWindowSize();
            this.setModelScale();

            // Update camera
            this.camera.aspect = this.cameraAspectRatio;
            this.responsiveZoom();
            this.resizeCameraSize();
            this.camera.updateProjectionMatrix();

            // Update renderer
            this.renderer.setSize(this.cameras.windowUser.width, this.cameras.windowUser.height);
            this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
        },
        responsiveZoom() {
            this.cameras.windowUser.width <= 800 ? (this.camera.zoom = 0.8) : (this.camera.zoom = 1);
        },

        resizeCameraSize() {
            this.camera.left = -this.cameras.frustum * this.cameraAspectRatio;
            this.camera.right = this.cameras.frustum * this.cameraAspectRatio;
            this.camera.top = this.cameras.frustum;
            this.camera.bottom = -this.cameras.frustum;
        },

        ////////////////////////////////
        //       END ON START METHODS
        ////////////////////////////////

        ////////////////////////////////
        //       START INIT SCENE AND RENDER
        ////////////////////////////////

        init() {
            this.setBaseScene();

            this.setLight();
            this.addSceneFog();

            /*------------------------------
            Start add meshes to the scenes
            ------------------------------*/
            this.addCircles();
            this.addGLTF();

            /*------------------------------
            End add meshes to the scenes
            ------------------------------*/

            this.setCamera();
            this.setControl();

            this.setRenderer();

            // console.log("this.scene", this.scene);
            this.gameLoop();
        },

        //======= START GAME LOOP =======//

        gameLoop() {
            // don't display the stats if on prod. Maybe this dev stuff should be handle differently
            this.isDevEnv() ? this.helpers.stats.update() : "";

            // Render
            this.controls.update();

            this.renderer.render(this.scene, this.camera);

            // Call gameLoop again on the next frame
            this.animation = requestAnimationFrame(this.gameLoop);
        },

        //======= END GAME LOOP =======//

        //======= START BASE THREEJS =======//

        setBaseScene() {
            // set container
            this.container = this.$refs.scene__container;

            // create scene
            this.scene = new THREE.Scene();

            this.isDevEnv() ? this.setHelpers() : "";

            this.setClock();
        },

        setHelpers() {
            // // stats
            // this.helpers.stats = new Stats();
            document.body.appendChild(this.helpers.stats.dom);

            // axes helpers
            const axesHelper = new THREE.AxesHelper(5);
            // x y z
            this.scene.add(axesHelper);

            // set gui globaly
            this.setGUI();
        },
        setGUI() {
            this.helpers.gui = new GUI();
        },
        setClock() {
            this.helpers.clock = new THREE.Clock();
        },

        //======= END BASE THREEJS =======//

        //======= START CAMERA AND CONTROL =======//

        setCamera() {
            this.camera = new THREE.OrthographicCamera(
                -this.cameras.frustum * this.cameraAspectRatio,
                this.cameras.frustum * this.cameraAspectRatio,
                this.cameras.frustum,
                -this.cameras.frustum,
                0.75,
                500
            );
            this.camera.position.set(
                this.cameras.defaultCameraPosition.camX,
                this.cameras.defaultCameraPosition.camY,
                this.cameras.defaultCameraPosition.camZ
            );

            this.responsiveZoom();

            this.camera.lookAt(0, 0, 0);
            this.camera.updateProjectionMatrix();

            this.scene.add(this.camera);
        },

        setControl() {
            this.controls = new OrbitControls(this.camera, this.container);
            this.controls.enableDamping = true;
            this.controls.enablePan = false;
            this.controls.enableZoom = false;
            this.controls.maxPolarAngle = Math.PI / 3;
            this.controls.minPolarAngle = Math.PI / 3;
            this.controls.rotateSpeed = 0.5;
            this.setTarget();
        },
        setTarget() {
            this.controls.target.set(0, 0, 0);
        },

        //======= END CAMERA AND CONTROL =======//

        //======= START RENDERER  =======//

        setRenderer() {
            this.renderer = new THREE.WebGLRenderer({
                canvas: this.container,
                powerPreference: "high-performance",
                antialias: true,
                alpha: true
            });

            this.renderer.setClearColor(0x000000, 0);
            this.renderer.setSize(this.cameras.windowUser.width, this.cameras.windowUser.height);

            this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
        },

        //======= END RENDERER  =======//

        ////////////////////////////////
        //       END INIT SCENE AND RENDER
        ////////////////////////////////

        ////////////////////////////////
        //       LOAD GLTF
        ////////////////////////////////

        addGLTF() {
            // setup the GLTF Loader
            this.setBaseLoader();
            // Loop all GLTF from an array in a JSON file (later will be from CRAFT)
            this.loadGLTF();
        },

        //======= START GLTF LOADER =======//

        setBaseLoader() {
            // draco always before GLTF Loader
            this.setDraco();
            this.setLoader();
        },
        setDraco() {
            this.gltf.dracoLoader = new DRACOLoader();
            //  path to a folder containing WASM/JS decoding libraries.
            this.gltf.dracoLoader.setDecoderPath("/three-assets/vendors/draco/"); // path needs be public
            this.gltf.dracoLoader.preload();
        },
        setLoader() {
            // set loader
            this.gltf.GLTFLoader = new GLTFLoader();
            this.gltf.GLTFLoader.setDRACOLoader(this.gltf.dracoLoader);
        },

        //======= END GLTF LOADER =======//

        //======= START UPLOAD GLTF =======//

        loadGLTF() {
            this.gtflLoader("/three-assets/about/about__draco.glb", "aboutGLTF");
        },
        gtflLoader(path, GTLFName) {
            // const newMaterial = new THREE.MeshStandardMaterial({ color: 0xfffff }); // add color dynamicly

            this.gltf.GLTFLoader.load(
                path,
                gltf => {
                    this[GTLFName] = gltf; // add model dynamicly
                    gltf.scene.traverse(child => {
                        if (child.isMesh) {
                            child.castShadow = false;
                            child.receiveShadow = false;
                            // child.material = newMaterial;
                            child.material.metalness = 0.5; // TODO REMOVE > TEST -> Update directly from Blender
                        }
                    });

                    gltf.scene.scale.set(this.modelScale, this.modelScale, this.modelScale);

                    this.scene.add(gltf.scene);
                    this.model = this.aboutGLTF.scene.children[0];
                    gltf.scene.position.set(0, -10, 0);

                    const stars = this.generateStars();
                    const lines = this.generateLines();

                    this.model.add(stars, ...lines);
                    this.model.position.set(0, -30, 100);
                    this.model.rotation.set(Math.PI / 2, 0, 0.5);

                    this.isModelLoaded = true;
                    this.revealModel();
                    this.initOnLeaveTimeline();
                },
                undefined
            );
        },
        setModelScale() {
            if (window.innerWidth < 768) {
                this.modelScale = 0.005;
            }
        },
        revealModel() {
            this.timelines.revealGLTFTimeline = gsap.timeline();

            this.timelines.revealGLTFTimeline
                .to(
                    this.aboutGLTF.scene.position,
                    {
                        y: -1,
                        duration: 3,
                        ease: "power4.out"
                    },
                    "started"
                )

                .to(
                    this.aboutGLTF.scene.rotation,
                    {
                        y: Math.PI * 0.3,
                        duration: 3,
                        ease: "power4.out"
                    },
                    "started"
                )
                .to(
                    this.models.lines[0].model.position,
                    {
                        y: -2,
                        duration: 2.5,
                        ease: "power4.out"
                    },
                    "started+=0.5"
                )
                .to(
                    this.models.lines[1].model.position,
                    {
                        y: -2,
                        duration: 2.5,
                        ease: "power4.out"
                    },
                    "started+=0.75"
                );
        },

        generateStars() {
            // console.log(aboutStars)
            const positionsArray = aboutStars.flat();

            const positions = new Float32Array(positionsArray.length);
            const scales = new Float32Array(positionsArray.length / 3);

            for (let i = 0; i < positionsArray.length; i++) {
                positions[i] = positionsArray[i];
                positions[i + 1] = positionsArray[i + 1];
                positions[i + 2] = positionsArray[i + 2];
                scales[i] = Math.random() * 1.5 + 1.5; //between when 1.5 and 3
            }

            const starGeometry = new THREE.BufferGeometry();
            starGeometry.setAttribute("position", new THREE.BufferAttribute(positions, 3));
            starGeometry.setAttribute("aScale", new THREE.BufferAttribute(scales, 1));

            // Use same random array of float as `scales` for the frequency (better for performance)
            starGeometry.setAttribute("aFrequency", new THREE.BufferAttribute(scales, 1));

            const starShaderMaterial = new THREE.ShaderMaterial({
                depthWrite: false,
                blending: THREE.AdditiveBlending,
                uniforms: {
                    uSize: { value: 3 * this.renderer.getPixelRatio() },
                    uTime: { value: 0 },
                    uOpacity: { value: 1 }
                },
                vertexColors: true,
                fragmentShader: starFragmentShader,
                vertexShader: starVertexShader
            });

            const stars = new THREE.Points(starGeometry, starShaderMaterial);

            return stars;
        },
        generateLines() {
            const starsPosition = aboutStars.flat();

            if (starsPosition.length == 0) return;

            const _connectedLines = aboutLines;

            const lines = [];
            const lineMaterial = new THREE.LineBasicMaterial({ opacity: 0.3, transparent: true });
            _connectedLines.forEach(connectedStars => {
                const lineGeometry = new THREE.BufferGeometry();

                let pairs = [];

                connectedStars.forEach(pair => {
                    pairs.push(new THREE.Vector3(...pair[0]));
                    pairs.push(new THREE.Vector3(...pair[1]));
                });
                lineGeometry.setFromPoints(pairs);
                lines.push(new THREE.Line(lineGeometry, lineMaterial));
            });

            return lines;
        },
        //======= END UPLOAD GLTF =======//

        addCircles() {
            this.models.lines.forEach((element, index) => {
                // to do: reuse the material and geomtry instead of doing it twice
                let points = new THREE.Path().absarc(0, 0, element.radius, 0, Math.PI * 2).getPoints(90);
                let geometryCircle = new THREE.BufferGeometry().setFromPoints(points);
                let materialCircle = new THREE.LineBasicMaterial({ color: 0xa9a0bb, transparent: true, opacity: 0.5 });
                this.models.lines[index].model = new THREE.Line(geometryCircle, materialCircle);

                this.models.lines[index].model.position.y = -10;
                this.models.lines[index].model.rotation.x = -Math.PI * 0.5;
                this.scene.add(this.models.lines[index].model);
            });
        },

        ////////////////////////////////
        //       START ANIMATION ON LEAVE
        ////////////////////////////////
        initOnLeaveTimeline() {
            this.timelines.onLeaveTimeline = gsap
                .timeline({
                    paused: true
                })
                .to(
                    this.aboutGLTF.scene.position,
                    {
                        y: -2,
                        duration: 2,
                        ease: "power4.out"
                    },
                    "leave"
                )

                .to(
                    this.aboutGLTF.scene.rotation,
                    {
                        y: this.aboutGLTF.scene.rotation.y + Math.PI * 0.1,
                        duration: 2,
                        ease: "power4.out"
                    },
                    "leave"
                )
                .to(
                    ".about-scene__container",
                    {
                        opacity: 0,
                        ease: "none",
                        duration: 0.5
                    },
                    "leave"
                )
                .to(
                    this.models.lines[0].model.position,
                    {
                        y: -10,
                        duration: 3,
                        ease: "power4.out"
                    },
                    "leave+=0.5"
                )
                .to(
                    this.models.lines[1].model.position,
                    {
                        y: -10,
                        duration: 3,
                        ease: "power4.out"
                    },
                    "leave+=0.75"
                );
        },
        leaveAboutPage() {
            this.timelines.onLeaveTimeline.play();
        },
        ////////////////////////////////
        //       END ANIMATION ON LEAVE
        ////////////////////////////////
        ////////////////////////////////
        //       START DESTROY TIMELINES
        ////////////////////////////////
        destroyTimelines() {
            this.timelineKiller("revealGLTFTimeline");
            this.timelineKiller("onLeaveTimeline");
        },

        timelineKiller(name) {
            this.timelines[name] ? (this.timelines[name].kill(), (this.timelines[name] = null)) : null;
        }
        ////////////////////////////////
        //       END DESTROY TIMELINES
        ////////////////////////////////
    }
};
</script>

<style lang="scss" scoped>
.about-scene {
    @include transition(1s linear opacity);
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 1;
    position: relative;
    &--mobile {
        pointer-events: none;
    }
    &--visible {
        opacity: 1;
    }

    &__container {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
}
</style>
