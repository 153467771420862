<template>
    <div class="c-about-content" v-if="paragraphs && paragraphs.length">
        <div class="c-about-content__gap" />
        <about-content-list :paragraphs="paragraphs" />
    </div>
</template>

<script>
import AboutContentList from "@/templates/components/About/AboutContent/AboutContentList/AboutContentList";

export default {
    components: {
        AboutContentList
    },
    props: {
        paragraphs: {
            type: Array,
            required: false,
            default: () => []
        }
    }
};
</script>

<style lang="scss">
.c-about-content {
    display: flex;
    flex-direction: column;
    &__gap {
        // easier to manage than a padding
        display: flex;
        height: calc(80vh - 12rem);
        max-height: 1000px;
    }
}
</style>
