<template>
    <div class="c-shared-map-icon">
        <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                class="assets-nbhd-map-svg-dots"
                d="M2.51957 19.13C3.04976 19.13 3.47957 18.7002 3.47957 18.17C3.47957 17.6398 3.04976 17.21 2.51957 17.21C1.98938 17.21 1.55957 17.6398 1.55957 18.17C1.55957 18.7002 1.98938 19.13 2.51957 19.13Z"
                fill="#FCEFEF"
            />
            <path
                class="assets-nbhd-map-svg-dots"
                d="M40.3702 6.21999C40.9003 6.21999 41.3302 5.79018 41.3302 5.25999C41.3302 4.72979 40.9003 4.29999 40.3702 4.29999C39.84 4.29999 39.4102 4.72979 39.4102 5.25999C39.4102 5.79018 39.84 6.21999 40.3702 6.21999Z"
                fill="#FCEFEF"
            />
            <path
                class="assets-nbhd-map-svg-dots"
                d="M58.1299 31.48C58.6601 31.48 59.0899 31.0502 59.0899 30.52C59.0899 29.9898 58.6601 29.56 58.1299 29.56C57.5997 29.56 57.1699 29.9898 57.1699 30.52C57.1699 31.0502 57.5997 31.48 58.1299 31.48Z"
                fill="#FCEFEF"
            />
            <path
                class="assets-nbhd-map-svg-dots"
                d="M12.6202 31.79C13.1504 31.79 13.5802 31.3602 13.5802 30.83C13.5802 30.2998 13.1504 29.87 12.6202 29.87C12.09 29.87 11.6602 30.2998 11.6602 30.83C11.6602 31.3602 12.09 31.79 12.6202 31.79Z"
                fill="#FCEFEF"
            />
            <path
                class="assets-nbhd-map-svg-dots"
                d="M30.96 13.5C31.4902 13.5 31.92 13.0702 31.92 12.54C31.92 12.0098 31.4902 11.58 30.96 11.58C30.4298 11.58 30 12.0098 30 12.54C30 13.0702 30.4298 13.5 30.96 13.5Z"
                fill="#FCEFEF"
            />
            <path
                class="assets-nbhd-map-svg-dots"
                d="M56.21 21.11C56.7402 21.11 57.17 20.6802 57.17 20.15C57.17 19.6198 56.7402 19.19 56.21 19.19C55.6798 19.19 55.25 19.6198 55.25 20.15C55.25 20.6802 55.6798 21.11 56.21 21.11Z"
                fill="#FCEFEF"
            />
            <path
                class="assets-nbhd-map-svg-dots"
                d="M43.1797 19.23C43.7099 19.23 44.1397 18.8002 44.1397 18.27C44.1397 17.7398 43.7099 17.31 43.1797 17.31C42.6495 17.31 42.2197 17.7398 42.2197 18.27C42.2197 18.8002 42.6495 19.23 43.1797 19.23Z"
                fill="#FCEFEF"
            />
            <path
                class="assets-nbhd-map-svg-dots"
                d="M23.5196 40.07C24.0498 40.07 24.4796 39.6402 24.4796 39.11C24.4796 38.5798 24.0498 38.15 23.5196 38.15C22.9894 38.15 22.5596 38.5798 22.5596 39.11C22.5596 39.6402 22.9894 40.07 23.5196 40.07Z"
                fill="#FCEFEF"
            />
            <path
                class="assets-nbhd-map-svg-dots"
                d="M20.1602 19.13C20.6904 19.13 21.1202 18.7002 21.1202 18.17C21.1202 17.6398 20.6904 17.21 20.1602 17.21C19.63 17.21 19.2002 17.6398 19.2002 18.17C19.2002 18.7002 19.63 19.13 20.1602 19.13Z"
                fill="#FCEFEF"
            />
            <path
                class="assets-nbhd-map-svg-dots"
                d="M30.96 49.16C31.4902 49.16 31.92 48.7302 31.92 48.2C31.92 47.6698 31.4902 47.24 30.96 47.24C30.4298 47.24 30 47.6698 30 48.2C30 48.7302 30.4298 49.16 30.96 49.16Z"
                fill="#FCEFEF"
            />
            <path
                class="assets-nbhd-map-svg-dots"
                d="M47.25 39.57C47.7802 39.57 48.21 39.1402 48.21 38.61C48.21 38.0798 47.7802 37.65 47.25 37.65C46.7198 37.65 46.29 38.0798 46.29 38.61C46.29 39.1402 46.7198 39.57 47.25 39.57Z"
                fill="#FCEFEF"
            />
            <path
                class="assets-nbhd-map-svg-dots"
                d="M26.4297 59.21C26.9599 59.21 27.3897 58.7802 27.3897 58.25C27.3897 57.7198 26.9599 57.29 26.4297 57.29C25.8995 57.29 25.4697 57.7198 25.4697 58.25C25.4697 58.7802 25.8995 59.21 26.4297 59.21Z"
                fill="#FCEFEF"
            />
            <path
                class="assets-nbhd-map-svg-dots"
                d="M13.5796 12.43C14.3749 12.43 15.0196 11.7853 15.0196 10.99C15.0196 10.1947 14.3749 9.54999 13.5796 9.54999C12.7844 9.54999 12.1396 10.1947 12.1396 10.99C12.1396 11.7853 12.7844 12.43 13.5796 12.43Z"
                fill="#FCEFEF"
            />
            <path
                class="assets-nbhd-map-svg-dots"
                d="M23.5201 3.66998C24.3154 3.66998 24.9601 3.02527 24.9601 2.22998C24.9601 1.43469 24.3154 0.789978 23.5201 0.789978C22.7248 0.789978 22.0801 1.43469 22.0801 2.22998C22.0801 3.02527 22.7248 3.66998 23.5201 3.66998Z"
                fill="#FCEFEF"
            />
            <path
                class="assets-nbhd-map-svg-dots"
                d="M41.8004 31C42.5956 31 43.2404 30.3553 43.2404 29.56C43.2404 28.7647 42.5956 28.12 41.8004 28.12C41.0051 28.12 40.3604 28.7647 40.3604 29.56C40.3604 30.3553 41.0051 31 41.8004 31Z"
                fill="#FCEFEF"
            />
            <path
                class="assets-nbhd-map-svg-dots"
                d="M33.8404 24.79C34.6357 24.79 35.2804 24.1453 35.2804 23.35C35.2804 22.5547 34.6357 21.91 33.8404 21.91C33.0451 21.91 32.4004 22.5547 32.4004 23.35C32.4004 24.1453 33.0451 24.79 33.8404 24.79Z"
                fill="#FCEFEF"
            />
            <path
                class="assets-nbhd-map-svg-dots"
                d="M50.1304 12.06C50.9257 12.06 51.5704 11.4153 51.5704 10.62C51.5704 9.8247 50.9257 9.17999 50.1304 9.17999C49.3351 9.17999 48.6904 9.8247 48.6904 10.62C48.6904 11.4153 49.3351 12.06 50.1304 12.06Z"
                fill="#FCEFEF"
            />
            <path
                class="assets-nbhd-map-svg-dots"
                d="M41.2603 53.65C42.0556 53.65 42.7003 53.0053 42.7003 52.21C42.7003 51.4147 42.0556 50.77 41.2603 50.77C40.465 50.77 39.8203 51.4147 39.8203 52.21C39.8203 53.0053 40.465 53.65 41.2603 53.65Z"
                fill="#FCEFEF"
            />
            <path
                class="assets-nbhd-map-svg-dots"
                d="M18.7198 48.86C19.25 48.86 19.6798 48.4302 19.6798 47.9C19.6798 47.3698 19.25 46.94 18.7198 46.94C18.1896 46.94 17.7598 47.3698 17.7598 47.9C17.7598 48.4302 18.1896 48.86 18.7198 48.86Z"
                fill="#FCEFEF"
            />
            <path
                class="assets-nbhd-map-svg-dots"
                d="M9.00981 51.6C9.54 51.6 9.9698 51.1702 9.9698 50.64C9.9698 50.1098 9.54 49.68 9.00981 49.68C8.47961 49.68 8.0498 50.1098 8.0498 50.64C8.0498 51.1702 8.47961 51.6 9.00981 51.6Z"
                fill="#FCEFEF"
            />
            <path
                class="assets-nbhd-map-svg-dots"
                d="M2.35016 36.95C3.14545 36.95 3.79016 36.3053 3.79016 35.51C3.79016 34.7147 3.14545 34.07 2.35016 34.07C1.55487 34.07 0.910156 34.7147 0.910156 35.51C0.910156 36.3053 1.55487 36.95 2.35016 36.95Z"
                fill="#FCEFEF"
            />
            <path
                class="assets-nbhd-map-svg-lines"
                opacity="0.25"
                d="M13.5795 11.39L12.6295 30.83L2.51953 18.17"
                stroke="#FCEFEF"
                stroke-width="0.32"
                stroke-miterlimit="10"
            />
            <path
                class="assets-nbhd-map-svg-lines"
                opacity="0.25"
                d="M23.5202 1.72998L20.1602 18.17L23.5202 39.11L34.3202 23.16"
                stroke="#FCEFEF"
                stroke-width="0.32"
                stroke-miterlimit="10"
            />
            <path
                class="assets-nbhd-map-svg-lines"
                opacity="0.25"
                d="M2.51953 36.12L18.7195 47.9L9.00953 50.63"
                stroke="#FCEFEF"
                stroke-width="0.32"
                stroke-miterlimit="10"
            />
            <path
                class="assets-nbhd-map-svg-lines"
                d="M40.37 5.27002L30.96 12.54L43.18 18.27L50.13 10.62"
                stroke="#FCEFEF"
                stroke-width="0.6"
                stroke-miterlimit="10"
            />
            <path
                class="assets-nbhd-map-svg-lines"
                opacity="0.25"
                d="M56.21 20.15L41.35 29.56L30.96 48.2"
                stroke="#FCEFEF"
                stroke-width="0.32"
                stroke-miterlimit="10"
            />
            <path
                class="assets-nbhd-map-svg-lines"
                opacity="0.25"
                d="M57.6997 30.52L47.2497 38.61L41.2597 52.21L26.4297 58.25"
                stroke="#FCEFEF"
                stroke-width="0.32"
                stroke-miterlimit="10"
            />
        </svg>
    </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.c-shared-map-icon {
    @include nbhdSvgMap;
}
</style>
