<template>
    <div class="c-neighbourhood-nav-btn">
        <!-- button -->
        <button
            class="c-neighbourhood-nav-btn__button"
            :class="{ 'c-neighbourhood-nav-btn__button--focus': isFocus }"
            :aria-label="$t('navigation.neigbourhood.map.ariaLabel')"
            @click="redirectFromStory"
            @focusin="isUserFocusing(true)"
            @focusout="isUserFocusing(false)"
        >
            <!-- svg -->
            <neighbourhood-nav-btn-map />
            <!-- copy below -->
            <neighbourhood-nav-btn-map-copy />
        </button>
    </div>
</template>

<script>
import { mapState } from "vuex";

import gsap from "gsap";

import NeighbourhoodNavBtnMap from "@/templates/components/Neighbourhood/NeighbourhoodNav/NeighbourhoodNavBtnMap/NeighbourhoodNavBtnMap";
import NeighbourhoodNavBtnMapCopy from "@/templates/components/Neighbourhood/NeighbourhoodNav/NeighbourhoodNavBtnMap/NeighbourhoodNavBtnMapCopy";
export default {
    components: {
        NeighbourhoodNavBtnMap,
        NeighbourhoodNavBtnMapCopy
    },
    computed: {
        ...mapState({
            currentEntry: state => state.global.currentEntry
        }),
        isStory() {
            return this.currentEntry.type && this.currentEntry.type !== "district";
        }
    },
    data() {
        return {
            timelines: {
                onLeaveFromStory: null
            },
            isFocus: false
        };
    },
    methods: {
        isUserFocusing(bool) {
            this.isFocus = bool;
        },
        redirectFromStory() {
            // if a story is open so first close while closing the page
            this.isStory ? this.closeStoryThenRedirect() : this.redirectWithDelayAnimation();
        },
        closeStoryThenRedirect() {
            this.playOnLeaveAnimation();
        },
        ////////////////////////////////
        //       START GO TO HOME FROM A STORY
        ////////////////////////////////
        playOnLeaveAnimation() {
            const durationAnimation = 1.8;
            const easeAnimation = "expo.out";
            this.timelines.onLeaveFromStory = gsap.timeline({
                // paused: true
            });
            this.timelines.onLeaveFromStory
                .add(() => {
                    this.redirectWithDelayAnimation();
                }, `started`)
                .to(
                    ".c-nbhd-story__inner",
                    {
                        y: 0,
                        ease: easeAnimation,
                        duration: durationAnimation
                    },
                    "started"
                )
                .to(
                    ".c-stories-article__inner__main",
                    {
                        scale: 0.9,

                        ease: easeAnimation,
                        duration: durationAnimation
                    },
                    "started"
                )

                .to(
                    ".c-nbhd-story-more-credit__inner",
                    {
                        scale: 0.9,
                        y: -50,
                        ease: easeAnimation,
                        duration: durationAnimation
                    },
                    "started"
                )
                .to(
                    ".c-nbhd-story-more-return__inner",
                    {
                        scale: 0.9,
                        y: -100,
                        ease: easeAnimation,
                        duration: durationAnimation
                    },
                    "started"
                )
                .to(
                    ".c-nbhd-story",
                    {
                        opacity: 0,
                        ease: easeAnimation,
                        duration: durationAnimation - 0.1
                    },
                    "started+=0.1"
                )
                .to(
                    ".l-header__inner",
                    {
                        "--opacity-header-background": 0,
                        ease: "none",
                        duration: 0
                    },
                    "started+=0.1"
                )

                .add(() => {
                    this.forceBackgroundTobeDarkOnLeave();
                }, "started+=0.1");

            this.isClassExist("c-shared-list__inner")
                ? this.addSharedListToTimeline(easeAnimation, durationAnimation)
                : null;
        },

        isClassExist(nameOfTheClass) {
            // true/false is easier to read even on a ternary, please don't judge too fast :D
            return document.getElementsByClassName(nameOfTheClass).length ? true : false;
        },
        addSharedListToTimeline(easeAnimation, durationAnimation) {
            this.timelines.onLeaveFromStory.to(
                ".c-shared-list__inner",
                {
                    scale: 0.9,
                    y: -100,
                    ease: easeAnimation,
                    duration: durationAnimation
                },
                "started"
            );
        },
        // playAnimationOnLeave() {
        //     this.timelines.onLeaveFromStory.play();
        // },
        ////////////////////////////////
        //       END GO TO HOME FROM A STORY
        ////////////////////////////////
        redirectWithDelayAnimation() {
            this.$store.commit("global/updatePageTransitionOutUrl", "/");
        },

        forceBackgroundTobeDarkOnLeave() {
            // https://mambomambo-team.atlassian.net/browse/CN-365
            this.$store.commit("constellations/overwriteStoryLightMode", true);
        },

        ////////////////////////////////
        //       START ON DESTROY
        ////////////////////////////////
        destroyTimelines() {
            this.timelineKiller("onLeaveFromStory");
        },
        timelineKiller(name) {
            this.timelines[name] ? (this.timelines[name].kill(), (this.timelines[name] = null)) : null;
        }

        ////////////////////////////////
        //       END ON DESTROY
        ////////////////////////////////
    }
};
</script>

<style lang="scss" scoped>
.c-neighbourhood-nav-btn {
    &__button {
        /* @include transform(translate(-50%, 0%)); */ // actually is the title not centered?
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
}
</style>
