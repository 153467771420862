<template>
    <ul class="c-enter-tips-audio">
        <!-- <ul class="c-enter-tips-audio__list"> -->
        <enter-tips-audio-with v-if="!isErrorPage" />
        <enter-tips-audio-with-out :is-error-page="isErrorPage" @clicked="clicked" />
        <!-- </ul> -->
    </ul>
</template>

<script>
import EnterTipsAudioWith from "@/templates/components/Enter/EnterTips/EnterTipsAudio/EnterTipsAudioWith";
import EnterTipsAudioWithOut from "@/templates/components/Enter/EnterTips/EnterTipsAudio/EnterTipsAudioWithOut/EnterTipsAudioWithOut";
export default {
    components: {
        EnterTipsAudioWith,
        EnterTipsAudioWithOut
    },
    props: {
        isErrorPage: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    methods: {
        clicked(bool) {
            this.$emit("clicked", bool);
        }
    }
};
</script>

<style lang="scss" scoped>
.c-enter-tips-audio {
    @include reset-list;
    --padding-bottom: var(--grid-gutter-half);
    display: grid;
    gap: var(--grid-gutter);

    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(2, 1fr);
    padding-bottom: var(--padding-bottom);

    @media #{md('sm')} {
        gap: var(--grid-gutter-half);
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(1, 1fr);
        --padding-bottom: var(--grid-gutter);
    }
}
</style>
