<template>
    <div :class="className" :style="`--max-height-screen: ${maxHeightBody}px`">
        <loader />

        <app-header @toggleNav="toggleNav" />

        <main class="l-main" ref="main">
            <transition @beforeLeave="beforeLeave" @enter="enter" name="fade">
                <router-view :entry="currentEntry" />
            </transition>
        </main>

        <global-audio />
    </div>
</template>

<script>
import AppHeader from "layout/AppHeader";
import GlobalAudio from "@/templates/objects/GlobalAudio/GlobalAudio";

import Loader from "objects/Loader";

import { mapState } from "vuex";
import { toKebab } from "src/utils";

export default {
    name: "AppBase",
    components: {
        AppHeader,
        GlobalAudio,
        // AppFooter,
        Loader
    },

    data() {
        return {
            navIsOpen: false,
            maxHeightBody: 0
        };
    },
    mounted() {
        window.addEventListener("resize", this.onResize);
        this.maxHeightArticle();
    },

    beforeDestroy() {
        window.removeEventListener("resize", this.onResize);
    },
    computed: {
        ...mapState({
            currentEntry: state => state.global.currentEntry,
            currentEntryName: state => state.global.currentEntryName,
            isNewVisit: state => state.home.isNewVisit,
            isNavOpen: state => state.global.isNavOpen
        }),
        className() {
            let classname = "l-wrapper";

            if (this.currentEntryName) {
                classname += ` p-${toKebab(this.currentEntryName)}`;

                // Remove previous page className
                document.body.className = "";
                document.body.classList.add(`p-${toKebab(this.currentEntryName)}`);
            }

            // Add nav state
            if (this.navIsOpen) {
                classname += " nav-is-open";
            }

            return classname;
        }
    },
    methods: {
        toggleNav(isOpen) {
            this.navIsOpen = isOpen;
        },
        beforeLeave() {
            this.disableAnimationEnter();
        },
        enter() {
            // console.log("enter");
            this.$store.commit("global/updatePageTransitionOutUrl", null);
            this.$store.commit("constellations/userGoToOtherStory", null);
        },
        disableAnimationEnter() {
            this.isNewVisit ? this.$store.commit("home/updateUserVisit", false) : null; // disable animation enter if user returning to home page
        },

        onResize() {
            this.maxHeightArticle();
            this.isNavOpen && window.innerWidth > 480 ? this.toggleBackNav() : null;
        },

        maxHeightArticle() {
            this.maxHeightBody = window.innerHeight;
        },
        toggleBackNav() {
            this.$store.commit("global/toggleNavigation");
            this.$store.commit("global/toggleNavigationBackground", false);
        }
    }
};
</script>
