<template>
    <li
        class="c-stories-article-illustrated-main-img-asset"
        :class="{
            'c-stories-article-illustrated-main-img-asset--visible': isVisible,
            'c-stories-article-illustrated-main-img-asset--first': indexImg === 0
        }"
        :style="`--z-index-asset: ${indexImg + 1}`"
    >
        <asset :asset="image" />
    </li>
</template>

<script>
import Asset from "@/templates/objects/Asset";
export default {
    components: { Asset },
    props: {
        image: {
            type: Array,
            required: false,
            default: () => []
        },
        indexImg: {
            type: Number,
            required: false,
            default: 0
        },
        isVisible: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {}
};
</script>

<style lang="scss" scoped>
.c-stories-article-illustrated-main-img-asset {
    @include fullWidthFixed(absolute);
    @include transition(1s linear all);
    --opacity-asset: 0;

    opacity: var(--opacity-asset);
    z-index: var(--z-index-asset);
    z-index: var(--z-index-asset);
    &--first {
        // for the corner to be place always arround the image instead
        position: relative;
    }
    &--visible {
        --opacity-asset: 1;
    }
}
</style>
