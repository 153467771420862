<template>
    <li class="c-shared-list-item-btn" :class="{ 'c-shared-list-item-btn--disabled': isButtonDisabled }">
        <btn
            :label="convertToRoman(storyIndex + 1)"
            :circle="true"
            typo="serif"
            :is-parent-handle-states="true"
            :is-active="isActive"
            :is-filled-by-default="false"
            :disabled="isButtonDisabled"
        />
    </li>
</template>

<script>
import Btn from "@/templates/components/Shared/SharedBtn/SharedBtn";
import convertorMixin from "@/mixins/convertorMixin.js";
export default {
    mixins: [convertorMixin],
    components: {
        Btn
    },
    props: {
        storyIndex: {
            type: Number,
            required: false,
            default: 0
        },
        // for the line only
        itemIndex: {
            type: Number,
            required: false,
            default: 0
        },
        isActive: {
            type: Boolean,
            required: false,
            default: false
        },
        // fixing a bug in the stories page
        isButtonDisabled: {
            type: Boolean,
            required: false,
            default: false
        }
    }
};
</script>

<style lang="scss" scoped>
.c-shared-list-item-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    &--disabled {
        button {
            cursor: auto;
        }
    }
}
</style>
