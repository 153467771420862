<template>
    <li class="c-credits-list-item-copy" :class="`c-credits-list-item-copy--${itemIndex}`">
        <div v-html="copy" />
    </li>
</template>

<script>
export default {
    props: {
        copy: {
            type: String,
            required: false,
            default: null
        },
        itemIndex: {
            type: Number,
            required: false,
            default: 0
        }
    }
};
</script>
<style lang="scss" scoped>
.c-credits-list-item-copy {
    text-align: center;
    &::v-deep p {
        @include transform(translateY(50px));
        opacity: 0;
    }
}
</style>
