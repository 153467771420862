<template>
    <div class="c-shared-animated-icon-mute">
        <!-- svg -->
        <!-- circle -->

        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0.25 3.33545H2.31431V4.33545H1.25V7.41664H4.42232V7.61649L7.88803 9.42509V8.89834H8.88803V11.0749L3.79412 8.41664H0.25V3.33545Z"
                fill="#A9A0BB"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.88797 0.68634V5.11138H7.88797V2.33534L6.08765 3.27383L5.6254 2.38708L8.88797 0.68634Z"
                fill="#A9A0BB"
            />
            <clipPath id="icon-mute-line">
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10.7547 9.95546L0.780426 0.869637L1.45384 0.130371L11.4281 9.21619L10.7547 9.95546Z"
                    fill="#A9A0BB"
                />
            </clipPath>
            <path
                clip-path="url(#icon-mute-line)"
                class="c-shared-animated-icon-mute__line"
                fill-rule="evenodd"
                stroke="#A9A0BB"
                stroke-miterlimit="10"
                stroke-width="10"
                clip-rule="evenodd"
                d="M10.7547 9.95546L0.780426 0.869637L1.45384 0.130371L11.4281 9.21619L10.7547 9.95546Z"
                fill="none"
            />
        </svg>
    </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.c-shared-animated-icon-mute {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;

    --animation: paused;

    &__line {
        /* animation from Cassie: https://www.cassie.codes/posts/creating-my-logo-animation/ */
        stroke-dasharray: 40;
        stroke-dashoffset: 40;
        animation: fill 1s ease-in-out forwards;
        animation-play-state: var(--animation);
    }
}

@keyframes fill {
    to {
        stroke-dashoffset: 0;
    }
}
</style>
