import { render, staticRenderFns } from "./NavigationMain.vue?vue&type=template&id=543656f3&scoped=true&"
import script from "./NavigationMain.vue?vue&type=script&lang=js&"
export * from "./NavigationMain.vue?vue&type=script&lang=js&"
import style0 from "./NavigationMain.vue?vue&type=style&index=0&id=543656f3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "543656f3",
  null
  
)

export default component.exports