<template>
    <!-- <div class="c-credits -debug">  -->
    <div class="c-credits">
        <shared-page
            v-if="entry.sequencesCredits"
            :cta-scroll-trigger="`c-shared-list-item--index-${entry.sequencesCredits.length - 1}`"
            :title="entry.heading"
        >
            <credits-list :sequences="entry.sequencesCredits" />
        </shared-page>
    </div>
</template>

<script>
import { gsap } from "gsap/all";
import SharedPage from "@/templates/components/Shared/SharedPage/SharedPage";
import CreditsList from "@/templates/components/Credits/CreditsList/CreditsList";
export default {
    components: {
        SharedPage,
        CreditsList
    },
    props: {
        entry: {
            type: Object,
            required: false,
            default: null
        }
    },
    data() {
        return {
            timelines: {
                introTimeline: null
            }
        };
    },
    mounted() {
        this.$nextTick(() => {
            this.setInterUntilClassIsSet();
        });
    },
    beforeDestroy() {
        this.destroyTimelines();
    },
    methods: {
        ////////////////////////////////
        //       START ONMOUT ANIMATION
        ////////////////////////////////

        //======= START FIND SPECIFIC CLASS BEFORE STARTING =======//

        setInterUntilClassIsSet() {
            const findClassInterval = setInterval(() => {
                this.isClassExist("c-shared-list-item--index-0")
                    ? (this.initTimeline(), clearInterval(findClassInterval))
                    : null;
            }, 100);
        },
        isClassExist(nameOfTheClass) {
            // true/false is easier to read even on a ternary, please don't judge too fast :D
            return document.getElementsByClassName(nameOfTheClass).length ? true : false;
        },

        //======= END FIND SPECIFIC CLASS BEFORE STARTING =======//

        initTimeline() {
            this.timelines.introTimeline = gsap.timeline({ paused: false });
            this.timelines.introTimeline
                .to(".c-stories-title", {
                    y: 0,
                    ease: "expo.inOut",
                    duration: 1
                })
                .to(
                    ".c-shared-list-item--index-0 .c-shared-list-item__btn",
                    {
                        opacity: 1,
                        y: 0,
                        ease: "expo.inOut",
                        duration: 1
                    },
                    "started"
                )

                .to(
                    ".c-credits-list-item-copy--0 p",
                    {
                        y: 0,
                        opacity: 1,
                        stagger: {
                            each: 0.1,
                            from: "start"
                        },

                        overwrite: "all",
                        ease: "expo.inOut",
                        duration: 1
                    },
                    "started+=0.3"
                );
            this.timelines.introTimeline.play();
        },
        ////////////////////////////////
        //       END ONMOUT ANIMATION
        ////////////////////////////////

        ////////////////////////////////
        //       START DESTROY TIMELINES
        ////////////////////////////////
        destroyTimelines() {
            this.timelineKiller("introTimeline");
        },

        timelineKiller(name) {
            this.timelines[name] ? (this.timelines[name].kill(), (this.timelines[name] = null)) : null;
        }
        ////////////////////////////////
        //       END DESTROY TIMELINES
        ////////////////////////////////
    }
};
</script>

<style lang="scss" scoped>
.c-credits {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;

    min-height: 200vh; // debug

    &__inner {
        @include mainContentWidth;
        display: flex;
        flex-direction: column;
        padding-top: 12rem;
    }
}
</style>
