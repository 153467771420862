<template>
    <li class="c-navigation-main-titles-neighbourhood">
        <div class="c-navigation-main-titles-neighbourhood__inner">
            <button
                class="c-navigation-main-titles-neighbourhood__inner__button"
                :class="{
                    'c-navigation-main-titles-neighbourhood__inner__button--focus': isFocus,
                    'c-navigation-main-titles-neighbourhood__inner__button--clickable': isStory
                }"
                :aria-label="$t('navigation.neigbourhood.main.ariaLabel')"
                @click="clicked"
                @focusin="isUserFocus(true)"
                @focusout="isUserFocus(false)"
            >
                <h2 class="c-navigation-main-titles-neighbourhood__inner__button__title">{{ title }}</h2>
            </button>
        </div>
    </li>
</template>

<script>
import { mapState } from "vuex";
export default {
    data() {
        return {
            title: null,
            isFocus: false
        };
    },
    computed: {
        ...mapState({
            currentEntry: state => state.global.currentEntry
        }),
        neigbhourhoodTitle() {
            return this.currentEntry.district && this.currentEntry.district.title
                ? this.currentEntry.district.title
                : this.currentEntry.title;
        },
        neigbhourhoodSlug() {
            return this.currentEntry.district && this.currentEntry.district.slug
                ? this.currentEntry.district.slug
                : this.currentEntry.slug;
        },
        isStory() {
            return this.currentEntry.type && this.currentEntry.type !== "district";
        }
    },
    watch: {
        neigbhourhoodTitle(val) {
            this.titleNeigbourhood(val);
        }
    },
    methods: {
        titleNeigbourhood(title) {
            // avoid flicker bug
            return title ? (this.title = title) : null;
        },
        clicked() {
            this.isStory ? this.$store.commit("constellations/toggleReturnToMapFromNav", true) : null;
        },
        isUserFocus() {}
    }
};
</script>

<style lang="scss" scoped>
.c-navigation-main-titles-neighbourhood {
    --max-width: 100%;

    --margin-inner: var(--grid-gutter-quarter) 0 0 0;
    --padding-inner: 0;

    --alignment: center;
    width: 100%; //debug
    max-width: var(--max-width);
    height: 0px;

    opacity: 0;

    display: flex;

    justify-content: var(--alignment);

    @media #{md("xs")} {
        --alignment: flex-start;
        --max-width: 50%;
        width: 0%;
        height: 100% !important;
    }
    &__inner {
        @include transform(translate(0rem, -2rem));
        display: flex;
        flex-direction: row;
        justify-content: center;

        position: absolute;

        // I was stuck for a while but this min width will allow the fake aligmenment, meaning the dot is always above the circle
        // I noted that in the mockup the dot is not aligned with the circle, in real it looks really off. I will leave as I did now and if they have feedback I will update.
        min-width: 16rem;

        @media #{md("xs")} {
            @include transform(translate(10rem, 0rem));
            --margin-inner: 0 0 0 var(--grid-gutter-half);
            --padding-inner: 0 0 0 var(--grid-gutter-half);

            justify-content: flex-start;
            align-items: center;
            position: relative;
        }

        &:before {
            @media #{md('xs')} {
                content: " ";
                position: absolute;
                width: 0.4rem;
                height: 0.4rem;
                left: 0px;
                background: var(--color-light);
                border-radius: 50%;
                top: calc(50% + 0.2rem);
                left: calc(var(--grid-gutter-half) - 2px);
            }
        }
        &__button {
            --intensity-shadow: 0rem;
            @include remove-button-style;
            @include shadowTitlesHover;

            &--clickable {
                cursor: pointer;
                &:hover {
                    --intensity-shadow: 1rem;
                }
            }
            &__title {
                margin: var(--margin-inner);
                padding: var(--padding-inner);
                --color-copy: $color-purple-light;
                // @include responsive-type(16, 30, 300, 1440);

                color: var(--color-copy);
                white-space: pre;
                font-kerning: none; // https://mambomambo-team.atlassian.net/browse/CN-420
                font-size: 1.6rem; // https://mambomambo-team.atlassian.net/jira/software/projects/CN/boards/2?selectedIssue=CN-447

                @media #{md('xs')} {
                    @include transform(translateY(0.1rem));
                    @include titles;
                    --color-copy: var(--color-light);
                    font-size: 3rem; // https://mambomambo-team.atlassian.net/jira/software/projects/CN/boards/2?selectedIssue=CN-447
                }
            }
        }
    }
}
</style>
