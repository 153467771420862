<template>
    <div
        class="c-neighbourhood-story-article-audio-player-main-progress-indicator"
        :style="`--progress-left: ${roundNumber(progress)}%`"
    >
        <p class="c-neighbourhood-story-article-audio-player-main-progress-indicator__copy">
            {{ convertToMinutes(durationProgress) }}
        </p>
    </div>
</template>

<script>
import convertorMixin from "@/mixins/convertorMixin.js";
export default {
    mixins: [convertorMixin],
    props: {
        progress: {
            type: Number,
            required: false,
            default: 0
        },
        duration: {
            type: Number,
            required: false,
            default: 0
        }
    },
    computed: {
        durationProgress() {
            return this.duration * (this.progress / 100);
        }
    },
    methods: {
        roundNumber(number) {
            return Math.round(number * 100) / 100;
        }
    }
};
</script>

<style lang="scss" scoped>
.c-neighbourhood-story-article-audio-player-main-progress-indicator {
    --progress-left: 0%;
    --gradient-progress-line: 252, 239, 239;

    @include transition(0.1s ease all);
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    left: var(--progress-left);
    pointer-events: none;
    margin-left: -0.1rem;

    .c-nbhd-story--light & {
        --gradient-progress-line: 28, 12, 46;
    }

    &:before {
        @include transition(0.1s ease all);
        content: " ";
        position: absolute;
        right: 0;
        bottom: 2rem;
        left: 0;
        width: 1px;
        height: 100%;

        background: rgb(var(--gradient-progress-line));
        background: linear-gradient(
            180deg,
            rgba(var(--gradient-progress-line), 0) 50%,
            rgba(var(--gradient-progress-line), 1) 100%
        );
    }
    &__copy {
        left: -50%;
        color: var(--color-light);
    }
}
</style>
