<template>
    <tag
        :is="tag"
        :disabled="isDisabled || !isVisible"
        class="c-shared-explore"
        :class="{
            'c-shared-explore--btn': tag === 'button',
            'c-shared-explore--label': isLabel,
            'c-shared-explore--visible': isVisible,
            'c-shared-explore--small': isSmall,
            'c-shared-explore--focus': isFocus,
            'c-shared-explore--parallax': parallaxPosition,
            'c-shared-explore--disabled': isDisabled
        }"
        :style="`--posX: ${parallaxPositionX}px; --posY: ${parallaxPositionY}px; --backgroundLabel: ${backgroundLabel}`"
        @click="userClicked"
        @mouseover="isMouseOver(true)"
        @mouseleave="isMouseOver(false)"
        @focusin="isUserFocus(true)"
        @focusout="isUserFocus(false)"
    >
        <div class="c-shared-explore__circle">
            <shared-explore-label
                :title="title"
                :copy="copy"
                :isVisible="isExploreVisible"
                :is-label="isLabel"
                :is-small="isSmall"
            />
        </div>
    </tag>
</template>

<script>
import SharedExploreLabel from "@/templates/components/Shared/SharedExplore/SharedExploreLabel";
export default {
    components: {
        SharedExploreLabel
    },
    props: {
        tag: {
            type: String,
            required: false,
            default: "button"
        },
        title: {
            type: String,
            required: false,
            default: null
        },
        copy: {
            type: String,
            required: false,
            default: null
        },
        isVisible: {
            type: Boolean,
            required: false,
            default: false
        },
        isLabel: {
            type: Boolean,
            required: false,
            default: false
        },
        isSmall: {
            type: Boolean,
            required: false,
            default: false
        },
        isDisabled: {
            type: Boolean,
            required: false,
            default: false
        },

        backgroundLabel: {
            type: String,
            required: false,
            default: "0,0,0,0"
        },
        /*------------------------------
        Start Parallax
        ------------------------------*/
        parallaxPosition: {
            type: Object,
            required: false,
            default: null
        }
        /*------------------------------
        End Parallax
        ------------------------------*/
    },
    data() {
        return {
            isExploreVisible: false,
            isFocus: false
        };
    },
    computed: {
        parallaxPositionX() {
            return this.parallaxPosition && this.parallaxPosition.offsetX ? this.parallaxPosition.offsetX : 0;
        },
        parallaxPositionY() {
            return this.parallaxPosition && this.parallaxPosition.offsetY ? this.parallaxPosition.offsetY : 0;
        }
    },

    watch: {
        isVisible() {
            this.toggleIsExploreVisible(this.isVisible);
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.toggleIsExploreVisible(this.isVisible);
        });
    },
    methods: {
        toggleIsExploreVisible(bool) {
            this.isExploreVisible = bool;
        },
        userClicked() {
            this.emitToParent("clicked");
        },
        emitToParent(emitName, val) {
            this.$emit(emitName, val);
        },
        isMouseOver(bool) {
            this.emitToParent("isHover", bool);
        },
        isUserFocus(bool) {
            this.isFocus = bool;
        }
    }
};
</script>

<style lang="scss" scoped>
.c-shared-explore {
    --translate: 0%, 0%;
    --scale: 0;
    --pointer-event-explore: none;
    --opacity: 0;
    --shadow-intensity: 0;
    --opacity-shadow: 1;

    --padding-btn: var(--grid-gutter-half);
    --min-width: 90px;

    --posX: 500px;
    --posY: 1000px;

    --backgroundLabels: 0, 0, 0, 0;

    @include remove-button-style;
    @include aspectRatioFallBack;
    @include transition(0.8s $out-expo all);
    @include transform(translate(var(--posX), var(--posY)));
    position: relative;

    pointer-events: var(--pointer-event-explore);
    transform-origin: top center;

    display: flex;
    justify-content: stretch;
    align-content: stretch;
    align-items: stretch;

    &--label {
        --translate: 0%, -20%;
    }

    &--visible {
        --scale: 1;
        --opacity: 1;
        --pointer-event-explore: auto;
    }
    &--small {
        --padding: 10px;
        --min-width: auto;
    }
    &--btn {
        @include radius(50%);
        &:hover {
            --shadow-intensity: 0.6;
        }
    }
    &--focus {
        --shadow-intensity: 0.6;
    }

    &--disabled {
        --pointer-event-explore: none;
        --opacity-shadow: 0;
    }

    &:after {
        @include circleBtnHoverShadowAnimation(0.5s);
        @include fullWidthFixed(absolute);
        @include radius(50%);
        @include transform(scale(var(--scale)));
        @include box-shadow($intensity: var(--shadow-intensity));

        content: " ";
        aspect-ratio: 1 / 1;

        opacity: var(--opacity-shadow);
    }

    &__circle {
        // --backgroundLabels: 28, 12, 46, 1;
        @include transform(translate(var(--translate)));
        @include transition(0.5s ease all);

        pointer-events: var(--pointer-event-explore);

        aspect-ratio: 1 / 1;
        padding: var(--padding-btn);
        z-index: 1;
        opacity: var(--opacity);

        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;

        cursor: pointer;
        min-width: var(--min-width);

        &:before {
            // the circle in the before allow smoother animation
            @include transition(0.5s ease all);
            @include radius(50%);
            @include fullWidthFixed(absolute);
            @include buttonBorder(1);
            @include transform(scale(var(--scale)));
            content: " ";
            aspect-ratio: 1 / 1;

            background: rgba(var(--backgroundLabel));
        }

        @include aspectRatioFallBack;
    }
}
</style>
