<template>
    <tag
        :is="tag"
        :href="url"
        :to="tag == 'router-link' && url !== null ? url : null"
        :rel="tag == 'a' ? 'noopener' : false"
        class="c-btn"
        :class="className"
        :style="style"
        v-touch:tap="userClickedOrTouch"
        @keyup.enter="userHitEnter"
        @mouseover="isMouseOver(true)"
        @mousemove="userMouseMove"
        @mouseleave="userMouseLeave"
        @focusin="isUserFocus(true)"
        @focusout="isUserFocus(false)"
    >
        <icon v-if="iconBefore !== null" class="c-btn__icon" :icon="iconBefore" />
        <span
            class="c-btn__label"
            :class="{ 'c-btn__label--copy': !iconLabel, 'c-btn__label--unselectable': !isLabelSelectable }"
        >
            <icon v-if="iconLabel" class="c-btn__label__icon" :icon="iconLabel" />
            <slot v-else>{{ label }}</slot>
        </span>
        <icon v-if="iconAfter !== null" class="c-btn__icon" :icon="iconAfter" />
        <shared-animated-icon-arrow v-if="iconAnimated" class="c-btn__icon" />
    </tag>
</template>

<script>
import { gsap } from "gsap";

import _ from "lodash";

import Icon from "@/templates/objects/Icon";
import SharedAnimatedIconArrow from "@/templates/components/Shared/SharedAnimatedIcons/SharedAnimatedIconArrow";

export default {
    name: "Btn",
    components: {
        Icon,
        SharedAnimatedIconArrow
    },
    props: {
        //======= START BASE =======//

        tag: {
            type: String,
            required: false,
            default: "button"
        },
        url: {
            type: String,
            required: false,
            default: null
        },

        isTouchEnabled: {
            type: Boolean,
            required: false,
            default: false
        },
        //  https://mambomambo-team.atlassian.net/browse/CN-229. Avoid mouseover to send wrong info when the cursor is above the label
        isLabelSelectable: {
            type: Boolean,
            required: false,
            default: true
        },

        //======= END BASE =======//

        //======= START LABEL AND COPY =======//

        typo: {
            type: String,
            required: false,
            default: "regular",
            validator: font => ["regular", "serif"].includes(font)
        },

        label: {
            type: String | Number,
            required: false,
            default: "Button Label"
        },

        //======= END LABEL AND COPY =======//

        //======= START SHAPE  & COLOUR =======//

        size: {
            type: String,
            required: false,
            default: "small",
            validator: font => ["small", "medium", "large"].includes(font)
        },

        rounded: {
            type: Boolean,
            required: false,
            default: false
        },
        circle: {
            type: Boolean,
            required: false,
            default: false
        },
        isFilledByDefault: {
            type: Boolean,
            required: false,
            default: true
        },

        //======= END SHAPE & COLOUR  =======//

        //======= START ICONS =======//

        iconBefore: {
            type: String,
            required: false,
            default: null
        },
        iconAfter: {
            type: String,
            required: false,
            default: null
        },
        iconAnimated: {
            type: String,
            required: false,
            default: null
        },
        iconLabel: {
            type: String,
            required: false,
            default: null
        },

        //======= END ICONS =======//

        //======= START ACTIVE STATES =======//

        isActive: {
            type: Boolean,
            required: false,
            default: false
        },
        isParentHandleStates: {
            type: Boolean,
            required: false,
            default: false
        },
        isVisible: {
            type: Boolean,
            required: false,
            default: false
        },
        isMousePositionNeeded: {
            type: Boolean,
            required: false,
            default: false
        }

        //======= END ACTIVE STATES =======//
    },
    data() {
        return {
            active: false,
            animationTimeline: null
        };
    },

    watch: {
        isVisible(bool) {
            this.iconAnimated ? this.timelineManager(bool) : null;
        }
    },

    computed: {
        className() {
            let classes = "";

            // if ((this.active || (this.isActive && this.isParentHandleStates)) && (this.rounded || this.circle)) {
            if (this.active || (this.isActive && this.isParentHandleStates)) {
                // Ad active class only if it's rounded or circle
                classes += " -active";
            }
            if (this.circle) {
                classes += " -circle";
            }
            if (this.size) {
                classes += ` -${this.size}`;
            }
            if (this.rounded) {
                classes += " -rounded";
            }
            if (this.typo == "serif") {
                classes += " -serif";
            }
            if (this.iconAnimated) {
                classes += " -animated";
            }
            if (this.isVisible && !this.iconAnimated) {
                classes += " -visible"; // if there is no animation
            }
            if (this.isFilledByDefault) {
                classes += " -filled"; // if there is no animation
            }

            return classes;
        },
        style() {
            let style;
            switch (this.size) {
                case "medium": {
                    style = `--size: 8rem;`;
                    break;
                }
                case "large": {
                    style = ` --size: 17rem;`;
                    break;
                }
                default:
                    style = ` --size: 4rem;`;
            }

            return style;
        }
    },
    mounted() {
        this.iconAnimated ? this.setAnimationTimeline() : null;
    },
    beforeDestroy() {
        this.animationTimeline ? this.destroyTimeline() : null;
    },
    methods: {
        ////////////////////////////////
        //       START ANIMATION
        ////////////////////////////////

        //======= START SETUP THE TIMELINE =======//

        setAnimationTimeline() {
            this.animationTimeline = gsap.timeline({});
            const durationAnimation = 1;

            // grow the icon line
            this.animationTimeline.to(".c-shared-animated-icon-arrow", {
                "--width-line": "100%",
                opacity: 1,

                duration: durationAnimation,
                ease: "power3.inOut"
            });

            // scale vertical the icon arrow
            this.animationTimeline.to(
                ".o-icon svg",
                {
                    scaleY: 1,
                    scaleX: 1,

                    duration: durationAnimation,
                    ease: "power3.inOut"
                },
                `-=${durationAnimation}`
            );

            // fade in copy appears from right to left
            this.animationTimeline.to(
                ".-animated .c-btn__label",
                {
                    x: 0,
                    opacity: 1,

                    duration: durationAnimation,
                    ease: "power3.inOut"
                },
                `-=${durationAnimation}`
            );

            // make shadow wider
            this.animationTimeline.to(
                ".c-btn",
                {
                    // opacity: 1,
                    "--left": "0%",
                    "--width": "100%",
                    "--opacity": 1,
                    duration: durationAnimation,
                    ease: "power3.inOut"
                },
                `-=${durationAnimation}`
            );
            this.animationTimeline.pause();
        },

        //======= END SETUP THE TIMELINE =======//

        //======= START PLAY THE TIMELINES =======//
        timelineManager(bool) {
            bool ? this.playTimeline() : this.reverseTimeline();
        },
        playTimeline() {
            this.animationTimeline.play();
        },
        reverseTimeline() {
            this.animationTimeline.reverse();
        },

        //======= END PLAY THE TIMELINES =======//

        //======= START DESTROY TIMELINE =======//

        destroyTimeline() {
            this.animationTimeline.kill();
            this.animationTimeline = null;
        },

        //======= END DESTROY TIMELINE =======//

        ////////////////////////////////
        //       END ANIMATION
        ////////////////////////////////
        ////////////////////////////////
        //       START USER INTERACTION
        ////////////////////////////////

        // userClicked(e){

        // },
        userHitEnter() {
            // enable user user keyboard to navigate
            // this.isTouchEnabled ? this.emitToParent("touched", this.url) : null;
            // for now only to focus to open
            this.emitToParent("clicked", this.url);
            // parents can handles states
            this.isParentHandleStates ? null : this.setActive();
        },
        userClickedOrTouch(e) {
            // emit a click to the parent
            this.isUserTouch(e) ? this.emitToParent("touched", this.url) : this.emitToParent("clicked", this.url);
            // parents can handles states
            this.isParentHandleStates ? null : this.setActive();
        },
        isUserTouch(e) {
            // true false is just for code readibility
            return e.type === "touchend" && this.isTouchEnabled ? true : false;
        },
        emitToParent(emitName, val) {
            this.$emit(emitName, val);
        },
        setActive() {
            this.active = !this.active;
        },
        userMouseLeave() {
            // send to parent that user don't hover anymore
            this.isMouseOver(false);
            //
            const resetOffset = {
                offsetX: 0,
                offsetY: 0
            };
            this.userMouseMove(resetOffset);
        },

        isMouseOver(bool) {
            this.isParentHandleStates ? this.emitToParent("isHover", bool) : null;
        },
        isUserFocus(bool) {
            // this.isParentHandleStates ? this.emitToParent("isHover", bool) :
            this.active = bool;
        },

        //======= START USER OVER THE BUTTON =======//

        userMouseMove(value) {
            this.isMousePositionNeeded ? this.debouncedParallax(value.offsetX, value.offsetY) : null;
        },

        debouncedParallax: _.throttle(
            function(offsetX, offsetY) {
                this.emitMousePosition(offsetX, offsetY);
            },
            100,
            { trailing: false }
        ),

        emitMousePosition(offsetX, offsetY) {
            this.$emit("mousePosition", {
                offsetX,
                offsetY
            });
        }

        //======= END USER OVER THE BUTTON =======//

        ////////////////////////////////
        //       END USER INTERACTION
        ////////////////////////////////
    }
};
</script>

<style lang="scss">
.c-btn {
    --shadow-intensity: 0.2;
    // --btn-padding-x: 2em;
    --btn-padding-x: 2em;
    --btn-padding-y: 1.5em;
    --radius: 0;
    // --btn-color: var(--color-dark);

    display: inline-flex;
    align-items: center;
    padding: var(--btn-padding-y) var(--btn-padding-x);
    border-radius: var(--radius);
    cursor: pointer;
    color: var(--btn-color, inherit);

    transition: box-shadow 0.8s $out-expo, color 0.4s $in-expo, background 0.4s $in-out-expo;
    position: relative;
    &.-active {
        --shadow-intensity: 0.4;
    }
    &:focus,
    &:hover {
        --shadow-intensity: 0.6;
    }

    &.-filled {
        background: var(--color-dark);
    }

    &.-rounded {
        --radius: 10rem;
        --width: 6rem;
        --left: 50%;
        --opacity: 0;
        padding: var(--btn-padding-y) var(--btn-padding-x);

        /* because of the animation with the shadow scale. Rather that creating a messy code for the animation, this solution is better */
        // read more: https://greensock.com/forums/topic/25283-how-to-fix-the-animation-of-pseudo-elements-and-to-optimize-the-performance-of-the-code/
        --scale-before: 0.5;
        &:before {
            @include transition(all 0.8s $out-expo);
            @include box-shadow($intensity: var(--shadow-intensity));
            content: " ";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: var(--left);
            opacity: var(--opacity);
            border-radius: var(--radius);
            width: var(--width);
        }
    }

    &.-circle {
        --btn-padding-x: 0;
        --btn-padding-y: 0;
        --radius: 50%;
        --btn-color: var(--color-purple-light);
        width: var(--size);
        height: var(--size);
        @include buttonBorder;
        font-size: fs("medium");
        display: grid;
        place-content: center;
        letter-spacing: 2px;
        /* border: 1px solid rgba($color-purple-light, 0.25); */

        height: 4rem;

        &:before {
            @include box-shadow($intensity: var(--shadow-intensity), $color: $color-light);
            @include transition(all 0.8s $out-expo);
            @include transform(scale(0));

            content: " ";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: var(--color-light);

            border-radius: var(--radius);

            .c-nbhd-story--light & {
                @include box-shadow($intensity: var(--shadow-intensity), $color: $color-purple-dark);
            }
        }

        .c-btn__label {
            mix-blend-mode: exclusion; // The transition is better and the difference with the real color is super minimal
        }
        &.-active,
        &:focus,
        &:hover {
            /* color: var(--color-dark); */ // keep it just in case we remove the blend mode
            // background: var(--color-dark); // overwrite
            &:before {
                @include transform(scale(1));
            }
        }
    }

    &.-active {
        // background: var(--color-light);
        /* color: var(--color-dark); */ // keep it just in case we remove the blend mode
    }

    &.-serif {
        font-family: ff("heading");
    }

    &.-large {
        font-size: fs("large");
        line-height: 1.1;
    }
    &.animated {
        width: 0%;
    }
}

.c-btn__label {
    --pointer-event-label: auto;
    display: flex;
    justify-content: center;

    pointer-events: var(--pointer-event-label);
    & + .c-btn__icon {
        margin-left: 2em;
    }

    &--unselectable {
        --pointer-event-label: none;
    }

    &--copy {
        @include transform(translateX(2px)); // Because its not well centered
        white-space: nowrap;
        .c-navigation-list__link & {
            @media #{md("xs")} {
                @include transform(translateY(-5rem));
            }
        }
    }

    .-animated & {
        @include transform(translate(50px, 0px));
        opacity: 0;
    }

    &__icon {
        display: flex;
        max-width: 1.6rem;
        svg {
            width: 100%;
            max-width: 1.6rem;
            fill: var(--color-purple-light);
        }
    }
}

.c-btn__icon {
    & + .c-btn__label {
        margin-left: 2em;
    }
}

.c-btn__group {
    --btns-gap: 1.6em;
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    gap: var(--btns-gap);

    &.-vertical {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: var(--btns-gap);

        > * {
            width: fit-content;
        }
    }
}

.c-navigation-list__link {
    @include transition(0.3s ease all);
    --height-line-anchor: 0.2rem;
    --translate-pos-line: -0.2rem;
    --opacity-line: 0;
    position: relative;

    &:after {
        @include transition(0.3s ease all);
        @include transform(translateY(var(--translate-pos-line)));
        opacity: var(--opacity-line);
        content: " ";
        position: absolute;
        width: 100%;
        height: var(--height-line-anchor);
        right: 0px;
        left: 0px;
        background: var(--color-light);
        bottom: calc(0px - var(--grid-gutter-half));
    }
    &:hover,
    &.-active {
        --translate-pos-line: 0rem;
        --opacity-line: 1;
        color: var(--color-light);
    }

    .c-navigation-mobile-upper__inner & {
        @include transition(0s ease all); // conflict with gsap
    }
}
</style>
