<template>
    <div class="c-home-navigation-control-buttons">
        <div class="c-home-navigation-control-buttons__inner">
            <ul
                class="c-home-navigation-control-buttons__inner__list"
                :class="{ 'c-home-navigation-control-buttons__inner__list--no-overflow': isActiveSliderVisible }"
            >
                <home-navigation-control-buttons-item
                    v-for="(item, index) in slides"
                    :key="index"
                    :item="item"
                    :item-index="index"
                    :is-active="isActive(index)"
                />
            </ul>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

import HomeNavigationControlButtonsItem from "components/Home/HomeNavigation/HomeNavigationControl/HomeNavigationControlButtons/HomeNavigationControlButtonsItem";
export default {
    components: {
        HomeNavigationControlButtonsItem
    },
    props: {
        slides: {
            type: Array,
            required: true
        }
    },
    computed: {
        ...mapState({
            current: state => state.home.current,
            isActiveSliderVisible: state => state.home.isActiveSliderVisible
        })
    },
    methods: {
        isActive(index) {
            // the slider buttons (I,II,III) are active at the end of the intro animation in the video sent by Guillaume
            return index === this.current && this.isActiveSliderVisible;
        }
    }
};
</script>

<style lang="scss" scoped>
.c-home-navigation-control-buttons {
    display: flex;
    flex: 0;
    justify-content: center;

    &__inner {
        &__list {
            @include reset-list;
            overflow: hidden;
            margin: var(--grid-gutter) var(--grid-gutter) var(--grid-gutter-half) var(--grid-gutter);

            display: grid;
            grid-template-columns: repeat(6, 1fr);
            gap: var(--grid-gutter-half);
            padding-bottom: var(--grid-gutter-half);
            &--no-overflow {
                overflow: visible;
            }
        }
    }
}
</style>
