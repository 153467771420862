<template>
    <div>
        <!-- scene neighbourhood -->
        <neighbourhood
            v-if="neighbourhoodGLTF && neighbourhoodLabels.length"
            :gltf="neighbourhoodGLTF"
            :labels="neighbourhoodLabels"
            :total-stars="totalStars"
        />
    </div>
</template>

<script>
import { SECTION_HANDLES } from "src/constants";

import Neighbourhood from "components/Neighbourhood/Neighbourhood";

//======= START LABEL POC =======//

import saintRochJson from "@/data/neighbourhood/saintRoch.json";
import saintJeanBaptisteJson from "@/data/neighbourhood/saintJeanBaptiste.json";
import limoilouJson from "@/data/neighbourhood/limoilou.json";
import montcalmJson from "@/data/neighbourhood/montcalm.json";
import saintSauveurJson from "@/data/neighbourhood/saintSauveur.json";
import vieuxQuebecJson from "@/data/neighbourhood/vieuxQuebec.json";

//======= END LABEL POC =======//

export default {
    name: "Constellations",
    components: {
        Neighbourhood
    },

    metaInfo() {
        return {
            title: this.entry.seo ? this.entry.seo.title : null
        };
    },
    props: {
        entry: {
            type: Object,
            required: true
        }
    },
    data: () => ({
        SECTION_HANDLES,
        siblings: [],
        neighbourhoodGLTF: null,
        neighbourhoodLabels: [],
        totalStars: 200
    }),
    computed: {
        currentIndex() {
            return this.siblings.findIndex(s => s.id === this.entry.id);
        },
        currentPosition() {
            return this.currentIndex + 1;
        }
    },
    mounted() {
        this.displayHeader();
    },
    watch: {
        entry(obj) {
            obj ? this.whichURIForJSON(obj) : null;
            obj && obj.constellation ? this.getSiblings() : null;
            obj && obj.totalStars ? this.getTotalStars() : null;
        }
    },
    methods: {
        getSiblings() {
            // If you move this method to a 'only Story' component, the condition won't be needed
            if (this.entry.type != "storyText" && this.entry.type != "storyAudio") return;

            this.$store.dispatch("constellations/getSiblingStories", this.entry).then(result => {
                this.siblings = result;
            });
        },
        getTotalStars() {
            const totalStars = parseInt(this.entry.totalStars);

            if (isNaN(totalStars) || totalStars <= 0) return;
            this.totalStars = Math.min(totalStars, 1000); // Do not put more than 1000 stars
        },

        whichURIForJSON(obj) {
            obj.district ? this.getNeigbourhoodLabels(obj.district.uri) : this.getNeigbourhoodLabels(obj.uri);
        },
        whichJsonFile(neighbourhood) {
            switch (neighbourhood) {
                case "montcalm":
                    this.neighbourhoodGLTF = montcalmJson;
                    break;
                case "limoilou":
                    this.neighbourhoodGLTF = limoilouJson;
                    break;
                case "saint-roch":
                    this.neighbourhoodGLTF = saintRochJson;
                    break;

                case "vieux-quebec":
                    this.neighbourhoodGLTF = vieuxQuebecJson;
                    break;
                case "saint-sauveur":
                    this.neighbourhoodGLTF = saintSauveurJson;
                    break;

                case "saint-jean-baptiste":
                    this.neighbourhoodGLTF = saintJeanBaptisteJson;
                    break;

                default:
                    this.neighbourhoodGLTF = saintRochJson;
            }
        },
        getNeigbourhoodLabels(uri) {
            uri ? this.fetchValues(uri) : null;
        },
        fetchValues(uri) {
            this.$store.dispatch("constellations/getNeigbourhoodLabels", uri).then(result => {
                this.neighbourhoodLabels = result;
            });
            this.whichJsonFile(uri);
        },

        ////////////////////////////////
        //       START HEADER ANIMATION MANAGEMENT
        ////////////////////////////////
        displayHeader() {
            this.$store.commit("global/toggleHeaderAnimation", true);
        }
        ////////////////////////////////
        //       END HEADER ANIMATION MANAGEMENT
        ////////////////////////////////
    }
};
</script>

<style lang="scss">
.p-constellations {
}
</style>
