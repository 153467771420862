<template>
    <li class="c-enter-cta-circles-items" :class="`c-enter-cta-circles-items--${indexCircle}`"></li>
</template>

<script>
export default {
    props: {
        indexCircle: {
            type: Number,
            required: true
        }
    }
};
</script>

<style lang="scss" scoped>
.c-enter-cta-circles-items {
    --scale: 0;
    --sizeBorder: calc(1px / var(--scale));
    @include aspectRatioFallBack;
    grid-row: 1/1;
    grid-column: 1/1;

    /* to improve the animation loop, I set the beginning of the animation at the last state */
    &--1 {
        --scale: 1;
    }
    &--2 {
        --scale: 0.25;
    }

    @include radius(50%);
    @include transform(scale(var(--scale)));
    @include customShadow(0px 0px 0px var(--sizeBorder) rgba(169, 160, 187, 0.2));

    position: relative;
    width: 95vw;
    aspect-ratio: 1/1;

    &:after {
        @include radius(50%);
        @include customShadow(0px 0px 0px var(--sizeBorder) rgba(169, 160, 187, 0.2));

        content: " ";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        // not crazy about include at the bottom, but it's an handy trick to make code simplier
        @include transform(scale(0.8));
    }
}
</style>
