<template>
    <li class="c-credits-list-item-img-item">
        <asset :asset="asset" />
    </li>
</template>

<script>
import Asset from "@/templates/objects/Asset";
export default {
    components: {
        Asset
    },
    props: {
        asset: {
            type: Object,
            required: false,
            default: null
        }
    }
};
</script>

<style lang="scss" scoped>
.c-credits-list-item-img-item {
    @include transform(translateY(50px));
    opacity: 0;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
}
</style>
