<template>
    <div class="c-shared-map-icon">
        <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                class="assets-nbhd-map-svg-dots"
                d="M2.51957 19.13C3.04976 19.13 3.47957 18.7002 3.47957 18.17C3.47957 17.6398 3.04976 17.21 2.51957 17.21C1.98938 17.21 1.55957 17.6398 1.55957 18.17C1.55957 18.7002 1.98938 19.13 2.51957 19.13Z"
                fill="#FCEFEF"
            />
            <path
                class="assets-nbhd-map-svg-dots"
                d="M40.3702 6.22005C40.9003 6.22005 41.3302 5.79024 41.3302 5.26005C41.3302 4.72986 40.9003 4.30005 40.3702 4.30005C39.84 4.30005 39.4102 4.72986 39.4102 5.26005C39.4102 5.79024 39.84 6.22005 40.3702 6.22005Z"
                fill="#FCEFEF"
            />
            <path
                class="assets-nbhd-map-svg-dots"
                d="M58.1299 31.4801C58.6601 31.4801 59.0899 31.0503 59.0899 30.5201C59.0899 29.9899 58.6601 29.5601 58.1299 29.5601C57.5997 29.5601 57.1699 29.9899 57.1699 30.5201C57.1699 31.0503 57.5997 31.4801 58.1299 31.4801Z"
                fill="#FCEFEF"
            />
            <path
                class="assets-nbhd-map-svg-dots"
                d="M12.6202 31.79C13.1504 31.79 13.5802 31.3602 13.5802 30.83C13.5802 30.2998 13.1504 29.87 12.6202 29.87C12.09 29.87 11.6602 30.2998 11.6602 30.83C11.6602 31.3602 12.09 31.79 12.6202 31.79Z"
                fill="#FCEFEF"
            />
            <path
                class="assets-nbhd-map-svg-dots"
                d="M30.96 13.5001C31.4902 13.5001 31.92 13.0703 31.92 12.5401C31.92 12.0099 31.4902 11.5801 30.96 11.5801C30.4298 11.5801 30 12.0099 30 12.5401C30 13.0703 30.4298 13.5001 30.96 13.5001Z"
                fill="#FCEFEF"
            />
            <path
                class="assets-nbhd-map-svg-dots"
                d="M56.21 21.1099C56.7402 21.1099 57.17 20.6801 57.17 20.1499C57.17 19.6197 56.7402 19.1899 56.21 19.1899C55.6798 19.1899 55.25 19.6197 55.25 20.1499C55.25 20.6801 55.6798 21.1099 56.21 21.1099Z"
                fill="#FCEFEF"
            />
            <path
                class="assets-nbhd-map-svg-dots"
                d="M43.1797 19.2301C43.7099 19.2301 44.1397 18.8003 44.1397 18.2701C44.1397 17.7399 43.7099 17.3101 43.1797 17.3101C42.6495 17.3101 42.2197 17.7399 42.2197 18.2701C42.2197 18.8003 42.6495 19.2301 43.1797 19.2301Z"
                fill="#FCEFEF"
            />
            <path
                class="assets-nbhd-map-svg-dots"
                d="M23.5196 40.07C24.0498 40.07 24.4796 39.6402 24.4796 39.11C24.4796 38.5798 24.0498 38.15 23.5196 38.15C22.9894 38.15 22.5596 38.5798 22.5596 39.11C22.5596 39.6402 22.9894 40.07 23.5196 40.07Z"
                fill="#FCEFEF"
            />
            <path
                class="assets-nbhd-map-svg-dots"
                d="M20.1602 19.13C20.6904 19.13 21.1202 18.7002 21.1202 18.17C21.1202 17.6398 20.6904 17.21 20.1602 17.21C19.63 17.21 19.2002 17.6398 19.2002 18.17C19.2002 18.7002 19.63 19.13 20.1602 19.13Z"
                fill="#FCEFEF"
            />
            <path
                class="assets-nbhd-map-svg-dots"
                d="M30.96 49.16C31.4902 49.16 31.92 48.7302 31.92 48.2C31.92 47.6698 31.4902 47.24 30.96 47.24C30.4298 47.24 30 47.6698 30 48.2C30 48.7302 30.4298 49.16 30.96 49.16Z"
                fill="#FCEFEF"
            />
            <path
                class="assets-nbhd-map-svg-dots"
                d="M47.25 39.57C47.7802 39.57 48.21 39.1402 48.21 38.61C48.21 38.0798 47.7802 37.65 47.25 37.65C46.7198 37.65 46.29 38.0798 46.29 38.61C46.29 39.1402 46.7198 39.57 47.25 39.57Z"
                fill="#FCEFEF"
            />
            <path
                class="assets-nbhd-map-svg-dots"
                d="M26.4297 59.21C26.9599 59.21 27.3897 58.7802 27.3897 58.25C27.3897 57.7198 26.9599 57.29 26.4297 57.29C25.8995 57.29 25.4697 57.7198 25.4697 58.25C25.4697 58.7802 25.8995 59.21 26.4297 59.21Z"
                fill="#FCEFEF"
            />
            <path
                class="assets-nbhd-map-svg-dots"
                d="M13.5796 12.43C14.3749 12.43 15.0196 11.7853 15.0196 10.99C15.0196 10.1948 14.3749 9.55005 13.5796 9.55005C12.7844 9.55005 12.1396 10.1948 12.1396 10.99C12.1396 11.7853 12.7844 12.43 13.5796 12.43Z"
                fill="#FCEFEF"
            />
            <path
                class="assets-nbhd-map-svg-dots"
                d="M23.5201 3.67004C24.3154 3.67004 24.9601 3.02533 24.9601 2.23004C24.9601 1.43475 24.3154 0.790039 23.5201 0.790039C22.7248 0.790039 22.0801 1.43475 22.0801 2.23004C22.0801 3.02533 22.7248 3.67004 23.5201 3.67004Z"
                fill="#FCEFEF"
            />
            <path
                class="assets-nbhd-map-svg-dots"
                d="M41.8004 31C42.5956 31 43.2404 30.3553 43.2404 29.56C43.2404 28.7647 42.5956 28.12 41.8004 28.12C41.0051 28.12 40.3604 28.7647 40.3604 29.56C40.3604 30.3553 41.0051 31 41.8004 31Z"
                fill="#FCEFEF"
            />
            <path
                class="assets-nbhd-map-svg-dots"
                d="M33.8404 24.79C34.6357 24.79 35.2804 24.1453 35.2804 23.35C35.2804 22.5547 34.6357 21.91 33.8404 21.91C33.0451 21.91 32.4004 22.5547 32.4004 23.35C32.4004 24.1453 33.0451 24.79 33.8404 24.79Z"
                fill="#FCEFEF"
            />
            <path
                class="assets-nbhd-map-svg-dots"
                d="M50.1304 12.0599C50.9257 12.0599 51.5704 11.4152 51.5704 10.6199C51.5704 9.82464 50.9257 9.17993 50.1304 9.17993C49.3351 9.17993 48.6904 9.82464 48.6904 10.6199C48.6904 11.4152 49.3351 12.0599 50.1304 12.0599Z"
                fill="#FCEFEF"
            />
            <path
                class="assets-nbhd-map-svg-dots"
                d="M41.2603 53.65C42.0556 53.65 42.7003 53.0053 42.7003 52.21C42.7003 51.4147 42.0556 50.77 41.2603 50.77C40.465 50.77 39.8203 51.4147 39.8203 52.21C39.8203 53.0053 40.465 53.65 41.2603 53.65Z"
                fill="#FCEFEF"
            />
            <path
                class="assets-nbhd-map-svg-dots"
                d="M18.7198 48.8599C19.25 48.8599 19.6798 48.4301 19.6798 47.8999C19.6798 47.3698 19.25 46.9399 18.7198 46.9399C18.1896 46.9399 17.7598 47.3698 17.7598 47.8999C17.7598 48.4301 18.1896 48.8599 18.7198 48.8599Z"
                fill="#FCEFEF"
            />
            <path
                class="assets-nbhd-map-svg-dots"
                d="M9.00981 51.5999C9.54 51.5999 9.9698 51.1701 9.9698 50.6399C9.9698 50.1097 9.54 49.6799 9.00981 49.6799C8.47961 49.6799 8.0498 50.1097 8.0498 50.6399C8.0498 51.1701 8.47961 51.5999 9.00981 51.5999Z"
                fill="#FCEFEF"
            />
            <path
                class="assets-nbhd-map-svg-dots"
                d="M2.35016 36.9501C3.14545 36.9501 3.79016 36.3054 3.79016 35.5101C3.79016 34.7148 3.14545 34.0701 2.35016 34.0701C1.55487 34.0701 0.910156 34.7148 0.910156 35.5101C0.910156 36.3054 1.55487 36.9501 2.35016 36.9501Z"
                fill="#FCEFEF"
            />
            <path
                class="assets-nbhd-map-svg-lines"
                opacity="0.25"
                d="M13.5795 11.39L12.6295 30.83L2.51953 18.17"
                stroke="#FCEFEF"
                stroke-width="0.32"
                stroke-miterlimit="10"
            />
            <path
                class="assets-nbhd-map-svg-lines"
                opacity="0.25"
                d="M23.5202 1.72998L20.1602 18.17L23.5202 39.11L34.3202 23.16"
                stroke="#FCEFEF"
                stroke-width="0.32"
                stroke-miterlimit="10"
            />
            <path
                class="assets-nbhd-map-svg-lines"
                d="M2.51953 36.12L18.7195 47.9L9.00953 50.63"
                stroke="#FCEFEF"
                stroke-width="0.6"
                stroke-miterlimit="10"
            />
            <path
                class="assets-nbhd-map-svg-lines"
                opacity="0.25"
                d="M40.37 5.27002L30.96 12.54L43.18 18.27L50.13 10.62"
                stroke="#FCEFEF"
                stroke-width="0.3"
                stroke-miterlimit="10"
            />
            <path
                class="assets-nbhd-map-svg-lines"
                opacity="0.25"
                d="M56.21 20.15L41.35 29.56L30.96 48.2"
                stroke="#FCEFEF"
                stroke-width="0.32"
                stroke-miterlimit="10"
            />
            <path
                class="assets-nbhd-map-svg-lines"
                opacity="0.25"
                d="M57.6997 30.52L47.2497 38.61L41.2597 52.21L26.4297 58.25"
                stroke="#FCEFEF"
                stroke-width="0.32"
                stroke-miterlimit="10"
            />
        </svg>
    </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.c-shared-map-icon {
    @include nbhdSvgMap;
}
</style>
