<template>
    <li class="c-neighbourhood-story-article-audio-player-main">
        <ul class="c-neighbourhood-story-article-audio-player-main__inner">
            <!-- progress -->
            <stories-article-audio-player-main-progress :progress="progress" :duration="duration" @seek="seek" />
            <!-- duration -->

            <stories-article-audio-player-main-duration :duration="duration" />
        </ul>
    </li>
</template>

<script>
import StoriesArticleAudioPlayerMainDuration from "@/templates/components/Stories/StoriesArticle/StoriesArticleAudio/StoriesArticleAudioPlayer/StoriesArticleAudioPlayerMainDuration";
import StoriesArticleAudioPlayerMainProgress from "@/templates/components/Stories/StoriesArticle/StoriesArticleAudio/StoriesArticleAudioPlayer/StoriesArticleAudioPlayerMainProgress/StoriesArticleAudioPlayerMainProgress";
export default {
    components: {
        StoriesArticleAudioPlayerMainDuration,
        StoriesArticleAudioPlayerMainProgress
    },
    props: {
        duration: {
            type: Number,
            required: false,
            default: 0
        },
        progress: {
            type: Number,
            required: false,
            default: 0
        }
    },
    methods: {
        seek(value) {
            this.$emit("seek", value);
        }
    }
};
</script>

<style lang="scss" scoped>
.c-neighbourhood-story-article-audio-player-main {
    &__inner {
        @include reset-list;
        display: grid;
        grid-auto-flow: column;
        gap: var(--grid-gutter);
        &__progress {
            flex: 1;
        }
        &__duration {
            flex: 0;
        }
    }
}
</style>
