<template>
    <li
        class="c-stories-more-siblings-item-author"
        :class="[
            { 'c-stories-more-siblings-item-author--illustrator': isIllustrator },
            `c-stories-more-siblings-item-author--index-${itemIndex}`
        ]"
    >
        <p>{{ author }}</p>
    </li>
</template>

<script>
export default {
    props: {
        isIllustrator: {
            type: Boolean,
            required: false,
            default: false
        },
        author: {
            type: String,
            required: false,
            default: null
        },
        itemIndex: {
            type: Number,
            required: false,
            default: 0
        }
    }
};
</script>

<style lang="scss" scoped>
.c-stories-more-siblings-item-author {
    text-align: center;
    &--illustrator {
        @include transform(translateY(50px));
        opacity: 0;
    }
    p {
        @include lightCopy();
    }
}
</style>
