<template>
    <div class="c-home-navigation-slider-item grid">
        <div class="c-home-navigation-slider-item__inner">
            <ul class="c-home-navigation-slider-item__inner__list">
                <li>
                    <h1 class="t-t1">{{ $t(`neigbourhood.list.${item.title}.name`) }}</h1>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        item: {
            type: Object,
            required: true
        }
    }
};
</script>

<style lang="scss" scoped>
.c-home-navigation-slider-item {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    &__inner {
        padding: var(--grid-gutter);
        &__list {
            @include transition(0.5s ease);
            @include reset-list;
            display: grid;
            opacity: 0;
            gap: var(--grid-gutter-half);
            /*
            .slider-active & {
                opacity: 1;
                @include transition-delay(1s);
            } */
        }
    }
}
</style>
