import aboutStars from './stars'

export const aboutLines = [
    [
        [aboutStars[2], aboutStars[6]],
        [aboutStars[6], aboutStars[4]],
    ],
    [
        [aboutStars[1], aboutStars[3]],
        [aboutStars[1], aboutStars[16]],
    ],
    [
        [aboutStars[12], aboutStars[15]],
        [aboutStars[15], aboutStars[8]],
    ],
]

export default aboutLines
