<template>
    <div class="c-about-content-list-item-connector" :class="{ 'c-about-content-list-item-connector--left': isLeft }">
        <div class="c-about-content-list-item-connector__inner">
            <!-- circle -->
            <btn
                :label="null"
                :circle="true"
                typo="serif"
                :class="
                    `c-about-content-list-item-connector__button c-about-content-list-item-connector__button--${paragraphIndex}`
                "
                :is-parent-handle-states="true"
                :is-active="true"
                :is-filled-by-default="false"
                :disabled="true"
            />
            <!-- line -->
            <about-content-list-item-connector-line :current="paragraphIndex" :target="paragraphIndex + 1" />
        </div>
    </div>
</template>

<script>
import Btn from "components/Shared/SharedBtn/SharedBtn";
import AboutContentListItemConnectorLine from "@/templates/components/About/AboutContent/AboutContentList/AboutContentListItem/AboutContentListItemConnector/AboutContentListItemConnectorLine";
export default {
    components: {
        Btn,
        AboutContentListItemConnectorLine
    },
    props: {
        paragraphIndex: {
            type: Number,
            required: false,
            default: 0
        }
    },
    computed: {
        isLeft() {
            return this.isEven(this.paragraphIndex);
        }
    },
    methods: {
        isEven(value) {
            return value % 2 == 0 ? true : false;
        }
    }
};
</script>

<style lang="scss" scoped>
.c-about-content-list-item-connector {
    display: flex;
    position: static;

    justify-content: center;
    @media #{md('md')} {
        justify-content: flex-start;
    }
    &--left {
        @media #{md('md')} {
            justify-content: flex-end;
        }
    }
    &__inner {
        display: flex;
        flex-direction: column;
        position: static;
    }

    &__button {
        position: relative;
        opacity: 0;
    }
}
// .js-about-content-list-item-connector-button--0 {
//     position: relative;
// }
</style>
