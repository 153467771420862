<template>
    <li class="c-stories-article-illustrated-progress">
        <stories-article-illustrated-progress-line :progress="progress" />
        <ul class="c-stories-article-illustrated-progress__list">
            <stories-article-illustrated-progress-step
                v-for="index in sequences"
                :key="index"
                :step="index"
                :is-active="isActive(index)"
            />
        </ul>
    </li>
</template>

<script>
import StoriesArticleIllustratedProgressLine from "@/templates/components/Stories/StoriesArticle/StoriesArticleIllustrated/StoriesArticleIllustratedProgress/StoriesArticleIllustratedProgressLine";
import StoriesArticleIllustratedProgressStep from "@/templates/components/Stories/StoriesArticle/StoriesArticleIllustrated/StoriesArticleIllustratedProgress/StoriesArticleIllustratedProgressStep";
export default {
    components: {
        StoriesArticleIllustratedProgressLine,
        StoriesArticleIllustratedProgressStep
    },
    props: {
        sequences: {
            type: Number,
            required: false,
            default: 0
        },
        activeStep: {
            type: Number,
            required: false,
            default: 0
        }
    },
    computed: {
        progress() {
            return (this.activeStep / (this.sequences - 1)) * 100;
        }
    },
    methods: {
        isActive(step) {
            return step <= this.activeStep + 1;
        }
    }
};
</script>

<style lang="scss" scoped>
.c-stories-article-illustrated-progress {
    @include transform(translateY(300px)); // animation

    @include reset-list;
    display: flex;
    height: 100%;
    position: relative;
    flex-direction: row;

    padding-right: var(
        --grid-gutter
    ); // not crazy about that but it fix safari 13 https://mambomambo-team.atlassian.net/jira/software/projects/CN/boards/2?selectedIssue=CN-415

    &__list {
        @include reset-list;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}
</style>
