<template>
    <li class="c-error-title-item">
        <h1 class="c-error-title-item__404">
            <anim-text
                :text="copy"
                class=" c-error-title-item__404__copy"
                tag="p"
                :visible="isVisible"
                :reveal="true"
                :chars="true"
                :word="false"
                :lines="false"
                :is-axis-y="true"
            />
        </h1>
    </li>
</template>

<script>
import AnimText from "@/templates/objects/AnimText";
export default {
    components: {
        AnimText
    },

    props: {
        itemIndex: {
            type: Number,
            required: false,
            default: 0
        },
        copy: {
            type: String,
            required: false,
            default: null
        },
        isVisible: {
            type: Boolean,
            required: false,
            default: false
        }
    }
};
</script>

<style lang="scss" scoped>
.c-error-title-item {
    position: fixed;

    &:nth-child(1) {
        left: 0;
        top: 5%;
        @media #{md("xs")} {
            bottom: 0;
        }
        @media #{md("md")} {
            left: 10vh;
            bottom: 0;
        }
    }
    &:nth-child(2) {
        left: 50%;
        top: -5vh;
        @media #{md("xs")} {
            left: 30vw;
            top: -15%;
        }
    }
    &:nth-child(3) {
        right: 0;
        bottom: 10vh;
        @media #{md("xs")} {
            right: calc((20vw));
        }
    }

    &__404 {
        @include extraLargeTitle;
        &__copy {
            &::v-deep p {
                @include largeTitle;
            }
        }
    }
}
</style>
