<template>
    <div class="c-stories-title">
        <div class="c-stories-title__inner">
            <h1 class="c-stories-title__inner__copy" :class="{ 'c-stories-title__inner__copy--large': isLarge }">
                <anim-text
                    :text="title"
                    class=""
                    :visible="isTitleVisible"
                    :chars="true"
                    :word="false"
                    :lines="false"
                    :is-axis-y="true"
                />
            </h1>
        </div>
    </div>
</template>

<script>
import AnimText from "@/templates/objects/AnimText";
export default {
    components: {
        AnimText
    },
    props: {
        title: {
            type: String,
            required: false,
            default: null
        },
        isTitleVisible: {
            type: Boolean,
            required: false,
            default: false
        },
        isLarge: {
            type: Boolean,
            required: false,
            default: false
        }
    }
};
</script>

<style lang="scss" scoped>
.c-stories-title {
    @include transform(translateY(100px));
    z-index: 3;
    padding-bottom: var(--grid-gutter); // remove gap so to fix bug on safari 13-14 as I was ask

    @media #{md("lg")} {
        padding-bottom: var(--grid-gutter-double); // remove gap so to fix bug on safari 13-14 as I was ask
    }
    .-debug & {
        @include transform(translateY(0px));
    }
    &__inner {
        display: flex;
        flex-direction: row;
        color: var(--color-light);
        align-content: center;
        align-items: center;
        justify-content: center;
        &__copy {
            @include titles;
            @include t1;
            &--large {
                @include largeTitle;
            }
        }
    }
}
</style>
