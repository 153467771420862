import { render, staticRenderFns } from "./HomeNavigationControlButtonsItem.vue?vue&type=template&id=0cc620b2&scoped=true&"
import script from "./HomeNavigationControlButtonsItem.vue?vue&type=script&lang=js&"
export * from "./HomeNavigationControlButtonsItem.vue?vue&type=script&lang=js&"
import style0 from "./HomeNavigationControlButtonsItem.vue?vue&type=style&index=0&id=0cc620b2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0cc620b2",
  null
  
)

export default component.exports