<template>
    <section
        class="c-stories-article"
        :class="{ 'c-stories-article--ready': isIllustratedAnimationDone }"
        v-touch:swipe.top="audioGoNextStep"
    >
        <!-- :style="`--max-height: ${maxHeightArticle}px`" -->
        <stories-article-stars v-if="currentEntry.audio" />
        <div class="c-stories-article__inner" ref="storiesArticleInner">
            <div
                class="c-stories-article__inner__main | grid"
                :class="{ 'c-stories-article__inner__main--overlay': isTemplateOverlap }"
            >
                <!-- title -->
                <shared-title-page :title="currentEntry.title" :is-title-visible="isTitleVisible" />

                <!-- <stories-more-credit /> -->
                <div v-if="currentEntry" class="c-stories-article__inner__main__article">
                    <!-- audio -->
                    <stories-article-audio
                        v-if="isAudioArticle"
                        :audio="currentEntry.audio"
                        :is-scroll-disabled="isScrollDisabled"
                        @goToNextSection="goToNextSection"
                    />
                    <!-- custom components: 50/50, full,  -->

                    <stories-article-overlap v-else-if="isTemplateOverlap" @goToNextSection="goToNextSection" />

                    <stories-article-illustrated
                        v-else
                        :is-scroll-disabled="isScrollDisabled"
                        @goToNextSection="goToNextSection"
                    />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { gsap } from "gsap";

import { mapState } from "vuex";

import convertorMixin from "@/mixins/convertorMixin.js";

import SharedTitlePage from "@/templates/components/Shared/SharedTitlePage";
import StoriesArticleAudio from "@/templates/components/Stories/StoriesArticle/StoriesArticleAudio/StoriesArticleAudio";
import StoriesArticleIllustrated from "@/templates/components/Stories/StoriesArticle/StoriesArticleIllustrated/StoriesArticleIllustrated";
import StoriesArticleOverlap from "@/templates/components/Stories/StoriesArticle/StoriesArticleOverlap/StoriesArticleOverlap";
import StoriesArticleStars from "@/templates/components/Stories/StoriesArticle/StoriesArticleStars";
export default {
    mixins: [convertorMixin],
    components: {
        SharedTitlePage,
        StoriesArticleAudio,
        StoriesArticleIllustrated,
        StoriesArticleOverlap,
        StoriesArticleStars
    },
    props: {
        isScrollDisabled: {
            type: Boolean,
            required: false,
            default: false
        },
        currentStoryIndex: {
            type: Number,
            required: false,
            default: null
        }
    },
    data() {
        return {
            timelines: {
                shared: null,
                illustrated: null,
                audio: null,
                controls: {
                    easeAnimation: "expo.inOut",
                    durationAnimation: 1.8
                }
            },
            isTitleVisible: false,
            isIllustratedAnimationDone: false
        };
    },
    computed: {
        ...mapState({
            currentEntry: state => state.global.currentEntry,
            fullyFetched: state => state.global.currentEntry.fullyFetched
        }),

        isAudioArticle() {
            return this.currentEntry.type === "storyAudio";
        },
        isTemplateOverlap() {
            return this.currentEntry.template === "overlap";
        }
        // might be helpeful for roman number automatisation
        // overwriteTitle() {
        //     return this.currentStoryIndex
        //         ? `${this.currentEntry.title} ${this.convertToRoman(this.currentStoryIndex)}`
        //         : this.currentEntry.title;
        // }
        // maxHeightArticle() {
        //     return window.innerHeight;
        // }
    },
    watch: {
        fullyFetched(bool) {
            bool ? this.initTimelines() : null;
        }
    },
    mounted() {
        this.fullyFetched ? this.initTimelines() : null;
        window.addEventListener("keydown", this.keyPressed);
    },

    beforeDestroy() {
        this.destroyTimelines();
        window.removeEventListener("keydown", this.keyPressed);
    },

    methods: {
        ////////////////////////////////
        //       START ANIMATION ON MOUNT
        ////////////////////////////////
        initTimelines() {
            this.$nextTick(() => {
                this.initSharedTimelines();
                this.isAudioArticle ? this.initAudioTimeline() : this.imgTimelineSwitch();
            });
        },
        initSharedTimelines() {
            const storiesArticleInner = gsap.utils.toArray(this.$refs.storiesArticleInner);

            this.timelines.shared = gsap
                .timeline({
                    paused: true
                })

                .to(
                    storiesArticleInner,
                    {
                        ease: this.timelines.controls.easeAnimation,
                        y: 0,
                        scale: 1,
                        duration: this.timelines.controls.durationAnimation
                    },
                    "start"
                )
                .to(
                    ".c-stories-title",
                    {
                        ease: this.timelines.controls.easeAnimation,
                        // scale: 1,
                        y: 0,
                        duration: this.timelines.controls.durationAnimation
                    },
                    "start"
                )
                .add(() => {
                    this.toggleTitle(true);
                }, "start")
                .to(
                    storiesArticleInner,
                    {
                        opacity: 1,
                        ease: "none",
                        duration: this.timelines.controls.durationAnimation
                    },
                    "start+=0.3"
                );

            this.startAnimation();
        },
        toggleTitle(bool) {
            this.isTitleVisible = bool;
        },
        startAnimation() {
            this.$nextTick(() => {
                this.timelines.shared.play();
                this.isAudioArticle ? this.playAudiotimeline() : this.playIllustratedTimeline();
            });
        },

        //======= START SPECIFIC TIMELINES =======//

        /*------------------------------
        Start Illustrated timeline
        ------------------------------*/
        imgTimelineSwitch() {
            this.isTemplateOverlap ? this.initOverlapTimeline() : this.initImgTimeline();
        },
        initOverlapTimeline() {
            this.timelines.illustrated = gsap
                .timeline({
                    paused: true
                })

                .to(
                    ".c-story-article-overlap-copy__inner",
                    {
                        ease: this.timelines.controls.easeAnimation,

                        y: 0,
                        duration: this.timelines.controls.durationAnimation
                    },
                    "start"
                )
                .to(
                    ".c-story-article-overlap-copy__inner",
                    {
                        opacity: 1,
                        ease: "none",
                        duration: this.timelines.controls.durationAnimation
                    },
                    "start+=0.2"
                );
        },
        initImgTimeline() {
            this.timelines.illustrated = gsap
                .timeline({
                    paused: true,
                    onComplete: () => {
                        this.isIllustratedAnimationDone = true;
                    }
                })
                .to(
                    ".c-stories-article-illustrated-progress",
                    {
                        ease: this.timelines.controls.easeAnimation,

                        y: 0,
                        duration: this.timelines.controls.durationAnimation
                    },
                    "start"
                )

                .to(
                    ".c-stories-article-illustrated-main-img",
                    {
                        opacity: 1,
                        ease: "none",
                        duration: this.timelines.controls.durationAnimation
                    },
                    "start+=0.1"
                )

                .to(
                    ".c-stories-article-illustrated-main-img-side",
                    {
                        ease: this.timelines.controls.easeAnimation,
                        opacity: 1,
                        y: 0,
                        duration: this.timelines.controls.durationAnimation
                    },
                    "start+=0.1"
                );

            this.responsiveAnimation();
        },

        //======= START RESPONSIVE 50/50 ANIMATION =======//

        responsiveAnimation() {
            this.isClassExist("c-stories-article-illustrated-main-copy-hor")
                ? this.setResponsiveTimeline5050("hor")
                : this.setResponsiveTimeline5050("vert");
        },
        isClassExist(nameOfTheClass) {
            // true/false is easier to read even on a ternary, please don't judge too fast :D
            return document.getElementsByClassName(nameOfTheClass).length ? true : false;
        },
        setResponsiveTimeline5050(className) {
            this.timelines.illustrated
                .to(
                    `.c-stories-article-illustrated-main-copy-${className}__inner`,
                    {
                        ease: this.timelines.controls.easeAnimation,

                        y: 0,
                        duration: this.timelines.controls.durationAnimation
                    },
                    "start+=0.18"
                )
                .to(
                    `.c-stories-article-illustrated-main-copy-${className}__inner`,
                    {
                        opacity: 1,
                        ease: "none",
                        duration: this.timelines.controls.durationAnimation
                    },
                    "start+=0.2"
                )
                .to(`.c-stories-article-illustrated-main-copy-${className}`, {
                    ease: this.timelines.controls.easeAnimation,

                    "--opacity-shadow": 1,
                    duration: this.timelines.controls.durationAnimation
                });
        },

        //======= END RESPONSIVE 50/50 ANIMATION =======//

        playIllustratedTimeline() {
            this.timelines.illustrated.play();
        },
        /*------------------------------
        End Illustrated timeline
        ------------------------------*/

        /*------------------------------
        Start audio timeline
        ------------------------------*/
        initAudioTimeline() {
            this.timelines.audio = gsap
                .timeline({
                    paused: true
                })
                .to(
                    ".c-neighbourhood-story-article-audio-player-btn",
                    {
                        ease: this.timelines.controls.easeAnimation,

                        y: 0,
                        duration: this.timelines.controls.durationAnimation
                    },
                    "start"
                )
                .to(
                    ".c-stories-article-audio-line__line",
                    {
                        ease: this.timelines.controls.easeAnimation,
                        "--line-height-audio": "100%",
                        y: 0,
                        opacity: 1,
                        duration: this.timelines.controls.durationAnimation
                    },
                    "start+=1"
                );
        },
        playAudiotimeline() {
            this.timelines.audio.play();
        },
        /*------------------------------
        End audio timeline
        ------------------------------*/

        //======= END SPECIFIC TIMELINES =======//

        ////////////////////////////////
        //       END ANIMATION ON MOUNT
        ////////////////////////////////

        ////////////////////////////////
        //       START AUDIO GO TO NEXT STEP
        ////////////////////////////////
        audioGoNextStep() {
            // audio player isn't full height neither a specific area where user would scroll like a list of paragraph
            // instead, I will trigger mobile scroll to next section in the parent which is the height of the screen
            this.isAudioArticle ? this.goToNextSection() : null;
        },
        ////////////////////////////////
        //       END AUDIO GO TO NEXT STEP
        ////////////////////////////////

        ////////////////////////////////
        //       START DESTROY
        ////////////////////////////////
        destroyTimelines() {
            this.timelineKiller("shared");
            this.timelineKiller("illustrated");
            this.timelineKiller("audio");
        },
        timelineKiller(name) {
            this.timelines[name] ? (this.timelines[name].kill(), (this.timelines[name] = null)) : null;
        },
        ////////////////////////////////
        //       END DESTROY
        ////////////////////////////////

        keyPressed(value) {
            return value.code === "ArrowDown" ? this.audioGoNextStep() : null;
        },
        goToNextSection() {
            this.$emit("goToNextSection");
        }
    }
};
</script>

<style lang="scss" scoped>
.c-stories-article {
    @include transition(1s linear all);
    @include fullHeightMobile;
    background-color: var(--color-purple-dark);

    display: flex;
    flex-direction: column;

    height: 100vh;

    max-height: var(--max-height-screen); // fix some issue on iOS
    min-height: var(--max-height-screen); // fix some issue on iOS

    @media #{md("xs")} {
        position: static;
    }
    &__inner {
        @include transform(translateY(200px) scale(0.8));
        display: flex;
        flex: 1;
        height: 100%;
        opacity: 0;

        z-index: 2; // ensure that the player is above the stars

        &__main {
            --paddin-top: calc(8rem + 8rem + 0.5rem);
            display: flex;
            flex: 1;
            flex-direction: column;
            // row-gap: var(--grid-gutter);
            padding-bottom: var(--grid-gutter);
            padding-top: var(--paddin-top);

            @media #{md("xs")} {
                // row-gap: var(--grid-gutter-double);
                //  --paddin-top: calc(8rem + 8rem + 0rem);
            }
            @media #{md("sm")} {
                --paddin-top: calc(8rem + 10rem + 1rem);
            }
            @media #{md("lg")} {
                --paddin-top: calc(8rem + 10rem + 2rem);
            }
            &--overlay {
                position: static;
            }
            &__article {
                display: flex;
                flex: 1;
                flex-direction: column;
                .c-stories-article__inner__main--overlay & {
                    position: static;
                }
            }
        }
    }
}
</style>
