<template>
    <div class="c-stories-more">
        <!-- animation spacer -->
        <stories-more-spacer />
        <!-- credits -->
        <stories-more-credit />
        <!-- sibblings stories -->

        <stories-more-siblings
            v-if="siblings.length > 1"
            :siblings="siblings"
            :current-story="currentStoryIndex"
            :is-user-scrolling="isUserScrolling"
        />
        <!-- retrun -->
        <stories-more-return
            v-if="currentEntry.fullyFetched && currentEntry.title"
            :is-user-scrolling="isUserScrolling"
            :has-sibblings="!isTitleAStar(currentEntry.title)"
        />
        <!-- :has-sibblings="false" -->
    </div>
</template>

<script>
import { mapState } from "vuex";

import isDevMixin from "@/mixins/isDevMixin";

import { gsap, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);

import StoriesMoreSiblings from "@/templates/components/Stories/StoriesMore/StoriesMoreSiblings/StoriesMoreSiblings";
import StoriesMoreCredit from "@/templates/components/Stories/StoriesMore/StoriesMoreCredit/StoriesMoreCredit";
import StoriesMoreReturn from "@/templates/components/Stories/StoriesMore/StoriesMoreReturn/StoriesMoreReturn";
import StoriesMoreSpacer from "@/templates/components/Stories/StoriesMore/StoriesMoreSpacer";
export default {
    mixins: [isDevMixin],
    components: {
        StoriesMoreSiblings,
        StoriesMoreCredit,
        StoriesMoreReturn,
        StoriesMoreSpacer
    },
    props: {
        isUserScrolling: {
            type: Boolean,
            required: false,
            default: false
        },
        siblings: {
            type: Array,
            required: false,
            default: () => []
        },
        currentStoryIndex: {
            type: Number,
            required: false,
            default: null
        }
    },
    computed: {
        ...mapState({
            currentEntry: state => state.global.currentEntry
        }),

        currentStoryId() {
            return Number(this.currentEntry.id);
        }
    },
    watch: {
        // currentEntry() {
        //     this.getSiblings(this.currentEntry);
        // },
        isUserScrolling(bool) {
            this.startAnimation(bool);
        }
    },
    data() {
        return {
            timelines: {
                intro: null,
                sibblings: null,
                scrollTrigger: null
            }
        };
    },
    mounted() {
        this.isChildrenMounted();
    },
    beforeDestroy() {
        this.destroyTimelines();
        this.resetScrollPageProgress();
    },
    methods: {
        isChildrenMounted() {
            const findChildInterval = setInterval(() => {
                const sharedTipsInfo = document.getElementsByClassName("c-shared-tips-info");
                const returnButton = document.getElementsByClassName("c-nbhd-story-more-return__inner");
                sharedTipsInfo.length && returnButton.length
                    ? (this.initAnimationIntro(), this.initScrollProgression(), clearInterval(findChildInterval))
                    : null;
            }, 200);
        },

        ////////////////////////////////
        //       START ANIMATION
        ////////////////////////////////

        //======= START INTRO TIMELINE =======//

        initAnimationIntro() {
            this.timelines.intro = gsap.timeline({ paused: true });

            const durationAnimation = 2;
            const ease = "expo.inOut";

            this.timelines.intro

                .to(
                    ".c-shared-tips",
                    {
                        y: 0,

                        duration: durationAnimation,
                        ease: ease
                    },
                    "intro"
                )
                .to(
                    ".c-shared-tips-line-copy",
                    {
                        y: 0,
                        opacity: 1,

                        duration: durationAnimation,
                        ease: ease
                    },
                    "intro"
                )
                .to(
                    ".c-shared-tips-info",
                    {
                        y: 0,
                        opacity: 1,

                        duration: durationAnimation,
                        ease: ease
                    },
                    "intro+=0.5"
                )
                .to(
                    ".c-shared-tips-line__inner",
                    {
                        "--width": "100%",

                        duration: durationAnimation,
                        ease: ease
                    },
                    "intro+=0.5"
                );
        },

        //======= END INTRO TIMELINE =======//

        startAnimation(bool) {
            bool ? this.timelines.intro.play() : this.timelines.intro.reverse();
        },

        ////////////////////////////////
        //       END ANIMATION
        ////////////////////////////////

        ////////////////////////////////
        //       START PAGE PROGRESSION
        ////////////////////////////////
        initScrollProgression() {
            // ensure that child components are fully ready
            const delayInit = setTimeout(() => {
                this.timelines.scrollTrigger = ScrollTrigger.create({
                    trigger: ".c-shared-tips",
                    endTrigger: ".c-nbhd-story-more-return",
                    start: "top top",
                    markers: this.isMarkerVisible(),
                    scroller: ".c-nbhd-story__inner",
                    end: "bottom bottom", // c-nbhd-story-more-return
                    scrub: 0,

                    onUpdate: self => {
                        this.calculateTotalProgression(self.progress);
                    }
                });
                clearTimeout(delayInit);
            }, 500);
        },
        easeScrollProgress() {
            this.$store.commit("constellations/toggleScrollProgressEase", true);
            const easeScrollProgress = setTimeout(() => {
                this.$store.commit("constellations/toggleScrollProgressEase", false);
                clearTimeout(easeScrollProgress);
            }, 300);
        },
        isMarkerVisible() {
            return this.isDevEnv() ? true : false;
        },
        calculateTotalProgression(progression) {
            // 50% + current progression
            const progress = ((1 + progression) / 2) * 100;

            this.updateStoryScrollProgressionGlobally(progress);
        },

        updateStoryScrollProgressionGlobally(progression) {
            this.$store.commit("constellations/updateStoryProgression", progression);
        },
        ////////////////////////////////
        //       END PAGE PROGRESSION
        ////////////////////////////////
        ////////////////////////////////
        //       START RUN STORIES RETURN
        ////////////////////////////////
        // fix bug https://mambomambo-team.atlassian.net/browse/CN-552
        //  it's a bit of nasty bug, it took me a fair amount of time to figure out.
        // sometimes the fetch for the sibbling takes a 1-2ms extra, so the children component by default think there is no sibling. My trick is simply to simply check if this is a star, there will be no sibling or tell child we are waiting for sibling
        isTitleAStar(copy) {
            return copy.includes("toile solitaire") ? true : false; // so far there is only FR so it's fine
        },
        ////////////////////////////////
        //       END RUN STORIES RETURN
        ////////////////////////////////

        ////////////////////////////////
        //       START ON DESTROY
        ////////////////////////////////
        destroyTimelines() {
            this.timelineKiller("intro");
            this.timelineKiller("sibblings");
            this.timelineKiller("scrollTrigger");
        },
        timelineKiller(name) {
            this.timelines[name] ? (this.timelines[name].kill(), (this.timelines[name] = null)) : null;
        },
        resetScrollPageProgress() {
            this.updateStoryScrollProgressionGlobally(0);
        }
        ////////////////////////////////
        //       END ON DESTROY
        ////////////////////////////////
    }
};
</script>

<style lang="scss" scoped>
.c-stories-more {
    display: flex;
    flex-direction: column;
    min-height: 125vh; //screenheight + padding top c-stories-more-spacer
}
</style>
