<template>
    <!-- <div class="c-credits -debug">  -->
    <div class="c-shared-page">
        <!-- stars -->
        <shared-stars :quantity="responsiveStarsQty" />

        <!-- inner -->
        <section class="c-shared-page__inner">
            <!-- title -->

            <shared-title-page :title="title" :is-title-visible="true" :is-large="true" />

            <!-- lisst -->
            <slot></slot>
            <!-- cta -->
            <shared-page-cta :cta-scroll-trigger="ctaScrollTrigger" @clicked="clicked" />
        </section>
    </div>
</template>

<script>
import { gsap } from "gsap/all";
import { mapState } from "vuex";
import SharedStars from "@/templates/components/Shared/SharedStars/SharedStars";
import SharedTitlePage from "@/templates/components/Shared/SharedTitlePage";
import SharedPageCta from "@/templates/components/Shared/SharedPage/SharedPageCta/SharedPageCta";
export default {
    components: {
        SharedStars,
        SharedTitlePage,
        SharedPageCta
    },
    props: {
        ctaScrollTrigger: {
            type: String,
            required: false,
            default: null
        },
        title: {
            type: String,
            required: false,
            default: null
        }
    },

    data() {
        return {
            timelines: {
                onLeaveTimeline: null
            }
        };
    },
    computed: {
        ...mapState({
            transitionOutUrl: state => state.global.transitionOutUrl
        }),
        responsiveStarsQty() {
            return window.innerWidth <= 480 ? 50 : 100;
        }
    },
    watch: {
        transitionOutUrl() {
            this.transitionOutUrl ? this.leaveSharedPage() : null;
        }
    },

    mounted() {
        this.initOnLeaveTimeline();
    },
    methods: {
        ////////////////////////////////
        //       START ANIMATION ON LEFT
        ////////////////////////////////

        //======= START ANIMATION =======//

        initOnLeaveTimeline() {
            this.timelines.onLeaveTimeline = gsap
                .timeline({
                    paused: true,
                    onComplete: () => {
                        this.redirectToPage();
                        this.scrollToTop();
                    }
                })
                .to(
                    ".c-shared-page",
                    {
                        scale: 0.95,
                        y: "5px",
                        ease: "power2.inOut",
                        duration: 1.2
                    },
                    "leave"
                )
                .to(
                    ".c-shared-page",
                    {
                        opacity: 0,
                        ease: "none",
                        duration: 1
                    },
                    "leave"
                );
        },

        //======= END ANIMATION =======//

        clicked() {
            this.leaveSharedPage();
        },

        leaveSharedPage() {
            this.timelines.onLeaveTimeline.play();
        },
        redirectToPage() {
            this.$router.push(this.transitionOutUrl);
        },
        scrollToTop() {
            window.scroll(0, 0);
        },

        ////////////////////////////////
        //       END ANIMATION ON LEFT
        ////////////////////////////////

        ////////////////////////////////
        //       START DESTROY TIMELINES
        ////////////////////////////////
        destroyTimelines() {
            this.timelineKiller("onLeaveTimeline");
        },

        timelineKiller(name) {
            this.timelines[name] ? (this.timelines[name].kill(), (this.timelines[name] = null)) : null;
        }
        ////////////////////////////////
        //       END DESTROY TIMELINES
        ////////////////////////////////
    }
};
</script>

<style lang="scss" scoped>
.c-shared-page {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;

    width: 100%;

    z-index: 3;
    pointer-events: none; // allow sharedPage to be click through https://mambomambo-team.atlassian.net/browse/CN-589

    &__inner {
        @include mainContentWidth;
        display: flex;
        flex-direction: column;
        padding-top: 12rem;
    }
}
</style>
