<template>
    <div class="c-story-article-overlap-copy">
        <div ref="overlapScroller" class="c-story-article-overlap-copy__inner">
            <div ref="overlapCopy" class="c-story-article-overlap-copy__inner__list | t-cms" v-html="copy"></div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

import { gsap, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);

import _ from "lodash";

import isDevMixin from "@/mixins/isDevMixin";

export default {
    mixins: [isDevMixin],
    props: {
        copy: {
            type: String,
            required: false,
            default: null
        }
    },
    data() {
        return {
            timelines: {
                scrollTrigger: null
            },
            timeoutScrollToTop: null
        };
    },
    computed: {
        ...mapState({
            isScrolledDown: state => state.constellations.isScrolledDown
        })
    },
    mounted() {
        this.$nextTick(() => {
            this.initScrollTrigger();
            this.initOnScrollProgress();
            window.addEventListener("resize", this.debounceOnResize);
        });
    },
    beforeDestroy() {
        this.destroyTimelines();
        window.removeEventListener("resize", this.debounceOnResize);
    },
    methods: {
        initScrollTrigger() {
            this.timelines.scrollTrigger = ScrollTrigger.create({
                trigger: this.$refs.overlapCopy,
                start: "bottom bottom",
                markers: this.isMarkerVisible(),
                scroller: this.$refs.overlapScroller,
                scrub: 0,
                onEnter: () => {
                    this.goToNextSection();
                    this.delayScrollToTop();
                }
            });
        },
        isMarkerVisible() {
            return this.isDevEnv() ? true : false;
        },
        goToNextSection() {
            this.$emit("goToNextSection");
        },

        ////////////////////////////////
        //       START SCROLL PROGRESSION
        ////////////////////////////////
        initOnScrollProgress() {
            const delayInit = setTimeout(() => {
                this.timelines.scrollTrigger = ScrollTrigger.create({
                    trigger: this.$refs.overlapCopy,
                    start: "top top",
                    markers: this.isMarkerVisible(),
                    scroller: this.$refs.overlapScroller,
                    end: "bottom bottom",
                    scrub: 0,

                    onUpdate: self => {
                        this.calculateTotalProgression(self.progress);
                    }
                });
                clearTimeout(delayInit);
            }, 500);
        },
        calculateTotalProgression(progression) {
            // 50% + current progression
            const progress = (progression * 100) / 2;

            this.isScrolledDown ? null : this.updateStoryScrollProgressionGlobally(progress);
        },

        updateStoryScrollProgressionGlobally(progression) {
            this.$store.commit("constellations/updateStoryProgression", progression);
        },
        ////////////////////////////////
        //       END SCROLL PROGRESSION
        ////////////////////////////////

        ////////////////////////////////
        //       START SCROLL TO TOP
        ////////////////////////////////
        // avoid gradient blinking and other ugly things by reseting the scroll to the top
        delayScrollToTop() {
            // wait that the animation is done so the scroll of the parahraph isn't visible by users
            this.timeoutScrollToTop = setTimeout(() => {
                this.debouncedScroll();
            }, 1000);
        },
        debouncedScroll: _.debounce(function() {
            this.scrollToTop();
        }, 1000),
        scrollToTop() {
            this.$refs.overlapScroller.scrollTop = 0;
            clearTimeout(this.timeoutScrollToTop);
        },
        ////////////////////////////////
        //       END SCROLL TO TOP
        ////////////////////////////////

        ////////////////////////////////
        //       START RESIZE
        ////////////////////////////////

        debounceOnResize: _.debounce(function() {
            this.onResize();
        }, 400),

        onResize() {
            // clear all timelines
            this.destroyTimelines();
            // restart all timelines
            this.initScrollTrigger();
        },

        ////////////////////////////////
        //       END RESIZE
        ////////////////////////////////

        ////////////////////////////////
        //       START DESTROY TIMELINES
        ////////////////////////////////
        destroyTimelines() {
            this.timelineKiller("scrollTrigger");
        },

        timelineKiller(name) {
            this.timelines[name] ? (this.timelines[name].kill(), (this.timelines[name] = null)) : null;
        }
        ////////////////////////////////
        //       END DESTROY TIMELINES
        ////////////////////////////////
    }
};
</script>

<style lang="scss" scoped>
.c-story-article-overlap-copy {
    --gradient-color: 28, 12, 46;
    --list-column: 1/7;
    @include articleParagraphFadeIn(0deg, 40vh, 0vh);
    display: grid;

    position: relative;
    height: 100%;
    opacity: 1;

    max-height: calc(100vh - 290px - 2rem);

    grid-column: 1/7;
    grid-row: 1/2;

    margin-top: calc(0px - var(--grid-gutter));
    @media #{md("xs")} {
        --list-column: 3/11;
    }

    @media #{md("md")} {
        --list-column: 4/10;
    }

    @media #{md("xs")} {
        grid-row: 1/7;
        grid-column: 1/13;
        margin-top: calc(0px - var(--grid-gutter-double));
    }

    &__inner {
        @include articleParagraphFadeIn(180deg, var(--grid-gutter-double), var(--grid-gutter-half));
        @include transform(translateY(200px)); // intro animation
        @include hide-scrollbar;
        opacity: 0;
        display: flex;
        flex-direction: column;

        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-gap: var(--grid-gutter-half);

        overflow: scroll;
        position: relative;
        z-index: 1;
        height: calc(100% + var(--grid-gutter-double));

        padding-top: var(--grid-gutter-double);

        @media #{md("xs")} {
            height: calc(100% + var(--grid-gutter-double));

            padding-top: var(--grid-gutter-double);
            grid-template-columns: repeat(12, 1fr);
        }

        .-debug & {
            opacity: 1;
            @include transform(translateY(0px)); // intro animation
        }

        &__list {
            @include reset-list;
            @include transform(translateY(0%));

            grid-column: var(--list-column);
            display: flex;
            flex-direction: column;
            height: var(--height);
            position: relative;
            &::v-deep p {
                color: var(--color-light);
                // padding-bottom: var(--grid-gutter-double);
            }
            &:after {
                position: relative;
                content: " ";
                height: calc(100vh - 5rem);
            }
        }
    }
}
</style>
