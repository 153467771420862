<template>
    <li class="c-neighbourhood-story-article-audio-player-main-progress-seeker">
        <input v-model="value" type="range" id="volume" name="volume" min="0" :max="max" />
    </li>
</template>

<script>
export default {
    props: {
        max: {
            type: Number,
            required: false,
            default: 0
        }
    },
    data() {
        return {
            value: 0
        };
    },
    watch: {
        value() {
            this.emitSeek();
        }
    },
    methods: {
        emitSeek() {
            this.$emit("seek", this.value);
        }
    }
};
</script>

<style lang="scss" scoped>
.c-neighbourhood-story-article-audio-player-main-progress-seeker {
    @include fullWidthFixed(absolute);
    z-index: 1;
    height: 100%;
    width: 100%;
    opacity: 0; // keep it invisibile. This component is user for the user to seek a specific time in the audio

    input {
        width: 100%;
        height: 100%;
        cursor: pointer;
    }
}
</style>
