<template>
    <li class="c-stories-article-illustrated-progress-step">
        <!-- number -->
        <span
            class="c-stories-article-illustrated-progress-step__number"
            :class="{ 'c-stories-article-illustrated-progress-step__number--active': isActive }"
            >{{ convertToRoman(step) }}</span
        >
    </li>
</template>

<script>
import convertorMixin from "@/mixins/convertorMixin.js";
export default {
    mixins: [convertorMixin],
    props: {
        step: {
            type: Number,
            required: false,
            default: 0
        },
        isActive: {
            type: Boolean,
            required: false,
            default: false
        }
    }
};
</script>

<style lang="scss" scoped>
.c-stories-article-illustrated-progress-step {
    --color-number: var(--color-purple-light);
    --transition-delay: 0s;

    display: flex;
    &__number {
        @include titles;
        @include transition(0.3s ease all);
        transition-delay: var(--transition-delay);
        font-size: 1.5rem;
        color: var(--color-number);

        &--active {
            --color-number: var(--color-light);
            // transition-delay: var(--transition-delay);
            --transition-delay: 0.5s;
        }
    }
}
</style>
