<template>
    <section
        class="c-home-scene"
        :class="{
            'c-home-scene--visible':
                isSceneReady && isExperienceStarted && homeModel && (!transitionOutUrl || transitionOutUrl === '/')
        }"
    >
        <canvas ref="home_scene__container" class="c-home-scene__container"></canvas>
    </section>
</template>

<script>
import { mapState } from "vuex";

import { gsap } from "gsap/all";
import isDevMixin from "@/mixins/isDevMixin";

import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
// STATS
import Stats from "stats-js";
import { GUI } from "@/vendors/dat.gui.module.js";

import homeStars from "@/data/home/homeStars.js";
import homeLines from "@/data/home/lines.js";

// Shaders
import { vertexShader, fragmentShader } from "src/shaders/home/";

export default {
    mixins: [isDevMixin],
    data() {
        return {
            // base
            container: null,
            scene: null,
            camera: null,
            controls: null,
            animation: undefined,

            clock: {
                clock: null,
                then: 0
            },
            // isSceneReady: false,

            //   lights
            lights: {
                directionalLight: null,
                directionalLight2: null,
                directionalLightMain: {
                    directionalLight: null,
                    colour: {
                        colour: 0xff94c1,
                        intensity: 0.5
                    },
                    position: [200, 100, 400]
                },
                directionalLightFront: {
                    directionalLight: null,
                    colour: {
                        colour: 0xfcacac,
                        intensity: 0.8
                    },
                    position: [-200, 100, 0]
                },
                directionalLightSide: {
                    directionalLight: null,
                    colour: {
                        colour: 0xffffff,
                        intensity: 0.8
                    },
                    position: [150, 400, 50]
                },
                directionalLightLight: {
                    directionalLight: null,
                    colour: {
                        colour: 0xff8c8c,
                        intensity: 1.5
                    },
                    // position: [200, 100, 200]
                    position: [200, 100, 400]
                }
                // directionalLightWhite: {
                //     directionalLight: null,
                //     colour: {
                //         colour: 0xfcacac,
                //         intensity: 1
                //     },
                //     position: [150, 400, 50]
                // }
            },
            // fog
            fog: {
                colour: 0x1c0c2e,
                near: 800,
                far: 1400
                // ORTHOGRAPHIC INFO IF NEEDED
                // near: 500,
                // far: 16000
            },

            //  helper
            helpers: {
                stats: null
            },
            cameras: {
                // ORTHOGRAPHIC INFO IF NEEDED
                // defaultCameraPosition: {
                //     camX: 0,
                //     camY: 650 + 100,
                //     camZ: 0
                // },
                // defaultCameraZoom: 1.2,
                // defaultCameraTarget: {
                //     camX: 0,
                //     camY: 200 + 0,
                //     camZ: -1000
                // },
                defaultCameraPosition: {
                    camX: 0,
                    camY: 650,
                    camZ: 0
                },
                defaultCameraZoom: 1,

                defaultCameraTarget: {
                    camX: -50, // it's hard to center the model
                    camY: 120,
                    camZ: -1000
                },

                windowUser: {
                    width: 0,
                    height: 0
                }
            },
            gltf: {
                GLTFLoader: null
            },
            homeGLTF: null,

            progress: {
                totalProgress: 0
            },

            animations: {
                rotations: {
                    previous: 0,
                    current: -1,
                    currentRotation: 0,
                    animation: {
                        // launch pad animation
                        animationMixer: [],
                        actionArray: [],
                        animationArray: [],
                        isAnimationStarted: false,
                        animation: null,
                        isAnimationOnMount: true,
                        gameLoopSpeed: 0.013
                    }
                },
                gsapAnimation: {
                    intro: {
                        duration: 3,
                        timeline: null
                    },
                    onLeave: {
                        timeline: null
                    }
                },
                isExperienceRunning: false
            }
        };
    },
    mounted() {
        this.setWindowSize();
        this.init();
        // Register an event listener when the Vue component is ready
        window.addEventListener("resize", this.onResize);
    },
    beforeDestroy() {
        this.beforeDestroyCluster();
    },
    computed: {
        ...mapState({
            homeModel: state => state.global.settings.model,
            current: state => state.home.current,
            isExperienceStarted: state => state.home.isExperienceStarted,
            isSceneReady: state => state.home.isSceneReady,
            transitionOutUrl: state => state.global.transitionOutUrl
        }),
        progressInPercent() {
            return this.progress.totalProgress * 100;
        }
    },

    watch: {
        current(val) {
            this.isSceneReady ? this.startRotation(val) : null;
        },
        isExperienceStarted(bool) {
            bool && this.isSceneReady && this.homeModel.url && !this.isExperienceRunning
                ? this.startExperience()
                : null;
        },
        homeModel(val) {
            val.url && this.isSceneReady && this.isExperienceStarted && !this.isExperienceRunning
                ? this.startExperience()
                : null;
        },
        transitionOutUrl(url) {
            url ? this.animationOnLeave() : null;
        }
    },

    methods: {
        ////////////////////////////////
        //       START ON MOUNTED METHODS
        ////////////////////////////////
        //   set window width
        setWindowSize() {
            this.cameras.windowUser.width = window.innerWidth;
            this.cameras.windowUser.height = window.innerHeight;
        },

        onResize() {
            // Update sizes
            this.setWindowSize();

            // Update camera
            this.camera.aspect = this.cameras.windowUser.width / this.cameras.windowUser.height;
            this.camera.updateProjectionMatrix();

            this.updateCameraZoom();
            this.setTarget();

            // Update renderer
            this.renderer.setSize(this.cameras.windowUser.width, this.cameras.windowUser.height);
            this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
        },

        ////////////////////////////////
        //       END ON MOUNTED METHODS
        ////////////////////////////////

        ////////////////////////////////
        //       START INIT SCENE AND RENDER
        ////////////////////////////////

        init() {
            this.setBaseScene();

            this.setLight();
            this.addSceneFog();

            /*------------------------------
            Start add meshes to the scenes
            ------------------------------*/
            this.addGLTF();
            /*------------------------------
            End add meshes to the scenes
            ------------------------------*/

            this.setCamera();
            this.setControl();

            this.setRenderer();

            this.gameLoop();
        },

        //======= START GAME LOOP =======//

        gameLoop() {
            // don't display the stats if on prod. Maybe this dev stuff should be handle differently
            this.isDevEnv() ? this.helpers.stats.update() : "";

            const elapsedTime = this.clock.clock.getElapsedTime();

            // Methods that doesn't not required 60fps
            this.CPUSaver(elapsedTime);

            // Call gameLoop again on the next frame
            this.animation = requestAnimationFrame(this.gameLoop);
        },

        CPUSaver(now) {
            // code inpirted from > https://gist.github.com/elundmark/38d3596a883521cb24f5
            // the difference is that I used timelapse istead of date, so instead of 1000 interval, we only need 1 / fps
            const fps = 60;
            const interval = 1 / fps; // replaced
            const delta = now - this.clock.then;

            if (delta > interval) {
                this.controls.update();
                // Render
                this.renderer.render(this.scene, this.camera);

                this.animations.rotations.animation.isAnimationStarted ? this.CPUSaverAnimationStarted(now) : null;

                // Just `then = now` is not enough.
                // Lets say we set fps at 10 which means
                // each frame must take 100ms
                // Now frame executes in 16ms (60fps) so
                // the loop iterates 7 times (16*7 = 112ms) until
                // delta > interval === true
                // Eventually this lowers down the FPS as
                // 112*10 = 1120ms (NOT 1000ms).
                // So we have to get rid of that extra 12ms
                // by subtracting delta (112) % interval (100).
                // Hope that makes sense.
                this.clock.then = now - (delta % interval);
            }
        },
        CPUSaverAnimationStarted(now) {
            this.animateRotation(now);
        },

        //======= END GAME LOOP =======//

        //======= START BASE THREEJS =======//

        setBaseScene() {
            // set container
            this.container = this.$refs.home_scene__container;

            // create scene
            this.scene = new THREE.Scene();
            this.setClock();

            this.isDevEnv() ? this.setHelpers() : "";
        },

        setHelpers() {
            // stats
            this.helpers.stats = new Stats();
            document.body.appendChild(this.helpers.stats.dom);

            // axes helpers
            const axesHelper = new THREE.AxesHelper(5);
            // x y z
            this.scene.add(axesHelper);

            // set gui globaly
            this.setGUI();
        },
        setGUI() {
            this.helpers.gui = new GUI();
            this.parameterLightGenerator("directionalLightMain");
            this.parameterLightGenerator("directionalLightFront");
            this.parameterLightGenerator("directionalLightSide");
            this.parameterLightGenerator("directionalLightLight");
        },

        parameterLightGenerator(lightName) {
            const parameters = {
                color: this.lights[lightName].colour.colour
            };

            this.helpers.gui.addColor(parameters, "color").onChange(() => {
                this.lights[lightName].directionalLight.color.set(parameters.color);
            });
        },
        setClock() {
            this.clock.clock = new THREE.Clock();
        },

        //======= END BASE THREEJS =======//

        //======= START LIGHTS =======//

        setLight() {
            this.setAmbientLight();
            this.directionalLightManager("directionalLightMain");
            this.directionalLightManager("directionalLightFront");
            this.directionalLightManager("directionalLightSide");
            this.directionalLightManager("directionalLightLight");
        },
        setAmbientLight() {
            const ambientLight = new THREE.AmbientLight(0xfcefef, 1);
            this.scene.add(ambientLight);
        },
        directionalLightManager(lightName) {
            this.lights[lightName].directionalLight = new THREE.DirectionalLight(
                this.lights[lightName].colour.colour,
                this.lights[lightName].colour.intensity
            );
            this.lights[lightName].directionalLight.position.set(
                this.lights[lightName].position[0],
                this.lights[lightName].position[1],
                this.lights[lightName].position[2]
            );
            this.scene.add(this.lights[lightName].directionalLight);

            this.directioLightHelper(lightName);
        },

        directioLightHelper(lightName) {
            const directionalLightHelper = new THREE.DirectionalLightHelper(
                this.lights[lightName].directionalLight,
                0.1
            );
            this.scene.add(directionalLightHelper);
        },

        //======= END LIGHTS =======//

        //======= START SET FOG =======//

        addSceneFog() {
            this.scene.fog = new THREE.Fog(this.fog.colour, this.fog.near, this.fog.far);
            this.scene.background = new THREE.Color(this.fog.colour);
        },

        //======= END SET FOG =======//

        //======= START CAMERA AND CONTROL =======//

        setCamera() {
            this.camera = new THREE.PerspectiveCamera(
                75,
                this.cameras.windowUser.width / this.cameras.windowUser.height,
                0.1,
                2000
            );

            this.camera.position.set(
                this.cameras.defaultCameraPosition.camX,
                this.cameras.defaultCameraPosition.camY,
                this.cameras.defaultCameraPosition.camZ
            );

            this.scene.add(this.camera);

            this.updateCameraZoom();
        },
        updateCameraZoom() {
            this.camera.zoom = this.calculateCameraZoom(window.innerWidth);

            this.camera.updateProjectionMatrix();
        },
        calculateCameraZoom(windowInnerWidth) {
            switch (true) {
                case windowInnerWidth <= 800:
                    return 0.9;
                case windowInnerWidth > 800 && windowInnerWidth <= 1400:
                    return 1.2;
                default:
                    return 1.7;
            }
        },

        setControl() {
            this.controls = new OrbitControls(this.camera, this.container);

            this.setTarget();
        },

        setTarget() {
            this.controls.target.set(
                this.cameras.defaultCameraTarget.camX,
                this.cameras.defaultCameraTarget.camY + this.responsiveTargetSize(window.innerWidth),
                this.cameras.defaultCameraTarget.camZ
            );
        },
        responsiveTargetSize(windowInnerWidth) {
            // https://mambomambo-team.atlassian.net/browse/CN-409
            switch (true) {
                case windowInnerWidth <= 800:
                    return 0;
                case windowInnerWidth > 800 && windowInnerWidth <= 1400:
                    return -30;
                default:
                    return 80;
            }
        },

        //======= END CAMERA AND CONTROL =======//

        //======= START RENDERER  =======//

        setRenderer() {
            this.renderer = new THREE.WebGLRenderer({
                canvas: this.container,
                powerPreference: "high-performance",
                antialias: true
            });
            this.renderer.setSize(this.cameras.windowUser.width, this.cameras.windowUser.height);

            this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
        },

        //======= END RENDERER  =======//

        ////////////////////////////////
        //       END INIT SCENE AND RENDER
        ////////////////////////////////

        ////////////////////////////////
        //       START ADD MESHES TO SCENE
        ////////////////////////////////



        //======= START ADD GLTF =======//

        addGLTF() {
            // setup the GLTF Loader
            this.setBaseLoader();
            // Loop all GLTF from an array in a JSON file (later will be from CRAFT)
            this.loadGLTF();
        },

        setBaseLoader() {
            // draco always before GLTF Loader
            this.setDraco();
            this.setLoader();
        },
        setDraco() {
            this.gltf.dracoLoader = new DRACOLoader();
            //  path to a folder containing WASM/JS decoding libraries.
            this.gltf.dracoLoader.setDecoderPath("/three-assets/vendors/draco/"); // path needs be public
            this.gltf.dracoLoader.preload();
        },
        setLoader() {
            // set loader
            this.gltf.GLTFLoader = new GLTFLoader();
            this.gltf.GLTFLoader.setDRACOLoader(this.gltf.dracoLoader);
        },
        loadGLTF() {
            this.gtflLoader(this.homeModel.url, "homeGLTF", [0, -10, 0]);
        },

        generateStars(model) {
            // Star Geometry

            const starsPosition = homeStars[model.name] ? homeStars[model.name].flat() : [];
            const positions = new Float32Array(starsPosition.length);
            const scales = new Float32Array(starsPosition.length / 3);

            if (starsPosition.length == 0) return;

            //We have to position the stars accordingly to the model
            for (let i = 0; i < starsPosition.length; i++) {
                positions[i] = starsPosition[i];
                positions[i + 1] = starsPosition[i + 1];
                positions[i + 2] = starsPosition[i + 2];
                scales[i] = Math.random() * 1.5 + 1.5; //between when 1.5 and 3
            }

            const starGeometry = new THREE.BufferGeometry();
            starGeometry.setAttribute("position", new THREE.BufferAttribute(positions, 3));
            starGeometry.setAttribute("aScale", new THREE.BufferAttribute(scales, 1));

            // Use same random array of float as `scales` for the frequency (better for performance)
            starGeometry.setAttribute("aFrequency", new THREE.BufferAttribute(scales, 1));

            const stars = new THREE.Points(starGeometry, this.starShaderMaterial);

            return stars;
        },
        animateStars(elapsedTime) {
            if (!this.homeStars) return;

            // We should Animate only the currentStars
            // But currentScene is not reliable
            // const currentScene = this.homeGLTF.scene.children[this.current];

            this.homeStars.forEach(({ stars }) => {
                stars.material.uniforms.uTime.value = elapsedTime * 2;
                stars.material.uniforms.uOpacity.value = Math.random() + 0.3; // opacity vary between 0.5 and 1
            });
        },
        generateLines(model) {
            const starsPosition = homeStars[model.name] ? homeStars[model.name].flat() : [];

            if (starsPosition.length == 0) return;

            const _connectedLines = homeLines[model.name];

            const lines = [];
            _connectedLines.forEach(connectedStars => {
                const lineGeometry = new THREE.BufferGeometry();

                let pairs = [];

                connectedStars.forEach(pair => {
                    pairs.push(new THREE.Vector3(...pair[0]));
                    pairs.push(new THREE.Vector3(...pair[1]));
                });
                lineGeometry.setFromPoints(pairs);
                lines.push(new THREE.Line(lineGeometry, this.lineMaterial));
            });

            return lines;
        },
        addStarsToModel(model) {
            const stars = this.generateStars(model);
            const lines = this.generateLines(model);

            // model.add(AxesHelper)
            if (!stars || !lines) return;

            this.homeStars.push({ name: model.name, stars });

            model.add(stars, ...lines);
        },

        gtflLoader(path, GTLFName, position) {
            //Load the stars texture
            this.textureLoader = new THREE.TextureLoader();
            this.starTexture = this.textureLoader.load("/three-assets/textures/star2.png");

            this.gltf.GLTFLoader.load(
                path,
                gltf => {
                    this[GTLFName] = gltf; // add model dynamicly
                    gltf.scene.traverse(child => {
                        if (child.isMesh) {
                            child.castShadow = false;
                            child.receiveShadow = false;

                            child.material.metalness = 0.1; // TODO REMOVE > TEST -> Update directly from Blender
                        }
                    });

                    gltf.scene.position.set(position[0], -500, position[2]);
                    gltf.scene.rotation.y = (Math.PI * 0.33 * (this.current)) - Math.PI * 0.33 ;

                    this.homeStars = [];

                    this.setStarsMaterial();
                    this.setLinesMaterial();

                    this.homeGLTF.scene.children.forEach(model => {
                        this.addStarsToModel(model);
                    });

                    this.scene.add(gltf.scene);

                    this.addAnimationMixer(gltf);

                    this.sceneIsReady(); // there is only on GLTF so this is easier
                },
                xhr => {
                    this.progressManager(xhr.loaded, xhr.total);
                },
                undefined
            );
        },
        setLinesMaterial() {
            this.lineMaterial = new THREE.LineBasicMaterial({ opacity: 0.3, transparent: true });
        },
        setStarsMaterial() {
            this.starShaderMaterial = new THREE.ShaderMaterial({
                depthWrite: false,
                blending: THREE.AdditiveBlending,
                uniforms: {
                    uSize: { value: 3 * this.renderer.getPixelRatio() },
                    uTime: { value: 0 },
                    uOpacity: { value: 1 }
                },
                vertexColors: true,
                fragmentShader: fragmentShader,
                vertexShader: vertexShader
            });
        },

        /*------------------------------
       Start Animation
       ------------------------------*/
        addAnimationMixer(gltf) {
            this.animations.rotations.animation.animationMixer = new THREE.AnimationMixer(gltf.scene);

            this.animations.rotations.animation.actionArray = gltf.animations;
        },
        /*------------------------------
       End Animation
       ------------------------------*/

        /*------------------------------
       Start Progress on GLTF Load
       ------------------------------*/
        progressManager(loaded, total) {
            this.progress.totalProgress = this.calculateProgress(loaded, total);
        },
        calculateProgress(loaded, total) {
            return loaded / total;
        },

        /*------------------------------
       End Progress on GLTF Load
       ------------------------------*/

        //======= END ADD GLTF =======//

        ////////////////////////////////
        //       END ADD MESHES TO SCENE
        ////////////////////////////////

        ////////////////////////////////
        //       START ANIMATION
        ////////////////////////////////

        /*------------------------------
        Start animation on mount
        ------------------------------*/

        sceneIsReady() {
            this.updateSceneIsReadyGlobally(true);
            // user returned to home page, so trigger the experience as soon as its loaded
            this.isExperienceStarted && !this.isExperienceRunning ? this.startExperience() : null;
        },

        updateSceneIsReadyGlobally(bool) {
            this.$store.commit("home/toggleIsSceneReady", bool);
        },

        startExperience() {
            this.isExperienceRunning = true;
            this.startRotation(this.current);
            gsap.to(this.homeGLTF.scene.position, {
                duration: this.animations.gsapAnimation.intro.duration,
                y: 80,
                ease: "power3.out"
            });
        },
        /*------------------------------
        End animation on mount
        ------------------------------*/

        //======= START PIVOT ROTATION =======//

        startRotation(index) {
            index = this.fallBackCurrentSlideId(index);

            if (Number(index) === Number(this.animations.rotations.current)) return;
            this.animations.rotations.current = index;

            // disable control slider to avoid user to scroll while the animation is still running
            this.toggleRotationCompleteGlobally(false);
            // rotate all GLTF
            this.pivotRotation(index);

            this.startRotationAnimation(index, false);
        },
        fallBackCurrentSlideId(index) {
            switch (index) {
                case -1:
                    // code block
                    return 5;
                case 6:
                    // code block
                    return 0;
                default:
                    // code block
                    return index;
            }
        },
        toggleRotationCompleteGlobally(bool) {
            // ensure that user don't trigger a new scroll while the animation is still runnign
            this.$store.commit("home/toggleRotationIsComplete", bool);
        },

        //======= START PIVOT ROTATION =======//

        pivotRotation(index) {
            this.calculateRotation(index);
            // run the pivot animation
            this.animations.gsapAnimation.intro.timeline = gsap.timeline({
                onComplete: () => {
                    this.toggleRotationCompleteGlobally(true);
                }
            });
            this.animations.gsapAnimation.intro.timeline
                .to(
                    this.homeGLTF.scene.rotation,
                    {
                        duration: this.isAnimationOnMount(),
                        y: this.animations.rotations.currentRotation,
                        ease: "power3.out"
                    },
                    "intro"
                )
                .add(() => {
                    this.removeOnMountAnimation();
                    this.$store.commit("home/updateUserVisit", false);
                }, "-=0.5");
            // reverse current GLTF Animation
            this.reverseCurrentGLTFAnimation(index);
        },

        calculateRotation(index) {
            const previous = this.animations.rotations.previous;

            if (previous === 0 && index === 5) {
                return (this.animations.rotations.currentRotation = this.calculatePiRotation(
                    Math.round(this.animations.rotations.currentRotation - 1)
                ));
            } else if (previous === 5 && index === 0) {
                return (this.animations.rotations.currentRotation = this.calculatePiRotation(
                    Math.round(this.animations.rotations.currentRotation + 1)
                ));
            }
            // the new target is lower than the current index
            if (index < previous) {
                return (this.animations.rotations.currentRotation = this.calculatePiRotation(
                    this.calculateRotationToLeft(index)
                ));
            }

            // is the new index higher than the current one and the current index is not
            // if yes, return simple Pi Calculation
            return (this.animations.rotations.currentRotation = this.calculatePiRotation(
                this.calculateRotationToRight(index)
            ));
        },

        calculateRotationToLeft(index) {
            return Math.round(
                this.animations.rotations.currentRotation - Math.abs(this.animations.rotations.previous - index)
            );
        },
        calculateRotationToRight(index) {
            return Math.round(
                this.animations.rotations.currentRotation + Math.abs(this.animations.rotations.previous - index)
            );
        },

        calculatePiRotation(index) {
            return Math.PI * (1 / 3) * index;
        },

        //======= END PIVOT ROTATION =======//

        reverseCurrentGLTFAnimation(index) {
            this.startRotationAnimation(this.animations.rotations.previous, true);

            this.animations.rotations.previous = index;
        },

        isAnimationOnMount() {
            return this.animations.rotations.animation.isAnimationOnMount
                ? this.animations.gsapAnimation.intro.duration
                : 2;
        },
        removeOnMountAnimation() {
            this.disableAnimationOnMount();
            this.udpateGameLoopSpeedToNormal();
        },
        disableAnimationOnMount() {
            this.animations.rotations.animation.isAnimationOnMount = false;
        },
        udpateGameLoopSpeedToNormal() {
            this.animations.rotations.animation.gameLoopSpeed = 0.02;
        },

        //======= END PIVOT ROTATION =======//

        //======= START GLTF ANIMATION =======//

        startRotationAnimation(index, isReverse) {
            this.animations.rotations.animation.isAnimationStarted = true;

            this.runGLTFAnimation(this.convertIndexToGLTF(index), isReverse);
        },

        convertIndexToGLTF(index) {
            return index + 1;
        },

        runGLTFAnimation(animationName, isReverse) {
            const clip = THREE.AnimationClip.findByName(
                this.animations.rotations.animation.actionArray,
                `${this.fallBackAnimation(animationName)}Action`
            );

            const action = this.animations.rotations.animation.animationMixer.clipAction(clip);
            action.paused = false;
            action.timeScale = this.isTimeScaleReverse(isReverse);
            action.clampWhenFinished = true;
            action.setLoop(THREE.LoopOnce);
            !isReverse ? action.play().reset() : action.play();
        },
        isTimeScaleReverse(bool) {
            return bool ? -1 : 1;
        },
        fallBackAnimation(animationName) {
            return animationName === 0 ? 6 : animationName;
        },

        animateRotation() {
            this.animations.rotations.animation.animationMixer.update(
                this.animations.rotations.animation.gameLoopSpeed
            );
        },

        //======= END GLTF ANIMATION =======//

        //======= START ANIMATION ON LEAVE =======//

        animationOnLeave() {
            this.animations.gsapAnimation.onLeave.timeline = gsap.timeline({});

            this.animations.gsapAnimation.onLeave.timeline
                .to(
                    this.camera.position,
                    {
                        duration: 3,
                        y: this.camera.position.y + 400,
                        ease: "power3.out"
                    },
                    "leaving"
                )
                .to(
                    this.controls.target,
                    {
                        duration: 3,
                        y: 190,
                        ease: "power3.out"
                    },
                    "leaving"
                )

                .add(() => {
                    this.pageRedirection(this.transitionOutUrl);
                }, `-=${this.animations.gsapAnimation.intro.duration / 2}`);
        },
        pageRedirection(url) {
            url !== "/" ? this.$router.push(url) : null;
        },

        //======= END ANIMATION ON LEAVE =======//

        ////////////////////////////////
        //       END ANIMATION
        ////////////////////////////////

        ////////////////////////////////
        //       START ON DESTROY
        ////////////////////////////////

        beforeDestroyCluster() {
            this.removeListenerAndLoops();
            this.destroyTimelines();
            this.resetStore();
        },
        removeListenerAndLoops() {
            cancelAnimationFrame(this.animation);
            this.animation = undefined;
            window.removeEventListener("resize", this.onResize);
        },
        resetStore() {
            this.updateSceneIsReadyGlobally(false);
        },
        destroyTimelines() {
            this.timelineKiller("onLeave", "timeline");
            this.timelineKiller("intro", "timeline");
        },
        timelineKiller(animation, name) {
            this.animations.gsapAnimation[animation][name]
                ? (this.animations.gsapAnimation[animation][name].kill(),
                  (this.animations.gsapAnimation[animation][name] = null))
                : null;
        }

        ////////////////////////////////
        //       END ON DESTROY
        ////////////////////////////////
    }
};
</script>

<style lang="scss">
.c-home-scene {
    @include transition(1s ease-out all);
    opacity: 0;
    pointer-events: none;
    &--visible {
        opacity: 1;
    }
    &__container {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: black;
        z-index: 1; // this can be improved by setting up other component to relative z-index 1
    }
}

.dg.ac {
    z-index: 99999 !important; //debug solution
}
</style>
