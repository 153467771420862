<template>
    <transition name="slide-fade">
        <div
            v-if="isVisible && title"
            class="c-shared-explore-label"
            :class="{ 'c-shared-explore-label--label': isLabel, 'c-shared-explore-label--small': isSmall }"
        >
            <p class="c-shared-explore-label__title">
                {{ title }}
            </p>
            <p v-if="copy" class="c-shared-explore-label__copy" v-html="copy" />
        </div>
    </transition>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: false,
            default: null
        },
        copy: {
            type: String,
            required: false,
            default: null
        },
        isVisible: {
            type: Boolean,
            required: false,
            default: false
        },
        isLabel: {
            type: Boolean,
            required: false,
            default: false
        },
        isSmall: {
            type: Boolean,
            required: false,
            default: false
        }
    }
};
</script>

<style lang="scss" scoped>
.c-shared-explore-label {
    --min-width-label: 100px;
    display: grid;
    gap: var(--grid-gutter-quarter);
    grid-template-columns: repeat(1, 1fr);
    text-align: center;
    min-width: var(--min-width-label);

    &--small {
        --min-width-label: 5rem;
    }
    &__title {
        // dont add the color here in case parent need to manage 2 colours it like in the story and the return in the story page
    }

    &__copy {
        .c-shared-explore-label--label & {
            @include lightCopy;
            font-size: 1.2rem;
        }
    }
}

.slide-fade-enter-active {
    transition: all 0.5s ease;
    transition-delay: 0.2s;
    .c-shared-explore-label__copy {
        transition: all 0.5s ease;
        transition-delay: 0.2s;
    }
}
.slide-fade-leave-active {
    transition: all 0.5s ease;
    .c-shared-explore-label__copy {
        transition: all 0.5s ease;
    }
}
.slide-fade-enter,
.slide-fade-leave-to {
    transform: translateY(3rem);
    opacity: 0;
    transition-delay: 0s;
    .c-shared-explore-label__copy {
        transform: translateY(2rem);
        opacity: 0;
        transition-delay: 0s;
    }
}
</style>
