<template>
    <div class="c-shared-animated-icon-arrow" :class="{ 'c-shared-animated-icon-arrow--dark': isDark }">
        <div class="c-shared-animated-icon-arrow__inner">
            <icon icon="arrow-right" />
        </div>
    </div>
</template>

<script>
import Icon from "@/templates/objects/Icon";
export default {
    components: {
        Icon
    },
    props: {
        isDark: {
            type: Boolean,
            required: false,
            default: false
        }
    }
};
</script>

<style lang="scss" scoped>
.c-shared-animated-icon-arrow {
    @include transition(0.3s $out-expo all);
    --width-line: 0%;
    --line-top: calc(1.4rem / 2);
    --rgb-arrow: 252, 239, 239;
    --scaleArrow: 0, 0;

    &--dark {
        --rgb-arrow: 28, 12, 46;
    }

    &__inner {
        display: flex;
        justify-content: flex-end;
        align-content: center;
        align-items: center;
        position: relative;

        height: 1.4rem; // not crazy at all about it but it some some weird bugs on Firefox
        &:after {
            @include transform(translateY(-50%));
            content: " ";
            position: absolute;
            top: var(--line-top);
            width: var(--width-line);
            height: 1px;
            right: 1px;

            background: var(--rgb-arrow);
            background: linear-gradient(90deg, rgba(var(--rgb-arrow), 0) 0%, rgba(var(--rgb-arrow), 1) 100%);
        }

        &::v-deep .o-icon svg {
            @include transform(scale(var(--scaleArrow))); // the scaleY only is buggy and there is not much difference
            transform-origin: center right;
            fill: none;
            stroke: rgb(var(--rgb-arrow));
            height: 100%;
            // fill: black;
        }
    }
}
</style>
