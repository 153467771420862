<template>
    <div class="p-about">
        <about-page :entry="entry" />
    </div>
</template>

<script>
import { mapState } from "vuex";
import { SECTION_HANDLES } from "src/constants";
import AboutPage from "@/templates/components/About/About";

export default {
    name: "About",
    components: {
        AboutPage
    },
    metaInfo() {
        return {
            title: this.entry.seo ? this.entry.seo.title : null
        };
    },
    props: {
        entry: Object
    },
    data: () => ({
        SECTION_HANDLES
    }),
    computed: {
        ...mapState({
            audio: state => state.global.settings.audio
        })
    },
    mounted() {
        this.$nextTick(() => {
            this.setAudio();
            this.displayHeader();
        });
    },
    watch: {
        audio() {
            this.setAudio();
        }
    },
    methods: {
        ////////////////////////////////
        //       START AUDIO
        ////////////////////////////////
        setAudio() {
            this.audio && this.audio.url ? this.$store.commit("global/updateUpComingTrack", this.audio.url) : null;
        },

        ////////////////////////////////
        //       END AUDIO
        ////////////////////////////////

        ////////////////////////////////
        //       START HEADER ANIMATION MANAGEMENT
        ////////////////////////////////
        displayHeader() {
            this.$store.commit("global/toggleHeaderAnimation", true);
        },
        ////////////////////////////////
        //       END HEADER ANIMATION MANAGEM
        ////////////////////////////////

    }
};
</script>

<style lang="scss">
.p-about {
    background: var(--color-dark);
    min-height: 100vh;
    color: var(--color-light);
}
</style>
