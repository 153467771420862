<template>
    <ul class="c-stories-article-illustrated-main-img-side-corners">
        <li v-for="index in 2" :key="index" class="c-stories-article-illustrated-main-img-side-corners__corner">
            <icon icon="corner" />
        </li>
    </ul>
</template>

<script>
import icon from "@/templates/objects/Icon";
export default {
    components: {
        icon
    }
};
</script>

<style lang="scss" scoped>
.c-stories-article-illustrated-main-img-side-corners {
    @include reset-list;
    position: static;
    display: none;
    @media #{md("xs")} {
        display: flex;
    }

    &__corner {
        position: absolute;
        width: 6.7rem;
        aspect-ratio: 1/1;
        &::v-deep svg {
            width: 6.7rem;
            height: 6.7rem;
            fill: var(--color-light);
        }

        &:nth-child(1) {
            top: 0;
            right: 0;
        }
        &:nth-child(2) {
            @include rotate(180deg);
            bottom: 0;
            left: 0;
        }
    }
}
</style>
