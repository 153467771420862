<template>
    <i :class="className">
        <svg :class="`svg-${icon}`">
            <title v-if="title">{{ title }}</title>
            <use v-bind:xlink:href="path"></use>
        </svg>
    </i>
</template>

<script>
export default {
    name: "Icon",
    props: {
        icon: {
            type: String,
            required: true
        },
        title: {
            type: String,
            default: null
        },
        size: {
            type: String,
            default: null
        }
    },
    computed: {
        path() {
            return `/dist/svg/sprite.svg#svg-${this.icon}`;
        },
        className() {
            let classname = `o-icon -${this.icon}`;

            if (this.size) {
                classname += ` -${this.size}`;
            }
            return classname;
        }
    }
};
</script>

<style lang="scss">
.o-icon {
    display: inline-block;
    vertical-align: middle;

    svg {
        display: block;
        width: var(--svg-width, 1em);
        height: calc(var(--svg-width, 1em) * var(--svg-ratio, 1));
        fill: currentColor;
    }
}

/*----------  SVG Sizes  ----------*/

.svg-arrow-right {
    --svg-width: 6rem;
    --svg-ratio: 13/60;
}
</style>
