<template>
    <div class="c-about">
        <about-scene />
        <shared-page
            v-if="entry && entry.paragraphs"
            :cta-scroll-trigger="`c-about-content-list`"
            :title="entry.heading"
        >
            <div class="c-about__inner">
                <about-content :paragraphs="entry.paragraphs" />
            </div>
        </shared-page>
    </div>
</template>

<script>
import { gsap } from "gsap/all";
import SharedPage from "@/templates/components/Shared/SharedPage/SharedPage";
import AboutScene from "@/templates/components/About/AboutScene/AboutScene";

import AboutContent from "./AboutContent/AboutContent";
import SharedList from "components/Shared/SharedList/SharedList";

export default {
    components: {
        SharedPage,
        AboutScene,
        SharedList,
        AboutContent
    },
    props: {
        entry: {
            type: Object,
            required: false,
            default: null
        }
    },
    data() {
        return {
            timelines: {
                introTimeline: null
            }
        };
    },

    mounted() {
        this.$nextTick(() => {
            this.setInterUntilClassIsSet();
        });
    },
    beforeDestroy() {
        this.destroyTimelines();
    },

    methods: {
        //======= START FIND SPECIFIC CLASS BEFORE STARTING =======//

        setInterUntilClassIsSet() {
            const findClassInterval = setInterval(() => {
                this.isClassExist("c-stories-title") ? (this.initTimeline(), clearInterval(findClassInterval)) : null;
            }, 200);
        },
        isClassExist(nameOfTheClass) {
            // true/false is easier to read even on a ternary, please don't judge too fast :D
            return document.getElementsByClassName(nameOfTheClass).length ? true : false;
        },

        //======= END FIND SPECIFIC CLASS BEFORE STARTING =======//

        initTimeline() {
            this.timelines.introTimeline = gsap.timeline({ paused: true });
            this.timelines.introTimeline.to(".c-stories-title", {
                y: 0,
                ease: "expo.inOut",
                duration: 1
            });

            this.timelines.introTimeline.play();
        },
        ////////////////////////////////
        //       END ONMOUT ANIMATION
        ////////////////////////////////

        ////////////////////////////////
        //       START DESTROY TIMELINES
        ////////////////////////////////
        destroyTimelines() {
            this.timelineKiller("introTimeline");
        },

        timelineKiller(name) {
            this.timelines[name] ? (this.timelines[name].kill(), (this.timelines[name] = null)) : null;
        }
        ////////////////////////////////
        //       END DESTROY TIMELINES
        ////////////////////////////////
    }
};
</script>

<style lang="scss" scoped>
.c-about {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;

    .c-stories-title {
        transform: translateY(0);
    }

    &__inner {
        @include mainContentWidth;
        display: flex;
        flex-direction: column;
        margin-bottom: 18rem;
        @media #{md('lg')} {
            margin-bottom: 25rem;
        }
    }
}
</style>
