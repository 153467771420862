<template>
    <div class="c-neighbourhood-story-article-audio">
        <div class="c-neighbourhood-story-article-audio__inner grid">
            <stories-article-audio-player v-if="audioSources.length" :loop="false" :sources="audioSources" />
        </div>
        <stories-article-audio-line />
    </div>
</template>

<script>
import StoriesArticleAudioLine from "@/templates/components/Stories/StoriesArticle/StoriesArticleAudio/StoriesArticleAudioLine";
import StoriesArticleAudioPlayer from "@/templates/components/Stories/StoriesArticle/StoriesArticleAudio/StoriesArticleAudioPlayer/StoriesArticleAudioPlayer";
export default {
    components: {
        StoriesArticleAudioLine,
        StoriesArticleAudioPlayer
    },
    props: {
        isScrollDisabled: {
            type: Boolean,
            required: false,
            default: false
        },
        audio: {
            type: Object,
            required: false,
            default: null
        }
    },
    data() {
        return {
            isDirectionUp: false,
            delta: {
                current: -1,
                old: 0
            }
        };
    },
    computed: {
        audioSources() {
            // it not good practice. If there is bugs so replace with proper code.
            return [this.audio.url];
        }
    },
    mounted() {
        window.addEventListener("wheel", this.mouseWheelEvent);
    },
    beforeDestroy() {
        window.removeEventListener("wheel", this.mouseWheelEvent);
    },
    methods: {
        ////////////////////////////////
        //       START SCROLLED
        ////////////////////////////////
        mouseWheelEvent(val) {
            this.isScrollDisabled ? this.updateDirection(val.deltaY) : null;
        },

        ////////////////////////////////
        //       END SCROLLED
        ////////////////////////////////
        ////////////////////////////////
        //       START UPDATE DELTA
        ////////////////////////////////

        // The logic is really similar that the article one except that on on 1 scroll it goes down right away
        updateDirection(newDelta) {
            this.isDirectionUp = this.whichDirection(newDelta);
            this.updateDelta(newDelta);
            this.updateActiveStep();
        },
        whichDirection(newDelta) {
            return this.delta.current - newDelta < 0 ? false : true;
        },
        updateDelta(newDelta) {
            this.delta.old = newDelta;
        },
        ////////////////////////////////
        //       END UPDATE DELTA
        ////////////////////////////////
        ////////////////////////////////
        //       START DO SOMETHING ON SCROLL
        ////////////////////////////////
        updateActiveStep() {
            this.isDirectionUp ? null : this.goNextStep();
        },

        goNextStep() {
            this.goToNextSection();
        },
        goToNextSection() {
            this.$emit("goToNextSection");
        }
        ////////////////////////////////
        //       END DO SOMETHING ON SCROLL
        ////////////////////////////////
    }
};
</script>

<style lang="scss" scoped>
.c-neighbourhood-story-article-audio {
    // margin-bottom: var(--grid-gutter-quadruple);
    display: flex;
    flex: 1;
    flex-direction: column;
    &__inner {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        gap: var(--grid-gutter);
    }
}
</style>
