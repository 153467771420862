<template>
    <div class="c-neighbourhood-nav-btn-map">
        <div class="c-neighbourhood-nav-btn-map__rotor">
            <div class="c-neighbourhood-nav-btn-map__rotor__inner">
                <neighbourhood-nav-btn-map-circle />
            </div>
        </div>
    </div>
</template>

<script>
import NeighbourhoodNavBtnMapCircle from "@/templates/components/Neighbourhood/NeighbourhoodNav/NeighbourhoodNavBtnMap/NeighbourhoodNavBtnMapCircle/NeighbourhoodNavBtnMapCircle";
export default {
    components: {
        NeighbourhoodNavBtnMapCircle
    }
};
</script>

<style lang="scss" scoped>
.c-neighbourhood-nav-btn-map {
    --shadow-intensity: 0;
    --opacity-map-background: 0;
    @include buttonBorder;
    @include radius(50%);
    display: flex;
    width: 6rem;
    height: 6rem;
    aspect-ratio: 1 / 1;

    @media #{md('xs')} {
        width: 8rem;
        height: 8rem;
    }
    .c-neighbourhood-nav-btn__button--focus &,
    .c-neighbourhood-nav-btn:hover & {
        --shadow-intensity: 0.6;
    }

    &__rotor {
        @include circleBtnHoverShadowAnimation();
        @include radius(50%);
        @include box-shadow(
            $intensity: var(--shadow-intensity),
            $color: $color-light
        ); // For some reason variables didn't works
        display: flex;
        flex: 1;
        position: relative;
        .c-nav--light & {
            @include box-shadow(
                $intensity: var(--shadow-intensity),
                $color: $color-purple-dark
            ); // For some reason variables didn't works
        }
        &__inner {
            display: flex;
            justify-content: center;
            align-content: center;
            align-items: center;
            flex: 1;

            &:after {
                @include radius(50%);
                @include buttonBorder;
                @include fullWidthFixed(absolute);
                @include transition(1s linear background-color);
                content: " ";
                opacity: var(--opacity-header-background);
                background-color: var(--background-color-header);
                z-index: -1;
            }
        }
    }
}
</style>
