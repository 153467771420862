<template>
    <div class="c-enter-tips">
        <div class="c-enter-tips__inner">
            <!-- block with line and stuff -->
            <shared-tips v-if="!isErrorPage" :tips="tips" :is-vertical="false" />
            <!-- audio -->
            <enter-tips-audio :is-error-page="isErrorPage" @clicked="clicked" />
            <!-- go without audio -->
        </div>
    </div>
</template>

<script>
import EnterTipsAudio from "@/templates/components/Enter/EnterTips/EnterTipsAudio/EnterTipsAudio";
import SharedTips from "@/templates/components/Shared/SharedTips/SharedTips";
export default {
    components: {
        EnterTipsAudio,
        SharedTips
    },
    props: {
        isErrorPage: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            tips: [
                {
                    icon: "navigation",
                    copy: this.$t("neigbourhood.tips.navigation")
                },
                {
                    icon: "stars",
                    copy: this.$t("neigbourhood.tips.discover")
                }
            ]
        };
    },
    methods: {
        clicked(bool) {
            this.$emit("clicked", bool);
        }
    }
};
</script>

<style lang="scss" scoped>
.c-enter-tips {
    grid-row: 3/4;
    justify-items: center;
    @media #{md("xs")} {
        grid-row: 4/6;
    }
    &__inner {
        display: flex;
        flex-direction: column;
        flex: 1;
        height: 100%;
        gap: var(--grid-gutter-half);
        .c-shared-tips {
            flex: 1; // only needed in the enter page.
        }
        @media #{md("xs")} {
            gap: var(--grid-gutter);
        }
    }
}
</style>
