var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.tag,{tag:"tag",staticClass:"c-shared-explore",class:{
        'c-shared-explore--btn': _vm.tag === 'button',
        'c-shared-explore--label': _vm.isLabel,
        'c-shared-explore--visible': _vm.isVisible,
        'c-shared-explore--small': _vm.isSmall,
        'c-shared-explore--focus': _vm.isFocus,
        'c-shared-explore--parallax': _vm.parallaxPosition,
        'c-shared-explore--disabled': _vm.isDisabled
    },style:(("--posX: " + _vm.parallaxPositionX + "px; --posY: " + _vm.parallaxPositionY + "px; --backgroundLabel: " + _vm.backgroundLabel)),attrs:{"disabled":_vm.isDisabled || !_vm.isVisible},on:{"click":_vm.userClicked,"mouseover":function($event){return _vm.isMouseOver(true)},"mouseleave":function($event){return _vm.isMouseOver(false)},"focusin":function($event){return _vm.isUserFocus(true)},"focusout":function($event){return _vm.isUserFocus(false)}}},[_c('div',{staticClass:"c-shared-explore__circle"},[_c('shared-explore-label',{attrs:{"title":_vm.title,"copy":_vm.copy,"isVisible":_vm.isExploreVisible,"is-label":_vm.isLabel,"is-small":_vm.isSmall}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }