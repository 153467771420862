<template>
    <div class="c-enter-main">
        <div class="c-enter-main__inner | grid">
            <!-- title -->
            <enter-titles :is-error-page="isErrorPage" />
            <!-- button center -->
            <enter-cta :is-error-page="isErrorPage" @clicked="clicked" />

            <!-- tips -->
            <enter-tips :is-error-page="isErrorPage" @clicked="clicked" />
        </div>
    </div>
</template>

<script>
import EnterCta from "@/templates/components/Enter/EnterCta/EnterCta";
import EnterTips from "@/templates/components/Enter/EnterTips/EnterTips";
import EnterTitles from "@/templates/components/Enter/EnterTitles/EnterTitles";
export default {
    components: {
        EnterCta,
        EnterTips,
        EnterTitles
    },

    props: {
        isErrorPage: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    methods: {
        clicked(bool) {
            this.$emit("clicked", bool);
        }
    }
};
</script>

<style lang="scss" scoped>
.c-enter-main {
    @include transform(translateY(20%));
    display: flex;
    justify-content: center;
    align-content: center;
    flex: 1;
    z-index: 2;
    height: 100%;
    &__inner {
        display: grid;
        gap: var(--grid-gutter-double);
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(3, 1fr);
        @media #{md("xs")} {
            grid-template-rows: repeat(5, 1fr);
            gap: var(--grid-gutter);
        }
    }
}
</style>
