import { render, staticRenderFns } from "./StoriesMore.vue?vue&type=template&id=2e522be5&scoped=true&"
import script from "./StoriesMore.vue?vue&type=script&lang=js&"
export * from "./StoriesMore.vue?vue&type=script&lang=js&"
import style0 from "./StoriesMore.vue?vue&type=style&index=0&id=2e522be5&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e522be5",
  null
  
)

export default component.exports