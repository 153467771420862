import { fetchApi } from 'src/graphql/config'
import { parseData } from 'src/store/parse'
import queries from 'src/graphql/queries'

/*
 * Entries
 */

// State
const state = {
    entries: [],
}

// Getters
const getters = {
    getEntryById:        state => id => state.entries.find(entry => entry.id === id),
    getEntryByHandle:    state => section => state.entries.find(entry => entry.section === section),
    getEntryBySlug:      state => (slug, handle) => state.entries.find(p => p.slug === slug && p.section === handle),
    getEntryIndexBySlug: state => slug => state.entries.findIndex(entry => entry.slug === slug),
    getEntryIndexById:   state => id => state.entries.findIndex(entry => entry.id === id),
    getEntriesCount:      state => state.entries.length,
}

// Actions
const actions = {
    loadSingle(store, handle) {

        return new Promise((resolve, reject) => {

            // Start load
            store.dispatch('loader/startLoad', null, {root: true});

            // Search if entry already exists
            let entry = store.getters.getEntryByHandle(handle)

            // If entry already exists, resolve with the entry value
            if (typeof entry !== 'undefined') {

                resolve(entry)

                // End load
                store.dispatch('loader/endLoad', null, {root: true});

            // If entry doesn't exist, load entry
            } else {

                fetchApi(queries[handle])
                    .then(r => {

                        // Exit on error
                        if (!r || !r.entry) {
                            reject({
                                code: 404,
                                message: 'Entry not found'
                            })
                            store.dispatch('loader/endLoad', null, {root: true});

                        } else {

                            // Flatten entry and add other data to object
                            let { entry, ...data} = r  // eslint-disable-line
                            data = { ...r.entry, ...data }
                            data = parseData(handle, data)

                            store.commit('addEntry', data)
                            resolve(data)
                            store.dispatch('loader/endLoad', null, {root: true});

                        }
                    })

            }

        })
    },
    loadEntry(store, {handle, slug}) {

        return new Promise((resolve, reject) => {

            // Start load
            store.dispatch('loader/startLoad', null, {root: true});

            // Search if entry already exists
            let entry = store.getters.getEntryBySlug(slug, handle)

            // If entry already exists, resolve with the entry value
            if(typeof entry !== 'undefined' && entry.fullyFetched) {
                resolve(entry)

                // End load
                store.dispatch('loader/endLoad', null, {root: true});

            // If entry doesn't exist, load entry
            } else {

                fetchApi(queries[handle](slug))

                    .then(r => {

                        // Exit on error
                        if (!r || !r.entry) {
                            reject({
                                code: 404,
                                message: 'Entry not found'
                            })
                            store.dispatch('loader/endLoad', null, {root: true});

                        } else {

                            // Flatten entry and add other data to object
                            let { entry, ...data} = r  // eslint-disable-line
                            data = { ...r.entry, ...data }
                            data.fullyFetched = true
                            data = parseData(handle, data)

                            //const index = store.getters.getEntryIndexById(data.id)
                            store.commit('addEntry', data)
                            resolve(data)
                            store.dispatch('loader/endLoad', null, {root: true});
                        }
                    })

            }

        })
    },
}

// Mutations
const mutations = {
    addEntry(state, data) {
        state.entries.push(data)
    },
}

// Export module
export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
}
