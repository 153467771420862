<template>
    <div class="stories-articles-stars">
        <div class="stories-articles-stars__inner">
            <shared-stars :quantity="responsiveStarsQty" />
        </div>
    </div>
</template>

<script>
import SharedStars from "@/templates/components/Shared/SharedStars/SharedStars";
export default {
    components: {
        SharedStars
    },
    computed: {
        responsiveStarsQty() {
            return window.innerWidth <= 480 ? 10 : 20;
        }
    }
};
</script>

<style lang="scss" scoped>
.stories-articles-stars {
    @include fullWidthFixed(absolute);
    height: 100%;
    width: 100%;
    display: flex;
    &__inner {
        padding-top: 8.5rem;
        position: relative;
        height: 100%;
        width: 100%;
        flex: 1;
        @media #{md("xs")} {
            padding-top: 8rem;
        }
    }
}
</style>
