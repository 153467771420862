<template>
    <div class="c-shared-page-cta">
        <div class="c-shared-page-cta__inner">
            <enter-titles />
            <!-- button center -->
            <enter-cta @clicked="clicked" />
            <shared-page-cta-line />
        </div>
    </div>
</template>

<script>
import { gsap, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);

import isDevMixin from "@/mixins/isDevMixin";

import EnterCta from "@/templates/components/Enter/EnterCta/EnterCta";
import EnterTitles from "@/templates/components/Enter/EnterTitles/EnterTitles";
import SharedPageCtaLine from "@/templates/components/Shared/SharedPage/SharedPageCta/SharedPageCtaLine";
export default {
    mixins: [isDevMixin],
    components: {
        SharedPageCtaLine,
        EnterCta,
        EnterTitles
    },
    props: {
        ctaScrollTrigger: {
            type: String,
            required: false,
            default: null
        }
    },
    data() {
        return {
            timelines: {
                timelineOnStart: null,
                scrollTrigger: []
            }
        };
    },
    mounted() {
        this.$store.commit("home/toggleAnimationStarted", false);
        this.$store.commit("home/toggleCTAVisible", false);
        this.$store.commit("home/toggleCirclesVisible", false);
        this.initTimelineStart();

        this.setInterUntilClassIsSet();
    },
    beforeDestroy() {
        this.destroyTimelines();
    },

    methods: {
        setInterUntilClassIsSet() {
            const findClassInterval = setInterval(() => {
                this.isClassExist(this.ctaScrollTrigger)
                    ? (this.initScrollTrigger(), clearInterval(findClassInterval))
                    : null;
            }, 100);
        },
        isClassExist(nameOfTheClass) {
            // true/false is easier to read even on a ternary, please don't judge too fast :D
            return document.getElementsByClassName(nameOfTheClass).length ? true : false;
        },
        ////////////////////////////////
        //       START START ANIMATION ON ENTER
        ////////////////////////////////
        initTimelineStart() {
            this.timelines.timelineOnStart = gsap.timeline({
                paused: true
            });

            const durationAnimation = 1.5;
            const durationAnimationUp = 2.5;
            const ease = "expo.inOut";
            // stars
            this.timelines.timelineOnStart
                // title
                .add(() => {
                    this.$store.commit("home/toggleAnimationStarted", true);
                })

                // kicker
                .to(
                    ".c-enter-main-title__kicker",
                    {
                        opacity: 1,
                        y: 0,

                        duration: durationAnimation,
                        ease: ease
                    },
                    "+=0.7"
                )
                // move everything up
                .to(
                    ".c-shared-page-cta",
                    {
                        y: 0,

                        duration: durationAnimationUp,
                        ease: ease
                    },
                    "goUp"
                )

                // background button + circles + line tips
                .to(
                    ".c-enter-cta-btn__inner",
                    {
                        "--scale-background": 1,
                        y: 0,

                        duration: durationAnimationUp,
                        ease: ease
                    },
                    "goUp"
                )
                .to(
                    ".c-shared-tips-line__inner",
                    {
                        "--width": "100%",

                        duration: durationAnimationUp,
                        ease: ease
                    },
                    "goUp"
                )

                // animation copy button
                .add(() => {
                    this.$store.commit("home/toggleCirclesVisible", true);
                }, "goUp")

                .to(
                    ".c-enter-cta-circles",
                    {
                        y: 0,
                        opacity: 1,

                        duration: durationAnimationUp + 1,
                        ease: ease
                    },
                    "goUp"
                )

                // underline
                .to(
                    ".c-enter-cta-btn-center",
                    {
                        "--width-first-line": "100%",

                        duration: 1,
                        ease: ease
                    },
                    "goUp+=1.5"
                )

                .to(
                    ".c-enter-cta-btn-center",
                    {
                        "--width-second-line": "100%",

                        duration: 1,
                        ease: ease
                    },
                    "goUp+=2"
                )

                .to(
                    ".c-enter-cta-btn-loader",
                    {
                        opacity: 1,

                        duration: 1.5,
                        ease: ease
                    },
                    "goUp+=2"
                )

                .add(() => {
                    this.$store.commit("home/toggleCTAVisible", true);
                }, "goUp+=1");
        },

        initScrollTrigger() {
            this.timelines.scrollTrigger = ScrollTrigger.create({
                trigger: `.${this.ctaScrollTrigger}`,
                start: "bottom center",
                markers: this.isMarkerVisible(),
                scrub: 0,
                onEnter: () => {
                    this.timelines.timelineOnStart.play();
                }
            });
        },

        isMarkerVisible() {
            return this.isDevEnv() ? true : false;
        },

        ////////////////////////////////
        //       START DESTROY TIMELINES
        ////////////////////////////////
        destroyTimelines() {
            this.timelineKiller("timelineOnStart");
            this.timelineKiller("scrollTrigger");
        },

        timelineKiller(name) {
            this.timelines[name] ? (this.timelines[name].kill(), (this.timelines[name] = null)) : null;
        },
        ////////////////////////////////
        //       END DESTROY TIMELINES
        ////////////////////////////////

        clicked() {
            this.updateRedirectPageGlobally();
        },
        updateRedirectPageGlobally() {
            this.$store.commit("global/updatePageTransitionOutUrl", "/");
        }
    }
};
</script>

<style lang="scss" scoped>
.c-shared-page-cta {
    @include transform(translateY(20%));
    display: flex;
    justify-content: center;
    align-content: center;
    flex: 1;
    z-index: 2;
    height: 100%;
    max-height: 100vh;

    padding-top: 5rem;

    pointer-events: auto; // allow sharedPage to be click through while the CTA isn't https://mambomambo-team.atlassian.net/browse/CN-589
    @media #{md("md")} {
        padding-top: 13rem;
    }
    &__inner {
        display: grid;
        gap: var(--grid-gutter-double);
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(3, 1fr);
        &::v-deep .c-enter-main-title__constellations {
            padding-bottom: var(--grid-gutter-half);
            @media #{md("xs")} {
                padding-bottom: var(--grid-gutter);
            }
        }

        .c-credit-cta-line {
            grid-row: 3/4;
        }

        @media #{md("xs")} {
            grid-template-rows: repeat(4, 1fr);
            gap: var(--grid-gutter);

            .c-enter-main-title {
                grid-row: 1/1;
            }
            .c-enter-cta {
                grid-row: 2/3;
            }
        }
    }
}
</style>
