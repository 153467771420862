<template>
    <div class="c-navigation-main-list">
        <navigation-list />
    </div>
</template>

<script>
import NavigationList from "@/templates/components/Navigation/NavigationMain/NavigationList";
import { mapState } from "vuex";
import Btn from "@/templates/components/Shared/SharedBtn/SharedBtn";
export default {
    components: {
        Btn,
        NavigationList
    },
    computed: {
        ...mapState({
            nav: state => state.global.navs,
            currentEntryUri: state => state.global.currentEntry.uri,
            transitionOutUrl: state => state.global.transitionOutUrl
        })
    },
    methods: {
        navList(navigtionItems) {
            // Temporary
            return navigtionItems.filter(nav => {
                return nav.url != "/" && nav.url != "/montcalm";
            });
        },
        isActive(url) {
            return this.$route.path === url || this.transitionOutUrl === url;
        },
        clicked(url) {
            this.toggleConstellationHeader(false);
            this.$store.commit("global/updatePageTransitionOutUrl", url);
        },
        toggleConstellationHeader(bool) {
            this.$store.commit("constellations/toggleConstellationHeaderVisibility", bool);
        }
    }
};
</script>

<style lang="scss" scoped>
.c-navigation-main-list {
    display: none;
    flex-direction: row;

    justify-content: flex-end;
    @media #{md('xs')} {
        display: flex;
    }
}
</style>
