<template>
    <div class="o-global-audio">
        <global-audio-player
            v-if="audioSources.length"
            :loop="true"
            :sources="audioSources"
            :is-audio-source-updated="isAudioSourceUpdated"
            @resetAudioSourceIsUpdated="fadeInAudio(false)"
        />
    </div>
</template>

<script>
import { mapState } from "vuex";
import GlobalAudioPlayer from "@/templates/objects/GlobalAudio/GlobalAudioPlayer";
export default {
    components: {
        GlobalAudioPlayer
    },
    data() {
        return {
            audioSources: [],
            isAudioSourceUpdated: false
        };
    },
    computed: {
        ...mapState({
            audio: state => state.global.settings.audio,
            currentTrack: state => state.global.currentTrack,
            isMute: state => state.global.isMute
        })
    },
    watch: {
        audio() {
            this.audioSources.length ? null : this.updateTrack();
        },
        currentTrack(val) {
            val !== this.audioSources[0] ? this.updateTrack() : null;
        }
    },

    methods: {
        updateTrack() {
            this.clearAudio();
            this.updateAudio(this.isDefaultTrack());
        },
        // clear audio
        clearAudio() {
            this.audioSources = [];
        },
        isDefaultTrack() {
            return this.currentTrack ? this.currentTrack : this.audio.url;
        },
        // addAudio

        updateAudio(url) {
            this.addAudio(url);
            !this.currentTrack ? null : this.fadeInAudio(true);
        },
        addAudio(url) {
            this.audioSources.push(url);
        },
        fadeInAudio(bool) {
            this.isAudioSourceUpdated = bool;
        }
    }
};
</script>

<style lang="scss" scoped>
.o-global-audio {
    // FOR DEBUG ONLY
    position: fixed;
    z-index: -999;
    bottom: 0px;
    left: 0px;
    background-color: green;
    // z-index: 999;
    opacity: 0;
}
</style>
