<template>
    <div class="c-enter-cta">
        <div class="c-enter-cta__inner">
            <enter-cta-btn :copy="copyButton()" @clicked="clicked" />
            <enter-cta-circles />
        </div>
    </div>
</template>

<script>
import EnterCtaCircles from "@/templates/components/Enter/EnterCta/EnterCtaCircles/EnterCtaCircles";
import EnterCtaBtn from "@/templates/components/Enter/EnterCta/EnterCtaBtn/EnterCtaBtn";
export default {
    components: {
        EnterCtaCircles,
        EnterCtaBtn
    },
    props: {
        isErrorPage: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    methods: {
        clicked(bool) {
            this.$emit("clicked", bool);
        },
        copyButton() {
            return this.isErrorPage ? this.$t("error.btn") : this.$t("enter.btn");
        }
    }
};
</script>

<style lang="scss" scoped>
.c-enter-cta {
    justify-items: center;
    align-items: center;
    grid-row: 2/3;
    @media #{md("xs")} {
        grid-row: 3/4;
        // @media (max-height: 750px) {
        //     grid-row: 2/3;
        // }
    }

    &__inner {
        display: flex;
        justify-content: center;
    }
}
</style>
