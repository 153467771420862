<template>
    <ul class="c-stories-article-illustrated-progress-line" :style="`--progress: ${progress}%`">
        <!-- line -->
        <li class="c-stories-article-illustrated-progress-line__plain"></li>
        <!-- animated line -->
        <li class="c-stories-article-illustrated-progress-line__progress"></li>
    </ul>
</template>

<script>
export default {
    props: {
        progress: {
            type: Number,
            required: false,
            default: 0
        }
    }
};
</script>

<style lang="scss" scoped>
.c-stories-article-illustrated-progress-line {
    @include reset-list;
    --progress: 0%;
    display: flex;
    flex-direction: column;
    padding-right: var(--grid-gutter-half);
    margin-top: 0.5rem;
    &__plain {
        height: 100%;
        width: 1px;
        background-color: var(--color-purple-light);
        opacity: 0.25;
    }
    &__progress {
        @include lineGradient(180deg);
        @include transition(0.5s ease all);
        position: absolute;
        width: 1px;
        top: 0px;
        right: 0px;
        left: 0px;
        height: var(--progress);

        .c-nbhd-story--light & {
            @include lineGradient(180deg, rgb(28, 12, 46));
        }

        &:after {
            @include box-shadow(1rem, 0.6);

            content: " ";
            width: 0.6rem;
            height: 0.6rem;
            border-radius: 50%;
            background: var(--color-light);
            position: absolute;
            bottom: 0px;
            left: -0.3rem;
        }
    }
}
</style>
