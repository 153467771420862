<template>
    <li class="c-nav-main-audio">
        <button
            :disabled="isButtonDisabled"
            :aria-label="$t('shared.audio.ariaLabel')"
            class="c-nav-main-audio__button"
            @click="clicked"
        >
            <song-wave :is-mute="isMute" />
        </button>
    </li>
</template>

<script>
import { mapState } from "vuex";
import _ from "lodash";
import SongWave from "@/templates/components/SongWave";
export default {
    components: {
        SongWave
    },
    data() {
        return {
            isButtonDisabled: false
        };
    },
    computed: {
        ...mapState({
            isMute: state => state.global.isMute
        })
    },

    methods: {
        clicked() {
            this.disableButton(true);
            this.debouncedFunction();
        },
        debouncedFunction: _.debounce(function() {
            this.toggleAudio();
            this.disableButton(false);
        }, 500),
        toggleAudio() {
            this.$store.commit("global/toggleMute", !this.isMute);
        },
        disableButton(bool) {
            // to tell user no need to click
            this.isButtonDisabled = bool;
        }
    }
};
</script>
<style lang="scss" scoped>
.c-nav-main-audio {
    @include transform(translateY(-5rem));
    opacity: 0;
    grid-column: 1/2;
    &__button {
        @include remove-button-style;
        padding: 3px;
        cursor: pointer;
        display: flex;
        flex-direction: flex-end;
    }
}
</style>
