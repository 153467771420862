<template>
    <div class="c-nbhd-story-more-credit ">
        <!-- -debug -->
        <div class="c-nbhd-story-more-credit__inner">
            <shared-tips
                v-if="isListReady"
                :tips="creditsList"
                :is-vertical="true"
                :is-credit="isCredit"
                :is-line-purple="true"
            />
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

import SharedTips from "@/templates/components/Shared/SharedTips/SharedTips";
export default {
    components: {
        SharedTips
    },
    data() {
        return {
            isCredit: true,
            creditsList: [],
            isListReady: false
        };
    },
    computed: {
        ...mapState({
            currentEntry: state => state.global.currentEntry,
            fullyFetched: state => state.global.currentEntry.fullyFetched
        })
    },
    mounted() {
        this.$nextTick(() => {
            this.fullyFetched ? this.buildCreditList() : null;
        });
    },
    watch: {
        fullyFetched() {
            !this.creditsList.length ? this.buildCreditList() : null;
        }
    },
    methods: {
        buildCreditList() {
            // reset list
            this.resetList();
            // make a loop of all possible credits
            const listOfPossibleCredits = ["writer", "illustrator", "narrator"];
            // loop and
            listOfPossibleCredits.forEach((credit, index) => {
                this.findCredits(credit);
                index + 1 === listOfPossibleCredits.length ? this.closeList() : null;
            });
        },
        findCredits(key) {
            this.currentEntry[key] ? this.pushCreditToArray(key) : null;
        },
        pushCreditToArray(key) {
            this.creditsList.push({
                icon: key,
                copy: this.currentEntry[key]
            });
        },
        closeList() {
            // meaning there its an audio
            this.currentEntry.type === "storyAudio" ? this.addAudioCredit() : null;
            this.isListReady = true;
        },
        addAudioCredit() {
            this.creditsList.push({
                icon: "sound-designer",
                copy: "Millimetrik"
            });
        },
        resetList() {
            this.isListReady = false;
            this.creditsList = [];
        }
    }
};
</script>

<style lang="scss" scoped>
.c-nbhd-story-more-credit {
    @include transition(1s linear all);
    background-color: var(--color-purple-dark);

    &__inner {
        // this si the scroll to trigger, so udpate at your own risk
        padding-top: 220px;

        display: flex;
        flex-direction: column;
        @media #{md("md")} {
            padding-top: 240px;
        }
        @media #{md("lg")} {
            padding-top: 250px;
        }
        @media #{md("xl")} {
            padding-top: 280px;
        }
        &::v-deep .c-shared-tips {
            @include transform(translateY(100px));
        }
    }
}
</style>
