<template>
    <ul
        class="c-neighbourhood-story-article-audio-player"
        :class="{ 'c-neighbourhood-story-article-audio-player--ready': isReady }"
    >
        <stories-article-audio-player-main :progress="progress * 100" :duration="duration" @seek="seeking" />

        <stories-article-audio-player-btn :playing="playing" :is-disabled="!isReady" @clicked="toggleAudio" />
    </ul>
</template>

<script>
import { mapState } from "vuex";
import _ from "lodash";
import VueHowler from "vue-howler";

import StoriesArticleAudioPlayerBtn from "@/templates/components/Stories/StoriesArticle/StoriesArticleAudio/StoriesArticleAudioPlayer/StoriesArticleAudioPlayerBtn";
import StoriesArticleAudioPlayerMain from "@/templates/components/Stories/StoriesArticle/StoriesArticleAudio/StoriesArticleAudioPlayer/StoriesArticleAudioPlayerMain";

export default {
    mixins: [VueHowler],
    components: {
        StoriesArticleAudioPlayerBtn,
        StoriesArticleAudioPlayerMain
    },
    data() {
        return {
            volume: 0,
            volumeRate: 0.1,
            isUserMutedNBHD: false,
            isReady: false
        };
    },
    computed: {
        ...mapState({
            isMute: state => state.global.isMute
        })
    },
    watch: {
        volume() {
            this.updateVolumeHowler();
        },
        isMute(bool) {
            !bool & this.playing ? this.pauseAudio() : false;
        },

        duration(val) {
            val !== 0 ? this.toggleIsReady(true) : null;
        }
    },
    mounted() {
        this.isUserIsMutedGlobally();
    },
    beforeDestroy() {
        this.destroyManager();
    },
    methods: {
        ////////////////////////////////
        //       START ON MOUNT
        ////////////////////////////////
        isUserIsMutedGlobally() {
            this.isMute ? this.toggleIsUserMuted(true) : null;
        },
        ////////////////////////////////
        //       END ON MOUNT
        ////////////////////////////////

        seeking(val) {
            this.setSeek(Number(val));
        },

        toggleAudio(bool) {
            bool ? this.playAudio() : this.pauseAudio();
        },
        playAudio() {
            this.isMute ? this.debouncedPlay(true) : this.debounceMuteNeigbourhood();
        },
        pauseAudio() {
            this.debouncedPlay(false);
        },

        //======= START MUTE NEIGHBOURHOOD AUDIO =======//
        debounceMuteNeigbourhood: _.debounce(function() {
            this.muteNeigbourhoodAudio();
        }, 700),
        muteNeigbourhoodAudio() {
            this.muteGlobally(true);
            const muteTimeout = setTimeout(() => {
                this.debouncedPlay(true);
                clearTimeout(muteTimeout);
            }, 700);
        },
        muteGlobally(bool) {
            this.$store.commit("global/toggleMute", bool);
        },
        toggleIsUserMuted(bool) {
            this.isUserMutedNBHD = bool;
        },

        //======= END MUTE NEIGHBOURHOOD AUDIO =======//

        debouncedPlay: _.debounce(function(bool) {
            this.fadeInOutAudio(bool);
            bool ? this.play() : null;
        }, 100),

        ////////////////////////////////
        //       START FADEIN AUDIO
        ////////////////////////////////
        fadeInOutAudio(bool) {
            this.fadeInOutInterval = setInterval(() => {
                this.debouncedFunction(bool);
            }, 50);
        },
        debouncedFunction: _.throttle(function(bool) {
            this.updateVolume(bool);
        }, 50),
        updateVolume(bool) {
            bool ? this.increaseVolume() : this.decreaseVolume();
        },
        increaseVolume() {
            this.volume < 1 ? (this.volume = this.volume + this.volumeRate) : this.clearIntervalFadeInOut();
        },
        decreaseVolume() {
            this.volume > 0 ? (this.volume = this.volume - this.volumeRate) : this.audioFadeOutInDone();
        },
        audioFadeOutInDone() {
            this.pause();
            this.isUserMutedNBHD ? null : this.muteGlobally(false);
            this.clearIntervalFadeInOut();
        },
        clearIntervalFadeInOut() {
            this.fadeInOutInterval ? clearInterval(this.fadeInOutInterval) : null;
        },

        updateVolumeHowler() {
            this.setVolume(this.volume);
        },

        ////////////////////////////////
        //       END FADEIN AUDIO
        ////////////////////////////////

        ////////////////////////////////
        //       START DISABLE CLICK UNTIL AUDIO IS LOADED
        ////////////////////////////////
        toggleIsReady(bool) {
            this.isReady = bool;
        },
        ////////////////////////////////
        //       END DISABLE CLICK UNTIL AUDIO IS LOADED
        ////////////////////////////////

        ////////////////////////////////
        //       START ON DESTROY
        ////////////////////////////////
        destroyManager() {
            this.isUserMutedNBHD ? this.muteGlobally(true) : this.muteGlobally(false);
        }
        ////////////////////////////////
        //       END ON DESTROY
        ////////////////////////////////
    }
};
</script>

<style lang="scss" scoped>
.c-neighbourhood-story-article-audio-player {
    @include transition(0.3s ease all);
    @include reset-list;
    display: flex;
    flex-direction: column;
    max-width: 35rem;
    justify-content: center;
    flex: 1;
    // cursor: wait;
    pointer-events: none;
    opacity: 0.2;

    &--ready {
        pointer-events: auto;
        opacity: 1;
        // cursor: auto;
    }
}
</style>
