<template>
    <div class="c-shared-tips" :class="{ 'c-shared-tips--vertical': isVertical }">
        <div class="c-shared-tips__inner">
            <shared-tips-credit v-if="isCredit" />
            <ul
                v-if="tips.length"
                class="c-shared-tips__inner__list"
                :class="{ 'c-shared-tips__inner__list--credit': tips[1].isCredit }"
            >
                <shared-tips-manager
                    v-for="(item, index) in tips"
                    :key="index"
                    :item-index="index"
                    :is-credit="isCredit"
                    :is-vertical="isVertical"
                    :tips="item"
                    :is-last="isLast(index)"
                    :is-line-purple="isLinePurple"
                />
            </ul>
        </div>
    </div>
</template>

<script>
import SharedTipsCredit from "@/templates/components/Shared/SharedTips/SharedTipsCredit";
import SharedTipsManager from "@/templates/components/Shared/SharedTips/SharedTipsManager";

export default {
    components: {
        SharedTipsCredit,
        SharedTipsManager
    },
    props: {
        tips: {
            type: Array,
            required: true
        },
        isVertical: {
            type: Boolean,
            required: false,
            default: false
        },
        isCredit: {
            type: Boolean,
            required: false,
            default: false
        },
        isLinePurple: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    data() {
        return {};
    },
    methods: {
        isLast(index) {
            return index + 1 === this.tips.length;
        }
    }
};
</script>

<style lang="scss" scoped>
.c-shared-tips {
    display: flex;
    flex: 0;

    justify-content: center;

    &__inner {
        height: 100%;
        &__list {
            @include reset-list;
            --direction: row;
            display: grid;
            height: 100%;
            grid-auto-flow: var(--direction);
            grid-auto-columns: 1fr;
            gap: var(--grid-gutter);

            .c-shared-tips--vertical & {
                --direction: column;
                gap: var(--grid-gutter);
                @media #{md('xs')} {
                    gap: var(--grid-gutter-double);
                }
            }

            &--credit {
                padding-bottom: var(--grid-gutter);
            }

            @media #{md('xs')} {
                row-gap: 0px;
                gap: var(--grid-gutter-double);
                justify-content: center;
                grid-auto-flow: column;
            }
        }
    }
}
</style>
