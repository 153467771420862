<template>
    <div class="c-shared-tips-line-copy" :class="{ 'c-shared-tips-line-copy--solo-credit': isSoloCredit }">
        <div class="c-shared-tips-line-copy__inner">
            <icon icon="credits" />
        </div>
    </div>
</template>

<script>
import Icon from "@/templates/objects/Icon";
export default {
    components: {
        Icon
    },
    props: {
        isSoloCredit: {
            type: Boolean,
            required: false,
            default: false
        }
    }
};
</script>

<style lang="scss" scoped>
.c-shared-tips-line-copy {
    @include transform(translateY(100px)); // animation
    // position: absolute;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    // left: -2.25rem;
    top: calc(0px - var(--grid-gutter));
    opacity: 0;

    // &--solo-credit {
    //     @include transform(translate(-50%, 0%));
    //     left: 50%;
    // }

    &::v-deep svg {
        --color-light-copy: var(--color-purple-light);
        width: 4.5rem;
        fill: var(--color-light-copy);
        .c-nbhd-story--light & {
            --color-light-copy: #1c0c2e;
        }
    }

    .-debug & {
        opacity: 1;
    }

    &__inner {
    }
}
</style>
