<template>
    <div class="c-navigation-main-right-mobile">
        <button @click="toggleNav" class="c-navigation-main-right-mobile__menu">
            <transition name="slide-fade" mode="out-in">
                <div key="close" v-if="isNavOpen">{{ $t("navigation.mobile.close") }}</div>
                <div key="menu" v-else>{{ $t("navigation.mobile.open") }}</div>
            </transition>
        </button>
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    data() {
        return {
            currentScroll: null
        };
    },
    computed: {
        ...mapState({
            isNavOpen: state => state.global.isNavOpen
        })
    },

    methods: {
        toggleNav() {
            this.$store.commit("global/toggleNavigation");
        }
    }
};
</script>

<style lang="scss" scoped>
.c-navigation-main-right-mobile {
    cursor: pointer;
    pointer-events: all;
    display: flex;
    justify-content: flex-end;
    padding: var(--grid-gutter-half) 0;
    @media #{md('xs')} {
        display: none;
        pointer-events: none;
    }
    &__menu {
        @include remove-button-style;
    }
}
</style>
