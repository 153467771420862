<template>
    <div class="c-shared-list">
        <div class="c-shared-list__inner | grid">
            <ul class="c-shared-list__inner__list" :class="{ 'c-shared-list__inner__list--story': isStory }">
                <shared-list-item
                    v-if="currentSibling && isStory"
                    :item="currentSibling"
                    :story-index="currentItemIndex"
                    :is-current="true"
                    :item-index="0"
                    :is-animation-started="isFirstBtnVisible"
                    :is-story="isStory"
                    :is-rotation-overwritten="isRotationOverwritten(0)"
                    :tag="tag"
                />
                <shared-list-item
                    v-for="(item, index) in filteredList"
                    :story-index="itemIndex(item.id)"
                    :key="index"
                    :item="item"
                    :is-visible="isVisible(index + startingIndex)"
                    :item-index="index + startingIndex"
                    :is-last="index + 1 >= filteredList.length"
                    :is-story="isStory"
                    :is-rotation-overwritten="isRotationOverwritten(index)"
                    :tag="tag"
                />
            </ul>
        </div>
    </div>
</template>

<script>
import _ from "lodash";

import { gsap, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);

import isDevMixin from "@/mixins/isDevMixin";

import SharedListItem from "@/templates/components/Shared/SharedList/SharedListItem/SharedListItem";
export default {
    mixins: [isDevMixin],
    components: {
        SharedListItem
    },
    props: {
        isUserScrolling: {
            type: Boolean,
            required: false,
            default: false
        },

        currentItemIndex: {
            type: Number,
            required: false,
            default: 0
        },
        filteredList: {
            type: Array,
            required: false,
            default: () => []
        },
        siblings: {
            type: Array,
            required: false,
            default: () => []
        },
        currentSibling: {
            type: Object,
            required: false,
            default: null
        },
        isStory: {
            type: Boolean,
            required: false,
            default: true
        },
        scroller: {
            type: String,
            required: false,
            default: ".c-nbhd-story__inner"
        },
        tag: {
            type: String,
            required: false,
            default: "button"
        }
    },
    data() {
        return {
            timelines: {
                activeSibbling: null,
                scrollTrigger: [],
                timelines: []
            },
            current: 0,
            isFirstBtnVisible: false
        };
    },
    watch: {
        isUserScrolling(bool) {
            bool ? this.toggleActiveDotTimeline(bool) : this.revertTimelines();
        }
    },
    computed: {
        startingIndex() {
            return this.isStory ? 1 : 0;
        }
    },

    mounted() {
        this.$nextTick(() => {
            this.initAllTimelines();
            window.addEventListener("resize", this.debounceOnResize);
        });
    },
    beforeDestroy() {
        this.destroyTimelines();
        window.removeEventListener("resize", this.debounceOnResize);
    },
    methods: {
        isVisible(index) {
            // return false;
            return this.current >= index ? true : false;
        },
        ////////////////////////////////
        //       START ADD SIBBLINGS
        ////////////////////////////////
        itemIndex(currentId) {
            return this.findStoryById(currentId);
        },
        findStoryById(currentId) {
            // id from craft is a string
            return this.isStory
                ? this.siblings.findIndex(x => x.id === String(currentId))
                : this.filteredList.findIndex(x => x.id === String(currentId));
        },
        ////////////////////////////////
        //       END ADD SIBBLINGS
        ////////////////////////////////
        ////////////////////////////////
        //       START ANIMATION
        ////////////////////////////////

        initAllTimelines() {
            const loopAnimationsTimeOut = setTimeout(() => {
                // on page landing there might be bug because the page is not fully loaded yet so scrollTrigger set triggers at the wrong page
                // this list should never ever be shows right on load, so it's actually even better like that.
                this.loopAnimations();
                clearTimeout(loopAnimationsTimeOut);
            }, 1000);
            this.initActiveDotTimeline();
        },

        //======= START TIMELINE CURRENT (ACTIVE DOT) =======//

        initActiveDotTimeline() {
            const easeAnimation = "expo.inOut";
            this.timelines.activeSibbling = gsap.timeline({ paused: true });
            this.timelines.activeSibbling
                .to(
                    `.c-shared-list-item--index-0 .c-shared-list-item__btn`,
                    {
                        opacity: 1,
                        y: 1,
                        ease: easeAnimation,
                        duration: 1,
                        delay: 0.5
                    },
                    "activeDot"
                )
                .add(() => {
                    this.toggleIsFirstBtnVisible(true);
                });
        },
        toggleActiveDotTimeline() {
            this.timelines.activeSibbling.play();
        },
        toggleIsFirstBtnVisible(bool) {
            this.isFirstBtnVisible = bool;
        },

        //======= END TIMELINE CURRENT (ACTIVE DOT) =======//

        //======= START TIMELINES =======//
        isMarkerVisible() {
            return this.isDevEnv() ? true : false;
        },

        loopAnimations() {
            this.filteredList.forEach((item, index) => {
                this.addAnimation(index);
            });
        },
        addAnimation(index) {
            this.initTimelines(index);
            // used a loop instead of trigger on each class in purpose, refactor at your own risk
            this.initScrollTrigger(index, this.setPosition(index));
        },
        setPosition(index) {
            return index === 0 ? "top bottom" : "top center";
        },

        initScrollTrigger(index, position) {
            this.timelines.scrollTrigger[index] = ScrollTrigger.create({
                trigger: `.c-shared-list-item--index-${index}`,
                start: position,
                markers: this.isMarkerVisible(),
                scroller: this.scroller,
                end: "-=100",
                scrub: 0,
                onEnter: () => {
                    this.timelines.timelines[index].play();
                }
            });
        },
        initTimelines(index) {
            this.timelines.timelines[index] = gsap.timeline({ paused: true });
            const easeAnimation = "expo.inOut";
            const duractionAnimation = 1;

            this.timelines.timelines[index]
                .to(`.c-shared-list-item--index-${index} .c-shared-list-item-line`, {
                    opacity: 1,
                    "--fade-fill": "20%",
                    ease: easeAnimation,
                    duration: duractionAnimation,
                    delay: index === 0 ? 1 : 0
                })
                .to(
                    `.c-shared-list-item--index-${index + 1} .c-shared-list-item__btn`,
                    {
                        opacity: 1,
                        y: 0,
                        ease: easeAnimation,
                        duration: duractionAnimation
                    },
                    "started-=0.5"
                )

                .to(
                    `.c-shared-list-item--index-${index + 1}`,
                    {
                        opacity: 1,
                        ease: "linear",
                        duration: duractionAnimation - 0.2
                    },
                    "started+=0.2"
                )

                .add(() => {
                    this.current = index + 1;
                }, "started");
            if (this.isClassExist(`c-stories-more-siblings-item-author--index-${index + 1}`)) {
                this.timelines.timelines[index].to(
                    `.c-stories-more-siblings-item-author--index-${index + 1}`,
                    {
                        opacity: 1,
                        y: 0,
                        ease: easeAnimation,
                        duration: duractionAnimation
                    },
                    "started+=0.3"
                );
            }
            if (this.isClassExist(`c-credits-list-item-copy--${index + 1}`)) {
                this.timelines.timelines[index].to(
                    `.c-credits-list-item-copy--${index + 1} p`,
                    {
                        y: 0,
                        opacity: 1,
                        stagger: {
                            each: 0.1,
                            from: "start"
                        },

                        overwrite: "all",
                        ease: easeAnimation,
                        duration: duractionAnimation
                    },
                    "started+=0.5"
                );
            }
            if (this.isClassExist(`c-credits-list-item-img__list--${index + 1}`)) {
                this.timelines.timelines[index].to(
                    `.c-credits-list-item-img__list--${index + 1} .c-credits-list-item-img-item`,
                    {
                        y: 0,
                        opacity: 1,
                        stagger: {
                            each: 0.1,
                            from: "start"
                        },

                        overwrite: "all",
                        ease: easeAnimation,
                        duration: duractionAnimation
                    },
                    "started+=0.5"
                );
            }
        },
        isClassExist(nameOfTheClass) {
            // true/false is easier to read even on a ternary, please don't judge too fast :D
            return document.getElementsByClassName(nameOfTheClass).length ? true : false;
        },
        //======= END TIMELINES =======//

        initScrollAnimation() {
            this.timelines.scrollTriggerReturn = ScrollTrigger.create({
                trigger: ".c-nbhd-story-more-return__inner",
                start: "bottom bottom",
                markers: this.isMarkerVisible(),
                scroller: ".c-nbhd-story__inner",
                // end: "",
                scrub: 0,
                onEnter: () => {
                    this.playTimeline();
                }
            });
        },

        //======= START REVERT TIMELINES =======//
        // user scrolled back to the top
        revertTimelines() {
            // timeout to have the animation reset to 0 while the component is still visible on scroll up
            const revertTimelinesTimeout = setTimeout(() => {
                this.timelines.activeSibbling.seek(0);
                this.timelines.activeSibbling.pause();
                this.toggleIsFirstBtnVisible(false);
                for (let index in this.filteredList) {
                    this.timelines.timelines[index].seek(0);
                    this.timelines.timelines[index].pause();
                    clearTimeout(revertTimelinesTimeout);
                }
                // this.filteredList.forEach((item, index) => {
                //     this.timelines.timelines[index].seek(0);
                //     this.timelines.timelines[index].pause();
                //     clearTimeout(revertTimelinesTimeout);
                // });
            }, 700);
        },

        //======= END REVERT TIMELINES =======//

        ////////////////////////////////
        //       END ANIMATION
        ////////////////////////////////

        ////////////////////////////////
        //       START OVERWRITE LINE DIRECTION
        ////////////////////////////////
        isRotationOverwritten(index) {
            // for the credit page

            return this.isStory
                ? this.isLineRotationOverwrittenInStory()
                : this.isLineRotationOverwrittenInCredits(index);
        },
        isLineRotationOverwrittenInStory() {
            // true false on improve code readability even if useless
            return window.innerWidth <= 480 ? true : false;
        },
        isLineRotationOverwrittenInCredits(index) {
            return index === this.filteredList.length - 2 ? true : false;
        },

        ////////////////////////////////
        //       END OVERWRITE LINE DIRECTION
        ////////////////////////////////

        ////////////////////////////////
        //       START RESIZE
        ////////////////////////////////

        debounceOnResize: _.debounce(function() {
            this.onResize();
        }, 400),

        onResize() {
            // clear all timelines
            this.destroyTimelines();
            // restart all timelines
            this.initAllTimelines();
        },

        ////////////////////////////////
        //       END RESIZE
        ////////////////////////////////

        ////////////////////////////////
        //       START ON DESTROY
        ////////////////////////////////
        destroyTimelines() {
            this.filteredList.forEach((item, index) => {
                this.timelineKiller(index);
                index + 1 === this.filteredList.length ? this.resetAnimationsArrays() : null;
            });
        },
        timelineKiller(index) {
            this.timelines.timelines[index] ? this.destroyTimelineAndScroll(index) : null;
        },
        destroyTimelineAndScroll(index) {
            this.timelines.timelines[index].kill();
            this.timelines.scrollTrigger[index].kill();
        },
        resetAnimationsArrays() {
            this.timelines.timelines = [];
            this.timelines.scrollTrigger = [];
        }

        ////////////////////////////////
        //       END ON DESTROY
        ////////////////////////////////
    }
};
</script>

<style lang="scss" scoped>
.c-shared-list {
    @include transition(1s linear all);
    background-color: var(--color-purple-dark);
    padding: var(--grid-gutter-double) 0 0 0;
    position: relative;
    display: flex;
    justify-content: center;

    &__inner {
        @include reset-list;
        position: relative;

        @include mainContentWidth;

        &__list {
            @include reset-list;
            display: grid;
            grid-auto-flow: row;
            gap: 18rem;
            @media #{md('lg')} {
                gap: 25rem;
            }

            li {
                &:nth-child(even) {
                    justify-content: flex-end;
                }
                &:nth-child(odd) {
                    justify-content: flex-start;
                }
                &:nth-child(1),
                &:nth-last-child(2) {
                    justify-content: center;
                }
                &:nth-last-child(1) {
                    justify-content: center;
                }
            }

            &--story {
                li {
                    justify-content: center;

                    &:nth-child(3) {
                        justify-content: center;
                        @media #{md('xs')} {
                            justify-content: flex-end;
                        }
                    }
                    &:nth-child(2) {
                        justify-content: center;
                        @media #{md('xs')} {
                            justify-content: flex-start;
                        }
                    }
                }
            }
        }
    }
}
</style>
