<template>
    <div class="c-nbhd-tips-panel">
        <shared-tips :tips="tips" :is-vertical="true" />
    </div>
</template>

<script>
import SharedTips from "@/templates/components/Shared/SharedTips/SharedTips";
export default {
    components: {
        SharedTips
    },
    data() {
        return {
            tips: [
                {
                    icon: "navigation",
                    copy: this.$t("neigbourhood.tips.navigation")
                },
                {
                    icon: "stars",
                    copy: this.$t("neigbourhood.tips.discover")
                }
            ]
        };
    }
};
</script>

<style lang="scss" scoped>
.c-nbhd-tips-panel {
    --shadow-intensity: 0;
    @include circleBtnHoverShadowAnimation;
    @include box-shadow($intensity: var(--shadow-intensity));
    @include transform(translateY(100%));
    padding: var(--grid-gutter);
    background: var(--color-purple-dark);

    .-debug & {
        @include transform(translateY(0%));
    }
}
</style>
