<template>
    <header
        class="l-header"
        :class="{
            'l-header--light': isStoryLightMode && district.id,
            'l-header--transparent': transitionOutUrl
        }"
    >
        <div class="l-header__inner | grid">
            <navigation />
        </div>
        <navigation-mobile />
    </header>
</template>

<script>
import { mapState } from "vuex";
import { gsap, CustomEase } from "gsap/all";
import Navigation from "components/Navigation/Navigation";
import NavigationMobile from "@/templates/components/Navigation/NavigationMobile/NavigationMobile";
gsap.registerPlugin(CustomEase);
export default {
    name: "AppHeader",
    components: {
        Navigation,
        NavigationMobile
    },

    data() {
        return {
            timelineHeaderVisible: null,
            isAnimationHeaderFinished: false,
            timelineHeaderConstellation: null,
            timelineHeaderBackground: null
        };
    },
    watch: {
        isHeaderVisible() {
            this.timelineHeaderVisible ? this.playTimelineHeader() : null;
        },
        isConstellationHeaderVisible(bool) {
            this.isAnimationHeaderFinished ? this.playTimeLineConstellations(bool) : null;
        },
        $route() {
            this.playBackgroundOpacityReset();
        }
    },

    computed: {
        ...mapState({
            isConstellationHeaderVisible: state => state.constellations.isConstellationHeaderVisible,
            isHeaderVisible: state => state.global.isHeaderVisible,
            district: state => state.global.currentEntry.district,
            isLightMode: state => state.global.currentEntry.lightMode,
            isLightModeOverwritten: state => state.constellations.isLightModeOverwritten,
            isScrollProgressEased: state => state.constellations.isScrollProgressEased,
            isNavOpen: state => state.global.isNavOpen,
            transitionOutUrl: state => state.global.transitionOutUrl
        }),
        isStoryLightMode() {
            return this.isLightMode && !this.isLightModeOverwritten;
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.setInterUntilClassIsSet();
            this.initTimeLineConstellations();
        });
    },
    beforeDestroy() {
        this.destroyTimelines();
    },

    methods: {
        //======= START FIND SPECIFIC CLASS BEFORE STARTING =======//

        setInterUntilClassIsSet() {
            const findClassInterval = setInterval(() => {
                this.isClassExist("c-navigation-list__link")
                    ? (this.initTimeLineHeader(), clearInterval(findClassInterval))
                    : null;
            }, 200);
        },
        isClassExist(nameOfTheClass) {
            // true/false is easier to read even on a ternary, please don't judge too fast :D
            return document.getElementsByClassName(nameOfTheClass).length ? true : false;
        },

        //======= END FIND SPECIFIC CLASS BEFORE STARTING =======//

        ////////////////////////////////
        //       START ANIMATION ON MOUNT
        ////////////////////////////////
        // init timeline
        initTimeLineHeader() {
            this.timelineHeaderVisible = gsap.timeline({
                paused: true,
                onComplete: () => {
                    this.toggleAnimationisFinished(true);
                    this.startConstelationAnimation();
                }
            });

            const durationAnimation = 1.5;
            const ease = "expo.inOut";
            // line
            this.timelineHeaderVisible
                // title
                .add(() => {
                    this.$store.commit("global/toggleHeaderLogo", true);
                })
                // border
                .to(".l-header", {
                    "--border-width": "50vw",

                    duration: durationAnimation,
                    ease: ease
                })

                // audio
                .to(
                    ".c-nav-main-audio",
                    {
                        y: 0,
                        opacity: 1,

                        duration: durationAnimation,
                        ease: ease
                    },
                    "-=1"
                )
                // menu
                .to(
                    ".c-nav-main-right",
                    {
                        y: 0,
                        opacity: 1,

                        duration: durationAnimation,
                        ease: ease
                    },
                    "nav-=1"
                )
                .to(
                    ".c-nav-main-right .c-navigation-main-list .c-btn__label--copy",
                    {
                        y: 0,

                        duration: durationAnimation,
                        ease: ease
                    },
                    "nav-=1"
                );
            // if user landed did not landed on home so run the animation
            this.isAnimationHeaderToRun();
        },
        isAnimationHeaderToRun() {
            this.isHeaderVisible ? this.playTimelineHeader() : false;
        },
        playTimelineHeader() {
            this.timelineHeaderVisible.play();
        },
        toggleAnimationisFinished(bool) {
            this.isAnimationHeaderFinished = bool;
            this.startConstelationAnimation();
        },
        startConstelationAnimation() {
            this.isConstellationHeaderVisible && this.isAnimationHeaderFinished
                ? this.playTimeLineConstellations(true)
                : null;
        },
        ////////////////////////////////
        //       END ANIMATION ON MOUNT
        ////////////////////////////////

        ////////////////////////////////
        //       START TIMELINE HEADER CONSTELLATIONS
        ////////////////////////////////
        initTimeLineConstellations() {
            this.timelineHeaderConstellation = gsap.timeline({
                paused: true,

                onReverseComplete: () => {
                    this.clearProps();
                }
            });

            const durationAnimation = 1.3;
            const ease = CustomEase.create("custom", "0.49, 0.00, 0.19, 1.00");

            this.timelineHeaderConstellation

                .to(
                    ".c-navigation-main-titles-neighbourhood",
                    {
                        width: "100%",
                        opacity: 1,

                        duration: durationAnimation,
                        ease: ease
                    },
                    "title"
                )
                // add a small space below the nav (mobile only)
                .to(
                    ".c-nav-list",
                    {
                        "--after-height": "0.8rem",

                        duration: durationAnimation,
                        ease: ease
                    },
                    "title"
                )

                .to(
                    ".c-navigation-main-titles-neighbourhood__inner",
                    {
                        x: 0,
                        y: 0,
                        duration: durationAnimation,
                        ease: ease
                    },
                    "title"
                )
                .to(
                    ".l-header",
                    {
                        "--gap-center": "2rem",

                        duration: durationAnimation,
                        ease: ease
                    },
                    "-=1"
                )
                .to(
                    ".c-neighbourhood-nav",
                    {
                        y: 0,
                        opacity: 1,
                        "--pointer-event": "inherit",

                        duration: durationAnimation,
                        ease: ease
                    },
                    "-=1"
                );
        },
        playTimeLineConstellations(bool) {
            bool ? this.timelineHeaderConstellation.play() : this.timelineHeaderConstellation.reverse();
        },
        clearProps() {
            gsap.set(".c-navigation-main-titles-neighbourhood", { clearProps: "all" });
            gsap.set(".c-navigation-main-titles-neighbourhood__inner", { clearProps: "all" });
        },
        ////////////////////////////////
        //       END TIMELINE HEADER CONSTELLATIONS
        ////////////////////////////////

        ////////////////////////////////
        //       START RESET HEADER BACKGROUND OPACITY
        ////////////////////////////////
        // https://mambomambo-team.atlassian.net/jira/software/projects/CN/boards/2?selectedIssue=CN-365

        playBackgroundOpacityReset() {
            gsap.to(".l-header__inner", {
                "--opacity-header-background": 1,
                ease: "none",
                duration: 0,
                delay: 1.3
            });
        },

        ////////////////////////////////
        //       END RESET HEADER BACKGROUND OPACITY
        ////////////////////////////////

        ////////////////////////////////
        //       START DESTROY TIMELINES
        ////////////////////////////////
        destroyTimelines() {
            if (this.timelineHeaderConstellation) {
                this.timelineHeaderConstellation.kill();
                this.timelineHeaderConstellation = null;
            }
            if (this.timelineHeaderBackground) {
                this.timelineHeaderBackground.kill();
                this.timelineHeaderBackground = null;
            }
        }
        ////////////////////////////////
        //       END DESTROY TIMELINES
        ////////////////////////////////
    }
};
</script>

<style lang="scss">
.l-header {
    --border-width: 0vw;
    --gap-center: 0rem;
    --background-color-header: var(--color-purple-dark);

    color: $color-purple-light;
    z-index: 15; // above the threejs canvas and the slider
    position: fixed;
    left: 0;
    top: 0;
    right: 0;

    &--light {
        --background-color-header: var(--color-light);
    }

    /* there is a small gap when there is the constellation menu */
    &:before {
        @include borderNav();
        right: calc(50vw + var(--gap-center));
    }
    &:after {
        @include borderNav();

        left: calc(50vw + var(--gap-center));
    }

    &__inner {
        --opacity-header-background: 1;

        position: static;
        width: 100%;
        &:before {
            @include transition(1s linear background-color);
            @include fullWidthFixed(absolute);
            content: " ";
            opacity: var(--opacity-header-background);
            background-color: var(--background-color-header);

            .p-home & {
                opacity: 0;
            }
        }
    }
}
</style>
