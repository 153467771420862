<template>
    <ul class="c-credits-list-item">
        <shared-list-item-btn
            :is-active="(isCurrent || isBtnHovered) && isAnimationStarted"
            :story-index="storyIndex"
            :item-index="itemIndex"
        />

        <!-- <stories-more-siblings-item-author v-if="!isCurrent" :v-if="story.writer" :author="story.writer" /> -->
        <shared-list-item-title
            v-if="!isCurrent"
            :is-visible="isVisible"
            :title="story.element.heading"
            :is-small="true"
        />

        <credits-list-item-copy v-if="story.element.body" :copy="story.element.body" :item-index="itemIndex" />
        <credits-list-item-img
            v-if="story.element.image.length"
            :images="story.element.image"
            :item-index="itemIndex"
        />
    </ul>
</template>

<script>
import CreditsListItemCopy from "@/templates/components/Credits/CreditsList/CreditsListItem/CreditsListItemCopy";
import CreditsListItemImg from "@/templates/components/Credits/CreditsList/CreditsListItem/CreditsListItemImg/CreditsListItemImg";
import SharedListItemBtn from "@/templates/components/Shared/SharedList/SharedListItem/SharedListItemBtn";

import SharedListItemTitle from "@/templates/components/Shared/SharedList/SharedListItem/SharedListItemTitle";
import StoriesMoreSiblingsItemAuthor from "@/templates/components/Stories/StoriesMore/StoriesMoreSiblings/StoriesMoreSiblingsItem/StoriesMoreSiblingsItemAuthor";
export default {
    components: {
        CreditsListItemCopy,
        CreditsListItemImg,
        SharedListItemBtn,

        SharedListItemTitle,
        StoriesMoreSiblingsItemAuthor
    },
    props: {
        story: {
            type: Object,
            required: true
        },
        isCurrent: {
            type: Boolean,
            required: false,
            default: false
        },
        // for the number in the list
        storyIndex: {
            type: Number,
            required: false,
            default: 0
        },
        // for the line only
        itemIndex: {
            type: Number,
            required: false,
            default: 0
        },
        // specific for the first btn. Ensure its triggered during the animation and not on mount
        isAnimationStarted: {
            type: Boolean,
            required: false,
            default: true
        },
        // animate the text
        isVisible: {
            type: Boolean,
            required: false,
            default: false
        },
        isBtnHovered: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            isRedirecting: false
        };
    },
    computed: {
        secondCredit() {
            return this.story.illustrator ? this.story.illustrator : this.story.narrator;
        }
    },
    methods: {
        ////////////////////////////////
        //       START USER CLICKED
        ////////////////////////////////
        // get story details
        clicked() {
            if (this.isCurrent && this.isRedirecting) return;
            // retdirect
            this.redirectToOtherPage();
        },
        redirectToOtherPage() {
            this.isRedirecting = true;
            this.$store.commit("constellations/userGoToOtherStory", this.story.uri);
        }
        ////////////////////////////////
        //       END USER CLICKED
        ////////////////////////////////
    }
};
</script>
<style lang="scss" scoped>
.c-credits-list-item {
    @include reset-list;

    position: relative;
    display: grid;
    flex-direction: column;
    row-gap: var(--grid-gutter-half);
}
</style>
