<template>
    <li class="c-neighbourhood-story-article-audio-player-main-progress">
        <ul class="c-neighbourhood-story-article-audio-player-main-progress__inner">
            <stories-article-audio-player-main-progress-seeker :max="duration" @seek="seek" />
            <li class="c-neighbourhood-story-article-audio-player-main-progress__inner__progress">
                <icon icon="audio-progress--thin" />
            </li>
            <li
                class="c-neighbourhood-story-article-audio-player-main-progress__inner__progress c-neighbourhood-story-article-audio-player-main-progress__inner__progress--fill"
                :style="`--progress: ${100 - progress}%`"
            >
                <icon icon="audio-progress--thick" />
            </li>
            <stories-article-audio-player-main-progress-indicator :progress="progress" :duration="duration" />
        </ul>
    </li>
</template>

<script>
import Icon from "@/templates/objects/Icon";
import StoriesArticleAudioPlayerMainProgressIndicator from "@/templates/components/Stories/StoriesArticle/StoriesArticleAudio/StoriesArticleAudioPlayer/StoriesArticleAudioPlayerMainProgress/StoriesArticleAudioPlayerMainProgressIndicator";
import StoriesArticleAudioPlayerMainProgressSeeker from "@/templates/components/Stories/StoriesArticle/StoriesArticleAudio/StoriesArticleAudioPlayer/StoriesArticleAudioPlayerMainProgress/StoriesArticleAudioPlayerMainProgressSeeker";
export default {
    components: {
        Icon,
        StoriesArticleAudioPlayerMainProgressIndicator,
        StoriesArticleAudioPlayerMainProgressSeeker
    },
    props: {
        progress: {
            type: Number,
            required: false,
            default: 0
        },
        duration: {
            type: Number,
            required: false,
            default: 0
        }
    },
    methods: {
        seek(value) {
            this.$emit("seek", value);
        }
    }
};
</script>

<style lang="scss" scoped>
.c-neighbourhood-story-article-audio-player-main-progress {
    display: flex;
    flex: 1;
    --progress: 90%;
    --fill-color-audio: var(--color-light);
    .c-nbhd-story--light & {
        --fill-color-audio: var(--color-dark);
    }
    &__inner {
        @include reset-list;
        display: flex;
        flex-direction: column;
        position: relative;

        &__progress {
            &::v-deep svg {
                width: 100%;
                height: 100%;
                fill: var(--fill-color-audio);
            }
            &--fill {
                @include fullWidthFixed(absolute);
                @include transition(all ease 0.2s);
                clip-path: inset(0 var(--progress) 0 0);
            }
        }
    }
}
</style>
