<template>
    <div class="o-global-audio-player">
        <span>Audio: Total duration: {{ duration }} seconds</span> | {{ playing ? "Pause" : "Play" }} | {{ volume }}
    </div>
</template>
<script>
import { mapState } from "vuex";
import _ from "lodash";
import VueHowler from "vue-howler";

export default {
    mixins: [VueHowler],
    props: {
        isAudioSourceUpdated: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            fadeInOutInterval: null,
            volume: 0,
            volumeRate: 0.1,
            volumeInterval: 100
        };
    },
    computed: {
        ...mapState({
            isPlaying: state => state.global.isPlaying,
            currentTrack: state => state.global.currentTrack,
            upComingAudioTrack: state => state.global.upComingAudioTrack,
            isMute: state => state.global.isMute
        })
    },
    watch: {
        isPlaying(bool) {
            this.toggleAudio(bool);
        },
        volume() {
            this.updateVolumeHowler();
        },
        upComingAudioTrack() {
            this.toggleAudio(false);
        },
        isAudioSourceUpdated(bool) {
            if (!bool) return;
            this.$nextTick(() => {
                this.duration > 0 && bool ? this.toggleAudio(true) : null;
            });
        },
        duration(val) {
            val > 0 && this.isAudioSourceUpdated ? this.toggleAudio(true) : null;
        },
        isMute(bool) {
            this.toggleAudio(!bool);
        }
    },
    methods: {
        ////////////////////////////////
        //       START TOGGLE AUDIO
        ////////////////////////////////
        toggleAudio(bool) {
            bool ? this.playAudio() : this.pauseAudio();
        },
        playAudio() {
            if (this.isMute) return;
            this.debouncedPlay(true);
        },
        debouncedPlay: _.debounce(function(bool) {
            this.fadeInOutAudio(bool);
            bool ? this.play() : null;
        }, 100),
        pauseAudio() {
            this.debouncedPlay(false);
        },
        ////////////////////////////////
        //       END TOGGLE AUDIO
        ////////////////////////////////

        ////////////////////////////////
        //       START FADEIN AUDIO
        ////////////////////////////////
        fadeInOutAudio(bool) {
            this.fadeInOutInterval = setInterval(() => {
                this.debouncedFunction(bool);
            }, 50);
        },
        debouncedFunction: _.throttle(function(bool) {
            this.updateVolume(bool);
        }, 50),
        updateVolume(bool) {
            bool ? this.increaseVolume() : this.decreaseVolume();
        },
        increaseVolume() {
            this.volume < 1 ? (this.volume = this.volume + this.volumeRate) : this.clearIntervalFadeInOut();
        },
        decreaseVolume() {
            this.volume > 0 ? (this.volume = this.volume - this.volumeRate) : this.audioFadeOutInDone();
        },
        audioFadeOutInDone() {
            this.pause();
            this.clearIntervalFadeInOut();
            this.isTrackToUpdate() ? (this.updateCurrentTrack(), this.resetAudioSourceIsUpdated()) : null;
        },
        clearIntervalFadeInOut() {
            this.fadeInOutInterval ? clearInterval(this.fadeInOutInterval) : null;
        },

        updateVolumeHowler() {
            this.setVolume(this.volume);
        },

        ////////////////////////////////
        //       END FADEIN AUDIO
        ////////////////////////////////

        ////////////////////////////////
        //       START SET TRACK
        ////////////////////////////////

        isTrackToUpdate() {
            return this.currentTrack !== this.upComingAudioTrack;
        },
        updateCurrentTrack() {
            this.$store.commit("global/updateCurrentTrack", this.upComingAudioTrack);
        },
        resetAudioSourceIsUpdated() {
            this.$emit("resetAudioSourceIsUpdated");
        }

        ////////////////////////////////
        //       END SET TRACK
        ////////////////////////////////
    }
};
</script>
<style lang="scss" scoped>
.o-global-audio-player {
}
</style>
