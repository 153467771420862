import { SECTION_HANDLES } from 'src/constants'
import { decodeHTMLCharCode } from 'src/utils'

/*
 * Data parser
 */

// Utilities
const arrayHasItem = array => {
    return typeof array !== 'undefined' && array.length > 0
}

export const parseIcon = icon => {
    return icon && icon.name ? icon.name.replace('svg-', '') : null
}

// Parse method
export const parseData = (type, data) => {

    // Standard single asset image
    if (data.image)
        data.image = arrayHasItem(data.image) ? data.image[0] : null

    // Decode special chars in SEO title
    if (data.seo && data.seo.title)
        data.seo.title = decodeHTMLCharCode(data.seo.title)

    switch(type) {
        case(SECTION_HANDLES.HOME): {

            // DO HOMEPAGE PARSE HERE

            break
        }

        // CONSTELLATIONS
        case(SECTION_HANDLES.CONSTELLATIONS): {

            if (data.audio)
                data.audio = arrayHasItem(data.audio) ? data.audio[0] : null

            if (data.model)
                data.model = arrayHasItem(data.model) ? data.model[0] : null

            if (data.district)
                data.district = arrayHasItem(data.district) ? data.district[0] : null

            // Remove unused content to keep a clean store
            if (data.type == 'storyText' && data.template == 'side') {
                delete data.body
            } else if (data.type == 'storyText' && data.template == 'overlap') {
                delete data.sequences
                delete data.textOnLeft
            }


            break;
        }
    }

    return data
}
