<template>
    <div class="c-shared-map-icon">
        <svg width="60" height="60" viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg">
            <path
                class="assets-nbhd-map-svg-dots"
                d="M1.60941 18.34C2.13961 18.34 2.56941 17.9102 2.56941 17.38C2.56941 16.8499 2.13961 16.42 1.60941 16.42C1.07922 16.42 0.649414 16.8499 0.649414 17.38C0.649414 17.9102 1.07922 18.34 1.60941 18.34Z"
                fill="#FCEFEF"
            />
            <path
                class="assets-nbhd-map-svg-dots"
                d="M39.46 5.43001C39.9902 5.43001 40.42 5.0002 40.42 4.47001C40.42 3.93982 39.9902 3.51001 39.46 3.51001C38.9298 3.51001 38.5 3.93982 38.5 4.47001C38.5 5.0002 38.9298 5.43001 39.46 5.43001Z"
                fill="#FCEFEF"
            />
            <path
                class="assets-nbhd-map-svg-dots"
                d="M57.2198 30.69C57.75 30.69 58.1798 30.2602 58.1798 29.73C58.1798 29.1998 57.75 28.77 57.2198 28.77C56.6896 28.77 56.2598 29.1998 56.2598 29.73C56.2598 30.2602 56.6896 30.69 57.2198 30.69Z"
                fill="#FCEFEF"
            />
            <path
                class="assets-nbhd-map-svg-dots"
                d="M11.71 31.0001C12.2402 31.0001 12.67 30.5703 12.67 30.0401C12.67 29.5099 12.2402 29.0801 11.71 29.0801C11.1798 29.0801 10.75 29.5099 10.75 30.0401C10.75 30.5703 11.1798 31.0001 11.71 31.0001Z"
                fill="#FCEFEF"
            />
            <path
                class="assets-nbhd-map-svg-dots"
                d="M30.0498 12.71C30.58 12.71 31.0098 12.2802 31.0098 11.75C31.0098 11.2198 30.58 10.79 30.0498 10.79C29.5196 10.79 29.0898 11.2198 29.0898 11.75C29.0898 12.2802 29.5196 12.71 30.0498 12.71Z"
                fill="#FCEFEF"
            />
            <path
                class="assets-nbhd-map-svg-dots"
                d="M55.2998 20.32C55.83 20.32 56.2598 19.8902 56.2598 19.36C56.2598 18.8298 55.83 18.4 55.2998 18.4C54.7696 18.4 54.3398 18.8298 54.3398 19.36C54.3398 19.8902 54.7696 20.32 55.2998 20.32Z"
                fill="#FCEFEF"
            />
            <path
                class="assets-nbhd-map-svg-dots"
                d="M42.2696 18.44C42.7998 18.44 43.2296 18.0102 43.2296 17.48C43.2296 16.9498 42.7998 16.52 42.2696 16.52C41.7394 16.52 41.3096 16.9498 41.3096 17.48C41.3096 18.0102 41.7394 18.44 42.2696 18.44Z"
                fill="#FCEFEF"
            />
            <path
                class="assets-nbhd-map-svg-dots"
                d="M22.6094 39.2801C23.1396 39.2801 23.5694 38.8503 23.5694 38.3201C23.5694 37.7899 23.1396 37.3601 22.6094 37.3601C22.0792 37.3601 21.6494 37.7899 21.6494 38.3201C21.6494 38.8503 22.0792 39.2801 22.6094 39.2801Z"
                fill="#FCEFEF"
            />
            <path
                class="assets-nbhd-map-svg-dots"
                d="M19.25 18.34C19.7802 18.34 20.21 17.9102 20.21 17.38C20.21 16.8499 19.7802 16.42 19.25 16.42C18.7198 16.42 18.29 16.8499 18.29 17.38C18.29 17.9102 18.7198 18.34 19.25 18.34Z"
                fill="#FCEFEF"
            />
            <path
                class="assets-nbhd-map-svg-dots"
                d="M30.0498 48.3699C30.58 48.3699 31.0098 47.9401 31.0098 47.41C31.0098 46.8798 30.58 46.45 30.0498 46.45C29.5196 46.45 29.0898 46.8798 29.0898 47.41C29.0898 47.9401 29.5196 48.3699 30.0498 48.3699Z"
                fill="#FCEFEF"
            />
            <path
                class="assets-nbhd-map-svg-dots"
                d="M46.3399 38.7801C46.8701 38.7801 47.2999 38.3503 47.2999 37.8201C47.2999 37.2899 46.8701 36.8601 46.3399 36.8601C45.8097 36.8601 45.3799 37.2899 45.3799 37.8201C45.3799 38.3503 45.8097 38.7801 46.3399 38.7801Z"
                fill="#FCEFEF"
            />
            <path
                class="assets-nbhd-map-svg-dots"
                d="M25.5196 58.42C26.0498 58.42 26.4796 57.9902 26.4796 57.46C26.4796 56.9298 26.0498 56.5 25.5196 56.5C24.9894 56.5 24.5596 56.9298 24.5596 57.46C24.5596 57.9902 24.9894 58.42 25.5196 58.42Z"
                fill="#FCEFEF"
            />
            <path
                class="assets-nbhd-map-svg-dots"
                d="M12.6695 11.64C13.4648 11.64 14.1095 10.9953 14.1095 10.2C14.1095 9.40472 13.4648 8.76001 12.6695 8.76001C11.8742 8.76001 11.2295 9.40472 11.2295 10.2C11.2295 10.9953 11.8742 11.64 12.6695 11.64Z"
                fill="#FCEFEF"
            />
            <path
                class="assets-nbhd-map-svg-dots"
                d="M22.6099 2.88C23.4052 2.88 24.0499 2.23529 24.0499 1.44C24.0499 0.64471 23.4052 0 22.6099 0C21.8146 0 21.1699 0.64471 21.1699 1.44C21.1699 2.23529 21.8146 2.88 22.6099 2.88Z"
                fill="#FCEFEF"
            />
            <path
                class="assets-nbhd-map-svg-dots"
                d="M40.8902 30.2101C41.6855 30.2101 42.3302 29.5654 42.3302 28.7701C42.3302 27.9748 41.6855 27.3301 40.8902 27.3301C40.0949 27.3301 39.4502 27.9748 39.4502 28.7701C39.4502 29.5654 40.0949 30.2101 40.8902 30.2101Z"
                fill="#FCEFEF"
            />
            <path
                class="assets-nbhd-map-svg-dots"
                d="M32.9302 24.0001C33.7255 24.0001 34.3702 23.3554 34.3702 22.5601C34.3702 21.7648 33.7255 21.1201 32.9302 21.1201C32.1349 21.1201 31.4902 21.7648 31.4902 22.5601C31.4902 23.3554 32.1349 24.0001 32.9302 24.0001Z"
                fill="#FCEFEF"
            />
            <path
                class="assets-nbhd-map-svg-dots"
                d="M49.2203 11.27C50.0156 11.27 50.6603 10.6253 50.6603 9.83002C50.6603 9.03473 50.0156 8.39001 49.2203 8.39001C48.425 8.39001 47.7803 9.03473 47.7803 9.83002C47.7803 10.6253 48.425 11.27 49.2203 11.27Z"
                fill="#FCEFEF"
            />
            <path
                class="assets-nbhd-map-svg-dots"
                d="M40.3502 52.86C41.1454 52.86 41.7902 52.2153 41.7902 51.42C41.7902 50.6247 41.1454 49.98 40.3502 49.98C39.5549 49.98 38.9102 50.6247 38.9102 51.42C38.9102 52.2153 39.5549 52.86 40.3502 52.86Z"
                fill="#FCEFEF"
            />
            <path
                class="assets-nbhd-map-svg-dots"
                d="M17.8096 48.07C18.3398 48.07 18.7696 47.6402 18.7696 47.11C18.7696 46.5798 18.3398 46.15 17.8096 46.15C17.2794 46.15 16.8496 46.5798 16.8496 47.11C16.8496 47.6402 17.2794 48.07 17.8096 48.07Z"
                fill="#FCEFEF"
            />
            <path
                class="assets-nbhd-map-svg-dots"
                d="M8.09965 50.81C8.62984 50.81 9.05965 50.3802 9.05965 49.85C9.05965 49.3198 8.62984 48.89 8.09965 48.89C7.56946 48.89 7.13965 49.3198 7.13965 49.85C7.13965 50.3802 7.56946 50.81 8.09965 50.81Z"
                fill="#FCEFEF"
            />
            <path
                class="assets-nbhd-map-svg-dots"
                d="M1.44 36.16C2.23529 36.16 2.88 35.5153 2.88 34.72C2.88 33.9247 2.23529 33.28 1.44 33.28C0.64471 33.28 0 33.9247 0 34.72C0 35.5153 0.64471 36.16 1.44 36.16Z"
                fill="#FCEFEF"
            />
            <path
                class="assets-nbhd-map-svg-lines"
                opacity="0.25"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.5803 29.6095L12.5096 10.5923L12.8292 10.6079L11.8585 30.4707L1.48438 17.4799L1.73443 17.2803L11.5803 29.6095Z"
                fill="#FCEFEF"
            />
            <path
                class="assets-nbhd-map-svg-lines"
                opacity="0.25"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M22.4532 0.907959L22.7668 0.972036L19.4126 17.3835L22.704 37.8958L33.2775 22.2803L33.5425 22.4597L22.516 38.7442L19.0874 17.3765L22.4532 0.907959Z"
                fill="#FCEFEF"
            />
            <path
                class="assets-nbhd-map-svg-lines"
                opacity="0.25"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M17.4484 47.0454L1.51538 35.4595L1.70358 35.2007L18.1705 47.1748L8.14278 49.9941L8.05617 49.6861L17.4484 47.0454Z"
                fill="#FCEFEF"
            />
            <path
                class="assets-nbhd-map-svg-lines"
                opacity="0.25"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M39.3681 4.36133L39.5515 4.59873L30.3359 11.7185L42.2332 17.2972L49.1088 9.72916L49.3308 9.93089L42.3064 17.6628L29.7637 11.7815L39.3681 4.36133Z"
                fill="#FCEFEF"
            />
            <path
                class="assets-nbhd-map-svg-lines"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M55.1394 19.1067L55.4604 19.6136L40.6648 28.9828L30.3119 47.5562L29.7878 47.2641L40.215 28.5575L55.1394 19.1067Z"
                fill="#FCEFEF"
            />
            <path
                class="assets-nbhd-map-svg-lines"
                opacity="0.25"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M56.6916 29.6035L56.8875 29.8566L46.4696 37.9217L40.4699 51.5438L25.5799 57.6082L25.4592 57.3119L40.2293 51.2963L46.2095 37.7184L56.6916 29.6035Z"
                fill="#FCEFEF"
            />
        </svg>
    </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.c-shared-map-icon {
    @include nbhdSvgMap;
}
</style>
