<template>
    <div class="c-neighbourhood-labels-item-line" :class="`c-neighbourhood-labels-item-line--${current} `">
        <span
            class="c-neighbourhood-labels-item-line__line"
            :class="`c-neighbourhood-labels-item-line__line--${constellationKey}`"
        />
    </div>
</template>

<script>
export default {
    props: {
        current: {
            type: Number,
            required: false,
            default: 0
        },
        constellationKey: {
            type: Number,
            required: false,
            default: 0
        }
    }
};
</script>

<style lang="scss" scoped>
.c-neighbourhood-labels-item-line {
    @include transition(0.05s linear all); // https://mambomambo-team.atlassian.net/browse/CN-549
    position: absolute;
    left: 20px; // half of the size of the button
    top: 20px; // half of the size of the button

    // line-height: 0.2rem;
    opacity: var(--opacity-line);
    pointer-events: none;
    transform-origin: top left;
    z-index: -1;

    .is-chrome &{
         @include transition(0s linear all);
    }
    &__line {
        --gradient-line: 252, 239, 239;
        --fade-fill: 20%;
        --rotation: 45deg;
        --opacity-line: 0;

        height: 100%;
        opacity: var(--opacity-line);
        position: absolute;
        width: 0%;
        z-index: -1;

        background: var(--gradient-line);
        background: linear-gradient(
            var(--rotation),
            rgba(var(--gradient-line), 0.5) var(--fade-fill),
            rgba(var(--gradient-line), 0) calc(100% - 10px)
        );
        .c-neighbourhood-labels--visible & {
            --opacity-line: 1;
        }
    }
}
</style>
