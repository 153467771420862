<template>
    <div class="c-home-intro-stars">
        <shared-stars :quantity="responsiveStarsQty" />
    </div>
</template>

<script>
import SharedStars from "@/templates/components/Shared/SharedStars/SharedStars";
export default {
    components: {
        SharedStars
    },
    computed: {
        responsiveStarsQty() {
            return window.innerWidth <= 480 ? 15 : 30;
        }
    }
};
</script>

<style lang="scss" scoped>
.c-home-intro-stars {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    opacity: 0;
}
</style>
