<template>
    <li class="c-stories-article-illustrated-main-copy-vert" :style="`--height: ${sequences.length * 100}%`">
        <!-- centered copy -->
        <div class="c-stories-article-illustrated-main-copy-vert__inner">
            <ul
                ref="scrollableMobileStory"
                class="c-stories-article-illustrated-main-copy-vert__inner__list"
                :class="{ 'c-stories-article-illustrated-main-copy-vert__inner__list--disabled': isDisabled }"
            >
                <stories-article-illustrated-main-copy-paragraph
                    v-for="(paragraph, index) in sequences"
                    :key="index"
                    :paragraph="paragraph"
                    :index-paragraph="index"
                    :is-last="isLast(index)"
                />
            </ul>
        </div>
    </li>
</template>

<script>
import isDevMixin from "@/mixins/isDevMixin";

import { gsap, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);

import StoriesArticleIllustratedMainCopyParagraph from "@/templates/components/Stories/StoriesArticle/StoriesArticleIllustrated/StoriesArticleIllustratedMain/StoriesArticleIllustratedMainCopy/StoriesArticleIllustratedMainCopyParagraph";
export default {
    mixins: [isDevMixin],
    components: {
        StoriesArticleIllustratedMainCopyParagraph
    },
    props: {
        position: {
            type: String,
            required: false,
            default: "centered"
        },
        sequences: {
            type: Array,
            required: false,
            default: () => []
        },
        isScrollDisabled: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            isDisabled: true,
            scrollTriggerAnimations: []
        };
    },
    mounted() {
        this.$nextTick(() => {
            this.delayInit();
        });
    },
    beforeDestroy() {
        this.destroyScrollTriggers();
    },
    methods: {
        isLast(index) {
            return index + 1 >= this.sequences.length;
        },

        ////////////////////////////////
        //       START DELAY SCROLL GSAP
        ////////////////////////////////
        delayInit() {
            // wait the end of the animation because scrollTrigger set triggers at the wrong place because of the scale
            const delayInitCopyVert = setTimeout(() => {
                this.initScrollTrigger();
                this.toggleDisableScroll(false);
                clearTimeout(delayInitCopyVert);
            }, 2000);
        },
        toggleDisableScroll(bool) {
            this.isDisabled = bool;
        },
        ////////////////////////////////
        //       END DELAY SCROLL GSAP
        ////////////////////////////////
        ////////////////////////////////
        //       START SCROLL
        ////////////////////////////////

        initScrollTrigger() {
            const sections = gsap.utils.toArray(
                this.$el.querySelectorAll(".c-stories-article-illustrated-main-copy-paragraph")
            );

            sections.forEach((section, index) => {
                let scrollTriggerCopyMobile = gsap.to(section, {
                    scrollTrigger: {
                        trigger: section,
                        start: "top top",
                        end: "bottom",
                        scrub: true,
                        markers: this.isMarkerVisible(),
                        scroller: ".c-stories-article-illustrated-main-copy-vert__inner__list",
                        onUpdate: self => {
                            this.isScrollDisabled
                                ? this.updateProgression(this.calculateProgress(index, self.progress))
                                : null;
                        },
                        onLeave: () => {
                            index + 1 === this.sequences.length ? this.goToNextSection() : null;
                        }
                    }
                });
                this.scrollTriggerAnimations.push(scrollTriggerCopyMobile);
            });
        },
        isMarkerVisible() {
            return this.isDevEnv() ? true : false;
        },

        // LEAVE THIS PART UNTIL JAN 5 2022 in case I need to put it back
        // setBottomTrigger(index) {
        //     return index + 1 === this.sequences.length ? this.setBottomTriggerLastItem() : "bottom";
        // },

        // setBottomTriggerLastItem() {
        //     // UI is different on mobile so the scroll trigger to next page will be different
        //     return this.isUserMobile ? "bottom" : "bottom";
        // },
        // isUserMobile() {
        //     return window.innerWidth <= 480 ? true : false;
        // },

        updateProgression(progress) {
            progress < this.sequences.length - 1
                ? this.$emit("activeStep", progress)
                : this.$emit("activeStep", this.sequences.length - 1);
        },

        calculateProgress(index, progress) {
            return index + progress;
        },

        updateScrollPageProgress(index, progress) {
            const totalPageScroll = this.calculateTotalStoryProgression(index, progress);

            this.emitPageScrollGlobally(totalPageScroll);
        },

        calculateTotalStoryProgression(index, progress) {
            return ((progress * (index + 1)) / (this.sequences.length - 1)) * 100;
        },

        ////////////////////////////////
        //       END SCROLL
        ////////////////////////////////

        ////////////////////////////////
        //       START USER IS AT THE END OF THE SCROLL
        ////////////////////////////////

        goToNextSection() {
            this.timeoutAndReset();
            this.$emit("goToNextSection");
        },
        timeoutAndReset() {
            const resetScrollerTimeOut = setTimeout(() => {
                this.resetScroller();
                clearTimeout(resetScrollerTimeOut);
            }, 500);
        },
        resetScroller() {
            this.$emit("activeStep", 0);
            this.activeStep = 0;
            this.isDirectionUp = false;
            this.$refs.scrollableMobileStory.scrollTop = 0;
        },
        ////////////////////////////////
        //       END USER IS AT THE END OF THE SCROLL
        ////////////////////////////////

        ////////////////////////////////
        //       START ON DESTROY
        ////////////////////////////////
        destroyScrollTriggers() {
            this.loopAllScrollTrigger();
        },
        loopAllScrollTrigger() {
            for (let i in this.scrollTriggerAnimations) {
                this.scrollTriggerAnimations[i].kill();

                i + 1 === this.scrollTriggerAnimations.length ? (this.scrollTriggerAnimations = []) : null;
            }
        },
        ////////////////////////////////
        //       END ON DESTROY
        ////////////////////////////////

        onResize() {
            // this.resetScroller();
        }
    }
};
</script>

<style lang="scss" scoped>
.c-stories-article-illustrated-main-copy-vert {
    @include storiesIllustratedArticleCopy;
    @include articleParagraphFadeIn(180deg, var(--grid-gutter-double), var(--grid-gutter-half));
    &__inner {
        margin-top: calc(0px - var(--grid-gutter-half));

        &__list {
            @include hide-scrollbar;
            --overflow-section: scroll;
            overflow: var(--overflow-section);
            scroll-snap-type: y mandatory;
            &--disabled {
                --overflow-section: hidden;
            }
        }
    }
}
</style>
