<template>
    <div class="c-home-navigation-control-cta-btn">
        <div
            class="c-home-navigation-control-cta-btn__inner"
            :class="{ 'c-home-navigation-control-cta-btn__inner--visible': isButtonVisible }"
        >
            <btn
                :label="$t('home.navigation.cta', { number: numberOfStories })"
                :rounded="true"
                size="small"
                icon-animated="arrow-right"
                :is-parent-handle-states="true"
                tag="button"
                :is-visible="isButtonVisible"
                @clicked="redirectUserWithDelay"
            />
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import { mapGetters } from "vuex";
import Btn from "components/Shared/SharedBtn/SharedBtn";
export default {
    components: {
        Btn
    },
    props: {
        isButtonVisible: {
            type: Boolean,
            required: false,
            default: true
        },
        slug: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            numberOfStories: 0,
            url: "/"
        };
    },
    computed: {
        ...mapGetters({
            getNeighbourhoodBySlug: "global/getNeighbourhoodBySlug"
        }),
        ...mapState({
            isNewVisit: state => state.home.isNewVisit
        })
    },
    watch: {
        isButtonVisible(val) {
            val ? this.getRelatedStoriesCount() : null;
            this.toggleButton(val);
        }
    },
    methods: {
        ////////////////////////////////
        //       START FETCH VALUE IN THE DATABASE
        ////////////////////////////////
        getRelatedStoriesCount() {
            // Get Neighbourhood from initial data
            const neighbourhood = this.getNeighbourhoodBySlug(this.slug);

            if (!neighbourhood) return;

            this.$store.dispatch("home/getRelatedStoriesCount", neighbourhood.id).then(count => {
                this.numberOfStories = count;
                this.url = this.slug;
            });
        },
        resetNumberOfStories() {
            this.numberOfStories = 0;
        },
        toggleButton(bool) {
            this.isButtonVisible = bool;
        },
        ////////////////////////////////
        //       END FETCH VALUE IN THE DATABASE
        ////////////////////////////////

        ////////////////////////////////
        //       START REDIRECT USER
        ////////////////////////////////
        redirectUserWithDelay() {
            // start animation on leave
            // is new visit to avoid some bug when user use tabs
            this.isNewVisit ? null : this.$store.commit("global/updatePageTransitionOutUrl", this.url);
            // redirect user to next page
        }

        ////////////////////////////////
        //       END REDIRECT USER
        ////////////////////////////////
    }
};
</script>

<style lang="scss" scoped>
.c-home-navigation-control-cta-btn {
    display: flex;
    justify-content: center;

    @include transform(
        translate(30px, -50%)
    ); // intro animation. I splited the animation between the 2 components because of some bugs with GSAP
    &__inner {
        @include transform(
            scale(0)
        ); // intro animation. I splited the animation between the 2 components because of some bugs with GSAP
        opacity: 0;
    }
}
</style>
