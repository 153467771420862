<template>
    <span
        class="o-loader"
        ref="loader"
    >
        <h1>Loader</h1>
    </span>
</template>


<script>

import Variables from 'src/mixins/variables';

import gsap from 'gsap';

export default {
    name: 'Loader',
    mixins: [ Variables ],
    data: () => ({
        firstload: true,
    }),
    methods: {
        animateIn(next) {
            gsap.set(this.$refs.loader, {
                zIndex: 500
            })
            gsap.to(this.$refs.loader, .4, {
                opacity: 1,
                ease: 'power2.in',
                onComplete: () => {
                    next()
                    this.animateOut()
                }
            })
        },
        animateOut() {
            gsap.to(this.$refs.loader, .4, {
                opacity: 0,
                onComplete: () => {
                    gsap.set(this.$refs.loader, {
                        clearProps: 'all'
                    })
                }
            })
        },
    },
    created() {
    },
}

</script>

<style lang="scss">

.o-loader {
    z-index: -10;
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    pointer-events: none;
    opacity: 0;
}

</style>
