<template>
    <li
        :id="`neighbourhood-labels-${indexLabel}`"
        class="c-neighbourhood-labels-items"
        :class="`c-neighbourhood-labels-items--${indexLabel}`"
    >
        <div
            class="c-neighbourhood-labels-items__inner"
            @mouseover="isExploreHover(true)"
            @mouseleave="isExploreHover(false)"
            :data-uri="this.label.story.uri"
        >
            <!-- {{ this.isHover.btn }}-{{ this.isHover.explore }} {{ isBtnOrExploreHover }} -->

            <btn
                :label="overwriteSmallButtonNumber(label.constellation.title, label.order)"
                :circle="true"
                typo="serif"
                :is-active="isBtnOrExploreHover"
                :is-parent-handle-states="true"
                :is-touch-enabled="true"
                :icon-label="isLabelSvg(label.constellation.title)"
                :is-mouse-position-needed="true"
                :is-label-selectable="false"
                :aria-label="$t('neigbourhood.labels.ariaLabel', { story: label.constellation.title })"
                @clicked="clicked"
                @touched="touched"
                @mousePosition="updateMousePosition"
            />
            <!-- @isHover="isBtnHover" -->
            <transition name="labels">
                <shared-explore
                    v-if="isBtnOrExploreHover"
                    tag="div"
                    :is-visible="isBtnOrExploreHover"
                    :title="$t('neigbourhood.labels.explore')"
                    :copy="label.story.title"
                    :is-label="true"
                    :parallax-position="parallaxPosition"
                    background-label="28, 12, 46, 1"
                    @clicked="clicked"
                    @touched="touched"
                />
            </transition>
            <!-- @isHover="isExploreHover" -->
            <neighbourhood-labels-item-line
                v-if="label.constellation.id && !label.constellation.isLast"
                :constellation-key="label.constellation.id"
                :current="indexLabel"
            />
        </div>
    </li>
</template>

<script>
import convertorMixin from "@/mixins/convertorMixin.js";
import Btn from "@/templates/components/Shared/SharedBtn/SharedBtn";
import SharedExplore from "@/templates/components/Shared/SharedExplore/SharedExplore";
import NeighbourhoodLabelsItemLine from "@/templates/components/Neighbourhood/NeighbourhoodLabels/NeighbourhoodLabelsItem/NeighbourhoodLabelsItemLine";
export default {
    mixins: [convertorMixin],
    components: {
        Btn,
        SharedExplore,
        NeighbourhoodLabelsItemLine
    },
    props: {
        label: {
            type: Object,
            required: true
        },
        indexLabel: {
            type: Number,
            required: false,
            default: 0
        },
        hasLine: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    data() {
        return {
            isHover: {
                btn: false,
                explore: false
            },
            touchCounter: 0,
            parallaxPosition: {
                offsetX: 0,
                offsetY: 0
            }
        };
    },
    computed: {
        isBtnOrExploreHover() {
            return this.isHover.btn || this.isHover.explore;
        }
    },
    watch: {
        isBtnOrExploreHover(bool) {
            this.updateSpotlightPositionGlobally(bool);
            bool ? null : this.resetTouchCounter();
        }
    },
    methods: {
        // overLabels(bool) {
        //     bool;
        // },
        ////////////////////////////////
        //       START TITLE
        ////////////////////////////////

        //======= START SMALL CIRCLE BUTTON =======//

        overwriteSmallButtonNumber(copy, order) {
            return this.isTitleAStar(copy) ? null : this.convertToRoman(order);
        },
        isTitleAStar(copy) {
            return copy.includes("toile solitaire"); // so far there is only FR so it's fine
        },
        isLabelSvg(copy) {
            return this.isTitleAStar(copy) ? "star" : null;
        },

        //======= END SMALL CIRCLE BUTTON =======//

        // Might be needed for romain automatisation later
        // overwriteCircleButton(copy, order) {
        //     return this.isTitleAStar(copy) ? copy : `${copy} ${this.convertToRoman(order)}`;
        // },

        ////////////////////////////////
        //       END TITLE
        ////////////////////////////////
        ////////////////////////////////
        //       START HOVER
        ////////////////////////////////
        isBtnHover(bool) {
            this.toggleHover(bool, "btn");
        },
        isExploreHover(bool) {
            this.toggleHover(bool, "explore");
        },
        toggleHover(bool, item) {
            this.isHover[item] = bool;
        },
        updateSpotlightPositionGlobally(bool) {
            bool ? this.$store.commit("constellations/UPDATE_POSITION_LIGHT", this.label.position) : null;
            this.$store.commit("constellations/TOGGLE_LIGHT", bool);
        },
        ////////////////////////////////
        //       END HOVER
        ////////////////////////////////
        ////////////////////////////////
        //       START CLICKED
        ////////////////////////////////

        //======= START TOUCH =======//

        touched() {
            this.touchCounter === 0 ? this.updateTouchCounter(1) : this.updateTransitionUrl(this.label.story.uri);
            //  will be helpfull for: https://mambomambo-team.atlassian.net/browse/CN-539
            // this.touchCounter === 0
            // ? (this.updateTouchCounter(1), this.toggleHover(true, "btn"))
            // : this.updateTransitionUrl(this.label.story.uri);
        },
        updateTouchCounter(val) {
            this.touchCounter = val;
        },
        resetTouchCounter() {
            this.touchCounter = 0;
        },
        resetHover() {
            this.toggleHover(false, "btn");
            this.toggleHover(false, "explore");
        },

        //======= END TOUCH =======//

        clicked() {
            this.updateTransitionUrl(this.label.story.uri);
        },

        updateTransitionUrl(url) {
            this.resetTouchCounter();
            // animation zoom out and redirect is managed from the scene to imporve the animations
            this.$store.commit("global/updatePageTransitionOutUrl", url);

            // wait the end of the animation to reset to avoid the label to show up during the transition
            // This does the work as I want to avoid more complexity to each labels, however if it leads to more issues, I think we need to look for if user ended page transition THEN reset it
            const delayReset = setTimeout(() => {
                this.resetHover();
                clearTimeout(delayReset);
            }, 1000);
        },
        ////////////////////////////////
        //       END CLICKED
        ////////////////////////////////

        ////////////////////////////////
        //       START PARALLAX EXPLORE
        ////////////////////////////////
        updateMousePosition(mousePosition) {
            this.parallaxPosition.offsetX = -mousePosition.offsetX / 2;
            this.parallaxPosition.offsetY = -mousePosition.offsetY / 2;
        }
        ////////////////////////////////
        //       END PARALLAX EXPLORE
        ////////////////////////////////
    }
};
</script>

<style lang="scss" scoped>
.c-neighbourhood-labels-items {
    @include transition(0.1s linear all);
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 1;
    .is-chrome & {
        @include transition(0s linear all);
    }
    @media #{md("xs")} {
        @include transition(0.05s linear all);
        .is-chrome & {
            @include transition(0s linear all);
        }
    }
    &:hover {
        z-index: 2;
    }
    &__inner {
        @include transition(1s $out-expo all);
        position: relative;
        opacity: 0;
        .c-neighbourhood-labels--visible & {
            @include transform(scale(1));
            opacity: 1;
        }

        &::v-deep .c-btn {
            @include transition(1s $out-expo all);
            z-index: 2;
            @include transform(scale(0));
            .c-neighbourhood-labels--visible & {
                @include transform(scale(1));
            }
        }
    }
}

.labels-enter-active,
.labels-leave-active {
    // transition: all 0.5s;

    // @include transform(scale(1));
}
.labels-enter, .labels-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
    &::v-deep .c-shared-explore__circle {
        &:before {
            @include transform(scale(0));
        }
    }
}
</style>
