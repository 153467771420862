<template>
    <nav
        class="c-nav"
        :class="{ 'c-nav--light': isStoryLightMode }"
        itemscope
        itemtype="http://schema.org/SiteNavigationElement"
    >
        <navigation-main />
        <neighbourhood-nav />
    </nav>
</template>

<script>
import { mapState } from "vuex";
import NavigationMain from "@/templates/components/Navigation/NavigationMain/NavigationMain";
import NeighbourhoodNav from "@/templates/components/Neighbourhood/NeighbourhoodNav/NeighbourhoodNav";

export default {
    components: {
        NavigationMain,
        NeighbourhoodNav
    },

    computed: {
        ...mapState({
            currentEntry: state => state.global.currentEntry,
            isNavBackgroundLight: state => state.global.isNavBackgroundLight,
            isLightModeOverwritten: state => state.constellations.isLightModeOverwritten, // // on mobile delay background color nav to the near end of the animation
            transitionOutUrl: state => state.global.transitionOutUrl
        }),
        isLightMode() {
            return this.currentEntry ? this.currentEntry.lightMode : false;
        },
        isStoryLightMode() {
            return this.isLightMode && !this.isLightModeOverwritten && !this.isNavBackgroundLight;
        }
        // isStory() {
        //     return this.currentEntry.type && this.currentEntry.type !== "district";
        // }
    }
};
</script>
<style lang="scss" scoped>
.c-nav {
    z-index: 2;
    &--light {
        --color-light: #1c0c2e;
    }
}
</style>
