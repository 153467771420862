<template>
    <li
        class="c-shared-list-item"
        :class="[{ 'c-shared-list-item--current': isCurrent }, `c-shared-list-item--index-${itemIndex}`]"
    >
        <tag
            :is="tag"
            @click="clicked"
            @mouseover="isUserHovering(true)"
            @mouseleave="isUserHovering(false)"
            @focusin="isUserHovering(true)"
            @focusout="isUserHovering(false)"
            class="c-shared-list-item__btn"
            :class="[`c-shared-list-item__btn--${itemIndex}`, { 'c-shared-list-item__btn--clickable': isStory }]"
            :disabled="isCurrent"
        >
            <!-- make a v if v else to display either sibbling either credits -->
            <stories-more-siblings-item
                v-if="isStory"
                :story-index="storyIndex"
                :story="item"
                :is-visible="isVisible"
                :item-index="itemIndex"
                :is-last="isLast"
                :is-current="isCurrent"
                :is-btn-hovered="isBtnHovered"
                :is-button-disabled="isCurrent"
            />
            <credits-list-item
                v-else
                :story-index="storyIndex"
                :story="item"
                :is-visible="isVisible"
                :item-index="itemIndex"
                :is-last="isLast"
                :is-current="isCurrent"
                :is-btn-hovered="isBtnHovered"
            />
        </tag>
        <shared-list-item-line
            v-if="!isLast"
            :current="itemIndex"
            :target="itemIndex + 1"
            :isCurrent="isCurrent"
            :is-rotation-overwritten="isRotationOverwritten"
        />
    </li>
</template>

<script>
import CreditsListItem from "@/templates/components/Credits/CreditsList/CreditsListItem/CreditsListItem";
import SharedListItemLine from "@/templates/components/Shared/SharedList/SharedListItem/SharedListItemLine";
import StoriesMoreSiblingsItem from "@/templates/components/Stories/StoriesMore/StoriesMoreSiblings/StoriesMoreSiblingsItem/StoriesMoreSiblingsItem";

export default {
    components: {
        CreditsListItem,
        SharedListItemLine,
        StoriesMoreSiblingsItem
    },
    props: {
        tag: {
            type: String,
            required: false,
            default: "button"
        },
        item: {
            type: Object,
            required: true
        },
        isCurrent: {
            type: Boolean,
            required: false,
            default: false
        },
        // for the number in the list
        storyIndex: {
            type: Number,
            required: false,
            default: 0
        },
        // for the line only
        itemIndex: {
            type: Number,
            required: false,
            default: 0
        },
        // specific for the first btn. Ensure its triggered during the animation and not on mount
        isAnimationStarted: {
            type: Boolean,
            required: false,
            default: true
        },
        // disable the lime if its the last item
        isLast: {
            type: Boolean,
            required: false,
            default: false
        },
        // animate the text
        isVisible: {
            type: Boolean,
            required: false,
            default: false
        },
        isStory: {
            type: Boolean,
            required: false,
            default: true
        },
        isRotationOverwritten: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            isBtnHovered: false,
            isRedirecting: false
        };
    },
    computed: {
        secondCredit() {
            return this.item.illustrator ? this.item.illustrator : this.item.narrator;
        }
    },
    methods: {
        ////////////////////////////////
        //       START USER CLICKED
        ////////////////////////////////
        // get story details
        clicked() {
            if ((this.isCurrent && this.isRedirecting) || !this.isStory) return;
            // retdirect
            this.redirectToOtherPage();
        },
        redirectToOtherPage() {
            this.isRedirecting = true;
            this.$store.commit("constellations/userGoToOtherStory", this.item.uri);
            this.easeScrollProgress(2000);
        },
        ////////////////////////////////
        //       END USER CLICKED
        ////////////////////////////////

        ////////////////////////////////
        //       START EASE SCROLL PROGRESS
        ////////////////////////////////

        easeScrollProgress(duration) {
            this.$store.commit("constellations/toggleScrollProgressEase", true);
            const easeScrollProgress = setTimeout(() => {
                this.$store.commit("constellations/toggleScrollProgressEase", false);
                clearTimeout(easeScrollProgress);
            }, duration);
        },
        ////////////////////////////////
        //       END EASE SCROLL PROGRESS
        ////////////////////////////////

        isUserHovering(bool) {
            this.isStory ? (this.isBtnHovered = bool) : null;
        }
    }
};
</script>
<style lang="scss" scoped>
.c-shared-list-item {
    --padding-bottom-button: var(--grid-gutter);
    --width-item: auto;

    --rotation: 90deg;

    display: flex;
    flex: 0;
    position: static;

    &--current {
        @include transform(translateY(0px));
        --padding-bottom-button: 0px;

        // this fix misaligments
        --width-item: 150px;
        @media #{md('md')} {
            --width-item: 270px;
        }
    }
    &__btn {
        @include remove-button-style;
        @include transform(translateY(100px)); // animation on visible

        position: relative;
        padding-bottom: var(--padding-bottom-button);
        width: var(--width-item);
        z-index: 1;
        pointer-events: none;

        // animation on mount
        opacity: 0;
        &--clickable {
            cursor: pointer;
            pointer-events: auto;
        }
        .-debug & {
            opacity: 1;
        }
    }
}
</style>
