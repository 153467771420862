<template>
    <li
        class="c-stories-article-illustrated-main-copy-paragraph"
        :class="[
            { 'c-stories-article-illustrated-main-copy-paragraph--last': isLast },
            `c-stories-article-illustrated-main-copy-paragraph--${indexParagraph}`
        ]"
    >
        <div class="c-stories-article-illustrated-main-copy-paragraph__copy | t-cms" v-html="paragraph.body" />
    </li>
</template>

<script>
export default {
    props: {
        paragraph: {
            type: Object,
            required: false,
            default: null
        },
        indexParagraph: {
            type: Number,
            required: false,
            default: 0
        },
        isLast: {
            type: Boolean,
            required: false,
            default: false
        }
    }
};
</script>

<style lang="scss" scoped>
.c-stories-article-illustrated-main-copy-paragraph {
    width: 100%;
    padding-bottom: 50%;

    @media #{md("xs")} {
        // rought screensize - everything at the top
        padding-bottom: calc(100vh - (8rem + 10rem + 2rem + 6rem + 7rem));
        // padding-bottom: 50vh; // alternatively we can do we values if we want to make next text
    }
    &--last {
        display: flex;
        flex-direction: column;
        padding-bottom: 0%;
        margin-bottom: 100vh; // allow the trigger to be at the end of the copy instead of the end of the component
    }

    &__copy {
        --extra-padding: 1rem;
        padding-top: calc(var(--grid-gutter-double) + var(--extra-padding));
        display: block;

        // there is some issue on safari and mobile safari
        .is-safari & {
            --extra-padding: -0.5rem;
        }
        .is-ios &,
        .is-mobile-safari & {
            --extra-padding: -1rem;
        }
    }
    &::v-deep p {
        color: var(--color-light);
    }
}
</style>
