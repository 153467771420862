<template>
    <li class="c-nav-main-titles">
        <ul class="c-nav-main-titles__list">
            <navigation-main-titles-logo />
            <navigation-main-titles-neighbourhood />
        </ul>
    </li>
</template>

<script>
import NavigationMainTitlesLogo from "@/templates/components/Navigation/NavigationMain/NavigationMainTitles/NavigationMainTitlesLogo";
import NavigationMainTitlesNeighbourhood from "@/templates/components/Navigation/NavigationMain/NavigationMainTitles/NavigationMainTitlesNeighbourhood";
export default {
    components: {
        NavigationMainTitlesLogo,
        NavigationMainTitlesNeighbourhood
    }
};
</script>

<style lang="scss" scoped>
.c-nav-main-titles {
    grid-column: 2/3;
    height: 100%;
    display: flex;
    align-content: center;
    justify-content: center;

    &__list {
        @include reset-list;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media #{md('xs')} {
            flex-direction: row;
        }
    }
}
</style>
