<template>
    <div
        class="c-home-navigation-control-cta"
        :class="{
            'c-home-navigation-control-cta--visible': isTitleVisible
        }"
    >
        <div class="c-home-navigation-control-cta__inner">
            <div class="c-home-navigation-control-cta__inner__title">
                <div class="c-home-navigation-control-cta__inner__title__copy">
                    <anim-text
                        v-if="title && isNavigationVisible"
                        :text="title"
                        class=" t-t1"
                        tag="h1"
                        :visible="isTitleVisible"
                        :reveal="isTitleVisible"
                        :chars="true"
                        :word="false"
                        :lines="false"
                        :is-axis-y="true"
                        @completedOut="switchTitle"
                    />
                </div>
            </div>
            <home-navigation-control-cta-btn
                :is-button-visible="isTitleVisible && isNavigationVisible"
                :slug="slides[fallBackCurrent].title"
                ref="ctaBtn"
            />
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import AnimText from "objects/AnimText";
import HomeNavigationControlCtaBtn from "components/Home/HomeNavigation/HomeNavigationControl/HomeNavigationControlCta/HomeNavigationControlCtaBtn";
export default {
    components: {
        AnimText,
        HomeNavigationControlCtaBtn
    },
    props: {
        slides: {
            type: Array,
            required: true
        },
        isNavigationVisible: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    data() {
        return {
            isTitleVisible: false,
            title: null,
            togleTitleTimeOut: null,
            fallBackTitle: null // fixing a console log error
        };
    },
    computed: {
        ...mapState({
            current: state => state.home.current
        }),
        // there is a bug because of how the slider was done, so I need to enter a fall back
        fallBackCurrent() {
            return this.getFallBackCurrentSlide(this.current);
        }
    },
    watch: {
        current() {
            this.toggleTitle(false);
        }
    },
    mounted() {
        this.switchTitle(0);
    },
    beforeDestroy() {
        this.togleTitleTimeOut = null;
    },
    methods: {
        getFallBackCurrentSlide(index) {
            switch (index) {
                case -1:
                    return 5;
                case 6:
                    return 0;
                default:
                    return index;
            }
        },
        toggleTitle(bool) {
            this.isTitleVisible = bool;
        },
        switchTitle() {
            this.title = null;

            this.$nextTick(() => {
                this.udpateCopy(this.current);

                this.toggleTitle(true);
            });
        },
        udpateCopy(index) {
            this.title = this.$t(`neigbourhood.list.${this.slides[index].title}.name`);
        }
    }
};
</script>

<style lang="scss" scoped>
.c-home-navigation-control-cta {
    display: flex;
    width: 100%;
    justify-content: center;
    @include transform(scale(1));

    &__inner {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: var(--grid-gutter);

        &__title {
            @include transition(1s ease-in-out);
            @include transform(scale(0.5));
            .c-home-navigation-control-cta--visible & {
                @include transform(scale(1));
            }
            &__copy {
                @include transition(0.5s ease-in-out);
                opacity: 0;
                text-align: center;
                .c-home-navigation-control-cta--visible & {
                    opacity: 1;
                }
            }
        }
    }
}
</style>
