<template>
    <li class="c-enter-tips-audio-with">
        <ul class="c-enter-tips-audio-with__inner">
            <li class="c-enter-tips-audio-with__inner__list">
                <!-- audio logo -->
                <song-wave />
            </li>
            <li class="c-enter-tips-audio-with__inner__list">
                <!-- audio copy -->
                <p class="c-enter-tips-audio-with__inner__list__copy">{{ $t("enter.tips.audio") }}</p>
            </li>
        </ul>
    </li>
</template>

<script>
import SongWave from "@/templates/components/SongWave";
export default {
    components: {
        SongWave
    }
};
</script>

<style lang="scss" scoped>
.c-enter-tips-audio-with {
    @include transform(translateY(100px));
    opacity: 0;
    grid-row: 1/2;
    display: flex;
    justify-content: center;

    // @media #{md('sm')} {
    //     min-width: 25rem;
    // }

    &__inner {
        @include reset-list;
        @include lightCopy;
        display: grid;
        // flex-direction: column;
        grid-auto-flow: row;
        justify-content: center;
        align-content: center;
        align-items: center;

        gap: var(--grid-gutter-half);
        // max-width: 16rem;
        // @media #{md('sm')} {
        //     max-width: 18rem;
        // }
        p {
            text-align: center;
        }

        &__list {
            display: flex;
            justify-content: center;
        }
    }
    @media #{md('sm')} {
        grid-row: 1/1;
        grid-column: 2/3;
    }
}
</style>
