<template>
    <li class="c-shared-list-item-item-title">
        <anim-text
            :text="title"
            :class="` | t-t${sizeTitle}`"
            :tag="`h${sizeTitle}`"
            :visible="isVisible"
            :chars="true"
            :word="false"
            :lines="false"
            :is-axis-y="true"
        />
    </li>
</template>

<script>
import AnimText from "@/templates/objects/AnimText";
export default {
    components: {
        AnimText
        // Icon
    },
    props: {
        title: {
            type: String,
            required: false
        },
        // animate the text
        isVisible: {
            type: Boolean,
            required: false,
            default: false
        },
        isSmall: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        sizeTitle() {
            return this.isSmall ? "2" : "1";
        }
    }
};
</script>

<style lang="scss" scoped>
.c-shared-list-item-item-title {
    /* not align vertically */
    /* margin-top: -0.4rem; */
    @include transform(translateY(-1rem));
    color: var(--color-light);
    display: flex;
    justify-content: center;

    // color: green;
    // .c-nbhd-story--light & {
    //     color: --color-purple-dark;

    //     color: blue;
    // }
}
</style>
